import SaveIcon from '@mui/icons-material/Save';
import { Button, Checkbox, FormControlLabel, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { ICommonProperties } from '../../../../../../../../../common/properties/ICommonProperties';
import { Colors } from '../../../../../../../../design/color/Colors';
import { RequirementEntityDrawerDomain } from '../../RequirementEntityDrawerDomain';
import { LoadingButton } from '@mui/lab';
import { ContainerContext } from '../../../../../../../../../common/container/ContainerContext';
import { RequirementEntityAdminDomain } from '../../../RequirementEntityAdminDomain';

export interface IFormActionsProperties extends ICommonProperties {
  onSave: any;
  onCancel: any;
  onDelete?: any;
  disabled?: boolean;
  disabledSave?: boolean;
  domain: RequirementEntityDrawerDomain;
}

export const FormActions = observer(
  ({ onCancel, onSave, disabled = false, onDelete = null, domain }: IFormActionsProperties) => {
    const classes = useStyles();
    const container = useContext(ContainerContext);
    const [entityDomain] = useState(new RequirementEntityAdminDomain(domain.layoutDomain, container));
    return (
      <Grid container display={'flex'} justifyContent={'space-between'}>
        <Grid item>
          <RequirementIsActiveCustomField domain={domain} />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            className={classes.actionButton}
            onClick={onCancel}
            disabled={domain.isSaving.entity}
          >
            Отменить
          </Button>
          {onDelete && (
            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={onDelete}
              disabled={disabled}
              style={{ color: Colors.negativePassive }}
            >
              Удалить
            </Button>
          )}
          <LoadingButton
            variant="contained"
            color="secondary"
            className={classes.actionButton}
            startIcon={<SaveIcon />}
            onClick={async () => {
              await onSave();
              if (domain.ui.validationErrors.list.length === 0) {
                await entityDomain.loadData();
                await entityDomain.loadDetail();
                const tempEntities = entityDomain.ui.rows.entities.list;
                await domain.loadDependencies();
                await domain.dataTableDomain.boot();
                domain.dataTableDomain.ui.rows.entities.setList(tempEntities);
                await domain.dataTableDomain.ui.renderTable();
              }
            }}
            loading={domain.isSaving.entity}
          >
            Сохранить
          </LoadingButton>
        </Grid>
      </Grid>
    );
  },
);

const RequirementIsActiveCustomField = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity?.isActive ?? true);

  useEffect(() => {
    setChecked(domain.ui.model.entity?.isActive ?? true);
  }, [domain.ui.model.entity?.isActive]);

  if (domain.ui.model.entity) {
    domain.ui.model.entity.isActive = isChecked;
  }

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isActive = event.target.checked;
  };

  return (
    <div>
      <FormControlLabel
        style={{ width: '100%', padding: '16px', margin: 0 }}
        control={<Checkbox color="success" checked={isChecked} onChange={handleChange} defaultChecked={true} />}
        label="Опубликовано"
      />
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    justifyContent: 'flex-end',
  },
  actionButton: {
    margin: theme.spacing(2),
  },
}));
