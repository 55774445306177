import React from 'react';
import { INotificationModel } from '../../../service/notification/INotificationModel';
import { NotificationText } from '../../../view/user/page/notification/parts/NotificationText';
import { GPBNotificationEvent } from './GpbNotificationEvents'
import { Link, Typography } from '@mui/material';

export const NotificationTextWithExtention = ({ notification }: { notification: INotificationModel }) => {
	//@ts-ignore
	const isGpbNotification = GPBNotificationEvent.GPB_APPLICATION_CHECK === notification.event
	if (isGpbNotification) {
		switch (notification.event as unknown) {
			case GPBNotificationEvent.GPB_APPLICATION_CHECK:
				return <GpbApplicationCheck notification={notification} />;
			default:
				return <Typography>{notification.action}</Typography>;
		}
	} else {
		return (
			<NotificationText notification={notification} />
		)
	}
}

export const GpbApplicationCheck = ({ notification }: { notification: INotificationModel }) => {
	if (notification.data.applicationId && notification.data.applicationName) {
		const url = `application/${notification.data.applicationId}/info`
		return (
			<Typography>
				{replaceSystemNameWithLink(notification.action, `"${notification.data.applicationName}"`, url)}
			</Typography>
		)
	} else {
		return (
			<Typography>
				{notification.action}
			</Typography>
		)
	}
}

function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const replaceSystemNameWithLink = (text, itemName, url) => {
	const regex = new RegExp(escapeRegExp(itemName), 'g'); // Регулярное выражение для поиска всех вхождений systemName
	return text.split(regex).reduce((acc, part, index, array) => (
		<>
			{acc}
			{part}
			{index < array.length - 1 && <Link href={url}>{itemName}</Link>}
		</>
	), null);
};
