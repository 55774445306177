import { Box, Chip, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ITheoryBlock } from '../../../../../../service/unit/interface/IUnitTheory';
import { ITheoryComponent } from '../ITheoryComponent';
import { TheoryComponentList } from '../TheoryComponentList';

export interface ISpecialBlock {
  name: string;
  color: string;
  text: string;
  contentBlocks: ITheoryBlock<ISpecialBlock>[];
}
export const SpecialBlock = observer(({ data }: ITheoryComponent<ISpecialBlock>) => {
  const classes = useStyles();
  return (
    <Box
      className={classes[data.color]}
      sx={{
        padding: '32px',
        borderRadius: '32px',
        position: 'relative',
        width: '100%',
        marginTop: '32px',
        marginBottom: '32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        '& > div': { background: 'transparent', boxShadow: 'none' },
        '& .MuiTableContainer-root': { background: 'transparent', boxShadow: 'none' },
      }}
    >
      <Chip label={data.name} className={'chip'} />
      {data.text && <Typography sx={{ fontSize: '20px', lineHeight: 1.6 }}>{data.text}</Typography>}
      {data.contentBlocks.map((block) => {
        const Element: ITheoryComponent<any> = TheoryComponentList[block.block];
        // @ts-ignore
        return <Element key={block.pseudoId} data={block.data} className="child" isChild />;
      })}
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    background: 'rgba(237, 108, 2, 0.12) !important',
    '& .chip': {
      position: 'absolute',
      top: '-12px',
      left: '40px',
      background: '#ED6C02 ',
      textTransform: 'uppercase',
      padding: '4px 16px',
      maxWidth: 'calc(100% - 80px)',
      height: 'auto',
    },
    '& .chip span': {
      textOverflow: 'unset',
      whiteSpace: 'break-spaces',
    },
  },
  secondary: {
    background: 'rgba(206, 147, 216, 0.12) !important',
    '& .chip ': {
      position: 'absolute',
      top: '-12px',
      left: '40px',
      background: '#7B1FA2 ',
      textTransform: 'uppercase',
      padding: '4px 16px',
      maxWidth: 'calc(100% - 80px)',
    },
    '& .chip span': {
      textOverflow: 'unset',
      whiteSpace: 'break-spaces',
    },
  },
  main: {
    background: 'rgba(25, 118, 210, 0.12) !important',
    '& .chip ': {
      position: 'absolute',
      top: '-12px',
      left: '40px',
      background: 'rgba(25, 118, 210, 1) ',
      textTransform: 'uppercase',
      padding: '4px 16px',
      maxWidth: 'calc(100% - 80px)',
    },
    '& .chip span': {
      textOverflow: 'unset',
      whiteSpace: 'break-spaces',
    },
  },
  success: {
    background: 'rgba(46, 125, 50, 0.12) !important',
    '& .chip ': {
      position: 'absolute',
      top: '-12px',
      left: '40px',
      background: 'rgba(46, 125, 50, 1) ',
      textTransform: 'uppercase',
      padding: '4px 16px',
      maxWidth: 'calc(100% - 80px)',
    },
    '& .chip span': {
      textOverflow: 'unset',
      whiteSpace: 'break-spaces',
    },
  },
  error: {
    background: 'rgba(211, 47, 47, 0.12) !important',
    '& .chip ': {
      position: 'absolute',
      top: '-12px',
      left: '40px',
      background: 'rgba(211, 47, 47, 1) ',
      textTransform: 'uppercase',
      padding: '4px 16px',
      maxWidth: 'calc(100% - 80px)',
    },
    '& .chip span': {
      textOverflow: 'unset',
      whiteSpace: 'break-spaces',
    },
  },
  info: {
    background: 'rgba(2, 136, 209, 0.12) !important',
    '& .chip ': {
      position: 'absolute',
      top: '-12px',
      left: '40px',
      background: 'rgba(2, 136, 209, 1)',
      textTransform: 'uppercase',
      padding: '4px 16px',
      maxWidth: 'calc(100% - 80px)',
    },
    '& .chip span': {
      textOverflow: 'unset',
      whiteSpace: 'break-spaces',
    },
  },
}));
