import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import { ApplicationDetailDomain } from '../../store/ApplicationDetailDomain';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { Colors } from '../../../../../../design/color/Colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ApplicationInfoHistoryDiffText } from './ApplicationInfoHistoryDiffText';
import { ConstructorType, injectValue } from '../../../../../../../common/container/inject';
import { ApplicationHistoryDomain } from './store/ApplicationHistoryDomain';
import { IApplicationModel } from '../../../../../../../service/application/entity/IApplicationModel';
import { IApplicationChanges } from './store/IApplicationChanges';
import { ApplicationInfoReport } from '../report/ApplicationInfoReport';
import { ApplicationProtocolReport } from '../report/ApplicationProtocolReport';
import { ContainerContext } from '../../../../../../../common/container/ContainerContext';
import { ApplicationStatuses, Version } from "../../store/ApplicationDetailUI";

export interface ApplicationInfoHistoryProperties {
  domain: ApplicationDetailDomain;
}

export const removeThirdVesrsionAudit = (renderAuditList: IApplicationModel[]) => {
  return { renderList: renderAuditList, thirdVersionAudits: [] };
}

export const ApplicationInfoHistory = observer(({ domain }: ApplicationInfoHistoryProperties) => {
  const classes = useStyles();
  const [historyDomain] = useState<ApplicationHistoryDomain>(
    new (injectValue<ConstructorType<ApplicationHistoryDomain>>(ApplicationHistoryDomain))(domain),
  );

  const container = useContext(ContainerContext);
  const clearAudits = container.get(removeThirdVesrsionAudit);
  historyDomain.clearThirdVersionAudits = clearAudits;
  const VersionListTextExtended = container.get(VersionListText);

  useEffect(() => {
    historyDomain.boot();
  }, [historyDomain, clearAudits]);
  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        <Grid container>
          <Grid item xs={2.5}>
            <MenuList dense>
              {historyDomain.ui.auditApplications.list.map((application, index) => {
                return (
                  <MenuItem
                    onClick={() => {
                      {
                        historyDomain.selectApplication(application);
                      }
                    }}
                    selected={historyDomain.ui.selectedApplicationsIds.value.includes(application.id || '')}
                  >
                    <VersionListTextExtended application={application} />
                  </MenuItem>
                );
              })}
            </MenuList>
          </Grid>
          <Grid item xs={9.5}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Timeline
                      sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                          flex: 0.2,
                        },
                      }}
                    >
                      {historyDomain.ui.selectedAuditApplication.list.map((application, index) => {
                        const isLatestVersion = historyDomain.ui.auditApplications.list[0]?.id === application?.id;
                        return application ? (
                          <TimelineItem position={'right'}>
                            <TimelineOppositeContent
                              sx={{ m: 'auto 0' }}
                              align="right"
                              variant="body2"
                              color="text.secondary"
                            >
                              <Typography variant="h6">{application?.auditDate?.toLocaleString()}</Typography>
                              <Typography variant="h6">
                                {application.auditUser
                                  ? 'Изменено ' + application?.auditUser?.displayName ||
                                  application?.auditUser?.login ||
                                  application?.auditUser?.email
                                  : ''}
                              </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              {index !== 0 && <TimelineConnector />}
                              {historyDomain.ui.selectedAuditApplication.list.length > 1 && <TimelineDot></TimelineDot>}
                              {index + 1 !== historyDomain.ui.selectedAuditApplication.list.length && (
                                <TimelineConnector />
                              )}
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                              <VersionInfo
                                domain={historyDomain}
                                application={application}
                                isLatestVersion={isLatestVersion}
                              />
                            </TimelineContent>
                          </TimelineItem>
                        ) : (
                          <div></div>
                        );
                      })}
                    </Timeline>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
});

export const VersionListText = observer(({ application }) => {
  const listText = application?.auditDate?.toLocaleString();

  return (<ListItemText style={{ whiteSpace: 'pre' }}>{listText}</ListItemText>)
})

const VersionInfo = observer(
  ({
    domain,
    application,
    isLatestVersion,
  }: {
    domain: ApplicationHistoryDomain;
    application: IApplicationModel;
    isLatestVersion: boolean;
  }) => {
    const changes = domain.ui.auditApplicationChanges.list.find(
      (changes) => changes.application?.id === application?.id,
    );
    const [expanded, setExpanded] = useState<boolean>(false);
    const audit = isLatestVersion
      ? undefined
      : {
        isReplaceId: true,
        auditDateInMS: (application?.auditDate || new Date()).getTime() + 10 * 1000,
      };

    const container = useContext(ContainerContext);
    const ApplicationInfoReportExtended = container.get(ApplicationInfoReport);
    const ExtendeProtocolReport = container.get(ApplicationProtocolReport);
    const versionNumber = application.versionNumber?.split('.');
    const lastApplicationVersion = domain.rootDomain.ui.application.entity.versionNumber?.split('.');
    const currentThirdVersionList = domain.thirdVersionAuditList.value.filter((audit) => {
      const auditVersionNumber = audit.versionNumber?.split('.');
      let isEqualVersions = false;
      if (versionNumber && auditVersionNumber) {
        isEqualVersions = versionNumber[1] === auditVersionNumber[1] &&
          auditVersionNumber[0] === versionNumber[0];
      }
      return isEqualVersions;
    });
    if (lastApplicationVersion && versionNumber) {
      if (lastApplicationVersion[1] === versionNumber[1] && lastApplicationVersion[0] === versionNumber[0]) {
        currentThirdVersionList.unshift(domain.rootDomain.ui.application.entity);
      };
    }
    return changes ? (
      <Grid
        key={`${changes.application?.id}_${changes.compareWithApplicationChanges?.application?.id}`}
        container
        direction="row"
      >
        <Grid container>
          <Grid xs={12}>
            <Accordion defaultExpanded={false} expanded={expanded} onChange={() => setExpanded(!expanded)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                {expanded && (
                  <Grid direction={'row'} container>
                    {changes.isSupport.compareApplicationChanges && (
                      <Typography variant="body2" color="text.secondary">
                        <ApplicationInfoHistoryDiffText
                          oldValue={'Название: ' + changes?.compareWithApplicationChanges?.application?.name || ''}
                          newValue={'Название: ' + changes?.application?.name || ''}
                        />
                      </Typography>
                    )}
                    {!changes.isSupport.compareApplicationChanges && (
                      <Typography gutterBottom variant="body2">
                        {'Название: ' + changes.application?.name}
                      </Typography>
                    )}
                  </Grid>
                )}
                {!expanded && (
                  <Grid container>
                    <Grid xs={12}>
                      <Grid direction={'row'} container>
                        {changes.isSupport.compareApplicationChanges && (
                          <Typography variant="body2" color="text.secondary">
                            <ApplicationInfoHistoryDiffText
                              oldValue={'Название: ' + changes?.compareWithApplicationChanges?.application?.name || ''}
                              newValue={'Название: ' + changes?.application?.name || ''}
                            />
                          </Typography>
                        )}
                        {!changes.isSupport.compareApplicationChanges && (
                          <Typography gutterBottom variant="body2">
                            {'Название: ' + changes.application?.name}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <Grid direction={'row'} container>
                        <Grid item>Характеристики: {changes.counters.specifications.saved}</Grid>
                        {changes.counters.specifications.newest > 0 && (
                          <Grid style={{ color: Colors.positivePassiveOpacity('0.5') }} item>
                            /{changes.counters.specifications.newest}
                          </Grid>
                        )}
                        {changes.counters.specifications.removed > 0 && (
                          <Grid style={{ color: Colors.negativePassiveOpacity('0.5') }} item>
                            /{changes.counters.specifications.removed}
                          </Grid>
                        )}
                      </Grid>
                      <Grid direction={'row'} container>
                        <Grid item>Текстовые поля: {changes.counters.customFields.saved}</Grid>
                        {changes.counters.customFields.newest > 0 && (
                          <Grid style={{ color: Colors.positivePassiveOpacity('0.5') }} item>
                            /{changes.counters.customFields.newest}
                          </Grid>
                        )}
                        {changes.counters.customFields.removed > 0 && (
                          <Grid style={{ color: Colors.negativePassiveOpacity('0.5') }} item>
                            /{changes.counters.customFields.removed}
                          </Grid>
                        )}
                        {changes.counters.customFields.edited > 0 && (
                          <Grid style={{ color: Colors.editOrangeOpacity('0.5') }} item>
                            /{changes.counters.customFields.edited}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <Grid direction={'row'} container>
                        <Grid item>Требования: {changes.counters.requirements.saved}</Grid>
                        {changes.counters.requirements.newest > 0 && (
                          <Grid style={{ color: Colors.positivePassiveOpacity('0.5') }} item>
                            /{changes.counters.requirements.newest}
                          </Grid>
                        )}
                        {changes.counters.requirements.removed > 0 && (
                          <Grid style={{ color: Colors.negativePassiveOpacity('0.5') }} item>
                            /{changes.counters.requirements.removed}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <ApplicationInfoReportExtended
                  domain={domain.rootDomain}
                  audit={audit}
                  application={application}
                />
                <ExtendeProtocolReport
                  domain={domain.rootDomain}
                  audit={audit}
                  application={application}
                  isLatestVersion={isLatestVersion}
                  displayedAuditList={currentThirdVersionList}
                  fullAuditList={domain.ui.auditApplications.list}
                />
                {changes.customInformationChanges.length > 0 ? (
                  <TextFields changes={changes} domain={domain} application={application} />
                ) : (
                  <Fragment></Fragment>
                )}
                {changes.specificationsChanges.length > 0 ? (
                  <Specifications changes={changes} domain={domain} application={application} />
                ) : (
                  <Fragment></Fragment>
                )}
                {changes.requirementsChanges.length > 0 ? (
                  <Requirements changes={changes} domain={domain} application={application} />
                ) : (
                  <Fragment></Fragment>
                )}

                {/*    <MainInfo changes={changes} domain={domain} application={application}/>*/}
                {/*    {changes.newRequirements.length > 0 ?*/}
                {/*        <NewRequirements changes={changes} domain={domain} application={application}/> :*/}
                {/*        <Fragment></Fragment>}*/}
                {/*    {changes.newSpecifications.length > 0 ?*/}
                {/*        <NewSpecifications changes={changes} domain={domain} application={application}/> :*/}
                {/*        <Fragment></Fragment>}*/}
                {/*    {changes.removedRequirements.length > 0 ?*/}
                {/*        <RemovedRequirements changes={changes} domain={domain} application={application}/> :*/}
                {/*        <Fragment></Fragment>}*/}
                {/*    {changes.removedSpecifications.length > 0 ?*/}
                {/*        <RemovedSpecifications changes={changes} domain={domain} application={application}/> :*/}
                {/*        <Fragment></Fragment>}*/}
                {/*    {changes.changedRequirements.length > 0 ?*/}
                {/*        <ChangedRequirements changes={changes} domain={domain} application={application}/> :*/}
                {/*        <Fragment></Fragment>}*/}
                {/*    {changes.changedSpecifications.length > 0 ?*/}
                {/*        <ChangedSpecifications changes={changes} domain={domain} application={application}/> :*/}
                {/*        <Fragment></Fragment>}*/}
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid xs={12}>
            <Divider style={{ width: '100%' }}></Divider>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Fragment></Fragment>
    );
  },
);

const Requirements = observer(
  ({
    changes,
    domain,
    application,
  }: {
    changes: IApplicationChanges;
    domain: ApplicationHistoryDomain;
    application: IApplicationModel;
  }) => {
    return (
      <Grid container direction={'row'} spacing={1}>
        <Grid style={{ marginTop: 50 }} item xs={12}>
          <Typography variant="h5" color="text.secondary">
            Требования:
          </Typography>
        </Grid>
        {changes.requirementsChanges.map((changes) => {
          const backgroundColor = changes.isNew
            ? Colors.positivePassiveOpacity('0.5')
            : changes.isRemoved
              ? Colors.negativePassiveOpacity('0.5')
              : null;
          return (
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 345 }} style={{ ...(backgroundColor && { backgroundColor }) }}>
                {/*<CardActionArea>*/}
                <CardContent>
                  {changes.isChanged && (
                    <Typography variant="h6" color="text.secondary">
                      <ApplicationInfoHistoryDiffText
                        oldValue={changes?.compareWithRequirementChanges?.requirement?.shortName || ''}
                        newValue={changes?.requirement?.shortName || ''}
                      />
                    </Typography>
                  )}
                  {!changes.isChanged && (
                    <Typography gutterBottom variant="h6">
                      {changes.requirement.shortName}
                    </Typography>
                  )}
                  {changes.isChanged && (
                    <Typography variant="body2" color="text.secondary">
                      <ApplicationInfoHistoryDiffText
                        oldValue={changes?.compareWithRequirementChanges?.requirement?.description || ''}
                        newValue={changes?.requirement?.description || ''}
                      />
                    </Typography>
                  )}
                  {!changes.isChanged && (
                    <Typography gutterBottom variant="body2">
                      {changes.requirement.description}
                    </Typography>
                  )}
                </CardContent>
                {/*</CardActionArea>*/}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  },
);

const Specifications = observer(
  ({
    changes,
    domain,
    application,
  }: {
    changes: IApplicationChanges;
    domain: ApplicationHistoryDomain;
    application: IApplicationModel;
  }) => {
    return (
      <Grid container direction={'row'} spacing={1}>
        <Grid style={{ marginTop: 50 }} item xs={12}>
          <Typography variant="h5" color="text.secondary">
            Характеристики:
          </Typography>
          <br />
        </Grid>
        {changes.specificationsChanges.map((changes) => {
          const backgroundColor = changes.isNew
            ? Colors.positivePassiveOpacity('0.5')
            : changes.isRemoved
              ? Colors.negativePassiveOpacity('0.5')
              : null;
          return (
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 345 }} style={{ ...(backgroundColor && { backgroundColor }) }}>
                {/*<CardActionArea>*/}
                <CardContent>
                  {changes.isChanged && (
                    <Typography variant="h6" color="text.secondary">
                      <ApplicationInfoHistoryDiffText
                        oldValue={changes?.compareWithSpecificationChanges?.specification?.name || ''}
                        newValue={changes?.specification?.name || ''}
                      />
                    </Typography>
                  )}
                  {!changes.isChanged && (
                    <Typography gutterBottom variant="h6">
                      {changes.specification.name}
                    </Typography>
                  )}
                  {changes.isChanged && (
                    <Typography variant="body2" color="text.secondary">
                      <ApplicationInfoHistoryDiffText
                        oldValue={changes?.compareWithSpecificationChanges?.specification?.description || ''}
                        newValue={changes?.specification?.description || ''}
                      />
                    </Typography>
                  )}
                  {!changes.isChanged && (
                    <Typography gutterBottom variant="body2">
                      {changes.specification.description}
                    </Typography>
                  )}
                </CardContent>
                {/*</CardActionArea>*/}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  },
);

const TextFields = observer(
  ({ changes }: { changes: IApplicationChanges; domain: ApplicationHistoryDomain; application: IApplicationModel }) => {
    return (
      <Grid container direction={'row'} spacing={1}>
        <Grid style={{ marginTop: 50 }} item xs={12}>
          <Typography variant="h5" color="text.secondary">
            Текстовые поля:
          </Typography>
          <br />
        </Grid>
        {changes.customInformationChanges.map((changes) => {
          const backgroundColor = Colors.editNeutralOpacity('0.5');
          return (
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 345 }} style={{ ...(changes?.isChanged && { backgroundColor }) }}>
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    {changes?.customField?.fieldName}
                  </Typography>
                  {changes?.isChanged && (
                    <Typography variant="body2" color="text.secondary">
                      <ApplicationInfoHistoryDiffText
                        oldValue={changes?.customFieldBefore?.value || ''}
                        newValue={changes?.customField?.value || ''}
                      />
                    </Typography>
                  )}
                  {!changes?.isChanged && (
                    <Typography gutterBottom variant="body2">
                      {changes?.customField?.value}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  paper: {
    minHeight: 500,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));
