import { FormControlLabel, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { AutocompleteSelectItemType, FormMultiAutocomplete } from '../../../../../../design/form/FormMultiAutocomplete';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { SpecificationCategoryModalDomain } from './SpecificationCategoryModalDomain';

export interface ISpecificationEntityAdminTableProperties {
  domain: SpecificationCategoryModalDomain;
  dataCy?: string;
}
export const SpecificationCategoryModal = observer(({ domain, dataCy }: ISpecificationEntityAdminTableProperties) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

  const getSpecificationsValues = () => {
    const items: any[] = [];
    for (let category of domain.ui.categories.list) {
      const specifications = domain.ui.entities.list.filter(
        (specification) => specification.categoryId === category.id,
      );
      items.push({
        name: `Категория - ${category.name}`,
        value: category.id,
        disabled: true,
        types: [AutocompleteSelectItemType.noSelection],
      });
      items.push(...specifications.map((specification) => ({ value: specification.id, name: specification.name })));
    }

    return items;
  };

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isActive = event.target.checked;
  };

  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
    setChecked(domain.ui.model.entity.isActive);
  }, [domain]);

  return (
    <FormModalContainer onClose={domain.onCancelUpdates} dataCy={`${domain.layoutDomain.ui.pageTitle.value}категорию`}>
      <FormTextField
        errorMessage={domain.getValidationErrorFor('name')?.message}
        value={domain.ui.model.entity.name}
        onChange={(newValue: any) => {
          domain.ui.model.entity.name = newValue;
        }}
        label="Название"
        required={true}
        inputProps={{
          'data-cy': 'spec-title'
        }}
      />

      <FormTextArea
        errorMessage={domain.getValidationErrorFor('description')?.message}
        value={domain.ui.model.entity.description}
        onChange={domain.getUpdateFieldHandler('description')}
        label="Описание"
        required={true}
        dataCy='spec-description'
      />

      <FormTextField
        type="text"
        errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
        value={domain.ui.model.entity.orderIndex?.toString()}
        onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
        label="Порядок отображения"
        dataCy='spec-order-number'
        inputProps={{ maxLength: 9 }}
      />

      <FormMultiAutocomplete
        errorMessage={domain.getValidationErrorFor('conditionMap')?.message}
        label="Показывать если есть значения"
        selected={domain.ui.model.entity.conditionsMap?.useIfSpecificationEntitiesIds || []}
        key={'useIfSpecificationEntitiesIds'}
        onChangeSelect={(newValue: any) => {
          if (!domain.ui.model.entity.conditionsMap) {
            domain.ui.model.entity.conditionsMap = {
              useIfSpecificationEntitiesIds: [],
              useIfNotSpecificationEntitiesIds: [],
            };
          }
          domain.ui.model.entity.conditionsMap.useIfSpecificationEntitiesIds = newValue.map((item) => item.value);
        }}
        values={getSpecificationsValues()}
        dataCy=''
      />

      <FormMultiAutocomplete
        errorMessage={domain.getValidationErrorFor('conditionMap')?.message}
        label="Показывать если нет значений"
        key={'useIfNotSpecificationEntitiesIds'}
        selected={domain.ui.model.entity.conditionsMap?.useIfNotSpecificationEntitiesIds || []}
        onChangeSelect={(newValue: any) => {
          if (!domain.ui.model.entity.conditionsMap) {
            domain.ui.model.entity.conditionsMap = {
              useIfSpecificationEntitiesIds: [],
              useIfNotSpecificationEntitiesIds: [],
            };
          }
          domain.ui.model.entity.conditionsMap.useIfNotSpecificationEntitiesIds = newValue.map((item) => item.value);
        }}
        values={getSpecificationsValues()}
        dataCy=''
      />

      <FormControlLabel
        style={{ width: '100%', padding: '16px', margin: 0 }}
        control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
        label="Статус"
      />
      <FormActions
        onSave={() => {
          domain.onSaveUpdates();
        }}
        onCancel={() => {
          domain.onCancelUpdates();
        }}
      />
    </FormModalContainer>
  );
});
