import { blueGrey, deepOrange, green, grey, lightGreen, orange, red } from '@mui/material/colors';

export class Colors {
  static positiveActive = green[500];
  static negativeActive = red[500];
  static warningActive = orange[500];
  static mainActive = blueGrey[50];
  static negativePassive = deepOrange[200];
  static negativePassiveText = '2b0000';
  static mainPassive = blueGrey[900];
  static positivePassive = lightGreen[200];
  static tableRowDisabled = grey[700];
  static detailsPanel = (opacity: string = '1') => `rgb(80, 80, 80, ${opacity})`;
  static selectedTabs = (opacity: string = '1') => `rgb(144,202,249,${opacity})`;

  static leftMenu = '#29363C';

  static blackOpacity = (opacity: string = '1') => `rgba(255, 255, 255, ${opacity})`;
  static negativeActiveOpacity = (opacity: string = '1') => `rgb(244, 67, 54, ${opacity})`;

  static negativePassiveOpacity = (opacity: string = '1') => `rgb(255, 171, 145, ${opacity})`;
  static positivePassiveOpacity = (opacity: string = '1') => `rgb(197, 225, 165, ${opacity})`;
  static editNeutralOpacity = (opacity: string = '1') => `rgb(67, 78, 84, ${opacity})`;
  static editOrangeOpacity = (opacity: string = '1') => `rgb(255, 255, 0, ${opacity})`;
  static newestActiveOpacity = (opacity: string = '1') => `rgb(33, 150, 243, ${opacity})`;
  static greenButtonOpacity = (opacity: string = '1') => `rgb(76,175,90,${opacity})`;
  static redButtonOpacity = (opacity: string = '1') => `rgb(225,0,0,${opacity})`;

  static rgbaOpacityTemplate = (
    template: string | null | undefined,
    opacity: string = '1',
    defaultColor = 'rgba(255, 255, 255, 0.16)',
  ) => ((template?.length || 0) > 0 ? template?.replace('?', opacity) : defaultColor);
}
