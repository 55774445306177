import { Search } from '@mui/icons-material';
import { Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { UserRatingTable } from './parts/UserRatingTable';
import { IUserRatingDomain } from './store/IUserRatingDomain';
import { UserRatingDomain } from './store/UserRatingDomain';
import { useI18n } from '../../../../../../application/language/useI18n';

export interface IUserRatingPage {
  layoutDomain: IMainLayoutDomainStore;
  includedTeam?: string[];
}
export const UserRating = observer(({ layoutDomain, includedTeam }: IUserRatingPage) => {
  const [userRatingDomain] = useState<IUserRatingDomain>(new UserRatingDomain(layoutDomain));
  const i18n = useI18n();

  useEffect(() => {
    userRatingDomain.boot(includedTeam);
  }, [userRatingDomain]);

  return (
    <>
      {userRatingDomain.ui.isLoading.value ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', marginTop: '48px' }}>
          <Box sx={{ display: 'flex', gap: '24px', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <TextField
              onChange={(event) => {
                userRatingDomain.ui.searchText.setValue(event.target.value);
                userRatingDomain.fullTextSearch();
              }}
              variant="standard"
              sx={{ flex: '1' }}
              label={i18n.translate('userRating.usersProjectRole')}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <Search />
                  </IconButton>
                ),
              }}
            />
            <FormControl variant="standard" sx={{ flex: '1' }}>
              <InputLabel>{i18n.translate('userRating.level')}</InputLabel>
              <Select
                defaultValue={'champions'}
                onChange={(event) => {
                  userRatingDomain.ui.filterType.setValue(event.target.value);
                  userRatingDomain.setFilter(userRatingDomain.ui.usersRatingFiltredList.list);
                }}
              >
                <MenuItem value={'champions'}>{i18n.translate('userRating.championsFirst')}</MenuItem>
                <MenuItem value={'newbies'}>{i18n.translate('userRating.newFirst')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <UserRatingTable
            style={{
              height: 'calc(100vh - 340px)',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
            rows={userRatingDomain.ui.usersRatingFiltredList.list}
            // TODO: раскомментировать после того, как будет обновлен дизайн карточки юзера
            // rowAction={userRatingDomain.onTableRedirect}
          />
        </Box>
      )}
    </>
  );
});
