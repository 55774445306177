import { MoreVert } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { OverflownContent } from '../../LearningUnitPreviewPage/shared/OverflownContent';
import { useI18n } from '../../../../../../../application/language/useI18n';

type Props = {
  unit: IUnitModel;
  handleEdit: (unitId: string) => void;
  handleDelete: (unitId: string) => void;
  handlePublish: (unitId: string) => void;
  handleUnpublish: (unitId: string) => void;
};

export const UnitCardHeader = observer(({ unit, handleEdit, handleDelete, handlePublish, handleUnpublish }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', gap: 1 }}>
      <OverflownContent contentClasses={classes.groupTitle}>{unit.settings.titleHeading}</OverflownContent>
      <Box sx={{ display: 'flex', alignItems: 'center  ' }}>
        {unit.isPublished ? (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              background: '#388E3C',
              height: 'fit-content',
              px: 2,
              py: 0.5,
              borderRadius: 4,
            }}
          >
            <CheckIcon />
            <Box sx={{ fontWeight: 600 }}>{i18n.translate('phrases.published')}</Box>
          </Box>
        ) : (
          <Button
            sx={{ fontWeight: 600 }}
            onClick={async (event) => {
              await handlePublish(unit.id);
            }}
          >
            {i18n.translate('phrases.publish')?.toUpperCase()}
          </Button>
        )}
        <IconButton
          sx={{ color: 'rgba(255,255,255,0.56)' }}
          onClick={(event) => {
            event.stopPropagation();
            handleClick(event);
          }}
        >
          <MoreVert />
        </IconButton>
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => handleClose(event)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {unit.isPublished && (
          <MenuItem
            onClick={async (event) => {
              handleClose(event);
              await handleUnpublish(unit.id);
            }}
          >
            {i18n.translate('phrases.unpublish')}
          </MenuItem>
        )}
        <MenuItem
          onClick={(event) => {
            handleClose(event);
            handleEdit(unit.id);
          }}
        >
          {i18n.translate('phrases.edit')}
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            handleClose(event);
            handleDelete(unit.id);
          }}
        >
          {i18n.translate('phrases.delete')}
        </MenuItem>
      </Menu>
    </Box>
  );
});

const useStyles = makeStyles(() => ({
  groupTitle: {
    fontWeight: '600',
    fontSize: '20px',
    cursor: 'pointer',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    lineClamp: 2,
  },
}));
