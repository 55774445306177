import React from 'react';

import { inject } from '../../../../../../../../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../../../../../../../../service/route/IRouterService';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../../../service/LearningRootService';
import { FinalResultsVerbose } from '../../../../../../shared/components/FinalResultsVerbose/FinalResultsVerbose';
import { IFinalResultsValues } from '../models/IFinalResultsValues';
import { IPassingFinalResultsDomain } from './IPassingFinalResultsDomain';
import { PassingFinalResultsUI } from './PassingFinalResultsUI';

const getResultStatus = (value, isPassed) =>
  !isPassed ? 'failure' : value >= 0.7 ? 'success' : value < 0.7 && value >= 0.5 ? 'neutral' : 'failure';

export class PassingFinalResultsDomain implements IPassingFinalResultsDomain {
  constructor(
    public ui = new PassingFinalResultsUI(),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
  ) {}
  redirect = (page: string) => {};
  redirectInNavigation = (page: string) => {};

  boot = async () => {
    const currentUnitResult = await this.learningRootService.unitResult.search({
      filter: [
        { fieldName: 'userId', type: 'equal', value: this.ui.layoutDomain.entity.ui.activeUser.entity.id },
        { fieldName: 'unitId', type: 'equal', value: this.ui.passingId.value },
      ],
    });

    const currentUnit = await this.learningRootService.unit.getById(this.ui.passingId.value);

    const resultsValues: IFinalResultsValues = {
      quizResult: currentUnitResult.data[0].quizResult,
      theoryResult: currentUnitResult.data[0].theoryResult,
      practiceResult: currentUnitResult.data[0].practiceResult,
      totalUnitResult: currentUnitResult.data[0].totalUnitResult,
    };

    const resultPercentage = currentUnitResult.data[0].totalUnitResult / currentUnit.score;
    const correctQuizAnswersCount = resultsValues?.quizResult?.questions?.filter((item) => item.result).length || 0;
    const correctPracticeAnswersCount = resultsValues?.practiceResult?.tasks?.filter((item) => item.result).length || 0;
    const correctQuizMaximumScore = currentUnit?.quiz?.isEnabled ? currentUnit?.quiz?.questions?.length : 0;
    const correctPracticeMaximumScore = currentUnit?.practice?.isEnabled ? currentUnit?.practice?.tasks?.length : 0;

    this.ui.stepContent.setList([
      {
        component: (
          <FinalResultsVerbose
            finalResultsVerbose={
              this.ui.results?.entity?.[getResultStatus(resultPercentage, currentUnitResult.data[0].isPassed)]
            }
            correctQuizMaximumScore={correctQuizMaximumScore}
            correctPracticeMaximumScore={correctPracticeMaximumScore}
            correctQuizAnswersCount={correctQuizAnswersCount}
            correctPracticeAnswersCount={correctPracticeAnswersCount}
            maximumScore={currentUnit.score}
            totalUnitResult={resultsValues.totalUnitResult}
          />
        ),
        isEneble: true,
      },
    ]);
  };

  onRedirectTo = () => {
    this.router.goTo(`/learning/user`);
  };
}
