import wretch from 'wretch';

import { IQuestionsQuizResult, ITasksQuizResult, IUnitResultModel } from './IUnitResultModel';
import { LearningCoreRestCRUDService } from '../common/LearningCoreRestCRUDService';
import { IUnitResultService } from './IUnitResultService';

wretch().errorType('json');

export class RestUnitResultService extends LearningCoreRestCRUDService<IUnitResultModel> implements IUnitResultService{
  getEndpoint(): string {
    const url = this.learningConfig.url.api.learning;
    return `${url}/unitResult`;
  }

  async updateManyModels(entitiesForUpdate: { unitResults: IUnitResultModel[]; userId: string }): Promise<any> {
    await this.request()
      .url(`${this.getEndpoint()}/updateMany`)
      .put(entitiesForUpdate)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);


  }
  async passTheory(body:{unitResultId:string}): Promise<void> {
    await this.request()
      .url(`${this.getEndpoint()}/theory/pass`)
      .post(body)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
  }

  async passQuiz(body:{unitResultId:string,questions:IQuestionsQuizResult[]}): Promise<void> {
    await this.request()
      .url(`${this.getEndpoint()}/quiz/pass`)
      .post(body)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
  }

  async passPractice( body: {unitResultId:string ,tasks: ITasksQuizResult[]; pickedLang: string}): Promise<void> {
    await this.request()
      .url(`${this.getEndpoint()}/practice/pass`)
      .post(body)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
  }

  async finishUnit(body:{unitResultId:string}):Promise<void> {
    await this.request()
    .url(`${this.getEndpoint()}/finish/unit`)
    .post(body)
    .json<{state:string,id:string}>()
    .catch(this.handleError)
  }


}
