import { GetApp } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { useI18n } from '../../../../../../application/language/useI18n';
import { downloadCSV, formatDataForCSV } from '../utils/useGetDataForCSV';
import { useGetProjectsWithUnitsStats } from '../utils/useGetProjectsWithUnitsStats';
import { StudyResultsDashboard } from './index';

export const StudyDashboardWrapper = observer(({ layoutDomain }) => {
  const { translate } = useI18n();
  const { projectsWithUnitsStats, isProjectsWithUserStatsLoading } = useGetProjectsWithUnitsStats(layoutDomain);

  const handleExportClick = () => {
    if (!isProjectsWithUserStatsLoading.value) {
      const formattedData = formatDataForCSV(projectsWithUnitsStats);
      downloadCSV(formattedData);
    }
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" startIcon={<GetApp />} onClick={handleExportClick}>
          {translate('analytics.exportReports')?.toUpperCase()}
        </Button>
      </Box>
      <StudyResultsDashboard layoutDomain={layoutDomain} />
    </Box>
  );
});
