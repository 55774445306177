import { IApplicationModel } from "../../../../service/application/entity/IApplicationModel";

export const removeThirdVesrsionAuditExtend = (renderAuditList: IApplicationModel[]) => {
  let thirdVersionAudits: IApplicationModel[] = [];
  const firstElementVersion = renderAuditList[0].versionNumber;
  const elementPositionsToRemove: number[] = [];

  let currentMajorVersion = firstElementVersion?.split('.')[0];
  let isChangedSecondVersion = Number(firstElementVersion?.split('.')[1]) > 0 ? true : false;
  let prevAudit = renderAuditList[0];

  for (let i = 1; i < renderAuditList.length - 1; i++) {
    const audit = renderAuditList[i];
    const version = audit.versionNumber?.split('.');
    const prevAuditVersion = prevAudit.versionNumber?.split('.');

    if (version && prevAuditVersion) {
      if (+version[1] !== 0) {
        isChangedSecondVersion = true;
      }
      if (isChangedSecondVersion && version[0] === currentMajorVersion && +version[1] === 0) {
        elementPositionsToRemove.push(i);
      }
      if (prevAudit.versionNumber === audit.versionNumber && (i >= 2)) {
        if (renderAuditList[i - 2].versionNumber?.split('.')[0] === version[0]) {
          elementPositionsToRemove.push(i);
        }
      }
      if (currentMajorVersion !== version[0]) {
        currentMajorVersion = version[0];
        if (+version[1] !== 0) {
          isChangedSecondVersion = true;
        } else {
          isChangedSecondVersion = false;
        }
      }
    }

    prevAudit = audit;
  };
  const uniqueIterators = [...new Set(elementPositionsToRemove)];
  uniqueIterators.reverse().forEach((item) => {
    thirdVersionAudits = [...thirdVersionAudits, renderAuditList[item]];
    renderAuditList.splice(item, 1);
  });
  return { renderList: renderAuditList, thirdVersionAudits: thirdVersionAudits };
}