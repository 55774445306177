import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { PassingQuizAccordionContent } from '../../../../parts/quiz/components/PassingQuizAccordion';
import { PassingContext } from '../../../../PassingContext';
import { PassingSidebarAccordion } from '../PassingSidebarAccordion';
import { useI18n } from '../../../../../../../../../../application/language/useI18n';

export const PassingSidebarQuiz = observer(() => {
  const {translate} = useI18n()
  const { passingDomain, quizDomain, redirectToStep } = useContext(PassingContext);

  if (!quizDomain.ui.isEnebled.value) {
    return <></>;
  }

  return (
    <PassingSidebarAccordion
      onChange={passingDomain.quizDomain.expandHandler}
      isExpanded={passingDomain.quizDomain.ui.isExpanded.value}
      onClick={(e) => {
        redirectToStep(e, 'quiz');
      }}
      title={translate('phrases.quiz')}
      timeLimitLabel={`${passingDomain.quizDomain.ui.passageTime.entity} ${translate('phrases.min')}`}
    >
      {passingDomain.quizDomain.ui.passingQuestionList.list.map((question, index) => {
        const currentAnswer = quizDomain.ui.currentUnitResult.value?.quizResult?.questions.filter((answer) => {
          return answer.pseudoId === question.pseudoId;
        })[0];
        return (
          <PassingQuizAccordionContent
            key={index}
            question={question}
            answer={currentAnswer}
            index={index}
            navigationRedirect={passingDomain.quizDomain.navigationRedirect}
            isEbaleToCheck={passingDomain.quizDomain.ui.isEbaleToCheck.value}
            isEbaleToRePass={passingDomain.quizDomain.ui.isEbaleToRePass.value}
          />
        );
      })}
    </PassingSidebarAccordion>
  );
});
