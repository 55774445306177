import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { inject } from '../../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../../service/route/IRouterService';
import { UserSystemRoleModelPermissionMap } from '../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { LearningAdminPage } from '../../learning/LearningAdminPage';

export interface IASMLearningPageProps {
  layoutDomain: IMainLayoutDomainStore;
}

export const ASMLearningPage = observer(({ layoutDomain }: IASMLearningPageProps) => {
  const router = inject<IRouterService>(RouterServiceToken);
  useEffect(() => {
    const isAdmin = layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);
    router.goTo(isAdmin ? '/learning/admin' : '/learning/user');
    layoutDomain.isSelected.setValue('learning');
  }, []);
  return <LearningAdminPage layoutDomain={layoutDomain} />;
});
