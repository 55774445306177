export enum RequirementType {
  system = 'system',
  local = 'local',
}

export enum RequirementTemplateType {
  custom = 'custom',
  fromRequirement = 'fromRequirement',
  fromVendor = 'fromVendor',
}

export enum RequirementCreationType {
  saved = 'saved',
  updated = 'updated',
  new = 'new',
}

export const RequirementCreationTypeNames: { [key in RequirementCreationType]: string } = {
  [RequirementCreationType.saved]: 'Верифицирован',
  [RequirementCreationType.updated]: 'Обновлен',
  [RequirementCreationType.new]: 'Новый',
};

export const getRequirementCreationTypeName = (type: RequirementCreationType): string => {
  return RequirementCreationTypeNames[type];
}