export const getLocaleByCount = (number, locales) => {
    const n = Math.abs(number) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
      return `${number} ${locales[2]}`;
    }
    if (n1 > 1 && n1 < 5) {
      return `${number} ${locales[1]}`;
    }
    if (n1 === 1) {
      return `${number} ${locales[0]}`;
    }
    return `${number} ${locales[2]}`;
  };
  