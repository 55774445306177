export const languagesList = [
  { name: 'JavaScript', value: 'javascript' },
  { name: 'HTML', value: 'html' },
  { name: 'PHP', value: 'php' },
  { name: 'JAVA', value: 'java' },
  { name: 'C#', value: 'csharp' },
  { name: 'XML', value: 'xml' },
  { name: 'Python', value: 'python' },
  { name: 'YAML', value: 'yaml' },
  { name: 'Plain Text', value: 'plaintext' },
  { name: 'Docker', value: 'dockerfile' },
  { name: 'Bash', value: 'bat' },
  { name: 'CSS', value: 'css' },
  { name: 'SQL', value: 'mysql' },
  { name: 'GO', value: 'go' },
];

export const replaceValueToName = (value: string) => {
  const displayName = languagesList.find((lang) => lang.value === value);
  return displayName?.name;
};
