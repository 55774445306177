import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent, useContext } from 'react';

import { ContainerContext } from '../../../../../../common/container/ContainerContext';
import { UserProjectItemView } from '../store/model/UserProjectItemView';
import { ProjectListDomain } from '../store/ProjectListDomain';
import { UserProjectTableExtensions } from './extension/UserProjectTableExtensions';
import { UserProjectTableRow } from './UserProjectTableRow';
import { useI18n } from '../../../../../../application/language/useI18n';

export interface UserProjectTableProperties {
  userProjectsData: UserProjectsView;
  domain: ProjectListDomain;
}

export interface UserProjectsView {
  userProjects: UserProjectItemView[];
}

export const UserProjectTable: FunctionComponent<UserProjectTableProperties> = ({ userProjectsData, domain }) => {
  const {translate} = useI18n();
  const classes = useStyles();
  const userProjectTableExtensions = useContext(ContainerContext).get(UserProjectTableExtensions);
  return (
    <TableContainer>
      <Table size={'small'} className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {domain.ui.tableSettings.value.showName && <TableCell className={classes.projectHeader}>{translate('projectPage.userProjectTable.project')}</TableCell>}
            {domain.ui.tableSettings.value.showSystemsCount && (
              <TableCell className={classes.header} align="right">
                {translate('projectPage.userProjectTable.systems')}
              </TableCell>
            )}
            {domain.ui.tableSettings.value.showRequirementsCount && (
              <TableCell className={classes.header} align="right">
                {translate('projectPage.userProjectTable.requirements')}
              </TableCell>
            )}
            {domain.ui.tableSettings.value.showLastActive && (
              <TableCell align="right" className={classes.stateHeader}>
                {translate('projectPage.userProjectTable.lastActivity')}
              </TableCell>
            )}
            {userProjectTableExtensions.map((Extension) => (
              <Extension.HeaderComponent domain={domain} userProjectsData={userProjectsData} />
            ))}
            <TableCell align="right" className={classes.actionHeader}>
              <div className={classes.actionHeader}>{translate('projectPage.userProjectTable.actions')}</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userProjectsData.userProjects.map((userProject) => (
            <UserProjectTableRow domain={domain} key={userProject.id} item={userProject} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: 'auto',
    tableLayout: 'auto',
  },
  header: {
    fontWeight: 'bold',
  },
  projectHeader: {
    fontWeight: 'bold',
    paddingLeft: '30px',
  },
  actionHeader: {
    paddingRight: '12px',
    fontWeight: 'bold',
  },
  stateHeader: {
    width: '240px',
    fontWeight: 'bold',
  },
}));
