import { extensionContainer } from '../../../../../common/container/ContainerContext';
import { ILocalization, ILocalizationToken } from '../../../application/language/ILocalization';
import { BlockType, ITheoryBlock } from '../../../service/unit/interface/IUnitTheory';

export interface IComponentList {
  type: string;
  list: ITheoryBlock<any>[];
}
export const createSettingList = (): IComponentList[] => {
  const { translate }: ILocalization = extensionContainer.getByToken(ILocalizationToken)

  return [
    {
      type: translate('createUnit.text'),
      list: [
        {
          block: BlockType.HEADING_1,
          data: {
            text: '',
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.heading1'),
        },
        {
          block: BlockType.HEADING_2,
          data: {
            text: '',
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.heading2'),
        },
        {
          block: BlockType.HEADING_3,
          data: {
            text: '',
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.heading3'),
        },
        {
          block: BlockType.PARAGRAPH,
          data: {
            text: '',
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.p'),
        },
        {
          block: BlockType.LIST,
          data: {
            type: 'Numbered',
            elementCount: 0,
            listData: [],
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.list'),
        },
        {
          block: BlockType.TABLE,
          data: {
            columnsCount: 0,
            rowsCount: 0,
            tableData: {
              headerText: [],
              rows: [],
            },
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.table'),
        },
      ],
    },
    {
      type: translate('createUnit.media'),
      list: [
        {
          block: BlockType.IMAGE,
          data: {
            name: '',
            originalName: '',
            data: '',
            indexName: '',
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.img'),
        },
        {
          block: BlockType.VIDEO,
          data: {
            name: '',
            originalName: '',
            data: '',
            indexName: '',
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.video'),
        },
        {
          block: BlockType.CODE_EXAMPLE,
          data: {
            language: '',
            example: {
              language: '',
              content: '',
            },
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.codeExample'),
        },
      ],
    },
    {
      type: translate('createUnit.spacing'),
      list: [
        {
          block: BlockType.SPACING_48,
          sortIndex: 0,
          data: '',
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.spacingSize', { size: '48px' }),
        },
        {
          block: BlockType.SPACING_64,
          sortIndex: 0,
          data: '',
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.spacingSize', { size: '64px' }),
        },
      ],
    },

    {
      type: translate('createUnit.nesting'),
      list: [
        {
          block: BlockType.HIDDEN_BLOCK,
          data: {
            name: '',
            subBlocks: [],
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.hiddenBlock'),
        },
        {
          block: BlockType.TABS,
          data: {
            tabsCount: 0,
            tabsData: {
              title: '',
              contentBlocks: [],
              sortIndex: 0,
              pseudoId: '',
            },
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.tabs'),
        },
        {
          block: BlockType.TAGS,
          data: {
            tagsCount: 0,
            tagsData: {
              title: '',
              contentBlocks: [],
              sortIndex: 0,
              pseudoId: '',
            },
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.tags'),
        },

        {
          block: BlockType.SPECIAL_BLOCK,
          data: {
            name: '',
            color: '',
            contentBlocks: [],
          },
          sortIndex: 0,
          pseudoId: '',
          parentId: '',
          name: translate('createUnit.specialBlock'),
        },
      ],
    },
    // {
    //   type: 'Additional',
    //   list: [
    //     {
    //       block: BlockType.HTML,
    //       data: { htmlData: '' },
    //       sortIndex: 0,
    //       pseudoId: '',
    //       parentId: '',
    //     },
    //   ],
    // },
  ];
}
