import { FormControlLabel, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { AdditionalColumnEntityModalDomain } from './AdditionalColumnEntityModalDomain';
import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormAutocomplete } from '../../../../../../design/form/FormAutocomplete';
import { AdditionalColumnType } from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';

export interface IAdditionalColumnEntityTableProperties {
  domain: AdditionalColumnEntityModalDomain;
}

export const AdditionalColumnEntityModal = observer(({ domain }: IAdditionalColumnEntityTableProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
  }, [domain]);
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <AdditionalColumnNameCustomFieldText domain={domain} />
      <AdditionalColumnDescriptionCustomFieldArea domain={domain} />
      <AdditionalColumnActiveStatusCustomSwitch domain={domain} />
      <AdditionalColumnSystemTypeCustomField domain={domain} />
      <AdditionalColumnOrderCustomFieldText domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const AdditionalColumnNameCustomFieldText = observer(({ domain }: { domain: AdditionalColumnEntityModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
    />
  );
});

const AdditionalColumnDescriptionCustomFieldArea = observer(({ domain }: { domain: AdditionalColumnEntityModalDomain }) => {
  return (
    <FormTextArea
      required={true}
      label={'Описание'}
      value={domain.ui.model.entity.description}
      onChange={domain.getUpdateFieldHandler('description')}
      errorMessage={domain.getValidationErrorFor('description')?.message}
    />
  );
});

const AdditionalColumnActiveStatusCustomSwitch = observer(({ domain }: { domain: AdditionalColumnEntityModalDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isActive = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isActive);
  }, [domain.ui.model.entity.isActive]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
      label="Статус"
    />
  );
});

const AdditionalColumnSystemTypeCustomField = observer(({ domain }: { domain: AdditionalColumnEntityModalDomain }) => {
  const values1 = Object.values(AdditionalColumnType);
  const systemTypeValues = [
    ...values1.map((category) => ({
      value: category,
      name: category,
    })),
  ];


  return (
    <FormAutocomplete
      required={true}
      label={'Тип'}
      selected={domain.ui.model.entity.type}
      values={systemTypeValues}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.type = newValue.value;
      }}
      errorMessage={domain.getValidationErrorFor('systemType')?.message}
    />
  );
});

const AdditionalColumnOrderCustomFieldText = observer(({ domain }: { domain: AdditionalColumnEntityModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Порядок'}
      type="text"
      value={domain.ui.model.entity.orderIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
      errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
      inputProps={{ maxLength: 9 }}
    />
  );
});
