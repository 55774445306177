import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useI18n } from '../../../../../../application/language/useI18n';
import { IAdminPageProperties } from '../../../../../../view/admin/page/IAdminPageProperties';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { Constructor } from '../../../design/constructor/Constructor';
import { ConstructorDomain } from '../../../design/constructor/store/ConstructorDomain';
import { CustomStepper } from '../../../design/stepper/CustomStepper';
import { FinalResults } from './parts/finalResults/FinalResults';
import { IUnitFinalResultsDomain } from './parts/finalResults/store/IUnitFinalResultsDomain';
import { UnitFinalResultsDomain } from './parts/finalResults/store/UnitFinalResultsDomain';
import { Practice } from './parts/practice/Practice';
import { IUnitPracticeDomain } from './parts/practice/store/IUnitPracticeDomain';
import { UnitPracticeDomain } from './parts/practice/store/UnitPracticeDomain';
import { Quiz } from './parts/quiz/Quiz';
import { IQuizDomain } from './parts/quiz/store/IQuizDomain';
import { QuizDomain } from './parts/quiz/store/QuizDomain';
import { Settings } from './parts/settings/Settings';
import { SettingDomain } from './parts/settings/store/SettingDomain';
import { UnitDomain } from './store/UnitDomain';

// @todo change IAdminPage for leaning
export interface IUnitPageProperties extends IAdminPageProperties {
  layoutDomain: IMainLayoutDomainStore;
}

export const UnitPage = observer(({ layoutDomain }: IUnitPageProperties) => {
  const [unitDomain] = useState<UnitDomain>(new UnitDomain(layoutDomain));
  const [settingsDomain] = useState<SettingDomain>(new SettingDomain(layoutDomain));
  const [constructorDomain] = useState<ConstructorDomain>(new ConstructorDomain(unitDomain, layoutDomain));
  const [quizDomain] = useState<IQuizDomain>(new QuizDomain(unitDomain));
  const [practiceDomain] = useState<IUnitPracticeDomain>(new UnitPracticeDomain(layoutDomain, unitDomain));
  const [resultDomain] = useState<IUnitFinalResultsDomain>(new UnitFinalResultsDomain(layoutDomain, unitDomain));
  const params = useParams() as any;
  const [isLoading, setIsLoading] = useState(true);
  const { translate } = useI18n();
  useEffect(() => {
    const loadData = async () => {
      await unitDomain.loadData(params?.unitId);
      setIsLoading(false);
    };

    loadData();
  }, [params.unitId]);

  useEffect(() => {
    unitDomain.boot(
      [translate('createUnit.stepSettings'),
      translate('createUnit.stepTheory'), translate('createUnit.stepTest'), translate('createUnit.stepPractice'), translate('createUnit.stepResult')],
      ['settings', 'theory', 'quiz', 'practice', 'finalResult'],
      [
        {
          name: 'settings',
          component: <Settings unitDomain={unitDomain} />,
          isEnable: true,
        },
        {
          name: 'theory',
          component: <Constructor domain={constructorDomain} unitDomain={unitDomain} />,
          isEnable: unitDomain.ui.unit.entity.theory.isEnabled,
        },
        {
          name: 'quiz',
          component: <Quiz domain={quizDomain} />,
          isEnable: unitDomain.ui.unit.entity.quiz.isEnabled,
        },
        {
          name: 'practice',
          component: <Practice domain={practiceDomain} />,
          isEnable: unitDomain.ui.unit.entity.practice.isEnabled,
        },
        {
          name: 'finalResult',
          component: <FinalResults domain={resultDomain} />,
          isEnable: unitDomain.ui.unit.entity.finalResult?.isEnabled,
        },
      ],
      params?.unitId,
    );
  }, [
    unitDomain,
    unitDomain.ui.unit.entity.theory.isEnabled,
    unitDomain.ui.unit.entity.quiz.isEnabled,
    unitDomain.ui.unit.entity.practice.isEnabled,
    unitDomain.ui.unit.entity.finalResult?.isEnabled,
  ]);

  return (
    <>
      {!isLoading && (
        <CustomStepper
          unitDomain={unitDomain}
          titleHeading={unitDomain.ui.unit.entity.settings.titleHeading}
          stepsTitles={unitDomain.ui.stepsTitles.list}
          stepsCodes={unitDomain.ui.stepsCodes.list}
          stepContent={unitDomain.ui.stepContent.list}
        />
      )}
    </>
  );
});
