import DatePicker from '@mui/lab/DatePicker';
import { Grid, Paper, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { Fragment, useState } from 'react';

import { useI18n } from '../../../../application/language/useI18n';
import ApplicationTheme from '../../../../application/theme/ApplicationTheme';
import { IAdminPageProperties } from '../../../../view/admin/page/IAdminPageProperties';
import { AdminTable } from '../../../../view/admin/page/table/AdminTable';
import { FormMultiAutocomplete } from '../../../../view/design/form/FormMultiAutocomplete';
import { LoggerEventListAsArray } from './service/log/LoggerEventList';
import { AdminLogsDomain } from './store/AdminLogsDomain';

export interface IAdminLogsTableProperties extends IAdminPageProperties {}

export const AdminLogsTable = observer(({ layoutDomain }: IAdminLogsTableProperties) => {
  const [domain] = useState(new AdminLogsDomain(layoutDomain));
  const { translate } = useI18n();

  return (
    <Fragment>
      <Paper style={{ padding: '25px', paddingLeft: '27px' }} key={`logs_filter`} elevation={2}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid style={{ paddingTop: '15px', marginRight: '20px' }}>
            <ApplicationTheme isFlip={true}>
              <DatePicker
                showTodayButton={true}
                label={translate('phrases.fromWhatDate')}
                value={domain.ui.startDate.value}
                onChange={(date: any) => {
                  domain.ui.startDate.setValue(new Date(date?.toDateString())?.getTime() || null);
                  domain.updateBySearch();
                }}
                InputProps={{
                  color: 'secondary',
                }}
                disabled={false}
                renderInput={(props) => <TextField {...props} />}
              />
            </ApplicationTheme>
          </Grid>
          <Grid style={{ paddingTop: '15px' }} item>
            <ApplicationTheme isFlip={true}>
              <DatePicker
                showTodayButton={true}
                label={translate('phrases.toWhatDate')}
                value={domain.ui.endDate.value}
                onChange={(date: any) => {
                  domain.ui.endDate.setValue(new Date(date?.toDateString())?.getTime() + 86399999 || null);
                  domain.updateBySearch();
                }}
                InputProps={{
                  color: 'secondary',
                }}
                disabled={false}
                //@ts-ignore
                renderInput={(props) => <TextField {...props} />}
              />
            </ApplicationTheme>
          </Grid>
          <Grid item xs={3}>
            <FormMultiAutocomplete
              values={LoggerEventListAsArray.map((item) => ({ value: item.key, name: item.value })).sort((a, b) => {
                return a.name.localeCompare(b.name);
              })}
              onChangeSelect={(newValue: any) => {
                domain.ui.selectedEvents.setValue(newValue.map((value: any) => value.value));
                domain.updateBySearch();
              }}
              key="events"
              selected={domain.ui.selectedEvents.value}
              label={translate('phrases.events')}
            />
          </Grid>
        </Grid>
      </Paper>
      <AdminTable tableDomain={domain} />
    </Fragment>
  );
});
