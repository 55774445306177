import AssignmentIcon from '@mui/icons-material/Assignment';
import { Button, Grid, Menu, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react';

import { ICoreSearchAudit } from '../../../../../../../service/coreCommon/common/audit/ICoreSearchAudit';
import { UserProjectRoleAction } from '../../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import {
  UserSystemRoleModelPermissionMap,
} from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ApplicationDetailDomain } from '../../store/ApplicationDetailDomain';
import { IActiveRequirementsActionsProperties } from '../requirements/ActiveRequirementsActions';
import { ContainerContext } from '../../../../../../../common/container/ContainerContext';
import { IApplicationModelWithUser } from '../applicationInfoHistory/store/ApplicationHistoryUI';
import { IApplicationModel } from '../../../../../../../service/application/entity/IApplicationModel';
import { DownloadAcceptanceTests } from './ApplicationInfoReport';

export interface IApplicationInfoReportProperties extends IActiveRequirementsActionsProperties {
  domain: ApplicationDetailDomain;
  audit?: ICoreSearchAudit;
  isNeedColor?: boolean;
  listWithAudits?: IApplicationModelWithUser[];
  fullVersion?: string;
  application?: IApplicationModel;
  displayedAuditList: IApplicationModelWithUser[];
  isLatestVersion: boolean;
  fullAuditList: IApplicationModelWithUser[];
}

export const ApplicationProtocolReport = observer(({ domain, audit, listWithAudits, fullVersion, displayedAuditList, application, isLatestVersion, fullAuditList }: IApplicationInfoReportProperties) => {
  return (
    <Fragment />
  )
});
