import React, { ComponentType } from 'react';
import { Route } from 'react-router-dom';

import NotFoundErrorPage from '../../../common/errorPage/Page404/NotFoundErrorPage';
import { IMainLayoutProperties, MainLayout } from './MainLayout';

interface IMainLayoutRouteProperties extends IMainLayoutProperties {
  component: ComponentType<any>;
  path: string;
  exact: boolean;
  computedMatch?: any;
}

const MainLayoutRoute = ({ component: Component, allowedPermissions, ...rest }: IMainLayoutRouteProperties) => {
  const containsId = (str) => {
    const regex = /id|Id/;
    return regex.test(str);
  };

  const areAllFieldsUUID = (obj) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return Object.values(obj).every((value) => uuidRegex.test(value as string));
  };

  if (containsId(rest.path) && !areAllFieldsUUID(rest.computedMatch.params)) {
    return <NotFoundErrorPage />;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <MainLayout {...rest} allowedPermissions={allowedPermissions}>
          <Component {...rest} {...matchProps} />
        </MainLayout>
      )}
    ></Route>
  );
};

export default MainLayoutRoute;
