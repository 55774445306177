import { ConstructorType, inject, injectValue } from '../../../../../../common/container/inject';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ApplicationDetailDomain } from '../../../../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { ApplicationDetailsMode } from '../../../../../../view/user/page/application/info/store/ApplicationDetailsMode';
import { ApplicationDetailUI } from '../../../../../../view/user/page/application/info/store/ApplicationDetailUI';
import { ILocalization, ILocalizationToken } from '../../../../application/language/ILocalization';
import { ILearningRootService, LearningRootServiceToken } from '../../../../service/LearningRootService';
import { IUnitModel } from '../../../../service/unit/interface/IUnitModel';
import { ILearningApplicationDetailDomain } from './ILearningApplicationDetailDomain';
import { ILearningApplicationDetailUI } from './ILearningApplicationDetailUI';

export const extendApplicationDetailDomainConstructor = (
  Ref: new (...args: any[]) => ApplicationDetailDomain,
): new (...args: any[]) => ILearningApplicationDetailDomain => {
  class ExtendedDomainRef extends Ref {
    constructor(
      public layoutDomain: IMainLayoutDomainStore,
      public ui: ILearningApplicationDetailUI = new (injectValue<ConstructorType<ILearningApplicationDetailUI>>(
        ApplicationDetailUI,
      ))(),
      public i18n: ILocalization = inject<ILocalization>(ILocalizationToken),
      protected rootService = injectRootService(layoutDomain.serviceType.value),
      private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
    ) {
      super(layoutDomain, ui);
    }

    async loadLearning(applicationId) {
      let specificationsIds;
      if (applicationId !== '') {
        specificationsIds = (await this.rootService.application.entity.getById(applicationId)).specificationsIds;
        if (!specificationsIds.length) {
          this.ui.isShowWarning.setValue(true);
        } else {
          this.ui.isShowWarning.setValue(false);
        }
      } else {
        this.ui.isShowWarning.setValue(false);
      }

      const applicationSpecifications: Set<string> = new Set(
        this.ui.mode.value === ApplicationDetailsMode.edit
          ? this.ui.editApplication.entity.specificationsIds
          : specificationsIds,
      );

      this.ui.applicationSpecificationsSet.setValue(applicationSpecifications);

      this.ui.isLoading.setValue(true);
      this.ui.unitList.setList([]);
      this.ui.groupedUnits.setList([{ groupName: this.i18n.translate('learningAdminPage.withoutContext'), unitsList: { required: [], notRequired: [] } }]);
      const isAdmin = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);
      this.ui.isAdmin.setValue(isAdmin);

      const searchResult = await this.learningRootService.unit.search({
        limit: 1000000,
        fields: {
          include: [
            'settings.characteristicsIds',
            'settings.titleHeading',
            'isPublished',
            'settings.teamRolesIds',
            'score',
          ],
        },
      });

      this.ui.unitList.setList(searchResult.data.filter((unit) => unit.isPublished));
      this.ui.passingList.setList(searchResult.data.filter((unit) => unit.isPublished));

      const { data } = await this.learningRootService.unitResult.search({
        filter: [
          {
            fieldName: 'userId',
            type: 'equal',
            value: this.layoutDomain.ui.activeUser.entity.id,
          },
        ],
      });

      this.ui.passingResultList.setList(data);
      const specificationSearchResult = await this.rootService.specification.entity.search({
        limit: 100000,
        fields: ['name', 'categoryId'],
      });
      this.ui.unitList.setList(searchResult.data);
      const { data: specificationCategoryData } = await this.rootService.specification.category.search({
        fields: ['name', 'id'],
        limit: 100000,
      });
      this.ui.specificationCategory.setList(specificationCategoryData);

      this.ui.characteristicList.setList(specificationSearchResult.data);
      this.getAdditionalData();
      this.createdGroupedList(this.ui.unitList.list);
      if (!this.ui.isAdmin.value) {
        const currentUserRoleMapping = this.ui.project.entity.rolesMap?.data?.find(
          (roleMap) => roleMap.userId === this.layoutDomain.ui.activeUser.entity.id,
        );
        const currentUserRoleId = currentUserRoleMapping?.roleId;

        if (currentUserRoleId) {
          this.filterByRole(currentUserRoleId);
        }
      }
      this.ui.isLoading.setValue(false);
    }

    createdGroupedList = (unitList: IUnitModel[]) => {
      const charIdToCategoryMap = new Map();
      this.ui.characteristicList.list.forEach((char) => {
        charIdToCategoryMap.set(
          char.id,
          this.ui.specificationCategory.list.find((cat) => cat.id === char.categoryId),
        );
      });

      const groups: any = {
        [`${this.i18n.translate('learningAdminPage.withoutContext')}`]: {
          notRequired: [],
          required: [],
        },
      };

      unitList.forEach((unit) => {
        if (!unit.isPublished) {
          return;
        }
        let hasContext = false;

        unit.settings.characteristicsIds.forEach((charId) => {
          const category = charIdToCategoryMap.get(charId);
          if (category) {
            hasContext = true;
            if (!groups[category.name]) {
              groups[category.name] = {
                required: [],
                notRequired: [],
              };
            }

            let commonItem;
            if (!this.ui.roleFilterIds.list.length) {
              commonItem = unit.settings.teamRolesIds.find((teamRoleId) =>
                this.ui.rolesWithUnits.list.some((item2) => item2.roleId === teamRoleId),
              );
            } else {
              commonItem = unit.settings.teamRolesIds.find((teamRoleId) =>
                this.ui.roleFilterIds.list.includes(teamRoleId),
              );
            }

            const isMatchSpecification = unit.settings.characteristicsIds.some((charId) => {
              return this.ui.applicationSpecificationsSet.value.has(charId);
            });

            if (commonItem && unit.settings.teamRolesIds.includes(commonItem) && isMatchSpecification) {
              groups[category.name].required.push(unit);
            } else {
              groups[category.name].notRequired.push(unit);
            }
          }
        });

        if (!hasContext && unit.settings.characteristicsIds.length === 0) {
          groups[`${this.i18n.translate('learningAdminPage.withoutContext')}`].notRequired.push(unit);
        }
      });

      const sortedGroupList = Object.keys(groups)
        .map((groupName) => ({
          groupName,
          unitsList: groups[groupName],
        }))
        .sort((a, b) => a.groupName.localeCompare(b.groupName));

      const isAllNotRequired = sortedGroupList.every((item) => {
        return item.unitsList.required.length === 0;
      });


      //Условие 1: анкета заполнена характеристиками, у ролей не выбраны обязательные юниты
      if (!this.ui.rolesWithUnits.list.length && this.ui.applicationSpecificationsSet.value.size > 0) {
        this.ui.isShowWarning.setValue(false);
      } else if (isAllNotRequired) {
        this.ui.isShowWarning.setValue(true);
      } else {
        this.ui.isShowWarning.setValue(false);
      }

      this.ui.groupedUnits.setList(sortedGroupList);
    };

    redirect = async (id: string) => {
      this.router.goTo(`/learning/${id}`);
    };

    dropFilterByRole() {
      this.ui.roleFilterIds.setList([]);
    }

    getAdditionalData = () => {
      const ids = this.ui.project.entity.rolesMap?.data?.map((user) => user.userId);
      this.ui.teamUserIds.setList(ids as string[]);
      const onlySelectedAppRoles = this.ui.allProjectRoles.value.filter((role) => {
        // @ts-ignore
        return this.ui.project.entity.rolesMap?.data.some((selectedRole) => selectedRole.roleId === role.id);
      });

      const rolesWithUnits = onlySelectedAppRoles
        .filter((role) => {
          return this.ui.unitList.list.some((unit) => unit.settings.teamRolesIds.includes(role.id as string) && unit.isPublished);
        })
        .map((role) => {
          return {
            roleId: role.id as string,
            name: role.name,
          };
        });
      this.ui.rolesWithUnits.setList(rolesWithUnits);
    };

    async filterByRole(roleId) {
      if (this.ui.roleFilterIds.list.includes(roleId)) {
        this.ui.roleFilterIds.setList(this.ui.roleFilterIds.list.filter((value) => value !== roleId));
      } else {
        this.ui.roleFilterIds.setList([...this.ui.roleFilterIds.list, roleId]);
      }

      await this.createdGroupedList(this.ui.unitList.list);
    }

    redirectTo(url: string) {
      this.router.goTo(url);
    }

    redirectToKnowledge = () => {
      this.router.goTo('/learning/admin');
    };
  }

  return ExtendedDomainRef;
};
