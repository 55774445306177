
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IStatusEntityModel } from '../../../../../../service/status/entity/IStatusEntityModel';
import { IStatusEntitySearch } from '../../../../../../service/status/entity/IStatusEntitySearch';
import { StatusEntityAdminUI } from './StatusEntityAdminUI'
import { StatusEntityModalDomain } from './modal/StatusEntityModalDomain'
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IUserProjectRoleModel } from '../../../../../../service/projectRole/entity/IUserProjectRoleModel';

export class CustomAppBar {
  Toolbar = TableHeader;
}
export class StatusEntityAdminDomain extends DataTableDomain<
  IStatusEntityModel,
  IStatusEntitySearch
> {
  public ui: StatusEntityAdminUI;
  public projectRoles = injectEntityList<IUserProjectRoleModel>([])
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.status.entity);
    this.ui = new StatusEntityAdminUI(this, null);
    this.modalDomain = new StatusEntityModalDomain(rootPrivateServices.status.entity, layoutDomain, this);
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle.setValue('Статусы');
    this.ui.tableSubtitle.setValue('Категории');
  }

  async loadData() {
    await this.loadDepenicies();
    await super.loadList();
    this.ui.isLoading.setValue(false);
  }
  
  async loadDepenicies() {
    this.ui.isLoading.setValue(true);
    const projectRoles = await this.rootPrivateServices.projectRole.entity.search({ limit: 10000 });
    this.ui.isLoading.setValue(false);
    this.projectRoles.setList(projectRoles.data);
  }

  async setPermissions() {
    await super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-update']]),
    );
  }
}
