import { IProjectsWithUserStats } from '../../../admin/page/passingListPage/store/IProjectsWithUserStats';

export interface IProjectToUserStatisticsMap {
  project: { id: string; name: string };
  users: {
    usersNotPassedStudying: number;
    usersNotStartedStudying: number;
    usersPassedStudying: number;
  };
}

export const useGetProjectToUserStatisticsMap = (projects: IProjectsWithUserStats[]) => {
  const projectToUserStatisticsMap: IProjectToUserStatisticsMap[] = [];

  projects?.forEach((project) => {
    let usersPassedStudying = 0;
    let usersNotPassedStudying = 0;
    let usersNotStartedStudying = 0;

    //Если на проекте нет обязательных юнитов, то его не отображаем
    if (!project.importantUnitsIds.size) {
      return;
    }

    project.users.forEach((userUnits) => {
      //Если у юзера есть обязательные юниты, то распределяем их по категориям
      if(userUnits.requiredForUserUnits.length) {
        if (userUnits.unitResults.length && userUnits.unitResults.every((unit) => unit?.isPassed)) {
          usersPassedStudying++;
        } else if (userUnits.unitResults.length && userUnits.unitResults.every((unit) => unit?.isPassed === null)) {
          usersNotStartedStudying++;
        } else if (!userUnits.unitResults.length) {
          usersNotStartedStudying++;
        } else {
          usersNotPassedStudying++;
        }
      }
    });

    projectToUserStatisticsMap.push({
      project: { ...project.project },
      users: { usersPassedStudying, usersNotPassedStudying, usersNotStartedStudying },
    });
  });

  return { projectToUserStatisticsMap };
};
