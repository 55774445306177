import { GridColumns } from '@mui/x-data-grid/models';
import React from 'react';
import { ISpecificationEntitySearch } from '../../../../../../service/specification/entity/ISpecificationeEntitySearch';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { IDataTableRowsUI } from '../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { IStatusEntityModel } from '../../../../../../service/status/entity/IStatusEntityModel';
import { IStatusEntitySearch } from '../../../../../../service/status/entity/IStatusEntitySearch';
import { StatusEntityAdminDomain } from './StatusEntityAdminDomain';

export class StatusEntityAdminUI
  extends DataTableUI<IStatusEntityModel, IStatusEntitySearch>
  implements IDataTableUI<IStatusEntityModel, IStatusEntitySearch> {
  constructor(
    public domain: StatusEntityAdminDomain,
    rows: IDataTableRowsUI<IStatusEntityModel, ISpecificationEntitySearch> | null,
  ) {
    super(domain, rows);
    this.rows.requestSearch = (searchTerm) => {
      this.requestSearch(searchTerm || '')
    }
  }
  setDomainId(): string {
    return 'settings/status/entity';
  }

  requestSearch = (searchTerm: string) => {
    this.rows.searchTerm.setValue(searchTerm);
    const searchRegex = new RegExp(this.rows.escapeRegExp(searchTerm.trim()), 'i');
    const filteredRows = [...this.rows.entities.list]
      .filter((row) => {
        return Object.keys(row).some((field) => {
          let value = ''
          if (field === 'rolesAccessIds') {
            value = this.domain.projectRoles.list
              .filter((projectRole) => row[field].includes(projectRole.id || ''))
              .map((projectRole) => projectRole?.name).join().toLocaleLowerCase();
            return searchRegex.test(value);
          } else {
            value = this.rows.getFieldValueAsString(field, row[field]);
            return searchRegex.test(value);
          }
        });
      });
    this.rows.setFilteredEntities(filteredRows);
  }

  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [
      {
        headerName: 'Название',
        field: 'name',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Описание',
        field: 'description',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Роли',
        field: 'rolesAccessIds',
        flex: 1,
        valueGetter: (params) => {
          const rolesNames: string[] = params?.value?.flatMap(item => {
            return this.domain.projectRoles.list.find(projectRole => projectRole.id === item)?.name
          }) || []
          const names = rolesNames.join(', \n');

          return names;
        },
        renderCell: (params: { value?: string }) => {
          return (<MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />)
        },
      },
      {
        headerName: 'Порядок отображения',
        type: 'string',
        field: 'orderIndex',
        flex: 0.5,
      },
      {
        headerName: 'Основной статус в Системах',
        field: 'isPrimaryApplicationStatus',
        type: 'boolean',
        flex: 0.5,
      },
      {
        headerName: 'Активен',
        field: 'isActive',
        type: 'boolean',
        flex: 0.3,
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
