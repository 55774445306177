import { ArrowDownward, ArrowUpward, DeleteForever, VisibilityOff } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { TranslateText } from '../../../../../../../../application/language/TranslateText';
import { ITheoryBlock } from '../../../../../../service/unit/interface/IUnitTheory';
import { IParagraph } from '../../../../../../service/unit/interface/theoryBlocks/IParagraph';
import { LearningRichTextEditor } from '../../../../richTextEditor/LearningRichTextEditor';

export interface HeadingProperties {
  data: ITheoryBlock<IParagraph>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
}

export const ParagraphComponent = observer(
  ({ data: { data, pseudoId }, onDelete, onUp, onDown }: HeadingProperties) => {
    return (
      <Box
        key={pseudoId}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          background: 'rgba(0,0,0,0.2)',
          padding: '16px 42px ',
          '&:hover .action': { visibility: 'visible' },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', alignItems: 'flex-end' }}>
          <Typography sx={{ marginRight: 'auto' }}>
            <TranslateText i18nKey='createUnit.p' />
          </Typography>
          <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
            <DeleteForever
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                onDelete();
              }}
            />
            <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => { }} />
            <ArrowUpward
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                onUp();
              }}
            />
            <ArrowDownward
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                onDown();
              }}
            />
          </Box>
        </Box>
        <LearningRichTextEditor
          defaultInputValue={data.text}
          changeHandler={(newValue) => {
            data.text = newValue;
          }}
        />
      </Box>
    );
  },
);
