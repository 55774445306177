import { Add, Close } from '@mui/icons-material';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { ICommonProperties } from '../../../../../../../../common/properties/ICommonProperties';
import { CodeEditor } from '../../../../../design/codeEditor/CodeEditor';
import { PracticePassage } from './parts/PracticePassage';
import { PracticeResult } from './parts/PracticeResult';
import { PracticeTask } from './PracticeTask';
import { IUnitPracticeDomain } from './store/IUnitPracticeDomain';

export interface IPracticeProperties extends ICommonProperties {
  domain: IUnitPracticeDomain;
}

export const Practice = observer(({ domain }: IPracticeProperties) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);

  useEffect(() => {
    domain.boot();
  }, [domain]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'scroll' }}>
      <Box sx={{ display: 'flex', width: '100%', padding: '8px 0px' }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', paddingRight: '8px' }}>
          {domain.ui.contentPage.value === 'task' && domain.ui.pickedTask.value !== null && (
            <PracticeTask
              task={domain.ui.pickedTask.value}
              addLanguages={domain.addLanguages}
              pickedLang={domain.ui.pickedLang.value}
              pickLang={domain.pickLang}
            />
          )}
          {domain.ui.contentPage.value === 'time' && <PracticePassage unitDomain={domain.unitDomain} />}
          {domain.ui.contentPage.value === 'result' && <PracticeResult unitDomain={domain.unitDomain} />}
        </Box>

        <Box
          sx={{
            padding: '8px 32px',
            flex: 0.75,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ maxHeight: 'calc(100% - 250px)', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '9px',
              }}
            >
              <Typography variant="h6">Задачи</Typography>
              <IconButton
                size="small"
                sx={{ background: 'rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}
                onClick={() => domain.addTask()}
              >
                <Add />
              </IconButton>
            </Box>

            <Box
              sx={{
                overflowY: 'auto',
                height: '100%',
                maxHeight: '250px',
                display: 'flex',
                flexDirection: 'column',
                gap: '9px',

                marginBottom: '35px',
              }}
            >
              {domain.unitDomain.ui.unit.entity.practice.tasks.map((task, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    domain.pickTask(task.pseudoId);
                  }}
                  sx={{
                    display: 'flex',
                    cursor: 'pointer',
                    justifyContent: 'space-between',
                    background: task.pseudoId === domain.ui.pickedTask.value?.pseudoId ? 'rgba(0,0,0,0.25)' : '',
                    padding: '8px 12px',
                    marginBottom: '8px',
                    borderRadius: '8px',
                    '&:hover': { background: 'rgba(0,0,0,0.25)' },
                    '&:hover .question__delete': { visibility: 'visible' },
                  }}
                >
                  <Typography variant="body1">{`Задача ${task.sortIndex}`}</Typography>
                  <Close
                    className="question__delete"
                    sx={{ visibility: 'hidden', cursor: 'pointer' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      domain.onDelete(index);
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>

          <Typography variant="h6" sx={{ marginBottom: '9px' }}>
            Настройки
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '9px', marginBottom: '32px' }}>
            <Typography
              onClick={() => {
                domain.changeContent('time');
              }}
              variant="body1"
              sx={{
                paddingLeft: '12px',
                paddingTop: '4px',
                paddingBottom: '4px',
                cursor: 'pointer',
                '&:hover': { background: 'rgba(0,0,0,0.2)' },
                background: domain.ui.contentPage.value === 'time' ? 'rgba(0,0,0,0.2)' : 'inherit',
              }}
            >
              Время прохождения и награда
            </Typography>
            <Typography
              onClick={() => {
                domain.changeContent('result');
              }}
              variant="body1"
              sx={{
                paddingLeft: '12px',
                paddingTop: '4px',
                paddingBottom: '4px',
                cursor: 'pointer',
                '&:hover': { background: 'rgba(0,0,0,0.2)' },
                background: domain.ui.contentPage.value === 'result' ? 'rgba(0,0,0,0.2)' : 'inherit',
              }}
            >
              Результаты
            </Typography>
          </Box>
        </Box>
      </Box>
      {domain.ui.pickedLang.value !== null && domain.ui.contentPage.value === 'task' && (
        <Box>
          {isExpand ? (
            <Dialog
              fullScreen
              open={isExpand}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                '& > div[role="presentation"]': { width: '100%', alignItems: 'flex-start' },
                '&  div[role="dialog"]': {
                  width: '100%',
                  alignItems: 'flex-start',
                  maxWidth: '100%',
                  height: '100%',
                },

                width: '100%',
              }}
            >
              <Box sx={{ flex: 1, width: '100%' }}>
                <CodeEditor lang={domain.ui.pickedLang.value} isExpand={isExpand} handleExpand={setIsExpand} />
              </Box>
            </Dialog>
          ) : (
            <Box sx={{ flex: 1 }}>
              <CodeEditor lang={domain.ui.pickedLang.value} isExpand={isExpand} handleExpand={setIsExpand} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
});
