import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { AdditionalColumnContentModalDomain } from './AdditionalColumnContentModalDomain';
import { IFileUploadDomain } from '../../../../../../design/fileUpload/store/IFileUploadDomain';
import { FileUploadDomain } from '../../../../../../design/fileUpload/store/FileUploadDomain';
import { FormAutocomplete } from '../../../../../../design/form/FormAutocomplete';
import { FormMultiAutocomplete } from '../../../../../../design/form/FormMultiAutocomplete';
import { AdditionalColumnRequirementRelationType } from '../../../../../../../service/additionalColumn/content/AdditionalColumnRequirementRelationType';
import { AdditionalColumnType } from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { UploadFileColumn } from '../../../../../../design/fileUpload/UploadFileColumn';
import { DownloadFileColumn } from '../../../../../../design/fileUpload/DownloadFileColumn';
import { FormActions } from '../../../../../../design/form/FormActions';
import { RichTextEditor } from '../../../../../../design/RichTextEditor/RichTextEditor';
import { FormTextField } from '../../../../../../design/form/FormTextField';

export interface IAdditionalColumnContentModalProperties {
  domain: AdditionalColumnContentModalDomain;
}

export const AdditionalColumnContentModal = observer(({ domain }: { domain: AdditionalColumnContentModalDomain }) => {
  const [uploadDomain] = useState<IFileUploadDomain>(new FileUploadDomain(domain.layoutDomain));
  const uiEntity = domain.ui.model.entity;

  useEffect(() => {
    if (domain.ui.model.entity.uploadedFileUrl) {
      uploadDomain.ui.currentFile.entity.fileUrl = domain.ui.model.entity.uploadedFileUrl;
      uploadDomain.ui.currentFile.entity.fileName = domain.ui.model.entity['content'];
    }
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
    return (() => domain.layoutDomain.setPageTitle(`Контент доп. колонок`))
  }, [domain]);

  return (
    <FormModalContainer onClose={domain.onCancelUpdates} width={500}>
      <FormAutocomplete
        values={domain.additionalColumnsValues.list}
        onChangeSelect={(newValue: any) => {
          domain.ui.model.entity.additionalColumnId = newValue.value;
          domain.setAdditionalColumn(newValue.value)
        }}
        key={'requirementsRelationType' + Date.now()}
        errorMessage={domain.getValidationErrorFor('additionalColumnId')?.message}
        selected={uiEntity.additionalColumnId}
        label="Дополнительная колонка"
        dataCy='extra-column-title'
      />

      {!(
        domain.activeAdditionalColumn.entity?.type === AdditionalColumnType.DOWNLOAD_FILE ||
        domain.activeAdditionalColumn.entity?.type === AdditionalColumnType.UPLOAD_FILE ||
        domain.activeAdditionalColumn.entity?.type === AdditionalColumnType.ENUM
      ) && (
          <FormTextField
            errorMessage={domain.getValidationErrorFor('content')?.message}
            value={uiEntity.content}
            onChange={domain.getUpdateFieldHandler('content')}
            label="Контент"
            multiline
            rows={10}
            required={true}
            inputProps={{
              'data-cy': 'extra-column-content'
            }}
          />
        )}

      {(
        domain.activeAdditionalColumn.entity?.type === AdditionalColumnType.ENUM
      ) && (
          <AdditionalColumnContentTextField domain={domain} />
        )}

      {domain.activeAdditionalColumn.entity?.type === AdditionalColumnType.DOWNLOAD_FILE && (
        <>
          {!uploadDomain.ui.currentFile.entity.fileName ? (
            <Box sx={{ padding: '10px 0' }}>
              <UploadFileColumn
                uploadDomain={uploadDomain}
                onChange={(event) => {
                  domain.ui.model.entity['content'] = event.fileName;
                  domain.ui.model.entity['uploadedFileUrl'] = event.url;
                }}
                sx={{ width: '80%', margin: 'auto' }}
              />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <DownloadFileColumn
                sx={{ width: '100%' }}
                originalFileName={uploadDomain.ui.currentFile.entity.fileName}
                uploadDomain={uploadDomain}
                defaultValue={uploadDomain.ui.currentFile.entity.fileUrl}
                isCanCancel={true}
                cancelFile={() => {
                  uploadDomain.ui.currentFile.entity.fileName = '';
                  domain.cancelFile(domain.ui.model.entity);
                  domain.ui.model.entity.uploadedFileUrl = '';
                }}
              />
            </Box>
          )}
        </>
      )}

      <FormAutocomplete
        values={domain.requirementsRelationTypes}
        onChangeSelect={(newValue: any) => {
          domain.ui.model.entity.requirementsRelationType = newValue.value;
        }}
        key="requirementsRelationType"
        errorMessage={domain.getValidationErrorFor('requirementsRelationType')?.message}
        selected={uiEntity.requirementsRelationType || AdditionalColumnRequirementRelationType.all}
        label="Тип применения"
        dataCy='extra-column-usage-type'
      />

      <FormMultiAutocomplete
        values={domain.requirementsValues.list}
        onChangeSelect={(newValue: any) => {
          domain.ui.model.entity.requirementsIds = newValue.map((value: any) => value.value);
        }}
        key="requirementsIds"
        selected={domain.ui.model.entity.requirementsIds}
        label="Требования"
        dataCy='extra-column-requirements'
        disabled={uiEntity.requirementsRelationType === AdditionalColumnRequirementRelationType.all}
        style={{ maxWidth: '500px' }}
      />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const AdditionalColumnContentTextField = observer(({ domain }: { domain: AdditionalColumnContentModalDomain }) => {
  const handleChange = (styledText, cleatText) => {
    const changeFuncStyled = domain.getUpdateFieldHandler('contentWithStyles')
    changeFuncStyled(styledText || '');
    const changeFunc = domain.getUpdateFieldHandler('content')
    changeFunc(cleatText || '');
  }
  const defaultDataForInput = domain.ui.model.entity?.contentWithStyles ?? domain.ui.model.entity?.content

  return (
    <div style={{ margin: 16 }}>
      <RichTextEditor
        defaultInputValue={defaultDataForInput || ''}
        changeHandler={handleChange}
        minHeight='200px'
        error={domain.getValidationErrorFor('content')?.message}
        toolbarProps={['insertTable']}
      />
    </div>
  )
});
