import { FormControl, FormHelperText, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../common/properties/ICommonProperties';

export interface IFormTextFieldProperties extends ICommonProperties {
  label: string;
  onChange: (newValue: string) => void;
  value?: string;
  type?: string;
  errorMessage?: string | null;
  isHaveErrors?: boolean;
  multiline?: boolean;
  rows?: string | number | undefined;
  helperText?: string | undefined;
  required?: boolean | undefined;
  disabled?: boolean;
  style?: any;
  inputProps?: any;
  variant?: 'outlined' | 'filled' | 'standard';
  dataCy?: string;
  size?: any;
  paddingSizing?: number;
}

export const FormTextField = observer(
  ({
    label,
    onChange,
    value = '',
    multiline = false,
    type = 'text',
    errorMessage = null,
    rows = undefined,
    helperText = undefined,
    required = false,
    disabled = false,
    style = {},
    inputProps = {},
    variant = 'outlined',
    dataCy,
    size = 'normal',
    paddingSizing = 2,
    isHaveErrors = false,
  }: IFormTextFieldProperties) => {
    const classes = useStyles();

    const onTextFieldChange = (event: any) => {
      onChange(event.target.value);
    };

    return (
      <FormControl fullWidth variant="outlined" sx={{ padding: paddingSizing }}>
        <TextField
          style={style}
          disabled={disabled}
          required={required}
          error={!!errorMessage || isHaveErrors}
          helperText={errorMessage || null}
          value={value}
          type={type}
          onChange={onTextFieldChange}
          color="secondary"
          label={label}
          variant={variant}
          multiline={multiline || false}
          rows={rows}
          inputProps={inputProps}
          data-cy={dataCy}
          size={size}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(2),
  },
}));
