import { CheckCircle, FlagOutlined } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { MouseEventHandler } from 'react';

import { OverflownContent } from '../../../../../../LearningUnitPreviewPage/shared/OverflownContent';

export interface IPassingAccordionItemProperties {
  title: string;
  passed: boolean;
  isActive: boolean;
  subHeader: ISubHeader[];
  navigationRedirect: (title: string) => void;
}

export interface IPassingAccordionItemProps {
  title: string;
  passed: boolean;
  isActive: boolean;
  subHeader: ISubHeader[];
  isActiveStep: boolean;
  hash?: string;
  activeAccordionTitle: string;
  onExpandIconClick: (title: string) => void;
  scrollToAnchor: (title: string, hash: string) => void;
  navigationRedirect: (title: string) => void;
  dataCy?: string;
}
export interface ISubHeader {
  title: string;
  type: string;
}
export const PassingTheoryAccordionContent = observer(
  ({
    title,
    passed,
    isActive,
    subHeader,
    navigationRedirect,
    isActiveStep,
    hash,
    activeAccordionTitle,
    scrollToAnchor,
    onExpandIconClick,
    dataCy,
  }: IPassingAccordionItemProps) => {
    const isExpanded = title === activeAccordionTitle;
    const onAccordionClick: MouseEventHandler<HTMLDivElement> = (event) => {
      event.stopPropagation();
      navigationRedirect(title);
      onExpandIconClick(title);
    };

    const onTitleClick: MouseEventHandler<HTMLDivElement> = () => {
      navigationRedirect(title);
      onExpandIconClick('');
    };

    const classes = useStyles(isActiveStep);

    return (
      <Box
        onClick={() => {
          navigationRedirect(title);
        }}
        data-cy={dataCy}
        sx={{
          padding: '4px 10px',
          display: 'flex',
          gap: '10px',
          color: 'white',
          cursor: 'pointer',
          '&:last-child': {
            marginBottom: '10px',
          },
          overflow: 'hidden',
          alignItems: 'center',
        }}
      >
        {!passed ? (
          <FlagOutlined
            sx={{ marginBottom: 'auto', marginTop: '10px', color: isActiveStep ? 'rgba(144, 202, 249, 1)' : '' }}
          />
        ) : (
          <CheckCircle
            sx={{ marginBottom: 'auto', marginTop: '10px', color: isActiveStep ? 'rgba(144, 202, 249, 1)' : '' }}
          />
        )}
        {subHeader.length !== 0 ? (
          <Accordion
            expanded={isExpanded}
            onClick={onAccordionClick}
            sx={{
              width: '100%',
              background: 'transparent',
              boxShadow: 'none',
              margin: 0,
              '& > div:first-of-type': { height: '48px' },
              '&.Mui-expanded > div:first-of-type': { height: '48px', minHeight: '48px !important' },
            }}
          >
            <AccordionSummary>
              <div className={classes.accordionContainer}>
                <OverflownContent contentClasses={classes.accordionTitle}>{title}</OverflownContent>
                {isExpanded ? (
                  <KeyboardArrowUpIcon sx={{ color: 'rgba(144, 202, 249, 1)' }} />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
            </AccordionSummary>
            <Box sx={{ maxHeight: '304px', overflowY: 'scroll' }}>
              {subHeader.map((subtitle, index) => {
                return (
                  <AccordionDetails
                    key={index}
                    sx={{
                      padding: 0,
                      marginBottom: '8px',
                      fontSize: '14px',
                      paddingLeft: subtitle.type,
                      color: hash === subtitle.title ? 'rgba(144, 202, 249, 1)' : '',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      scrollToAnchor(title, subtitle.title);
                    }}
                  >
                    <div className={classes.accordionContainer}>
                      <OverflownContent contentClasses={classes.accordionSubTitle}>{subtitle.title}</OverflownContent>
                    </div>
                  </AccordionDetails>
                );
              })}
            </Box>
          </Accordion>
        ) : (
          <div onClick={onTitleClick} className={classes.accordionContainer}>
            <OverflownContent contentClasses={classes.accordionTitle}>{title}</OverflownContent>
          </div>
        )}
      </Box>
    );
  },
);

const useStyles = (isActiveStep) =>
  makeStyles(() => ({
    accordionContainer: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' },
    accordionTitle: {
      color: isActiveStep ? 'rgba(144, 202, 249, 1)' : '',
      margin: '8px 16px 8px 16px',
      cursor: 'pointer',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      whiteSpace: 'normal',
      lineClamp: 2,
    },
    accordionSubTitle: {
      width: '90%',
      margin: '8px 16px 8px 16px',
      cursor: 'pointer',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      whiteSpace: 'normal',
      lineClamp: 2,
    },
  }))();
