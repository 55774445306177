import { Theme } from '@emotion/react';
import { Add, ArrowDropDown, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Chip, List, Paper, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { TranslateText } from '../../../../../../../application/language/TranslateText';
import { RequirementCreationType, getRequirementCreationTypeName } from '../../../../../../../service/requirement/entity/RequirementType';
import { useButtonStyles } from '../../../../../../design/button/ButtonStyle';
import { Colors } from '../../../../../../design/color/Colors';
import { ConfirmModal } from '../../../../../../design/modal/confirm/ConfirmModal';
import { InformModal } from '../../../../../../design/modal/inform/InformModal';
import { RequirementEntityAdminDomain } from '../RequirementEntityAdminDomain';
import SearchRequirement from './SearchRequirement';

export interface QuickSearchToolbarProperties {
  tableDomain: RequirementEntityAdminDomain;
}

export const AppBar = observer(({
  tableDomain,
}: QuickSearchToolbarProperties) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  const [isOpenList, setIsOpenList] = useState(false);
  const appBarRef = useRef<HTMLDivElement>(null);

  const tagsFilteredValues = tableDomain?.filterDomain.ui.searchTagsIds.value.map((item) => {
    return tableDomain?.filterDomain.ui.tags.list.filter((tag) => tag.id === item)
  }).flat()
  const specificationsFilteredValues = tableDomain?.filterDomain.ui.searchSpecificationsIds.value.map((item) => {
    return tableDomain?.filterDomain.ui.specifications.list.filter((tag) => tag.id === item)
  }).flat()
  const requirementCreationTypeFilteredValues = tableDomain?.filterDomain.ui.searchRequirementCreationTypes.value
    .flatMap((item: string) => {
      const typeName = getRequirementCreationTypeName(item as RequirementCreationType);
      return typeName ? [{ name: typeName, id: item }] : [];
    }) ?? [];

  const filteredValues = [
    ...tagsFilteredValues,
    ...specificationsFilteredValues,
    ...requirementCreationTypeFilteredValues
  ]

  const handleDeleteItem = (id: any) => {
    const result = tableDomain.ui.rows.rowsCounter.value.filteredRow.filter((row: { id: any }) => {
      return row.id !== id;
    });
    tableDomain.ui.rows.rowsCounter.setValue({ counter: result.length, filteredRow: result });
    tableDomain.ui.rows.setSelectionEntities(result);
  };

  useEffect(() => {
    if (appBarRef.current) {
      const div = appBarRef.current
      const resizeObserver = new ResizeObserver(entries => {
        tableDomain.ui.toolBarHeight?.setValue(div?.offsetHeight)
      });
      resizeObserver.observe(div);
      return () => resizeObserver.unobserve(div);
    }
  }, []);

  const acceptConfirmation = () => {
    let notSavedReqs = tableDomain.ui.rows.selectionEntities.list.filter((req) => req.creationType !== RequirementCreationType.saved);
    if (notSavedReqs.length === 0 && tableDomain.ui.rows.selectionEntities.list.length === 0) {
      notSavedReqs = tableDomain.ui.modifiedRequirements.list;
    }
    const requirementWord = tableDomain.getRequirementsWord(notSavedReqs.length);
    const requirementNames = notSavedReqs.map((req) => req.shortName);
    return () => (<>
      {notSavedReqs.length ?
        <ConfirmModal
          title='Обновление требований в системах'
          text={`Добавлены и изменены ${requirementWord}: ${requirementNames.join(', ')}. Отправить обновления в системы?`}
          onCancel={async () => {
            //@ts-ignore
            tableDomain.layoutDomain.modalWindow.closeModalWindow();
          }}
          onOk={async () => {
            await tableDomain.acceptRequirements(notSavedReqs);
            tableDomain.ui.isLoading.setValue(true)
            await tableDomain.loadData();
            await tableDomain.boot();
            tableDomain.ui.isLoading.setValue(false);
            tableDomain.ui.rows.selectionEntities.setList([]);
            tableDomain.filterDomain.triggerSearch()
            //@ts-ignore
            tableDomain.layoutDomain.modalWindow.closeModalWindow();
          }}
        /> :
        <InformModal
          title='Выбранные требования верифицированы'
          text={``}
          onOk={() => {
            tableDomain.ui.rows.selectionEntities.setList([]);
            //@ts-ignore
            tableDomain.layoutDomain.modalWindow.closeModalWindow();
          }}
        />}
    </>
    );
  };

  const onTryToAccept = () => {
    //@ts-ignore
    tableDomain.layoutDomain.modalWindow.showModalWindow(acceptConfirmation());
  };

  return (
    <>
      <div className={classes.tableToolBar} ref={appBarRef}>
        <Toolbar className={classes.actions}>
          <div className={classes.tableName}>
            <h3 className={classes.tableNameMain}>{tableDomain.ui.tableTitle.value}</h3>
            <h1 className={classes.tableNameType}>{tableDomain.ui.tableSubtitle.value}</h1>
          </div>
          <div className={classes.buttons}>
            <div>
              {tableDomain.ui.rows.rowsCounter.value.filteredRow.length !== 0 && (
                <Button
                  endIcon={<ArrowDropDown />}
                  className={classes.button}
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setIsOpenList(!isOpenList);
                  }}
                >
                  Выбрано элементов: {tableDomain.ui.rows.rowsCounter.value.counter}
                </Button>
              )}
              {isOpenList && (
                <div style={{ zIndex: 10 }}>
                  <Paper elevation={3}>
                    <List
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                        overflowY: 'scroll',
                        maxHeight: '320px',
                      }}
                    >
                      {tableDomain.ui.rows.rowsCounter.value.filteredRow.map((row: any) => (
                        <Chip
                          color="secondary"
                          label={row.name || row.shortName}
                          key={row.id}
                          variant="outlined"
                          style={{ marginBottom: '5px', padding: '2.5px 10px', justifyContent: 'space-between' }}
                          onDelete={() => {
                            handleDeleteItem(row.id);
                          }}
                        />
                      ))}
                    </List>
                    {tableDomain.ui.rows.rowsCounter.value.filteredRow.length !== 0 && (
                      <Button
                        variant="text"
                        color="secondary"
                        style={{ alignSelf: 'center', width: '100%', background: '#ffffff14' }}
                        onClick={() => {
                          tableDomain.handleClearCheckBox();
                          setIsOpenList(false);
                        }}
                      >
                        Очистить список выбранных
                      </Button>
                    )}
                  </Paper>
                </div>
              )}
            </div>
            {
              tableDomain.isCanResyncRequirements.value &&
              <Button
                disabled={!tableDomain.ui.modifiedRequirements.list.length}
                onClick={async () => {
                  onTryToAccept();
                }}
                className={classes.saveButton}
                variant="contained"
                color="secondary"
              >
                Обновить в системах
              </Button>
            }
            {tableDomain.ui.features.value.isCanDownloadReport &&
              tableDomain.onHandleDownload &&
              <Button
                onClick={() => tableDomain.onHandleDownload('docx')}
                className={classes.saveButton}
                variant="contained"
                color="secondary"
                endIcon={<Save />}
              >
                Выгрузить требования
              </Button>
            }
            {tableDomain.ui.isCanCreate.value
              && <LoadingButton
                className={buttonClasses.button}
                style={{
                  backgroundColor: `${Colors.greenButtonOpacity('0.7')} `,
                  marginBottom: '20px',
                  marginRight: '10px',
                }}
                variant="outlined"
                startIcon={<Add />}
                color="success"
                //@ts-ignore
                loading={tableDomain.drawerDomain?.isSaving.entity}
                onClick={() => {
                  tableDomain.drawerDomain.ui.isEditMode.setValue(true)
                  tableDomain.onHandleAdd()
                }}
              >
                <TranslateText i18nKey='phrases.add' />
              </LoadingButton>}

          </div>
        </Toolbar>
        {!!filteredValues.length &&
          <div className={classes.filterValueBlock}>
            <Chip
              color="secondary"
              label={'Очистить все'}
              className={classes.filterValue}
              variant="outlined"
              onClick={() => {
                tableDomain?.filterDomain.ui.searchSpecificationsIds.setValue([])
                tableDomain?.filterDomain.ui.searchTagsIds.setValue([])
                tableDomain?.filterDomain.ui.searchRequirementCreationTypes.setValue([])
                tableDomain.loadData()
              }}
            />
            <div style={{ overflowX: 'auto', display: 'flex', paddingBottom: 10 }}>
              {filteredValues.map((item) => {
                return (
                  <Chip
                    color="secondary"
                    label={item.name}
                    key={item.id}
                    className={classes.filterValue}
                    variant="outlined"
                    onDelete={() => {
                      //@ts-ignore
                      tableDomain?.filterDomain.ui.searchSpecificationsIds.value.remove(item.id)
                      //@ts-ignore
                      tableDomain?.filterDomain.ui.searchRequirementCreationTypes.value.remove(item.id)
                      //@ts-ignore
                      tableDomain?.filterDomain.ui.searchTagsIds.value.remove(item.id)
                      tableDomain?.filterDomain.triggerSearch();
                    }}
                  />
                )
              })}
            </div>
          </div>}
      </div>

      <SearchRequirement tableDomain={tableDomain} />
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  tableToolBar: {
    padding: '20px 10px 0px 15px',
    borderBottom: '1px solid rgba(81, 81, 81, 1)',
    zIndex: 999,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: window.innerWidth - 239
  },
  search: {
    alignSelf: 'flex-end',
  },
  actions: {
    padding: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  button: {
    width: '220px',
    height: 'fit-content',
    marginRight: '10px',
    color: '#f4f4f4',
  },
  add: {
    marginBottom: '20px',
    marginRight: '10px',
  },
  delete: {
    backgroundColor: `${Colors.redButtonOpacity('0.6')} `,
    marginBottom: '20px',
  },
  buttons: {
    display: 'flex',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  saveButton: {
    height: 'fit-content',
    marginRight: '10px',
  },
  tableName: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
  },
  tableNameMain: {
    fontWeight: 100,
    margin: 0,
  },
  tableNameType: {
    fontSize: 44,
    margin: 0,
    fontWeight: 100,
  },
  filterValue: {
    justifyContent: 'center',
    height: '32px',
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    borderRadius: '16px',
    marginRight: 5,
  },
  filterValueBlock: {
    width: '100%',
    display: 'flex',
    marginBottom: 10,
  }
}));