import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { UserProjectRoleAdminForm } from '../../projectRole/entity/form/UserProjectRoleAdminForm';
import { UserProjectRoleAdminTable } from '../../projectRole/entity/table/UserProjectRoleAdminTable';
import { SystemRoleAdminTable } from '../../systemRole/entity/SystemRoleAdminTable';
import { UserAdminForm } from '../../user/entity/form/UserAdminForm';
import { UserAdminTable } from '../../user/entity/table/UserAdminTable';
import { ISettingsItem } from '../ISettingsItem';

export const UserSettingsItem: ISettingsItem = {
  id: 'user',
  settingsItem: {
    key: 'user',
    title: 'settings.userManagementTitle',
    buttons: [
      {
        title: 'settings.users',
        url: `/settings/user/entity`,
        permissions: [UserSystemRoleModelPermissionMap['settings-user-entity-read']],
      },
      {
        title: 'settings.projectRoles',
        url: `/settings/user/project/role/entity`,
        permissions: [UserSystemRoleModelPermissionMap['settings-user-project-role-read']],
      },
      {
        title: 'settings.systemRoles',
        url: `/settings/user/role/system`,
        permissions: [UserSystemRoleModelPermissionMap['settings-user-system-role-read']],
      },
    ],
    permissions: [
      UserSystemRoleModelPermissionMap['settings-user-entity-read'],
      UserSystemRoleModelPermissionMap['settings-user-project-role-read'],
      UserSystemRoleModelPermissionMap['settings-user-system-role-read'],
    ],
    infoText: 'settings.userManagementDes',
    orderIndex: 900,
  },
  routers: [
    {
      key: 'user-new-settings',
      path: '/settings/settings/user/entity/new/server',
      pageTitle: 'Добавить пользователя',
      component: UserAdminForm,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-user-entity-create']],
    },

    {
      key: 'user-edit-settings',
      path: '/settings/user/entity/:userId/edit',
      pageTitle: 'Редактировать пользователя',
      component: UserAdminForm,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-user-entity-update']],
    },
    {
      key: 'user-list-settings',
      path: '/settings/user/entity',
      pageTitle: 'Пользователи',
      component: UserAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-user-entity-read']],
    },
    {
      key: 'project-role-new-settings',
      path: '/settings/user/project/role/new',
      pageTitle: 'Добавить проектную роль',
      component: UserProjectRoleAdminForm,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-user-project-role-create']],
    },
    {
      key: 'project-role-edit-settings',
      path: '/settings/user/project/role/:roleId/edit',
      pageTitle: 'Редактировать проектную роль',
      component: UserProjectRoleAdminForm,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-user-project-role-update']],
    },
    {
      key: 'project-role-list-settings',
      path: '/settings/user/project/role/entity',
      pageTitle: 'Проектные роли',
      component: UserProjectRoleAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-user-project-role-read']],
    },
    {
      key: 'system-role-list-settings',
      path: '/settings/user/role/system',
      pageTitle: 'Системные роли',
      component: SystemRoleAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-user-system-role-read']],
    },
    {
      key: 'system-role-new-settings',
      path: '/settings/user/role/system/new',
      pageTitle: 'Добавить системную роль',
      component: SystemRoleAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-user-system-role-create']],
    },
    {
      key: 'system-role-edit-settings',
      path: '/settings/user/role/system/:id/edit',
      pageTitle: 'Редактировать системную роль',
      component: SystemRoleAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-user-system-role-update']],
    },
  ],
};
