import AssignmentIcon from '@mui/icons-material/Assignment';
import { Button, Grid, Menu, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react';

import { ICoreSearchAudit } from '../../../../../../../service/coreCommon/common/audit/ICoreSearchAudit';
import { UserProjectRoleAction } from '../../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import {
  UserSystemRoleModelPermissionMap,
} from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ApplicationDetailDomain } from '../../store/ApplicationDetailDomain';
import { IActiveRequirementsActionsProperties } from '../requirements/ActiveRequirementsActions';
import { ContainerContext } from '../../../../../../../common/container/ContainerContext';
import { IApplicationModelWithUser } from '../applicationInfoHistory/store/ApplicationHistoryUI';
import { IApplicationModel } from '../../../../../../../service/application/entity/IApplicationModel';
import { Version } from '../../store/ApplicationDetailUI';

export interface IApplicationInfoReportProperties extends IActiveRequirementsActionsProperties {
  domain: ApplicationDetailDomain;
  audit?: ICoreSearchAudit;
  isNeedColor?: boolean;
  listWithAudits?: IApplicationModelWithUser[];
  fullVersion?: string;
  application?: IApplicationModel;
  isNeedProtocol?: boolean;
}

const formats = ['docx', 'pdf'];

export const generateName = ({
  domain,
  reportName,
}: {
  domain: ApplicationDetailDomain;
  reportName?: string;
}): string => {
  return ``;
};

export const generateWithMinorVersion = ({ domain }: { domain: ApplicationDetailDomain }): string => {
  return ``;
};

export const generateMajorVersion = ({ domain }: { domain: ApplicationDetailDomain }): string => {
  return ``;
};

export const DownloadAcceptanceTests = observer(
  ({
    domain,
    extendedGenerateName,
    format,
    audit,
    popupState,
    listWithAudits,
    fullVersion,
    application,
  }: {
    domain: ApplicationDetailDomain;
    extendedGenerateName: ({
      domain,
      reportName,
    }: {
      domain: ApplicationDetailDomain;
      reportName?: string | undefined;
    }) => string;
    format: string;
    audit: ICoreSearchAudit | undefined;
    popupState: any;
    listWithAudits?: IApplicationModel[] | undefined;
    fullVersion?: string | null;
    application?: IApplicationModel,
  }) => {
    const name = extendedGenerateName({ domain, reportName: 'Протокол ПСИ ИБ' })
    return (
      <MenuItem
        onClick={() => {
          domain.downloadApplicationProtocolReport({
            format,
            audit,
            reportName: extendedGenerateName({ domain, reportName: 'Протокол ПСИ ИБ' }),
          });
          popupState.close();
        }}
      >
        Протокол ({format})
      </MenuItem>
    );
  },
);

export const QuestionnaireDownload =
  observer((
    {
      domain,
      format,
      audit,
      isNeedColor,
      popupState,
      application,
    }: {
      domain: ApplicationDetailDomain,
      format: string,
      audit?: ICoreSearchAudit,
      isNeedColor?: boolean,
      popupState: any,
      application?: IApplicationModel,
    }) => {
    const container = useContext(ContainerContext);
    const extendedGenerateName = container.get(generateName);

    return (<MenuItem
      onClick={() => {
        domain.downloadApplicationQuestionnaire({
          format,
          audit,
          isNeedColor: true,
          reportName:
            `${extendedGenerateName({ domain, reportName: 'Анкета ИБ' })}`,
        });
        popupState.close();
      }}
    >
      Анкета ({format})
    </MenuItem>);
  });

export const RequirementsDownload = observer((
  {
    domain,
    format,
    audit,
    isNeedColor,
    popupState,
    application,
  }: {
    domain: ApplicationDetailDomain,
    format: string,
    audit?: ICoreSearchAudit,
    isNeedColor?: boolean,
    popupState: any,
    application?: IApplicationModel,
  }) => {
  const container = useContext(ContainerContext);
  const extendedGenerateName = container.get(generateName);

  return (
    <MenuItem
      onClick={() => {
        domain.downloadApplicationRequirementsReport({
          format,
          audit,
          isNeedColor,
          reportName: `${extendedGenerateName({ domain, reportName: 'Требования ИБ' })}`,
        });
        popupState.close();
      }}
    >
      Требования ({format})
    </MenuItem>
  );
});

export const TestingProgramDownload = observer((
  {
    domain,
    format,
    audit,
    isNeedColor,
    popupState,
    application,
  }: {
    domain: ApplicationDetailDomain,
    format: string,
    audit?: ICoreSearchAudit,
    isNeedColor?: boolean,
    popupState: any,
    application?: IApplicationModel,
  }) => {
  const container = useContext(ContainerContext);
  const extendedGenerateName = container.get(generateName);

  return (
    <MenuItem
      onClick={() => {
        domain.downloadApplicationTestProgramReport({
          format,
          audit,
          reportName: extendedGenerateName({ domain, reportName: `ПИМИ ИБ` }),
          versionNumber: '',
        });
        popupState.close();
      }}
    >
      Программа испытаний ({format})
    </MenuItem>
  );
});

export const ApplicationInfoReport = observer(({ domain, audit, isNeedColor, listWithAudits, fullVersion, application, isNeedProtocol = true }: IApplicationInfoReportProperties) => {
  const isCanSeeReports =
    domain.ui.userProjectRoleActions.value.includes(UserProjectRoleAction.reportsAccess) ||
    domain.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]) ||
    domain.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['project-not-in-team-permission-report-access'],
    ]);
  const classes = useStyles();
  const container = useContext(ContainerContext);
  const extendedGenerateName = container.get(generateName);
  const ExtendedAcceptanceTestsDownload = container.get(DownloadAcceptanceTests);
  const ExtendedQuestionnaireDownload = container.get(QuestionnaireDownload);
  const ExtendedRequirementsDownload = container.get(RequirementsDownload);
  const ExtendedTestingProgramDownload = container.get(TestingProgramDownload);

  return isCanSeeReports ? (
    <Grid key="downloadApplicationReport" className={classes.button} item>
      <PopupState variant="popover" popupId="reportsDownloadMenu">
        {(popupState) => (
          <React.Fragment>
            <Button variant="outlined" startIcon={<AssignmentIcon />} color="secondary" {...bindTrigger(popupState)}>
              Отчёты
            </Button>
            <Menu {...bindMenu(popupState)}>
              {formats.map((format) => {
                return (
                  <ExtendedQuestionnaireDownload
                    domain={domain}
                    popupState={popupState}
                    format={format}
                    audit={audit}
                    application={application}
                    isNeedColor={isNeedColor}
                  />
                );
              })}

              {formats.map((format) => {
                return (
                  <ExtendedRequirementsDownload
                    domain={domain}
                    popupState={popupState}
                    format={format}
                    audit={audit}
                    application={application}
                    isNeedColor={isNeedColor}
                  />
                );
              })}

              {formats.map((format) => {
                return (
                  <ExtendedTestingProgramDownload
                    domain={domain}
                    popupState={popupState}
                    format={format}
                    audit={audit}
                    application={application}
                    isNeedColor={isNeedColor}
                  />
                );
              })}

              {isNeedProtocol && formats.map((format) => {
                return (
                  <ExtendedAcceptanceTestsDownload
                    domain={domain}
                    format={format}
                    audit={audit}
                    extendedGenerateName={extendedGenerateName}
                    popupState={popupState}
                    listWithAudits={listWithAudits}
                    fullVersion={fullVersion}
                  />
                );
              })}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </Grid>
  ) : (
    <Fragment />
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: theme.spacing(1),
  },
}));
