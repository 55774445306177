import { inject } from '../../../../../../../../common/container/inject';
import { IDataTableDomain } from './../../../../../../../design/dataTable/store/IDataTableDomain';
import { DataTableFormDomain } from '../../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IMainLayoutDomainStore } from '../../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ActiveDirectoryModalUI, IActiveDirectoryModalUI } from './ActiveDirectoryAdminModalUI';
import { IActiveDirectoryServerEntityService } from '../../../../../../../../service/activeDirectory/server/IActiveDirectoryServerEntityService';
import { ActiveDirectoryServerAdminModel } from './ActiveDirectoryServerAdminModel';
import { IEntityListStore } from '../../../../../../../../common/store/interface/IEntityListStore';
import { IUserSystemRoleModel } from '../../../../../../../../service/systemRole/entity/IUserSystemRoleModel';
import { injectEntityList } from '../../../../../../../../common/store/base/injectEntityList';
import { IRootAdminService, RootAdminServiceToken } from '../../../../../../../../service/RootAdminService';
import { IActiveDirectorySystemRoleToGroupNameMapItem } from '../../../../../../../../service/activeDirectory/server/IActiveDirectoryServerEntityModel';

export interface IActiveDirectoryModalDomain<ModelType> extends IDataTableFormDomain<ModelType> {
  ui: IActiveDirectoryModalUI<ModelType>
}
export class ActiveDirectoryModalDomain
  extends DataTableFormDomain<ActiveDirectoryServerAdminModel, IActiveDirectoryServerEntityService>
  implements IActiveDirectoryModalDomain<ActiveDirectoryServerAdminModel> {
  protected rootPrivateServices = inject<IRootAdminService>(RootAdminServiceToken);
  public systemRoles: IEntityListStore<IUserSystemRoleModel> = injectEntityList([]);
  public ui: IActiveDirectoryModalUI<ActiveDirectoryServerAdminModel>
  constructor(
    entityService: IActiveDirectoryServerEntityService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<ActiveDirectoryServerAdminModel, any>,
    ui = new ActiveDirectoryModalUI(),

  ) {
    super(entityService, layoutDomain, dataTableDomain);
    this.ui = ui
    this.modalOptions.setValue({
      fullWidthMaxSize: '900px',
    });
  }

  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependencies()
  }

  async loadDependencies() {
    const systemRoles = (await this.rootPrivateServices.systemRole.entity.search({ limit: 100000 })).data;
    this.systemRoles.setList(systemRoles);
  }

  save = async () => {
    this.ui.isLoading.setValue(true)
    this.ui.validationErrors.setList([])
    this.ui.mappingValidationErrorsIndexes.setValue([]);

    if (!this.ui.model.entity.systemRoleToAdGroup) {
      this.ui.model.entity.systemRoleToAdGroup = { mapping: [] };
    }
    this.checkSavingMapping()
    await this.removeValidationErrors();
    if (this.ui.mappingValidationErrorsIndexes.value?.length === 0) {
      await super.save();
      if (this.ui.validationErrors.list.length === 0) {
        await this.dataTableDomain.boot();
        this.onCloseModal();
      }
    }
    this.ui.isLoading.setValue(false)
  };

  async checkSavingMapping() {
    const mapping: IActiveDirectorySystemRoleToGroupNameMapItem[] = this.ui.model.entity.systemRoleToAdGroup.mapping
    const mappingValidationErrorsIndexes: number[] = []
    const clearMapping = mapping.map((mappingItem, index) => {
      if (mappingItem.groupName && mappingItem.systemRoleId) {
        return mappingItem
      } else {
        mappingValidationErrorsIndexes.push(index)
      }
    }).filter((item) => !!item)
    this.ui.mappingValidationErrorsIndexes.setValue([...mappingValidationErrorsIndexes])
  }
}
