import i18next from 'i18next';
import { ITranslationsKeys } from '../../locales/ITranslationsKeys';

export interface ILocalization {
  i18n: typeof i18next;
  translate(key: ITranslationsKeys, options?: Record<string, any>): string;
  translateAnyway(key: string, options?: Record<string, any>): string;
  changeLanguage(language: string): any;
  getCurrentLanguage: () => string
}
export const ILocalizationToken = Symbol.for('ILocalizationToken');
