import { CollectionsBookmarkOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Box, Chip, CircularProgress, Paper, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { ConfigManager } from '../../../../../../application/config/ConfigManager';
import { TranslateText } from '../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../application/language/useI18n';
import { IUnitListAdminPageDomain } from '../../../../../../extension/learning/view/admin/page/unitListPage/store/IUnitListAdminPageDomain';
import { UnitListAdminPageDomain } from '../../../../../../extension/learning/view/admin/page/unitListPage/store/UnitListAdminPageDomain';
import { ApplicationInfoWarning } from '../../../../../../extension/learning/view/user/applicationInfo/parts/ApplicationInfoWarning';
import { ProjectButtonLink } from '../../../../../design/link/ProjectButtonLink';
import { IAdminPageProperties } from '../../../IAdminPageProperties';
import { AdminTable } from '../../../table/AdminTable';
import { UserProjectRoleAdminDomain } from './store/UserProjectRoleAdminDomain';

export interface UserProjectRoleAdminTableProperties extends IAdminPageProperties {}

export const UserProjectRoleAdminTable = observer(({ layoutDomain }: UserProjectRoleAdminTableProperties) => {
  const { translate } = useI18n();
  const [domain] = useState(new UserProjectRoleAdminDomain(layoutDomain));
  const [unitListAdminPageDomain] = useState<IUnitListAdminPageDomain>(new UnitListAdminPageDomain(layoutDomain));
  const extensions = ConfigManager.getConfig()?.extensions?.include;
  const classes = useStyles();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const unitsUpdating = searchParams.get('unitsUpdating');

  useEffect(() => {
    if (extensions.includes('learning')) {
      unitListAdminPageDomain.loadData();
    }
  }, [extensions, unitListAdminPageDomain]);

  useEffect(() => {
    //TODO: отрефакторить, избавиться от useEffect, собирать данные на бэке
    if (extensions.includes('learning') && unitListAdminPageDomain.ui.filtredGroupedUnits.list.length) {
      const allPublishedUnits = unitListAdminPageDomain.ui.filtredGroupedUnits.list
        .flatMap((group) => group.unitsList)
        .filter((item) => item.isPublished);

      domain.ui.columns.setList([
        ...domain.ui.columns.list,
        {
          field: 'requiredUnits',
          title: translate('phrases.units'),
          render: (rowData) => {
            if (!rowData?.requiredUnits?.length) {
              return <></>;
            }
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  alignItems: 'start',
                }}
              >
                <div>{rowData.requiredUnits.filter((_, i) => i < 10).join(', ')}</div>
                <Chip label={rowData.requiredUnits.length} variant="outlined" />
              </div>
            );
          },
        },
      ]);

      const rolesMappedToUnits = domain.ui.entities.list.map((item) => ({
        ...item,
        requiredUnits: allPublishedUnits
          .filter((unit) => unit.settings.teamRolesIds.includes(item.id as string))
          .map((item) => item.settings.titleHeading),
      }));

      domain.ui.entities.setList(rolesMappedToUnits);
    }
  }, [domain.ui.columns, extensions, unitListAdminPageDomain.ui.filtredGroupedUnits.list.length]);

  return (
    <Fragment>
      {unitsUpdating && (
        <Box my={2}>
          <ApplicationInfoWarning
            warningText={translate('projectRoles.rolesUpdatedTitle')}
            description={translate('projectRoles.rolesUpdatedMessage')}
          />
        </Box>
      )}

      {domain.ui.isCanCreate.value && (
        <Paper className={classes.root} elevation={3}>
          <ProjectButtonLink icon={<AddIcon />} url={`/settings/user/project/role/new`}>
            <TranslateText i18nKey={'phrases.addRole'} />
          </ProjectButtonLink>
        </Paper>
      )}

      {unitListAdminPageDomain.ui.isLoading.value && (
        <Stack justifyContent="center" width="100%" flexDirection="row" height="400px" alignItems="center">
          <CircularProgress />
        </Stack>
      )}

      <Box display={unitListAdminPageDomain.ui.isLoading.value ? 'none' : 'block'}>
        <AdminTable tableDomain={domain} />
      </Box>
    </Fragment>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));
