import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { UIStore } from '../../../../../../common/store/base/UIStore';
import { IProjectModel } from '../../../../../../service/project/IProjectModel';
import { TransferApplicationType } from '../parts/TransferTypeEnum';
import { IProjectApplicationView } from './model/IProjectApplicationView';
import { IProjectView, defaultProjectView } from './model/IProjectView';
import { IStatusValueModel } from '../../../../../../service/status/value/IStatusValueModel';

export class ProjectInfoUI extends UIStore {
  constructor(
    public isLoading = injectPrimitive<boolean>(true),
    public projects = injectEntityList<IProjectView>([defaultProjectView]),
    public filteredProjects = injectEntityList<IProjectView>([]),
    public projectsNewMessagesData = injectPrimitive<any[]>([]),
    public tableSettings = injectPrimitive<{ showRequirement: boolean; showStatuses: boolean }>({
      showRequirement: true,
      showStatuses: true,
    }),
    public teamTableRowsHeaders = injectEntityList<{ title: string; key: string }>([
      {
        title: 'fullName',
        key: 'fullname',
      },
      {
        title: 'empty',
        key: 'empty',
      },
      {
        title: 'projetRole',
        key: 'projetRole',
      },
    ]),

    public teamTableRowsContent = injectEntityList<{
      projectId: string;
      data: { memberId: string; data: { width?: number; content: any }[] }[];
    }>([]),

    public isOpenTransferModal = injectPrimitive<boolean>(false),
    public projectNames = injectEntityList<IProjectModel>([]),
    public transferProjectId = injectPrimitive<string>(''),
    public transferApplication = injectEntity<IProjectApplicationView | null>(null),
    public transferApplicationType = injectPrimitive<TransferApplicationType | null>(null),
    public copyApplicationData = injectPrimitive<{
      newApplicationName: string,
      applicationId: string,
      projectId: string,
      newLabelsForJira: string[],
      needToCopy: {
        isNeedLocalRequirements: boolean,
        isNeedStatuses: boolean,
        isNeedAdditionalColumns: boolean,
        isNeedJiraIntegrations: boolean,
        isNeedVerifyAllRequirements: boolean
      }
    }>({
      newApplicationName: '',
      applicationId: '',
      projectId: '',
      newLabelsForJira: [],
      needToCopy: {
        isNeedLocalRequirements: false,
        isNeedStatuses: false,
        isNeedAdditionalColumns: false,
        isNeedJiraIntegrations: false,
        isNeedVerifyAllRequirements: false
      }
    }),
    public isTransferLoading = injectPrimitive<boolean>(false),
    public searchTerm = injectPrimitive<string>(''),
    public projectsTotalCount = injectPrimitive<number>(0),
    public applicationsTotalCount = injectPrimitive<number>(0),
    public projectListRenderLimit = injectPrimitive<number>(10),
    public allStatusesValues = injectEntityList<IStatusValueModel>([])
  ) {
    super();
  }

  startLoading() {
    this.isLoading.setValue(true);
  }

  endLoading() {
    this.isLoading.setValue(false);
  }
}
