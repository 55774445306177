import { FormControlLabel, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { StatusValueModalDomain } from './StatusValueModalDomain';
import { FormAutocomplete, FormAutocompleteOptionIconPosition } from '../../../../../../design/form/FormAutocomplete';
import { StatusValueColor } from '../../color/StatusValueColor';
import { StatusValueColorIcon } from '../../color/StatusValueColorIcon';
import { Colors } from '../../../../../../design/color/Colors';

export interface IStatusValueAdminTableProperties {
  domain: StatusValueModalDomain;
  dataCy?: string;
}

export const StatusValueModal = observer(({ domain }: IStatusValueAdminTableProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
  }, [domain]);
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <StatusValueNameCustomFieldText domain={domain} />
      <StatusValueDescriptionCustomFieldArea domain={domain} />
      <StatusValueStatusDropDown domain={domain} />
      <StatusValueOrderCustomFieldText domain={domain} />
      <StatusValueShowInStatsCustomSwitch domain={domain} />
      <StatusValueColorAutoComplete domain={domain} />
      <StatusValueActiveStatusCustomSwitch domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const StatusValueNameCustomFieldText = observer(({ domain }: { domain: StatusValueModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
      inputProps={{
        'data-cy': 'spec-entity-title'
      }}
    />
  );
});

const StatusValueDescriptionCustomFieldArea = observer(
  ({ domain }: { domain: StatusValueModalDomain }) => {
    return (
      <FormTextArea
        required={true}
        label={'Описание'}
        value={domain.ui.model.entity.description}
        onChange={domain.getUpdateFieldHandler('description')}
        errorMessage={domain.getValidationErrorFor('description')?.message}
        dataCy='spec-entity-description'
      />
    );
  },
);

const StatusValueStatusDropDown = observer(({ domain }: { domain: StatusValueModalDomain }) => {
  const roleValues: any = [
    ...domain.dataTableDomain.statusEntities.list.map((status) => ({
      value: status.id,
      name: status.name,
    })),
  ];
  return (
    <FormAutocomplete
      values={roleValues}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.statusId = newValue.value
      }}
      selected={domain.ui.model.entity.statusId || ''}
      label="Категория статуса"
      errorMessage={undefined}
      dataCy='category-select'
    />
  );
});

const StatusValueOrderCustomFieldText = observer(({ domain }: { domain: StatusValueModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Порядок'}
      type="text"
      value={domain.ui.model.entity.orderIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
      errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
      inputProps={{
        maxLength: 9,
        'data-cy': 'spec-entity-order-number'
      }}
    />
  );
});

const StatusValueActiveStatusCustomSwitch = observer(
  ({ domain }: { domain: StatusValueModalDomain }) => {
    const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

    const handleChange = (event: any) => {
      setChecked(event.target.checked);
      domain.ui.model.entity.isActive = event.target.checked;
    };
    useEffect(() => {
      setChecked(domain.ui.model.entity.isActive);
    }, [domain.ui.model.entity.isActive]);
    return (
      <FormControlLabel
        style={{ width: '100%', padding: '16px', margin: 0 }}
        control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
        label="Активен"
      />
    );
  },
);

const StatusValueShowInStatsCustomSwitch = observer(
  ({ domain }: { domain: StatusValueModalDomain }) => {
    const [isChecked, setChecked] = useState(domain.ui.model.entity.isShowInStats);

    const handleChange = (event: any) => {
      setChecked(event.target.checked);
      domain.ui.model.entity.isShowInStats = event.target.checked;
    };
    useEffect(() => {
      setChecked(domain.ui.model.entity.isShowInStats);
    }, [domain.ui.model.entity.isShowInStats]);

    return (
      <FormControlLabel
        style={{ width: '100%', padding: '16px', margin: 0 }}
        control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={false} />}
        label="Показывать в системах"
      />
    );
  },
);

const StatusValueColorAutoComplete = observer(({ domain }: { domain: StatusValueModalDomain }) => {
  const color = StatusValueColor.find((item) => item.id === domain.ui.model.entity.colorId);

  return (
    <div key={domain.ui.model.entity.id + 'color'} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
      <div style={{ width: '80%' }}>

        <FormAutocomplete
          label={'Цвет'}
          onChangeSelect={function (newValue: any) {
            domain.ui.model.entity.colorId = newValue?.value;
            // props.onChange(newValue?.value || null);
          }}
          selected={domain.ui.model.entity.colorId || ''}
          values={StatusValueColor.map((item) => ({
            value: item.id,
            name: item.name,
            icons: [
              {
                position: FormAutocompleteOptionIconPosition.left,
                fragment: (
                  <StatusValueColorIcon
                    color={Colors.rgbaOpacityTemplate(item.rgbaTemplate, '0.6', 'rgba(255, 255, 255, 0.16)')}
                  />
                ),
              },
            ],
          }))}
        />
      </div>
      <StatusValueColorIcon
        color={Colors.rgbaOpacityTemplate(color?.rgbaTemplate, '0.6', 'rgba(255, 255, 255, 0.16)')}
        title={color?.name || 'Цвет не выбран'}
      />
    </div>
  );
});
