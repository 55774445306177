import { UserSystemRoleModelPermissionMap } from '../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ILocalization } from '../application/language/ILocalization';
import { LearningRoutes } from '../constants/LearningRoutes';
import { LearningAdminPage } from '../LearningAdminPage';
import { LearningUserPage } from '../LearningUserPage';
import LearningUnitPreviewPage from '../view/admin/page/LearningUnitPreviewPage';
import { LearningUserRatingPage } from '../view/admin/page/learningUserRatingPage/LearningUserRatingPage';
import { PassingPage } from '../view/admin/page/passing/Passing';
import { UnitPage } from '../view/admin/page/unit/UnitPage';
import { LearningAnalyticsPage } from '../view/analytics/LearningAnalyticsPage';

type IArgs = {
  i18n: ILocalization
}
export const LearningRouter = ({ i18n: { translate } }: IArgs) => [
  {
    path: LearningRoutes.LearningAdmin,
    pageTitle: translate('learningUserPage.learningTitle'),
    component: LearningAdminPage,
    allowedPermissions: [UserSystemRoleModelPermissionMap['global-allow-any']],
    fullHeight: true,
  },
  {
    path: LearningRoutes.LearningUnitCreate,
    pageTitle: translate('createUnit.pageTitle'),
    component: UnitPage,
    allowedPermissions: [UserSystemRoleModelPermissionMap['global-allow-any']],
    fullHeight: true,
  },
  {
    path: LearningRoutes.LearningUnitEdit,
    pageTitle: 'Редактирование Юнита',
    component: UnitPage,
    allowedPermissions: [UserSystemRoleModelPermissionMap['global-allow-any']],
    fullHeight: true,
  },
  {
    path: LearningRoutes.LearningUser,
    pageTitle: translate('learningUserPage.learningTitle'),
    component: LearningUserPage,
    allowedPermissions: [],
    fullHeight: true,
  },
  {
    path: LearningRoutes.LearningUnitPassing,
    pageTitle: 'Прохождение',
    component: PassingPage,
    allowedPermissions: [],
    showNavBar: false,
    fullHeight: true,
  },
  {
    path: LearningRoutes.LearningUserRating,
    pageTitle: 'Рейтинг пользователя',
    component: LearningUserRatingPage,
    allowedPermissions: [UserSystemRoleModelPermissionMap['global-allow-any']],
    fullHeight: true,
  },
  {
    path: LearningRoutes.LearningAdminAnalytics,
    pageTitle: translate('navigation.analytics'),
    component: LearningAdminPage,
    allowedPermissions: [],
    fullHeight: true,
    overflowY: true,
  },
  {
    path: LearningRoutes.LearningUserAnalytics,
    pageTitle: translate('navigation.analytics'),
    component: LearningUserPage,
    allowedPermissions: [],
    fullHeight: true,
    overflowY: true,
  },
  {
    path: LearningRoutes.LearningUnitPreview,
    pageTitle: 'Превью',
    component: LearningUnitPreviewPage,
    showNavBar: false,
    showPaddings: false,
    allowedPermissions: [UserSystemRoleModelPermissionMap['global-allow-any']],
    fullHeight: true,
  },
  {
    path: LearningRoutes.LearningAnalyticsTab,
    pageTitle: 'Аналитика Обучение',
    component: LearningAnalyticsPage,
    showNavBar: true,
    showPaddings: true,
    allowedPermissions: [UserSystemRoleModelPermissionMap['global-allow-any']],
    fullHeight: true,
  },
];
