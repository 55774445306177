import { ILocalization, ILocalizationToken } from '../../../../../../../application/language/ILocalization';
import { IContainer } from '../../../../../../../common/container/IContainer';
import { inject } from '../../../../../../../common/container/inject';
import { UserProjectRoleAction } from '../../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { UserProjectRoleActionExtension } from '../../../../../../../service/projectRole/entity/actions/UserProjectRoleActionExtension';
import { IRootAdminService, RootAdminServiceToken } from '../../../../../../../service/RootAdminService';
import { IRouterService, RouterServiceToken } from '../../../../../../../service/route/IRouterService';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { FormDomain } from '../../../../form/store/FormDomain';
import { IUserProjectRoleAdminModel } from './UserProjectRoleAdminModel';

export class UserProjectRoleAdminFormDomain extends FormDomain<IUserProjectRoleAdminModel, any> {
  public actionsValues: { name: string; value: string }[] = [];

  constructor(
    layoutDomain: IMainLayoutDomainStore,
    private container: IContainer,
    protected rootPrivateServices = inject<IRootAdminService>(RootAdminServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private i18n: ILocalization = inject<ILocalization>(ILocalizationToken)
  ) {
    super(layoutDomain, rootPrivateServices.projectRole.entity);
    this.setActionsValues();
  }

  async loadData(id: string | null) {
    await super.loadData(id);
  }

  save = async (urlParams: string) => {
    this.ui.model.entity.isActive = true;
    await this.removeValidationErrors();
    const id = await super.save();
    if (this.ui.validationErrors.list.length === 0) {
      this.router.goTo('/settings/user/project/role/entity' + urlParams);
    }

    return id as string
  };

  cancelEdit = async () => {
    this.router.goTo('/settings/user/project/role/entity');
  };

  backToRolesPage = () => {
    this.router.goTo('/settings/user/project/role/entity');
  }

  private setActionsValues() {
    const additionalActions = this.container.get(UserProjectRoleActionExtension);
    this.actionsValues = [
      {
        value: UserProjectRoleAction.updateFieldsProject,
        name: this.i18n.translate('projectRoles.editingProjectFields'),
      },
      {
        value: UserProjectRoleAction.updateTeamProject,
        name: this.i18n.translate('projectRoles.editingProjectTeams'),
      },
      {
        value: UserProjectRoleAction.creteApplication,
        name: this.i18n.translate('projectRoles.creatingNewProfiles'),
      },
      {
        value: UserProjectRoleAction.editApplication,
        name: this.i18n.translate('projectRoles.editingProfiles'),
      },
      {
        value: UserProjectRoleAction.reportsAccess,
        name: this.i18n.translate('projectRoles.generatingReports'),
      },
      {
        value: UserProjectRoleAction.customRequirementsAccess,
        name: this.i18n.translate('projectRoles.creatingRequirements'),
      },
      {
        value: UserProjectRoleAction.acceptCancelRequirementAccess,
        name: this.i18n.translate('projectRoles.acceptingAndDeletingRequirements'),
      },
      {
        value: UserProjectRoleAction.newMessagesNotification,
        name: this.i18n.translate('projectRoles.receivingNotificationsOfNewComments'),
      },
      {
        value: UserProjectRoleAction.showUnverifiedRequirements,
        name: this.i18n.translate('projectRoles.managingRequirementsVerification'),
      },
      {
        value: UserProjectRoleAction.showRegulators,
        name: this.i18n.translate('projectRoles.viewingRequirementsOfExternalRegulatoryAuthorities'),
      },
      {
        value: UserProjectRoleAction.jiraIntegration,
        name: this.i18n.translate('projectRoles.integratingASWithJira'),
      },
      ...additionalActions,
    ];
  }
}
