import { Close } from '@mui/icons-material';
import { Button, Divider, FormControlLabel, Grid, IconButton, Switch, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { IValidationErrorData } from '../../../../../../common/error/IValidationErrorData';
import { Colors } from '../../../../../../view/design/color/Colors';
import { FormModalContainer } from '../../../../../../view/design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../view/design/form/FormActions';
import { FormBox } from '../../../../../../view/design/form/FormBox';
import { FormTextField } from '../../../../../../view/design/form/FormTextField';
import { FormTextLabel } from '../../../../../../view/design/form/FormTextLabel';
import { IRiskManagerQuestionAnswerPresetItemModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { RiskManagerQuestionAnswerPresetFormDomain } from './RiskManagerQuestionAnswerPresetFormDomain';

export interface IRiskManagerQuestionAnswerPresetFormProperties {
  domain: RiskManagerQuestionAnswerPresetFormDomain;
}

export const RiskManagerQuestionAnswerPresetForm = observer(
  ({ domain }: IRiskManagerQuestionAnswerPresetFormProperties) => {
    const answersEndRef = useRef<any>(null);

    const scrollToBottom = () => {
      answersEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
      scrollToBottom();
    }, [domain.ui.model.entity?.answers.length]);

    const classes = useStyles();
    return (
      <FormModalContainer onClose={domain.onCancelUpdates}>
        <div style={{ width: 600 }}>
          <NameField domain={domain} />
          <IsDefaultField domain={domain} />
          <Grid
            className={classes.answerTitleContainer}
            justifyContent={'space-between'}
            container
            flexDirection="row"
            alignItems={'end'}
          >
            <Grid item>
              <FormTextLabel variant={'h5'} text={'Ответы: '} />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                // className={classes.actionButton}
                onClick={() => {
                  domain.ui.addAnswer();
                }}
              // disabled={disabled}
              >
                Добавить ответ
              </Button>
            </Grid>
          </Grid>
          <Divider light={true} variant={'middle'} />
          <Grid className={classes.answerContainer} flexWrap={'nowrap'} container flexDirection="column">
            {domain.ui.model.entity.answers.map((answer, index) => (
              <Grid key={answer.pseudoId} container>
                <AnswerNameField domain={domain} answer={answer} index={index} />
                <AnswerSortIndexField domain={domain} answer={answer} index={index} />
                <AnswerDefaultValueField domain={domain} answer={answer} index={index} />
                <FormBox>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      domain.ui.removeAnswer(answer.pseudoId);
                    }}
                    style={{ color: Colors.negativePassive }}
                  >
                    Удалить ответ
                  </Button>
                </FormBox>

                <FormBox>
                  <Divider style={{ width: 470 }} light={true} variant={'fullWidth'} />
                </FormBox>
              </Grid>
            ))}
            {domain.ui.model.entity.answers.length === 0 && (
              <Grid container justifyContent="center">
                Нет ответов
              </Grid>
            )}
            <div ref={answersEndRef} />
          </Grid>
          <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
        </div>
      </FormModalContainer>
    );
  },
);

const NameField = observer(({ domain }: { domain: RiskManagerQuestionAnswerPresetFormDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.ui.getPresetError('name')}
    />
  );
});

const IsDefaultField = observer(({ domain }: { domain: RiskManagerQuestionAnswerPresetFormDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isDefault);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isDefault = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isDefault);
  }, [domain.ui.model.entity.isDefault]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} />}
      label="По умолчанию"
    />
  );
});

const AnswerNameField = observer(
  ({
    domain,
    answer,
    index,
  }: {
    domain: RiskManagerQuestionAnswerPresetFormDomain;
    answer: IRiskManagerQuestionAnswerPresetItemModel;
    index: number;
  }) => {
    return (
      <FormTextField
        required={true}
        label={'Название'}
        value={answer.text}
        onChange={(newValue: any) => {
          answer.text = newValue;
        }}
        errorMessage={domain.ui.getAnswerError(index, 'text')}
      />
    );
  },
);

const AnswerSortIndexField = observer(
  ({
    domain,
    answer,
    index,
  }: {
    domain: RiskManagerQuestionAnswerPresetFormDomain;
    answer: IRiskManagerQuestionAnswerPresetItemModel;
    index: number;
  }) => {
    return (
      <FormTextField
        type="text"
        required={true}
        label={'Порядок сортировки'}
        value={answer.sortIndex?.toString()}
        onChange={domain.getUpdateIntegerFieldHandlerForSortIndex(answer)}
        errorMessage={domain.ui.getAnswerError(index, 'sortIndex')}
        inputProps={{ maxLength: 9 }}
      />
    );
  },
);

const AnswerDefaultValueField = observer(
  ({
    domain,
    answer,
    index,
  }: {
    domain: RiskManagerQuestionAnswerPresetFormDomain;
    answer: IRiskManagerQuestionAnswerPresetItemModel;
    index: number;
  }) => {
    return (
      <FormTextField
        type={'text'}
        required={true}
        label={'Значение по умолчанию'}
        value={answer.defaultValue?.toString()}
        onChange={(newValue: any) => {
          const INTEGER_REGEX = /^-?\d*$/;
          if (INTEGER_REGEX.test(newValue) || newValue === '') {
            answer.defaultValue = newValue
          }
        }}
        errorMessage={domain.ui.getAnswerError(index, 'defaultValue')}
        inputProps={{ maxLength: 9 }}
      />
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  answerTitleContainer: {
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  answerContainer: {
    // padding: theme.spacing(2),
    height: '400px',
    width: '100%',
    // overflow: 'scroll',
    overflow: 'scroll',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}));
