import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IUnitChapter } from '../../../../service/unit/interface/IUnitTheory';
import { IComponentList, createSettingList } from '../ComponentList';
import { IConstructorUI } from './IConstructorUI';

export class ConstructorUI implements IConstructorUI {
  constructor(
    public isEnabled = injectPrimitive<boolean>(true),
    public isLoading = injectPrimitive<boolean>(false),
    public isEmpty = injectPrimitive<boolean>(false),
    public componentList = injectEntityList<IComponentList>(createSettingList()),
    public activeChapter = injectPrimitive<IUnitChapter>({
      title: 'Chapter 1',
      theoryBlocks: [],
      pseudoId: '',
      sortIndex: 1,
    }),
  ) { }

  startLoading(): void {
    this.isEmpty.setValue(false);
    this.isLoading.setValue(true);
  }
  endLoading(): void {
    this.isLoading.setValue(false);
  }
}
