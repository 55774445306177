import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { IList } from '../../../../../../service/unit/interface/theoryBlocks/IList';

export interface IListProperties {
  data: IList;
}
export const List = observer(({ data }: IListProperties) => {
  const classes = useStyles();

  return data.type === 'Numbered' ? (
    <ol className={classes.listContainer}>
      {data.listData.map((value, index) => (
        <li key={index}>{value.text}</li>
      ))}
    </ol>
  ) : (
    <ul className={classes.listContainer}>
      {data.listData.map((value, index) => (
        <li key={index}>{value.text}</li>
      ))}
    </ul>
  );
});

const useStyles = makeStyles(() => ({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    fontSize: '20px',
    lineHeight: 1.6,
    margin: '0 0 16px 0',
  },
}));
