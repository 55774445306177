export enum UserSystemRoleModelPermissionMap {
  'plugin-settings-logger-is-allow' = 'plugin-settings-logger-is-allow',
  'global-allow-any' = 'global-allow-any',
  'is-have-access-to-projects' = 'is-have-access-to-projects',
  'project-can-create' = 'project-can-create',
  'project-in-team-delete' = 'project-in-team-delete',
  'project-in-team-update' = 'project-in-team-update',
  'project-in-team-read' = 'project-in-team-read',
  'project-not-in-team-access' = 'project-not-in-team-access',
  'project-not-in-team-permission-create-project' = 'project-not-in-team-permission-create-project',
  'project-not-in-team-permission-update-team-project' = 'project-not-in-team-permission-update-team-project',
  'project-not-in-team-permission-update-fields-project' = 'project-not-in-team-permission-update-fields-project',
  'project-not-in-team-permission-update-project' = 'project-not-in-team-permission-update-project',
  'project-not-in-team-permission-delete-project' = 'project-not-in-team-permission-delete-project',
  'project-not-in-team-permission-create-application' = 'project-not-in-team-permission-create-application',
  'project-not-in-team-permission-update-application' = 'project-not-in-team-permission-update-application',
  'project-not-in-team-permission-delete-application' = 'project-not-in-team-permission-delete-application',
  'project-not-in-team-permission-report-access' = 'project-not-in-team-permission-report-access',
  'project-not-in-team-permission-custom-requirement-access' = 'project-not-in-team-permission-custom-requirement-access',
  'project-not-in-team-permission-accept-cancel-access' = 'project-not-in-team-permission-accept-cancel-access',
  'project-not-in-team-permission-show-unverified-requirements-access' = 'project-not-in-team-permission-show-unverified-requirements-access',
  'project-not-in-team-permission-show-regulators' = 'project-not-in-team-permission-show-regulators',
  'project-not-in-team-permission-new-comments-access' = 'project-not-in-team-permission-new-comments-access',
  'project-not-in-team-permission-jira-intergration' = 'project-not-in-team-permission-jira-intergration',
  'settings-is-allow' = 'settings-is-allow',
  'settings-specifications-delete' = 'settings-specifications-delete',
  'settings-specifications-create' = 'settings-specifications-create',
  'settings-specifications-update' = 'settings-specifications-update',
  'settings-specifications-read' = 'settings-specifications-read',
  'settings-requirements-delete' = 'settings-requirements-delete',
  'settings-requirements-create' = 'settings-requirements-create',
  'settings-requirements-update' = 'settings-requirements-update',
  'settings-requirements-read' = 'settings-requirements-read',
  'settings-requirements-accept' = 'settings-requirements-accept',
  'settings-requirement-vendors-delete' = 'settings-requirement-vendors-delete',
  'settings-requirement-vendors-create' = 'settings-requirement-vendors-create',
  'settings-requirement-vendors-update' = 'settings-requirement-vendors-update',
  'settings-requirement-vendors-read' = 'settings-requirement-vendors-read',
  'settings-tags-delete' = 'settings-tags-delete',
  'settings-tags-create' = 'settings-tags-create',
  'settings-tags-update' = 'settings-tags-update',
  'settings-tags-read' = 'settings-tags-read',
  'settings-statuses-delete' = 'settings-statuses-delete',
  'settings-statuses-create' = 'settings-statuses-create',
  'settings-statuses-update' = 'settings-statuses-update',
  'settings-statuses-read' = 'settings-statuses-read',
  'settings-performers-delete' = 'settings-performers-delete',
  'settings-performers-create' = 'settings-performers-create',
  'settings-performers-update' = 'settings-performers-update',
  'settings-performers-read' = 'settings-performers-read',
  'settings-additional-columns-delete' = 'settings-additional-columns-delete',
  'settings-additional-columns-create' = 'settings-additional-columns-create',
  'settings-additional-columns-update' = 'settings-additional-columns-update',
  'settings-additional-columns-read' = 'settings-additional-columns-read',
  'settings-integrations-delete' = 'settings-integrations-delete',
  'settings-integrations-create' = 'settings-integrations-create',
  'settings-integrations-update' = 'settings-integrations-update',
  'settings-integrations-read' = 'settings-integrations-read',
  'settings-application-template-delete' = 'settings-application-template-delete',
  'settings-application-template-create' = 'settings-application-template-create',
  'settings-application-template-update' = 'settings-application-template-update',
  'settings-application-template-read' = 'settings-application-template-read',
  'settings-user-entity-delete' = 'settings-user-entity-delete',
  'settings-user-entity-create' = 'settings-user-entity-create',
  'settings-user-entity-update' = 'settings-user-entity-update',
  'settings-user-entity-read' = 'settings-user-entity-read',
  'settings-user-project-role-delete' = 'settings-user-project-role-delete',
  'settings-user-project-role-create' = 'settings-user-project-role-create',
  'settings-user-project-role-update' = 'settings-user-project-role-update',
  'settings-user-project-role-read' = 'settings-user-project-role-read',
  'settings-user-system-role-delete' = 'settings-user-system-role-delete',
  'settings-user-system-role-create' = 'settings-user-system-role-create',
  'settings-user-system-role-update' = 'settings-user-system-role-update',
  'settings-user-system-role-read' = 'settings-user-system-role-read',
  'settings-user-system-role-application-transfer' = 'settings-user-system-role-application-transfer',
}
