import { ApplicationRouterExtensions } from '../../application/ApplicationRouterExtensions';
import { IContainer } from '../../common/container/IContainer';
import { AnalyticsPageComponent } from '../../view/admin/page/analytics/AnalyticsPageInjects';
import { LeftNavigationInjects } from '../../view/layout/main/parts/LeftNavigation/LeftNavigationInjects';
import { MainLayoutBottomLogoMenu } from '../../view/layout/main/parts/LeftNavigation/parts/MainLayoutBottomLogoMenu';
import { ApplicationDetailDomain } from '../../view/user/page/application/info/store/ApplicationDetailDomain';
import { ApplicationDetailUI } from '../../view/user/page/application/info/store/ApplicationDetailUI';
import { ProjectInfoDomain } from '../../view/user/page/project/info/store/ProjectInfoDomain';
import { LeftProjectButtons } from '../../view/user/page/project/list/inject/LeftProjectButtons';
import { ProjectListDomain } from '../../view/user/page/project/list/store/ProjectListDomain';
import { IExtension } from '../interface/IExtension';
import { ILocalization, ILocalizationToken } from './application/language/ILocalization';
import { LearningNavigation } from './navigation/LearningNavigation';
import { LearningRouter } from './router/LearningRouter';
import { LearningRootService, LearningRootServiceToken } from './service/LearningRootService';
import { LearningAnalyticsPage } from './view/analytics/LearningAnalyticsPage';
import { extendApplicationDetailDomainConstructor } from './view/user/applicationInfo/domain/extendApplicationDetailDomainConstructor';
import { extendApplicationDetailUIConstructor } from './view/user/applicationInfo/domain/extendApplicationDetailUIConstructor';
import { LearningBottomMenuLogo } from './view/user/bottomMenuLogo/LearningBottomMenuLogo';
import { extendProjectInfoDomainConstructor } from './view/user/projectInfo/domain/extendProjectInfoDomainConstructor';
import { extendProjectListDomainConstructor } from './view/user/projectList/domain/extendProjectListDomainConstructor';
import { ReportButton } from './view/user/projectList/ReportButton';
export default class LearningExtension implements IExtension {
  // @ts-ignore
  async loadExtension(container: IContainer) {
    container.set(MainLayoutBottomLogoMenu, LearningBottomMenuLogo);

    container = this.setRouters(container);
    container = this.setSettingsPage(container);
    container = this.setServices(container);
    container = this.setProjectTableColumns(container);
    container = this.setApplicationInfoPage(container);
    container = this.setReportButton(container);
    container = this.setAnalyticsPageMain(container);

    return container;
  }

  setSettingsPage(container: IContainer): IContainer {
    const settingsPageExtensions = container.get(LeftNavigationInjects);
    settingsPageExtensions.push(LearningNavigation);
    return container;
  }

  setRouters(container: IContainer) {
    let routerExtension = container.get(ApplicationRouterExtensions);
    const i18n: ILocalization = container.getByToken(ILocalizationToken)
    routerExtension.push(...LearningRouter({i18n}));
    container.set(ApplicationRouterExtensions, routerExtension);
    return container;
  }

  setServices(container: IContainer) {
    container.setByToken(LearningRootServiceToken, new LearningRootService());
    return container;
  }

  setProjectTableColumns(container: IContainer): IContainer {
    const projectListDomainConstructor = container.get(ProjectListDomain);
    const extendedProjectListDomainConstructor = extendProjectListDomainConstructor(projectListDomainConstructor);
    container.set(ProjectListDomain, extendedProjectListDomainConstructor);

    const projectInfoDomainConstructor = container.get(ProjectInfoDomain);
    const extendedProjectListInfoDomainConstructor = extendProjectInfoDomainConstructor(projectInfoDomainConstructor);
    container.set(ProjectInfoDomain, extendedProjectListInfoDomainConstructor);

    return container;
  }

  setApplicationInfoPage(container: IContainer): IContainer {
    const applicationDetailUIConstructor = container.get(ApplicationDetailUI);
    const extendedApplicationDetailUIConstructor = extendApplicationDetailUIConstructor(applicationDetailUIConstructor);
    container.set(ApplicationDetailUI, extendedApplicationDetailUIConstructor);

    const applicationDetailDomainConstructor = container.get(ApplicationDetailDomain);
    const extendedApplicationDetailDomainConstructor = extendApplicationDetailDomainConstructor(
      applicationDetailDomainConstructor,
    );
    container.set(ApplicationDetailDomain, extendedApplicationDetailDomainConstructor);

    return container;
  }

  setReportButton(container: IContainer): IContainer {
    const leftProjectListButtons = container.get(LeftProjectButtons);
    leftProjectListButtons.push({
      Component: ReportButton,
    });
    container.set(LeftProjectButtons, leftProjectListButtons);
    return container;
  }

  setBottomLogoMenu(container: IContainer): IContainer {
    return container;
  }
  setAnalyticsPageMain(container: IContainer,): IContainer {
    container.set(AnalyticsPageComponent,{Component:LearningAnalyticsPage})
    return container;
  }
}
