import React from "react";
import { FormHelperText, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export const RichTextArea = observer(({
  data,
  dataWithStyles,
  isNeedShowTable = false,
}: {
  data: string,
  dataWithStyles: string,
  isNeedShowTable?: boolean,
}) => {
  let currentText = dataWithStyles || data || ''
  if (!isNeedShowTable) {
    currentText = currentText.replace(/<figure class="table">(.*?)<\/figure>/g, '*ТАБЛИЦА*');
  }
  const classes = useStyles({ minHeight: '' });
  return (
    <>
      <div
        style={{ lineHeight: '1.2' }}
        className={classes.richTextAreaTable}
        dangerouslySetInnerHTML={{ __html: currentText }}
      />
    </>
  )
})

export const RichTextEditor = observer((
  {
    defaultInputValue = '',
    changeHandler,
    minHeight = '0%',
    disabled = false,
    error = '',
    toolbarProps = [],
  }: {
    defaultInputValue?: string,
    changeHandler: any,
    minHeight?: string,
    disabled?: boolean,
    error?: string,
    toolbarProps?: string[],
  }
) => {
  const classes = useStyles({ minHeight: minHeight });

  const handleChange = async (editor: Editor) => {
    const data = editor.getData();
    let plainText = data
    plainText = plainText.replace(/&nbsp;/ig, ' ');
    plainText = plainText.replace(/<figure class="table">(.*?)<\/figure>/g, '*ТАБЛИЦА*\n');
    plainText = plainText.replace(/<style([\s\S]*?)<\/style>/gi, '');
    plainText = plainText.replace(/<script([\s\S]*?)<\/script>/gi, '');
    plainText = plainText.replace(/<\/div>/ig, '\n');
    plainText = plainText.replace(/<\/li>/ig, '\n');
    plainText = plainText.replace(/<li>/ig, '  *  ');
    plainText = plainText.replace(/<\/ul>/ig, '\n');
    plainText = plainText.replace(/<\/p>/ig, '\n');
    plainText = plainText.replace(/<br\s*[\/]?>/gi, "\n");
    plainText = plainText.replace(/<[^>]+>/ig, '');
    await changeHandler(data, plainText)
  }
  return (
    <>
      <div className={classes.textEditor} style={{ minHeight: minHeight }}>
        <CKEditor
          editor={Editor}
          data={defaultInputValue || ''}
          config={
            {
              toolbar: {
                items:
                  [
                    'heading',
                    '|',
                    'bold', 'italic', 'Underline', 'fontColor',
                    '|',
                    'numberedList', 'bulletedList',
                    ...toolbarProps,
                    '|',
                    'undo', 'redo'
                  ]
              }
            }
          }
          disabled={disabled}
          onReady={editor => { }}
          onChange={(event, editor) => {
            handleChange(editor)
          }}
        />
        <FormHelperText error={!!error}>{error}</FormHelperText>
      </div>
    </>
  )
})

// https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html#the-full-list-of-content-styles

const useStyles = makeStyles((theme: Theme) => ({
  textEditor: (props: { minHeight: string }) => ({
    '--ck-border-radius': '4px',
    '--ck-font-size-base': '14px',
    '--ck-custom-background': '#263238',
    '--ck-custom-foreground': 'hsl(255, 3%, 18%)',
    '--ck-custom-border': 'rgba(255, 255, 255, 0.23)',
    '--ck-custom-white': 'hsl(0, 0%, 100%)',
    '--ck-color-base-border': 'var(--ck-custom-border)',
    '--ck-color-base-foreground': 'var(--ck-custom-background)',
    '--ck-color-focus-border': 'white',
    '--ck-focus-ring': '1px solid white',
    '--ck-color-text': 'hsl(0, 0%, 98%)',
    '--ck-color-shadow-drop': 'hsla(0, 0%, 0%, 0.2)',
    '--ck-color-shadow-inner': 'hsla(0, 0%, 0%, 0.1)',
    '--ck-color-button-default-background': 'var(--ck-custom-background)',
    '--ck-color-button-default-hover-background': 'hsl(270, 1%, 22%)',
    '--ck-color-button-default-active-background': 'hsl(270, 2%, 20%)',
    '--ck-color-button-default-active-shadow': 'hsl(270, 2%, 23%)',
    '--ck-color-button-default-disabled-background': 'var(--ck-custom-background)',
    '--ck-color-button-on-background': 'var(--ck-custom-foreground)',
    '--ck-color-button-on-hover-background': 'hsl(255, 4%, 16%)',
    '--ck-color-button-on-active-background': 'hsl(255, 4%, 14%)',
    '--ck-color-button-on-active-shadow': 'hsl(240, 3%, 19%)',
    '--ck-color-button-on-disabled-background': 'var(--ck-custom-foreground)',
    '--ck-color-button-action-background': 'hsl(168, 76%, 42%)',
    '--ck-color-button-action-hover-background': 'hsl(168, 76%, 38%)',
    '--ck-color-button-action-active-background': 'hsl(168, 76%, 36%)',
    '--ck-color-button-action-active-shadow': 'hsl(168, 75%, 34%)',
    '--ck-color-button-action-disabled-background': 'hsl(168, 76%, 42%)',
    '--ck-color-button-action-text': 'var(--ck-custom-white)',
    '--ck-color-button-save': 'hsl(120, 100%, 46%)',
    '--ck-color-button-cancel': 'hsl(15, 100%, 56%)',
    '--ck-color-dropdown-panel-background': 'var(--ck-custom-background)',
    '--ck-color-dropdown-panel-border': 'var(--ck-custom-foreground)',
    '--ck-color-split-button-hover-background': 'var(--ck-color-button-default-hover-background)',
    '--ck-color-split-button-hover-border': 'var(--ck-custom-foreground)',
    '--ck-color-input-background': 'hsl(270, 1%, 29%)',
    '--ck-color-input-border': 'hsl(257, 3%, 43%)',
    '--ck-color-input-text': 'hsl(0, 0%, 98%)', '--ck-color-input-disabled-background': 'hsl(255, 4%, 21%)',
    '--ck-color-input-disabled-border': 'hsl(250, 3%, 38%)',
    '--ck-color-input-disabled-text': 'hsl(0, 0%, 78%)',
    '--ck-color-labeled-field-label-background': 'var(--ck-custom-background)',
    '--ck-color-list-background': 'var(--ck-custom-background)',
    '--ck-color-list-button-hover-background': 'var(--ck-custom-foreground)',
    '--ck-color-list-button-on-background': 'hsl(208, 88%, 52%)',
    '--ck-color-list-button-on-text': 'var(--ck-custom-white)',
    '--ck-color-panel-background': 'hsl(270, 1%, 29%)',
    '--ck-color-panel-border': 'var(--ck-custom-border)',
    '--ck-color-toolbar-background': 'var(--ck-custom-background)',
    '--ck-color-toolbar-border': 'var(--ck-custom-border)',
    '--ck-color-tooltip-background': 'hsl(252, 7%, 14%)',
    '--ck-color-tooltip-text': 'hsl(0, 0%, 93%)',
    '--ck-color-image-caption-background': 'hsl(0, 0%, 97%)',
    '--ck-color-image-caption-text': 'hsl(0, 0%, 20%)',
    '--ck-color-widget-blurred-border': 'hsl(0, 0%, 87%)',
    '--ck-color-widget-hover-border': 'hsl(43, 100%, 68%)',
    '--ck-color-widget-editable-focus-background': 'white',
    '--ck-color-link-default': 'hsl(190, 100%, 75%)',
    '& .ck-editor__main > .ck': {
      backgroundColor: '#263238 !important',
    },
    '& .ck-editor__editable': {
      minHeight: props.minHeight
    },
  }),
  richTextAreaTable: {
    '& table, th, td': {
      border: '1px solid ',
      borderCollapse: 'collapse',
      padding: '6px'
    }
  }
}));
