import { FormControlLabel, Grid, Paper, Radio, RadioGroup } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RequirementTemplateType, RequirementType } from '../../../../../../service/requirement/entity/RequirementType';
import { FormActions } from '../../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../../design/form/FormAutocomplete';
import { FormCheckboxField } from '../../../../../design/form/FormCheckboxField';
import { FormMultiAutocomplete } from '../../../../../design/form/FormMultiAutocomplete';
import { FormTextField } from '../../../../../design/form/FormTextField';
import { FormTextLabel } from '../../../../../design/form/FormTextLabel';
import { IAdminPageProperties } from '../../../IAdminPageProperties';
import { SpecificationFormSelection } from '../../../specification/parts/formSelection/SpecificationFormSelection';
import { TagFormSelection } from '../../../tag/parts/formSelection/TagFormSelection';
import { RequirementFormDomain } from './store/RequirementFormDomain';
import { RichTextEditor } from '../../../../../design/RichTextEditor/RichTextEditor';

export interface IRequirementFormProperties extends IAdminPageProperties {
  requirementId?: string | null;
  requirementApplicationId?: string | null;
  onSave?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  isDelete?: boolean;
}

export const RequirementForm = observer(
  ({
    layoutDomain,
    requirementId = null,
    requirementApplicationId = null,
    onDelete,
    isDelete,
    onSave,
    onCancel,
  }: IRequirementFormProperties) => {
    const [domain] = useState(new RequirementFormDomain(layoutDomain));

    const { id = requirementId, applicationId = requirementApplicationId } = useParams<any>();
    const uiEntity = domain.ui.model.entity;
    const isSystemRequirementType = domain.ui.type.value === RequirementType.system;
    useEffect(() => {
      domain.loadData(id, applicationId);
      domain.setEvents(onDelete, onSave, onCancel);
      domain.setIsDelete(isDelete);
      domain.setShortNameGeneratorState(isSystemRequirementType);
    }, [domain, id, applicationId, onDelete, isDelete, onSave, onCancel, isSystemRequirementType]);
    const lableColor = !domain.ui.model.entity?.categoryId ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.7)'

    const handleChange = (styledText, cleatText) => {
      const changeFuncStyled = domain.getUpdateFieldHandler('descriptionWithStyles')
      changeFuncStyled(styledText);
      const changeFunc = domain.getUpdateFieldHandler('description')
      changeFunc(cleatText);
    }

    const defaultDataForInput = domain.ui.model.entity?.descriptionWithStyles ?? domain.ui.model.entity?.description

    return (
      <Grid container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={8}>
          <Paper elevation={3}>
            <Grid item style={{ padding: 16 }}>
              <RadioGroup
                row
                defaultValue={domain.loacalRequirementType.value}
                value={domain.loacalRequirementType.value}
                name="radio-buttons-group"
                onChange={(e) => {
                  domain.changeLocalRequirementType(e)
                }}
              >
                <FormControlLabel value={RequirementTemplateType.custom} control={<Radio size='small' />} label="Заполнить вручную" />
                <FormControlLabel value={RequirementTemplateType.fromRequirement} control={<Radio size='small' />} label="Из шаблонов требований" />
                <FormControlLabel value={RequirementTemplateType.fromVendor} control={<Radio size='small' />} label="Из требований внешних регуляторов" />
              </RadioGroup>
            </Grid>
            <FormAutocomplete
              errorMessage={domain.getValidationErrorFor('category')?.message}
              label="Категория"
              selected={uiEntity.categoryId}
              selectedDefault={null}
              onChangeSelect={(newValue: any) => {
                domain.ui.model.entity.categoryId = newValue?.value;
                domain.generateShortName();
                if (domain.loacalRequirementType.value === RequirementTemplateType.fromRequirement) {
                  const currentRequirements = domain.requirements.list
                    .filter(item => item.categoryId === newValue?.value)
                    .map(item => ({ name: item.shortName || '', value: item.id, categoryId: item.categoryId, description: item?.description }))
                  domain.templateRequirementList.setValue(currentRequirements)
                }
              }}
              values={domain.ui.getCategoriesValues()}
              required={true}
              disabled={domain.ui.isLoading.value}
            />
            {domain.loacalRequirementType.value === RequirementTemplateType.fromRequirement &&
              <FormAutocomplete
                label="Шаблон требования"
                values={domain.templateRequirementList.value}
                selectedDefault={null}
                selected={domain.selectedRequirement.value || null}
                onChangeSelect={(newValue: any) => {
                  domain.changeTemplateRequirement(newValue)
                }}
                isNeedDescriprion={true}
                disabled={!domain.ui.model.entity.categoryId || domain.ui.isLoading.value}
              />
            }
            {domain.loacalRequirementType.value === RequirementTemplateType.fromVendor &&
              <VendorRequirementCategoryFormAutocomplete domain={domain} />}
            <FormTextField
              errorMessage={domain.getValidationErrorFor('shortName')?.message}
              value={uiEntity.shortName}
              onChange={(newValue: any) => {
                domain.ui.model.entity.shortName = newValue;
              }}
              label="Короткое название"
              disabled={!domain.ui.model.entity.categoryId || domain.ui.isLoading.value}
              required={true}
              dataCy='req-short-name'
            />
            <Grid item padding={'16px'}>
              <div style={{ color: lableColor, paddingBottom: 2 }}>
                Описание*
              </div>
              <RichTextEditor
                defaultInputValue={defaultDataForInput || ''}
                changeHandler={handleChange}
                minHeight='200px'
                disabled={!domain.ui.model.entity?.categoryId || domain.ui.isLoading.value}
                error={domain.getValidationErrorFor('description')?.message}
              />
            </Grid>
            <FormTextField
              type="text"
              errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
              value={uiEntity.orderIndex?.toString()}
              onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
              label="Порядок отображения"
              disabled={!domain.ui.model.entity.categoryId || domain.ui.isLoading.value}
              inputProps={{ maxLength: 9 }}
            />

            {isSystemRequirementType && (
              <Paper elevation={3} style={{ margin: 10 }}>
                <FormTextLabel text={'Характеристики'} />
                <SpecificationFormSelection
                  onChange={(ids) => {
                    domain.ui.model.entity.specificationsIds = ids;
                  }}
                  selectedSpecificationsIds={domain.ui.model.entity.specificationsIds || []}
                  specificationCategories={domain.ui.specificationsCategories.list}
                  specifications={domain.ui.specifications.list}
                  disabled={!domain.ui.model.entity.categoryId || domain.ui.isLoading.value}
                />
              </Paper>
            )}
            {isSystemRequirementType && (
              <Paper elevation={3} style={{ margin: 10 }}>
                <FormTextLabel text={'Тэги'} />
                <TagFormSelection
                  onChange={(ids) => {
                    domain.ui.model.entity.tagsIds = ids;
                  }}
                  selectedTagsIds={domain.ui.model.entity.tagsIds || []}
                  tagCategories={domain.ui.tagsCategories.list}
                  tags={domain.ui.tags.list}
                  disabled={!domain.ui.model.entity.categoryId || domain.ui.isLoading.value}
                />
              </Paper>
            )}
            {isSystemRequirementType && (
              <FormMultiAutocomplete
                values={domain.ui.performersTypeAutocompleteValues()}
                onChangeSelect={domain.getMultiSelectFieldHandler('performersTypesIds')}
                key="performersTypesIds"
                selected={uiEntity.performersTypesIds}
                label="Тип исполнителей"
                disabled={!domain.ui.model.entity.categoryId || domain.ui.isLoading.value}
              />
            )}
            {isSystemRequirementType && (
              <FormCheckboxField
                value={uiEntity.isActive}
                defaultValue={true}
                onChange={domain.getUpdateFieldHandler('isActive')}
                label="Активен"
                disabled={!domain.ui.model.entity.categoryId || domain.ui.isLoading.value}
                dataCy='req-form-checkbox'
              />
            )}
          </Paper>

          <FormActions
            onSave={domain.save}
            onCancel={domain.cancelEdit}
            onDelete={isDelete ? domain.delete : undefined}
            disabled={domain.ui.isLoading.value}
            isLoading={domain.ui.isLoading.value}
          />
        </Grid >
      </Grid >
    );
  },
);


const VendorRequirementCategoryFormAutocomplete = observer(({ domain }: { domain: RequirementFormDomain }) => {
  return (
    <>
      <FormAutocomplete
        label="Категория требований регуляторов"
        values={domain.vendorCategories.list.map(item => ({ value: item.id, name: item.name }))}
        selectedDefault={null}
        selected={domain.selectedVendorCategory.value || null}
        onChangeSelect={(newValue: any) => {
          domain.selectedVendorCategory.setValue(newValue.value)
          const currentRequirements = domain.vendorRequirements.list
            .filter(item => item.vendorId === newValue?.value)
            .map(item => ({ name: item.shortName || '', value: item.id, vendorId: item.vendorId, description: item.description }))
          domain.templateRequirementList.setValue(currentRequirements)
        }}
        required={false}
        disabled={!domain.ui.model.entity.categoryId || domain.ui.isLoading.value}
      />
      <FormAutocomplete
        label="Требование регулятора"
        values={domain.templateRequirementList.value}
        selectedDefault={null}
        selected={domain.selectedRequirement.value || null}
        onChangeSelect={(newValue: any) => {
          domain.changeTemplateRequirement(newValue)
        }}
        isNeedDescriprion={true}
        required={false}
        disabled={!domain.ui.model.entity.categoryId || domain.ui.isLoading.value || !domain.selectedVendorCategory.value}
      />
    </>
  );
});

