import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IApplicationRequirementEntity } from './IApplicationRequirementModel';
import { IRequirementSearch } from './IRequirementSearch';
import { IApplicationRequirementService } from './IApplicationRequirementService';

export class RestApplicationRequirementService
  extends RestCRUDService<IApplicationRequirementEntity, IRequirementSearch>
  implements IApplicationRequirementService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/application_requirement`;
  }


  async getAppReqByAppId(appliactionId: string, withDeleted?: boolean): Promise<[IApplicationRequirementEntity]> {
    const searchRequest = {
      filter: [{ fieldName: 'applicationDataId', type: 'equal', value: appliactionId }],
      withDeleted,
      limit: 100000,
    }
    const result = await this.request()
      .url(`${this.getEndpoint()}/search`)
      .post(searchRequest)
      .json<any>()
      .catch(this.handleError);
    return result.data;
  }
}
