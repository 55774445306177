import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useI18n } from '../../../../../../../../../../../application/language/useI18n';

interface IQuizAccordionItemProps {
  result: number;
  showResult: boolean;
  index: number;
}

export const QuizAccordionItem = ({ result, showResult, index }: IQuizAccordionItemProps) => {
  const { translate } = useI18n();
  return showResult ? (
    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      {result > 0.5 ? <CheckCircleOutline /> : <CancelOutlined />}
      <Typography variant="body1" sx={{ color: result > 0.5 ? 'rgba(102, 187, 106, 1)' : 'rgba(239, 83, 80, 1)' }}>
        {translate('phrases.question')} {index + 1}
      </Typography>
    </Box>
  ) : (
    <Typography variant="body1">{translate('phrases.question')} {index + 1}</Typography>
  );
};
