import { injectRootService } from '../../../../../../../../service/RootServiceFactory';
import { IUserEntityService } from '../../../../../../../../service/user/IUserEntityService';
import { DataTableFormDomain } from '../../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { UserAdminModel } from '../UserAdminModel';
import { UserModalUI } from './UserModalUI';

export class UserModalDomain
  extends DataTableFormDomain<UserAdminModel, IUserEntityService>
  implements IDataTableFormDomain<UserAdminModel> {
  public ui: UserModalUI;
  constructor(
    entityService: IUserEntityService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<UserAdminModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new UserModalUI());
    this.ui = new UserModalUI();
  }

  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependency();
  }

  async loadDependency() {
    const systemRoleIdSearchResult = await this.rootPrivateServices.systemRole.entity.search({ limit: 10000000 });
    this.ui.systemRoleId.setList(systemRoleIdSearchResult.data);
  }
}
