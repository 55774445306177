import { ChevronRight, ErrorOutline, ExpandMore } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import { TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { IPrimitiveStore } from '../../../../../../../common/store/interface/IPrimitiveStore';
import { IFile } from './IFileTree';

export interface IFileTree {
  fileTree: IFile[];
  openContextMenu: any;
  pickingFile: (file: IFile) => void;
  isInputFileOpen: IPrimitiveStore<boolean>;
  isInputFolderOpen: IPrimitiveStore<boolean>;
  isInputRenameOpen: IPrimitiveStore<boolean>;
  creatingNewFolder: (value: string) => void;
  creatingNewFile: (value: string) => void;
  currentFile: IFile;
  toggleQuestion: (currentFile: IFile) => void;
  rename: (name: string) => void;
}
export const FileTree = observer(
  ({
    fileTree,
    openContextMenu,
    pickingFile,
    isInputFileOpen,
    isInputFolderOpen,
    isInputRenameOpen,
    creatingNewFolder,
    creatingNewFile,
    currentFile,
    toggleQuestion,
    rename,
  }: IFileTree) => {
    const [name, setName] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const [expands, setExpands] = useState<string[]>([]);
    useEffect(() => {
      isInputRenameOpen.value && setName(currentFile.name);
    }, [isInputRenameOpen.value]);
    function renderTree(file: IFile) {
      if (file.children?.length !== 0) {
        return (
          <TreeItem
            sx={{
              '& > div': {
                flexDirection: file.isQuestion === true ? 'row-reverse' : 'row',
              },
              '& .MuiTreeItem-label': {
                paddingLeft: file.isQuestion === true ? '23px' : '',
              },
              '& .MuiTreeItem-iconContainer': {
                marginRight: file.isQuestion === true ? '0px' : '4px',
              },
            }}
            endIcon={file.isQuestion && <ErrorOutline />}
            id={file.id}
            nodeId={file.id}
            label={file.name}
            onContextMenu={(event) => openContextMenu(file, event)}
            onClick={() => pickingFile(file)}
          >
            {file.children?.map((file) => {
              return renderTree(file);
            })}
          </TreeItem>
        );
      } else {
        return (
          <TreeItem
            id={file.id}
            nodeId={file.id}
            label={file.name}
            onContextMenu={(event) => openContextMenu(file, event)}
            onClick={() => {
              pickingFile(file);
            }}
          />
        );
      }
    }
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        defaultExpanded={expands}
        multiSelect
        sx={{
          flexGrow: 1,
          maxWidth: 400,
        }}
      >
        {fileTree !== null &&
          fileTree.map((file) => {
            expands.push(file.id);
            return renderTree(file);
          })}
        {isInputFolderOpen.value && (
          <form
            style={{ padding: '10px 4px 0px 0px' }}
            onSubmit={(event) => {
              event.preventDefault();
              if (name !== '') {
                creatingNewFolder(name);
                isInputFolderOpen.setValue(false);
                setName('');
                setError(false);
              } else {
                setError(true);
              }
            }}
          >
            <TextField
              onChange={(event) => {
                setName(event.target.value);
              }}
              label="Название папки"
              id={'filetree__input'}
              onBlur={() => isInputFolderOpen.setValue(false)}
              sx={{ height: '24px', '& div': { height: '24px !important' } }}
              error={error}
              helperText={error ? 'Значение не может быть пустым' : ' '}
            />
          </form>
        )}
        {isInputFileOpen.value && (
          <form
            style={{ padding: '10px 4px 0px 0px' }}
            onSubmit={(event) => {
              event.preventDefault();
              if (name !== '') {
                creatingNewFile(name);
                isInputFileOpen.setValue(false);
                setName('');
                setError(false);
              } else {
                setError(true);
              }
            }}
          >
            <TextField
              onChange={(event) => {
                setName(event.target.value);
              }}
              label="Название файла"
              id={'filetree__input_file'}
              onBlur={() => isInputFileOpen.setValue(false)}
              sx={{ height: '24px', '& div': { height: '24px !important' } }}
              error={error}
              helperText={error ? 'Значение не может быть пустым' : ' '}
            />
          </form>
        )}
        {isInputRenameOpen.value && (
          <form
            style={{ padding: '10px 4px 0px 0px' }}
            onSubmit={(event) => {
              event.preventDefault();
              if (name !== '') {
                rename(name);
                isInputRenameOpen.setValue(false);
                setName('');
                setError(false);
              } else {
                setError(true);
              }
            }}
          >
            <TextField
              onChange={(event) => {
                setName(event.target.value);
              }}
              value={name}
              id={'file_rename'}
              onBlur={() => isInputRenameOpen.setValue(false)}
              sx={{ height: '24px', '& div': { height: '24px !important' }, width: '100%' }}
              error={error}
              helperText={error ? 'Значение не может быть пустым' : ' '}
            />
          </form>
        )}
      </TreeView>
    );
  },
);
