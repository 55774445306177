import { CircularProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { TranslateText } from '../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../application/language/useI18n';
import { Dashboard } from '../../shared/dashboards/Dashboard';
import { useGetProjectsWithUnitsStats } from '../utils/useGetProjectsWithUnitsStats';
import { useGetProjectToUserStatisticsMap } from '../utils/useGetUserStatistics';
import { useMapToDashboardData } from '../utils/useMapToDashboardData';
import styles from './StudyResultsDashboard.module.css';

//@ts-ignore
export const StudyResultsDashboard = observer(({ layoutDomain }) => {
  const { projectsWithUnitsStats, isProjectsWithUserStatsLoading } = useGetProjectsWithUnitsStats(layoutDomain);
  const { projectToUserStatisticsMap } = useGetProjectToUserStatisticsMap(projectsWithUnitsStats);
  const { dashboardData } = useMapToDashboardData(projectToUserStatisticsMap);
  const { translate } = useI18n();

  if (isProjectsWithUserStatsLoading.value) {
    return (
      <div className={styles.spinnerContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (!projectsWithUnitsStats.length || !dashboardData.length) {
    return (
      <div className={styles.noResultsContainer}>
        <img src="/pictures/UnitsDashboardNoresults.png" />
        <Typography typography="h5">
          <TranslateText i18nKey={'analytics.noResults'} />
        </Typography>
        <Typography typography="subtitle2" sx={{ opacity: 0.7 }}>
          <TranslateText i18nKey={'analytics.noResultsMessage'} />
        </Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        background: '#2b353a',
        borderRadius: '10px',
        padding: '24px',
      }}
    >
      <div
        style={{
          fontWeight: 500,
          fontSize: '20px',
          marginBottom: '20px',
        }}
      >
        {`${translate('analytics.resultsByProject')}${dashboardData.length ? ` (${dashboardData.length})` : ''}`}
      </div>
      <Dashboard data={dashboardData} />
    </div>
  );
});
