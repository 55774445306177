import { IRootPublicService } from '../../../../../../../service/public/RootPublicService';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { IRootAdminService } from '../../../../../../../service/RootAdminService';
import { CommonFilterDomain } from '../../../../../../common/requirement/filter/store/CommonFilterDomain';
import { ApplicationDetailDomain } from '../ApplicationDetailDomain';
import { RequirementViewModel } from '../RequirementViewModel';

interface IAppReqData {
  applicationReqId: string;
  requirement: RequirementViewModel;
}

export class ApplicationRequirementFilterDomain extends CommonFilterDomain {
  constructor(rootService: IRootPublicService | IRootAdminService, public rootDomain: ApplicationDetailDomain) {
    super(rootService);
    this.addFilters();
  }

  addFilters() {
    this.addExtensionFunctionality(
      'filterBySpecifications',
      (requirements) => this.filterBySpecifications(requirements),
      'filters',
    );
    this.addExtensionFunctionality('filterByTags', (requirements) => this.filterByTags(requirements), 'filters');
    this.addExtensionFunctionality(
      'filterByStatuses',
      (requirements) => this.filterByStatuses(requirements),
      'filters',
    );
    this.addExtensionFunctionality('filterByVendors', (requirements) => this.filterByVendors(requirements), 'filters');
    this.addExtensionFunctionality('filterByText', (requirements) => this.filterByText(requirements), 'filters');
  }

  protected async loadStatuses() {
    this.ui.statuses.setList(this.rootDomain.ui.statuses.list);
    const statusValues = this.rootDomain.ui.statusesValues.list.filter((statusValue) => {
      if (statusValue.isActive !== true) {
        const statusValueUsage = this.rootDomain.ui.applicationData.entity.statusesData?.data.find(
          (requirementStatusData) => requirementStatusData.statusValueId === statusValue.id,
        );

        return !!statusValueUsage;
      }
      return true;
    });
    this.ui.statusesValues.setList(statusValues);
  }

  protected async loadVendors() {
    this.ui.vendors.setList(this.rootDomain.ui.vendors.list);
  }

  protected async loadApplicationReq() {
    this.ui.applicationReq.setList(this.rootDomain.ui.applicationReq.list);
  }

  protected async loadSpecifications() {}

  async setSettings() {
    super.setSettings();
    this.ui.isShowSpecificationsSearch.setValue(false);
  }

  async triggerSearch() {
    await  this.loadApplicationReq();
    let requirements = this.getRequirements();
    //@ts-ignore
    requirements = requirements.map((req) => {
      const appReqId = this.ui.applicationReq.list.find((appReq) => appReq.requirementId === req.id)?.id;
      return {
        applicationReqId: appReqId,
        requirement: req
      }
    })

    for (const [id, filter] of this.extensionFunctionality.filters) {
      requirements = filter(requirements, this);
    }

    this.setRequirements(requirements);
  }

  getRequirements(): IRequirementModel[] {
    return this.rootDomain.ui.tableRequirements.list || super.getRequirements();
  }

  setRequirements(requirements: IRequirementModel[]) {
    super.setRequirements(requirements);
    this.rootDomain.ui.updateByFilter(requirements as IAppReqData[]);
  }

  filterByStatuses(data: IAppReqData[]): IAppReqData[] {
    const searchStatuses = this.ui.searchStatusesIds.value.statuses;
    if (this.ui.searchStatusesIds.value.totalCount > 0) {
      data = data.filter((requirement) => {
        for (const searchStatusIndex in searchStatuses) {
          const searchStatus = searchStatuses[searchStatusIndex];
          if (searchStatus.values.length > 0 || searchStatus.isIncludeEmpty === true) {
            const statusValues = searchStatus.values;
            const requirementStatusData =
              requirement.requirement.statusesData?.filter((statusData) => statusData.statusId === searchStatus.id) || [];
            const sameStatusValues =
              requirementStatusData?.filter((data) => statusValues.includes(data.statusValueId)) || [];
            if (searchStatus.isIncludeEmpty && requirementStatusData.length > 0 && sameStatusValues.length === 0) {
              return false;
            }

            if (!searchStatus.isIncludeEmpty && sameStatusValues.length === 0) {
              return false;
            }
          }
        }
        return true;
      });
    }

    return data;
  }

  filterByVendors(data: IAppReqData[]): IAppReqData[] {
    const searchVendorsIds = this.ui.searchVendorsIds.value;
    if (searchVendorsIds.length > 0) {
      data = data.filter((requirement) => {
        const vendorRequirements = this.rootDomain.ui.vendorRequirements.list.filter((vendorRequirement) =>
          vendorRequirement.requirementsIds.includes(requirement.requirement.id || ''),
        );
        return vendorRequirements.find((vendorRequirement) => searchVendorsIds.includes(vendorRequirement.vendorId));
      });
    }

    return data;
  }

  filterBySpecifications(data: IAppReqData[]): IAppReqData[] {
    const searchSpecificationsIds = this.ui.searchSpecificationsIds.value;
    if (searchSpecificationsIds.length > 0) {
      const specificationsByCategories: any = {};
      searchSpecificationsIds.forEach((specificationId) => {
        const searchSpecification = this.ui.specifications.list.find(
          (specification) => specification.id === specificationId,
        );
        if (searchSpecification?.categoryId) {
          if (!specificationsByCategories[searchSpecification?.categoryId]) {
            specificationsByCategories[searchSpecification?.categoryId] = [];
          }
          specificationsByCategories[searchSpecification?.categoryId].push(specificationId);
        }
      });

      data = data.filter((requirement) => {
        for (const categoryId in specificationsByCategories) {
          const categorySpecifications = specificationsByCategories[categoryId];
          const sameSpecificationsInCategory = requirement.requirement.specificationsIds?.filter((specificationId) =>
            categorySpecifications.includes(specificationId),
          );

          if (sameSpecificationsInCategory?.length === 0) {
            return false;
          }
        }
        return true;
      });
    }

    return data;
  }

  filterByTags(data: IAppReqData[]): IAppReqData[] {
    const searchTagsIds = this.ui.searchTagsIds.value;
    if (searchTagsIds.length > 0) {
      const tagsByCategories: any = {};
      searchTagsIds.forEach((tagId) => {
        const searchTag = this.ui.tags.list.find((tag) => tag.id === tagId);
        if (searchTag?.categoryId) {
          if (!tagsByCategories[searchTag?.categoryId]) {
            tagsByCategories[searchTag?.categoryId] = [];
          }
          tagsByCategories[searchTag?.categoryId].push(tagId);
        }
      });

      data = data.filter((requirement) => {
        for (const categoryId in tagsByCategories) {
          const categoryTags = tagsByCategories[categoryId];
          const sameTagsInCategory = requirement.requirement.tagsIds?.filter((tagId) => categoryTags.includes(tagId));

          if (sameTagsInCategory?.length === 0) {
            return false;
          }
        }
        return true;
      });
    }

    return data;
  }

  filterByText(data: IAppReqData[]): IAppReqData[] {
    if (this.ui.searchText.value.length >= 2) {
      const searchText = this.ui.searchText.value.toLocaleLowerCase();
      data = data.filter((tableRow) => {
        const includeTextInShortName = tableRow.requirement.shortName?.toLocaleLowerCase().search(searchText);
        const includeTextInDescription = tableRow.requirement.description?.toLocaleLowerCase().search(searchText);

        const category = this.rootDomain.ui.requirementsCategories.list.find(
          (category) => category.id === tableRow.requirement.categoryId,
        );
        const includeTextInCategory = category ? category.name?.toLocaleLowerCase().search(searchText) : -1;

        return includeTextInShortName !== -1 || includeTextInDescription !== -1 || includeTextInCategory !== -1;
      });
    }

    return data;
  }
}
