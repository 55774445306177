import React, { useContext } from 'react';

import { observer } from 'mobx-react';
import { MenuItem } from '@mui/material';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { ICoreSearchAudit } from '../../../service/coreCommon/common/audit/ICoreSearchAudit';
import { IApplicationModel } from '../../../service/application/entity/IApplicationModel';
import { Version } from '../../../view/user/page/application/info/store/ApplicationDetailUI';
import { generateName } from '../../../view/user/page/application/info/parts/report/ApplicationInfoReport';
import { ContainerContext } from '../../../common/container/ContainerContext';
import { IApplicationModelWithUser } from '../../../view/user/page/application/info/parts/applicationInfoHistory/store/ApplicationHistoryUI';

export const ExtendedDownloadAcceptanceTests = observer(
  ({
    domain,
    format,
    audit,
    popupState,
    listWithAudits = [],
    fullVersion = null,
    application,
    fullAuditList,
  }: {
    domain: ApplicationDetailDomain;
    format: string;
    audit: ICoreSearchAudit | undefined;
    popupState: any;
    listWithAudits?: IApplicationModel[] | undefined;
    fullVersion?: string | null;
    application?: IApplicationModel,
    fullAuditList?: IApplicationModelWithUser[];
  }) => {
    // const onlyMajorAudits: IApplicationModel[] | undefined = listWithAudits.filter(
    //   (audit) => audit.versionNumber?.split('.')[0] === majorVersion,
    // );
    let curentAplicationVersionNumber = domain.ui.application.entity.versionNumber?.split('.');
    let auditApplicationVersion = application?.versionNumber?.split('.');

    let generatedVersion = domain.ui.getVersionWithStatus({ versionType: Version.patch, application });
    const splitedGeneratedVersion = generatedVersion.split('.');

    if (auditApplicationVersion && curentAplicationVersionNumber) {
      const auditIndex = fullAuditList?.findIndex((item) => {
        const splitedVersion = item?.versionNumber?.split('.');
        if (splitedVersion && auditApplicationVersion) {
          if (splitedVersion[0] === auditApplicationVersion[0] && splitedVersion[1] === auditApplicationVersion[1]) {
            return true;
          }
        }
        return false;
      });
      let isNeedDecrement = false;

      if (auditIndex && auditIndex - 1 >= 0 && fullAuditList) {
        const checkVersion = fullAuditList[auditIndex - 1].versionNumber?.split('.');
        if (checkVersion && checkVersion[0] > auditApplicationVersion[0]) {
          isNeedDecrement = true;
        }


      };

      if (isNeedDecrement) {
        generatedVersion = `${splitedGeneratedVersion[0]}.${splitedGeneratedVersion[1]}.${+splitedGeneratedVersion[2] - 1}`
      } else {
        generatedVersion = `${splitedGeneratedVersion[0]}.${splitedGeneratedVersion[1]}.${+splitedGeneratedVersion[2]}`
      }
    };
    const container = useContext(ContainerContext);
    const extendedGenerateName = container.get(generateName);
    const reportBtnMajor = ' версии ' + generatedVersion;
    const reportNameMajor = '_версия ' + generatedVersion;
    return (
      <>
        <MenuItem
          onClick={() => {
            domain.downloadApplicationProtocolReport({
              format,
              audit: audit,
              versionNumber: generatedVersion,
              reportName: `${extendedGenerateName({ domain, reportName: 'Протокол ПСИ ИБ' })}${reportNameMajor}`,
            });
            popupState.close();
          }}
        >
          Протокол ({format}) {reportBtnMajor}
        </MenuItem>
        {/* TODO try to finish downloading by 3rd version number with statuses*/}
        {/*{onlyMajorAudits.map((application) => (*/}
        {/*  <MenuItem*/}
        {/*    onClick={() => {*/}
        {/*      domain.downloadApplicationProtocolReport({*/}
        {/*        format,*/}
        {/*        audit: {*/}
        {/*          auditDateInMS: application.auditDate ? application.auditDate.getTime() + 10 * 1000 : 0,*/}
        {/*          isReplaceId: true,*/}
        {/*        },*/}
        {/*        reportName: extendedGenerateName({ domain, reportName: 'Протокол ПСИ ИБ' }),*/}
        {/*      });*/}
        {/*      popupState.close();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Протокол ({format}) {application.versionNumber}*/}
        {/*  </MenuItem>*/}
        {/*))}*/}
      </>
    );
  },
);
