import { Box, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

interface Props {
  groupName: string;
  passingPercent: number;
  count: number;
}

export const GroupCardComponentHeader = ({ groupName, passingPercent, count }: Props) => {

  return (
    <Box sx={{marginBottom: 2}}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Typography sx={{color: 'rgba(255, 255, 255, 0.7)'}} variant="h5">{groupName}</Typography>
        <Typography
          sx={{
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '32px',
            alignSelf: 'self-start',
            padding: '0px 12px',
          }}
          variant="subtitle1"
        >
          {count}
        </Typography>
      </Box>
    </Box>
  );
};
