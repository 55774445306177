import { FormControlLabel, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { StatusEntityModalDomain } from './StatusEntityModalDomain';
import { FormMultiAutocomplete } from '../../../../../../design/form/FormMultiAutocomplete';

export interface IStatusEntityAdminTableProperties {
  domain: StatusEntityModalDomain;
  dataCy?: string;
}

export const StatusEntityModal = observer(({ domain }: IStatusEntityAdminTableProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
  }, [domain]);
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <StatusEntityNameCustomFieldText domain={domain} />
      <StatusEntityDescriptionCustomFieldArea domain={domain} />
      <StatusEntityRolesDropDown domain={domain} />
      <StatusEntityOrderCustomFieldText domain={domain} />
      <StatusEntityPrimaryApplicationStatusCustomSwitch domain={domain} />
      <StatusEntityActiveStatusCustomSwitch domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const StatusEntityNameCustomFieldText = observer(({ domain }: { domain: StatusEntityModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
      inputProps={{
        'data-cy': 'spec-entity-title'
      }}
    />
  );
});

const StatusEntityDescriptionCustomFieldArea = observer(
  ({ domain }: { domain: StatusEntityModalDomain }) => {
    return (
      <FormTextArea
        required={true}
        label={'Описание'}
        value={domain.ui.model.entity.description}
        onChange={domain.getUpdateFieldHandler('description')}
        errorMessage={domain.getValidationErrorFor('description')?.message}
        dataCy='spec-entity-description'
      />
    );
  },
);

const StatusEntityRolesDropDown = observer(({ domain }: { domain: StatusEntityModalDomain }) => {
  const roleValues: any = [
    ...domain.dataTableDomain.projectRoles.list.map((role) => ({
      value: role.id,
      name: role.name,
    })),
  ];
  return (
    <FormMultiAutocomplete
      values={roleValues}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.rolesAccessIds = newValue.map((value) => {
          return value.value;
        });
      }}
      selected={domain.ui.model.entity.rolesAccessIds}
      label="Роли"
      errorMessage={undefined}
      dataCy='category-select'
    />
  );
});

const StatusEntityOrderCustomFieldText = observer(({ domain }: { domain: StatusEntityModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Порядок'}
      type="text"
      value={domain.ui.model.entity.orderIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
      errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
      inputProps={{
        maxLength: 9,
        'data-cy': 'spec-entity-order-number'
      }}
    />
  );
});

const StatusEntityActiveStatusCustomSwitch = observer(
  ({ domain }: { domain: StatusEntityModalDomain }) => {
    const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

    const handleChange = (event: any) => {
      setChecked(event.target.checked);
      domain.ui.model.entity.isActive = event.target.checked;
    };
    useEffect(() => {
      setChecked(domain.ui.model.entity.isActive);
    }, [domain.ui.model.entity.isActive]);
    return (
      <FormControlLabel
        style={{ width: '100%', padding: '16px', margin: 0 }}
        control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
        label="Активен"
      />
    );
  },
);

const StatusEntityPrimaryApplicationStatusCustomSwitch = observer(
  ({ domain }: { domain: StatusEntityModalDomain }) => {
    const [isChecked, setChecked] = useState(domain.ui.model.entity.isPrimaryApplicationStatus);

    const handleChange = (event: any) => {
      setChecked(event.target.checked);
      domain.ui.model.entity.isPrimaryApplicationStatus = event.target.checked;
    };
    useEffect(() => {
      setChecked(domain.ui.model.entity.isPrimaryApplicationStatus);
    }, [domain.ui.model.entity.isPrimaryApplicationStatus]);
    
    return (
      <FormControlLabel
        style={{ width: '100%', padding: '16px', margin: 0 }}
        control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={false} />}
        label="Основной статус в системах"
      />
    );
  },
);
