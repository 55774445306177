import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../service/unit/interface/IUnitModel';

export interface IUnitListAdminCardProperties {
  unit: IUnitModel;
  applicationId: string;
  isRequired: boolean;
}

export const UnitListAdminCard = observer(({ unit, isRequired }: IUnitListAdminCardProperties) => {
  return (
    <Box
      sx={{
        padding: '8px 10px 8px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        background: isRequired ? 'rgba(33, 150, 243, 0.3)' : 'rgba(255, 255, 255, 0.12)',
        alignItems: 'center',
        width: '100%',
        borderRadius: '8px',
      }}
    >
      <Typography sx={{ cursor: 'pointer' }}>{unit.settings.titleHeading}</Typography>
    </Box>
  );
});
