import { IProjectToUserStatisticsMap } from './useGetUserStatistics';

export const useMapToDashboardData = (projectsWithUserStats: IProjectToUserStatisticsMap[]) => {
  const dashboardData = projectsWithUserStats.map((item) => {
    const total =
      item.users.usersNotPassedStudying + item.users.usersPassedStudying + item.users.usersNotStartedStudying;
    return {
      name: item.project.name,
      uvCount: item.users.usersNotPassedStudying,
      pvCount: item.users.usersPassedStudying,
      dvCount: item.users.usersNotStartedStudying,
      uv: Math.round((item.users.usersNotPassedStudying * 100) / total),
      pv: Math.round((item.users.usersPassedStudying * 100) / total),
      dv: Math.round((item.users.usersNotStartedStudying * 100) / total),
    };
  });

  return { dashboardData };
};
