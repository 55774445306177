import { ArrowDownward, ArrowUpward, DeleteForever, VisibilityOff } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { TranslateText } from '../../../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../../../application/language/useI18n';
import { ITheoryBlock } from '../../../../../../service/unit/interface/IUnitTheory';
import { ITable } from '../../../../../../service/unit/interface/theoryBlocks/ITable';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';

export interface ITableProperties {
  data: ITheoryBlock<ITable>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
}
export const TableComponent = observer(({ data: { data, pseudoId }, onDelete, onUp, onDown }: ITableProperties) => {
  const { translate } = useI18n();

  return (
    <Box
      key={pseudoId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        padding: '16px 42px ',
        '&:hover .action': { visibility: 'visible' },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', alignItems: 'flex-end' }}>
        <Typography sx={{ marginRight: 'auto' }}>
          <TranslateText i18nKey='createUnit.table' />
        </Typography>
        <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
          <DeleteForever
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onDelete();
            }}
          />
          <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => { }} />
          <ArrowUpward
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onUp();
            }}
          />
          <ArrowDownward
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onDown();
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '33px' }}>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <FormTextFieldStandard
            variant="outlined"
            label={translate('createUnit.columns')}
            inputProps={{ min: 0 }}
            value={data.columnsCount.toString()}
            onChange={(event) => {
              if (Number(event) > data.columnsCount) {
                data.tableData.headerText.push('');
                data.tableData.rows.forEach((row) => row.push(''));
              } else {
                data.tableData.headerText.pop();
                data.tableData.rows.forEach((row) => row.pop());
              }
              data.columnsCount = Number(event);
            }}
            type="number"
          />
          <FormTextFieldStandard
            variant="outlined"
            label={translate('createUnit.rows')}
            inputProps={{ min: 0 }}
            value={data.rowsCount.toString()}
            onChange={(event) => {
              if (Number(event) > data.rowsCount) {
                data.tableData.rows.push([]);
                for (let i = 0; i < data.columnsCount; i++) {
                  data.tableData.rows[data.tableData.rows.length - 1].push('');
                }
              } else {
                data.tableData.rows.pop();
              }
              data.rowsCount = Number(event);
            }}
            type="number"
          />
        </Box>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          {data.tableData.headerText.map((text, index) => {
            return (
              <FormTextFieldStandard
                variant="outlined"
                label={translate('createUnit.tableTitle')}
                multiline
                value={data.tableData.headerText[index]}
                onChange={(event) => {
                  data.tableData.headerText[index] = event;
                }}
              />
            );
          })}
        </Box>
        {data.tableData.rows.map((row, index) => {
          return (
            <Box sx={{ display: 'flex', gap: '16px' }}>
              {row.map((text, index) => {
                return (
                  <FormTextFieldStandard
                    variant="outlined"
                    multiline
                    label={translate('createUnit.cellText')}
                    value={row[index]}
                    onChange={(event) => {
                      row[index] = event;
                    }}
                  />
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});
