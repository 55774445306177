import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { useI18n } from '../../../../../../../../application/language/useI18n';
import { IUnitModel } from '../../../../../../service/unit/interface/IUnitModel';
import { IUnitResultModel } from '../../../../../../service/unitResult/IUnitResultModel';
import { getLocaleByCount } from '../../../../../../utils/localeByCount';
import { PracticeIcons } from '../../icons/PracticeIcon';
import { TimeIcon } from '../../icons/TimeIcon';
import { UnitIcon } from '../../icons/UnitIcon';
import { UNIT_LIST_ITEMS_STYLES, getUnitStatus } from '../utils/passingListUserCard';
import { useFormatTime } from '../utils/useFormatTime';

type Props = {
  unit: IUnitModel;
  unitResult: IUnitResultModel;
};

export const UnitCardStats = observer(({ unit, unitResult }: Props) => {
  const i18n = useI18n();
  const unitStatus = getUnitStatus(unitResult.isPassed);
  const getQuestionsTranslate = (value: number) =>
    getLocaleByCount(
      value,
      i18n.i18n.language === 'en' ? ['question', 'questions', 'questions'] : ['вопрос', 'вопроса', 'вопросов'],
    );

  const getTasksTranslate = (value: number) =>
    getLocaleByCount(
      value,
      i18n.i18n.language === 'en' ? ['task', 'tasks', 'tasks'] : ['практика', 'практики', 'практик'],
    );

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {unit?.quiz.isEnabled && (
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <UnitIcon />
            <Box>{getQuestionsTranslate(unit?.quiz.questions.length)}</Box>
          </Box>
        )}
        {unit?.practice.isEnabled && (
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <PracticeIcons />
            <Box>{getTasksTranslate(unit?.practice.tasks.length)}</Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          <TimeIcon />
          <Box>
            {useFormatTime(
              Number(unit?.quiz.isEnabled && unit?.quiz.passageTime) +
                Number(unit?.practice.isEnabled && unit?.practice.passageTime) +
                (unit?.theory.isEnabled ? 30 : 0),
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', color: '#FFFFFF50' }}>
        <Box sx={{ marginRight: '4px' }}>
          <span style={{ color: UNIT_LIST_ITEMS_STYLES[unitStatus].color }}>
            {Math.round(unitResult.totalUnitResult)}
          </span>
          /{Math.round(unit.score)}
        </Box>

        {UNIT_LIST_ITEMS_STYLES[unitStatus].icon}
      </Box>
    </Box>
  );
});
