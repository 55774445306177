import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { ContainerContext } from '../../../../common/container/ContainerContext';
import { AnalyticsPage } from './AnalyticsPage';
import { AnalyticsPageComponent } from './AnalyticsPageInjects';

export const AnalyticsPageMain = observer(({ layoutDomain }) => {
  const container = useContext(ContainerContext);
  const ComponentContainer =
    container.get(AnalyticsPageComponent).Component !== null
      ? container.get(AnalyticsPageComponent)
      : { Component: AnalyticsPage };
  return <ComponentContainer.Component layoutDomain={layoutDomain} />;
});
