import wretch from 'wretch';

import { IUnitModel } from './interface/IUnitModel';
import { LearningCoreRestCRUDService } from '../common/LearningCoreRestCRUDService';
import { IUnitService } from './IUnitService';

wretch().errorType('json');

export class RestUnitService extends LearningCoreRestCRUDService<IUnitModel> implements IUnitService {
  getEndpoint(): string {
    const url = this.learningConfig.url.api.learning;
    return `${url}/unit`;
  }

  async getByIdWithAnswers(id: string) {
    const searchResult = await this.request()
      .url(`${this.getEndpoint()}/withAnswers/${id}`)
      .post({ showAnswers: true })
      .res()
      .catch(this.handleError);

    const searchResultText = await searchResult.text();

    return JSON.parse(searchResultText, this.dateTimeReviver);
  }

  async publishByModel(model: IUnitModel): Promise<boolean> {
    const result = await this.request()
      .url(`${this.getEndpoint()}/publish`)
      .put(model)
      .json<any>()
      .catch(this.handleError);
    return result?.id;
  }
  async unpublishByModel(model: IUnitModel): Promise<boolean> {
    const result = await this.request()
      .url(`${this.getEndpoint()}/unpublish`)
      .put(model)
      .json<any>()
      .catch(this.handleError);
    return result?.id;
  }
  async updateProjectRoles({ roleId, unitIds }: { roleId: string; unitIds: string[] }): Promise<string[]> {
    const result = await this.request()
      .url(`${this.getEndpoint()}/updateProjectRoles`)
      .post({ roleId, unitIds })
      .json<any>()
      .catch(this.handleError);
    return result;
  }
}
