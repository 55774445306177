import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { IRequirementService } from '../../../../../../../service/requirement/entity/IRequirementService';
import { RequirementType } from '../../../../../../../service/requirement/entity/RequirementType';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { RequirementEntityModalUI } from './RequirementEntityModalUI';

export class RequirementEntityModalDomain
  extends DataTableFormDomain<IRequirementModel, IRequirementService>
  implements IDataTableFormDomain<IRequirementModel>
{
  public ui: RequirementEntityModalUI;
  constructor(
    entityService: IRequirementService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<IRequirementModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new RequirementEntityModalUI());
    this.ui = new RequirementEntityModalUI();
  }
  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependencies();
  }

  async loadDependencies() {
    const [
      specificationsSearchResult,
      entitiesSearchResult,
      performersSearchResult,
      tagCategoriesSearchResult,
      specificationCategorySearchResult,
      categoriesSearchResult,
      usersSearchResult,
    ] = await Promise.all([
      this.rootPrivateServices.specification.entity.search({ limit: 100000 }),
      this.rootPrivateServices.tag.entity.search({ limit: 1000000 }),
      this.rootPrivateServices.performer.type.search({ limit: 1000000 }),
      this.rootPrivateServices.tag.category.search({ limit: 1000000 }),
      this.rootPrivateServices.specification.category.search({
        limit: 1000000,
      }),
      this.rootPrivateServices.requirement.category.search({ limit: 100000 }),
      this.rootPrivateServices.user.entity.search({ limit: 100000 }),
    ]);
    this.ui.specifications.setList(specificationsSearchResult.data);
    this.ui.tags.setList(entitiesSearchResult.data);
    this.ui.performers.setList(performersSearchResult.data);
    this.ui.tagCategory.setList(tagCategoriesSearchResult.data);
    this.ui.specificationsCategories.setList(specificationCategorySearchResult.data);
    this.ui.categories.setList(categoriesSearchResult.data);
    this.ui.users.setList(usersSearchResult.data);
  }

  setShortNameGeneratorState(isCanGenerate: boolean) {
    this.ui.isCanGenerateShortName.setValue(isCanGenerate);
  }

  generateShortName = async (): Promise<string> => {
    const categoryId = this.ui.model.entity.categoryId || null;
    if (categoryId && this.ui.isCanGenerateShortName.value === true) {
      const category = await this.rootPrivateServices.requirement.category.getById(categoryId);
      const categoryRequirements = await this.rootPrivateServices.requirement.entity.search({
        filter: { categoryId: { equal: categoryId }, type: { equal: RequirementType.system } },
      });
      let namesNumbers = categoryRequirements.data.map((requirement) => {
        return requirement?.shortName?.includes(category?.shortName)
          ? Number(requirement?.shortName?.replace(category?.shortName, ''))
          : 0;
      });

      namesNumbers = namesNumbers.filter(function (value) {
        return !Number.isNaN(value);
      });

      namesNumbers.push(0);
      const lastNameIndex = Math.max(...namesNumbers);
      const newNameIndex = Math.round(lastNameIndex + 1);
      const categoryName = category?.shortName || '';
      let generatedShortName = `${categoryName}${newNameIndex}`;
      if (Number.isNaN(newNameIndex)) {
        generatedShortName = '';
      }
      this.ui.model.entity.shortName = generatedShortName;
    }

    return '';
  };
}
