import React from 'react';

export const TimeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.1"
      d="M13.0833 11.1874C13.6528 10.2792 13.9686 9.23507 13.9978 8.16352C14.027 7.09197 13.7686 6.03216 13.2495 5.09429C12.7303 4.15643 11.9694 3.37477 11.0459 2.8306C10.1223 2.28643 9.06984 1.99962 7.99789 2L8 8L13.0833 11.1874Z"
      fill="#F48FB1"
    />
    <path
      d="M7.99334 1.3335C4.31334 1.3335 1.33334 4.32016 1.33334 8.00016C1.33334 11.6802 4.31334 14.6668 7.99334 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 7.99334 1.3335ZM8.00001 13.3335C5.05334 13.3335 2.66668 10.9468 2.66668 8.00016C2.66668 5.0535 5.05334 2.66683 8.00001 2.66683C10.9467 2.66683 13.3333 5.0535 13.3333 8.00016C13.3333 10.9468 10.9467 13.3335 8.00001 13.3335Z"
      fill="#0094FF"
    />
    <path d="M8.33334 4.6665H7.33334V8.6665L10.8333 10.7665L11.3333 9.9465L8.33334 8.1665V4.6665Z" fill="#96F2FF" />
    <path d="M8.33322 4.6665H7.33322V8.6665L8.33325 8.1665L8.33322 4.6665Z" fill="#0094FF" />
  </svg>
);
