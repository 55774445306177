import React, { Fragment } from 'react';

import { useI18n } from '../../../../../application/language/useI18n';
import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { SettingsItemView } from '../../../../../view/admin/page/settings/SettingsPage';
import { IPageWithLayoutProperties } from '../../../../../view/layout/page/IPageWithLayoutProperties';

export const AdminLogsSettingsMenu = ({ layoutDomain }: IPageWithLayoutProperties) => {
  const { translate } = useI18n();
  return (
    <Fragment>
      {layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['plugin-settings-logger-is-allow']]) && (
        <SettingsItemView
          key="logs"
          title={translate('settings.loggingTitle')}
          buttons={[
            {
              title: translate('settings.eventLog'),
              url: `/settings/logs`,
            },
          ]}
          infoText={translate('settings.loggingDes')}
        />
      )}
    </Fragment>
  );
};
