import { extensionContainer } from '../../../../../../../common/container/ContainerContext';
import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IApplicationModel } from '../../../../../../../service/application/entity/IApplicationModel';
import { ISpecificationCategoryModel } from '../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ILocalization, ILocalizationToken } from '../../../../../application/language/ILocalization';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { IUnitResultModel } from '../../../../../service/unitResult/IUnitResultModel';
import { IUserDataModel, UserDataLevel } from '../../../../../service/userData/IUserDataModel';
import { IApplicationsWithUnits, IGroupedUnit } from './IGroupedUnit';
import { IPassingListPageUI } from './IPassingListPageUI';
import { IProjectsWithUserStats } from './IProjectsWithUserStats';
import { IAllPossibleUnitsScore } from './IUserScoreProgress';

const createTranslatedGroupUnits = () => {
  const i18n: ILocalization = extensionContainer.getByToken(ILocalizationToken)

  return injectEntityList([
    { groupName: i18n.translate('learningAdminPage.withoutContext'), importantUnitList: [], unimportantUnitList: [], passedAmount: 0 },
  ])
}

export class PassingListPageUI implements IPassingListPageUI {
  constructor(
    public passingList = injectEntityList<IUnitModel>([]),
    public characteristic: IEntityListStore<ISpecificationEntityModel> = injectEntityList([]),
    public specificationCategory: IEntityListStore<ISpecificationCategoryModel> = injectEntityList([]),

    public systems = injectEntityList<IApplicationModel>([]),
    public isAdminMode = injectPrimitive<boolean>(false),
    public groupedUnits: IEntityListStore<IGroupedUnit> = createTranslatedGroupUnits(),
    public unitResults = injectEntityList<IUnitResultModel>([]),
    public filtredGroupedUnits: IEntityListStore<IGroupedUnit> = injectEntityList([]),
    public passingReslutList = injectEntityList<IUnitResultModel>([]),
    public isLoading = injectPrimitive<boolean>(false),
    public allUnitsScore = injectPrimitive<IAllPossibleUnitsScore>({
      allUnitsScore: 0,
      possibleScoreOnePart: 0,
    }),
    public userData = injectPrimitive<IUserDataModel>({
      totalScore: 0,
      level: UserDataLevel.JUNIOR,
      maxScore: 0,
      toNextLevelScore: 0,
      userId: '',
    }),
    public importantUnitList = injectEntityList<IUnitModel>([]),
    public unimportantUnitList = injectEntityList<IUnitModel>([]),
    public applicationsWithUnits = injectEntityList<IApplicationsWithUnits>([]),
    public projectsWithUserStats = injectEntityList<IProjectsWithUserStats>([]),
    public isProjectsWithUserStatsLoading = injectPrimitive<boolean>(false),
  ) {
  }
}
