import { FileType } from '../../../../../../service/fileStore/IFileStoreService';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { FileUploadUI } from './FileUploadUi';
import { IFileUploadDomain } from './IFileUploadDomain';
import { IFileUploadUI } from './IFileUploadUI';

export class FileUploadDomain implements IFileUploadDomain {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    protected rootService = injectRootService(layoutDomain.serviceType.value),
    public ui: IFileUploadUI = new FileUploadUI(),
  ) {}

  async uploadToFileStore(file, fileType: FileType) {
    return await this.rootService.fileStore.uploadImage(file, fileType);
  }

  upLoad(files: any) {
    this.ui.files.setList(files);
  }
  loading(status, lenght) {}
  deleteFile() {
    this.ui.files.setList([]);
  }
  uppercaseFormater(list: string[]): string[] {
    const formattedList = list.map((item) => {
      return item.replace(/[\s.%]/g, '').toUpperCase();
    });
    return formattedList;
  }
}
