export const AccessDeniedIcon = () => {
  return (
    <svg width="592" height="257" viewBox="0 0 592 257" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1876_16553)">
        <g clip-path="url(#clip1_1876_16553)">
          <path
            d="M279.958 115.217L280.607 74.444C280.905 61.2706 291.362 50.4858 304.535 49.8819C319.056 49.2149 331.11 61.0813 330.772 75.6022L331.052 114.559C330.953 118.971 347.631 117.033 347.689 112.977L345.966 76.5307C346.512 53.1989 327.822 34.054 303.723 35.032V35.0545C283.815 35.8612 265.598 53.3612 265.102 74.6693L264.588 115.848L279.958 115.222V115.217Z"
            fill="#344046"
          />
          <path
            d="M336.88 118.065C334.733 118.065 332.779 117.741 331.552 117.002C330.289 116.24 330.041 115.24 330.059 114.537L329.779 75.6113C329.933 68.9052 327.275 62.3929 322.485 57.7148C317.653 52.9962 311.288 50.558 304.579 50.878C291.98 51.4549 281.888 61.8205 281.599 74.4711L280.95 115.24C280.941 115.767 280.521 116.195 279.993 116.218L264.624 116.844C264.357 116.867 264.087 116.754 263.888 116.565C263.69 116.375 263.586 116.114 263.586 115.839L264.1 74.6604C264.601 53.564 282.61 35.0275 303.483 34.0676C303.546 34.0496 303.614 34.0406 303.682 34.036C315.424 33.5493 326.405 37.7226 334.593 45.7628C342.844 53.8615 347.238 64.795 346.962 76.5442L348.686 112.923C348.677 113.784 348.262 114.96 346.349 116.019C344.111 117.259 340.259 118.061 336.88 118.061V118.065ZM305.725 48.8634C312.542 48.8634 318.948 51.4774 323.878 56.2907C329.062 61.3518 331.94 68.4004 331.773 75.6249L332.053 114.55C332.053 114.699 332.044 114.974 332.585 115.303C334.89 116.695 342.068 116.128 345.388 114.289C346.205 113.838 346.696 113.338 346.701 112.959L344.978 76.5713C345.239 65.3088 341.057 54.8935 333.199 47.1824C325.448 39.5749 315.068 35.6134 303.957 36.0145C303.894 36.0326 303.831 36.0416 303.763 36.0416C283.814 36.8528 266.563 54.5465 266.094 74.6874L265.593 114.807L278.978 114.262L279.614 74.426C279.926 60.7254 290.852 49.5124 304.494 48.886C304.904 48.8679 305.315 48.8589 305.725 48.8589V48.8634Z"
            fill="#90CAF9"
          />
          <path
            d="M297.019 227.324L314.234 228.004C338.071 227.41 359.147 198.945 356.887 165.491L353.269 111.972L251.943 111.161C242.952 111.125 235.413 119.138 236.036 128.098L239.162 173.296C241.219 203.708 266.509 227.324 297.019 227.324Z"
            fill="#90CAF9"
          />
          <path
            d="M313.615 226.486C347.179 226.486 373.737 198.102 371.472 164.648L367.962 112.766H260.83C255.903 112.766 252.006 116.934 252.335 121.842L255.759 172.458C257.816 202.866 283.106 226.481 313.615 226.481V226.486Z"
            fill="#344046"
          />
          <path
            d="M313.62 227.477C282.704 227.477 256.855 203.339 254.771 172.526L251.329 121.644C251.153 119.061 252.032 116.596 253.801 114.703C255.569 112.81 257.974 111.769 260.563 111.769H359.571C364.795 111.769 369.167 115.848 369.518 121.058L369.997 128.147C370.033 128.692 369.618 129.17 369.072 129.206C368.526 129.229 368.052 128.828 368.012 128.282L367.533 121.193C367.249 117.024 363.753 113.757 359.571 113.757H260.563C258.529 113.757 256.643 114.573 255.253 116.06C253.864 117.547 253.174 119.481 253.309 121.509L256.751 172.39C258.768 202.162 283.742 225.485 313.615 225.485C341.729 225.485 365.977 204.565 370.019 176.825C370.1 176.284 370.606 175.892 371.147 175.987C371.688 176.064 372.067 176.568 371.986 177.114C367.804 205.822 342.708 227.473 313.615 227.473L313.62 227.477Z"
            fill="#90CAF9"
          />
          <path
            d="M314.951 193.663C317.685 193.663 319.85 191.355 319.679 188.629L317.108 148.297C316.95 145.805 314.879 143.867 312.38 143.867C309.646 143.867 307.481 146.174 307.652 148.901L310.223 189.232C310.381 191.725 312.452 193.663 314.951 193.663Z"
            fill="#90CAF9"
          />
          <path
            d="M317.295 168.93C326.67 166.719 332.475 157.335 330.263 147.97C328.05 138.605 318.657 132.804 309.283 135.015C299.908 137.225 294.102 146.609 296.315 155.974C298.527 165.34 307.921 171.14 317.295 168.93Z"
            fill="#90CAF9"
          />
        </g>
        <path
          d="M583.795 110.226C594.337 110.226 594.921 94.4149 584.391 93.9072C584.104 93.8934 583.814 93.8851 583.522 93.8851C581.643 93.8851 579.833 94.1819 578.134 94.7257C576.725 86.1697 569.271 79.639 560.283 79.639C551.295 79.639 543.516 86.461 542.352 95.2944C540.172 94.254 537.732 93.6687 535.154 93.6687C533.714 93.6687 532.317 93.8518 530.983 94.193C521.764 96.5595 523.699 110.226 533.219 110.226H583.795Z"
          fill="#4B5D66"
        />
        <path
          d="M64.6722 196.013C76.0883 196.013 76.7203 178.895 65.3182 178.343C65.0063 178.329 64.6945 178.321 64.377 178.321C62.3416 178.321 60.3814 178.643 58.5437 179.231C57.0178 169.965 48.9486 162.896 39.2171 162.896C29.4856 162.896 21.0655 170.281 19.8014 179.847C17.443 178.72 14.7978 178.088 12.0078 178.088C10.4485 178.088 8.9366 178.285 7.49149 178.654C-2.49063 181.214 -0.393972 196.013 9.91114 196.013"
          fill="#4B5D66"
        />
        <path
          d="M452.432 82.9349C459.688 90.5698 461.674 90.6225 469.334 83.3899C461.671 90.6197 461.618 92.5978 468.877 100.23C461.621 92.595 459.636 92.5423 451.976 99.7749C459.638 92.5451 459.691 90.567 452.432 82.9349Z"
          fill="#4B5D66"
        />
        <path
          d="M189.773 51C197.029 58.6349 199.015 58.6876 206.674 51.455C199.012 58.6848 198.959 60.6629 206.218 68.295C198.962 60.6601 196.976 60.6074 189.316 67.84C196.979 60.6102 197.032 58.6321 189.773 51Z"
          fill="#4B5D66"
        />
        <path
          d="M93.1764 154.939C100.433 162.574 102.418 162.627 110.078 155.394C102.415 162.624 102.362 164.602 109.621 172.234C102.365 164.599 100.38 164.546 92.7197 171.779C100.382 164.549 100.435 162.571 93.1764 154.939Z"
          fill="#4B5D66"
        />
        <path
          d="M33.5201 86.1975C40.7763 93.8324 42.7616 93.8851 50.4215 86.6525C42.7588 93.8823 42.7059 95.8604 49.9649 103.492C42.7087 95.8576 40.7234 95.8049 33.0635 103.037C40.7262 95.8077 40.7791 93.8296 33.5201 86.1975Z"
          fill="#4B5D66"
        />
        <path
          d="M527.509 205.542C535.171 198.312 535.224 196.334 527.965 188.702C535.222 196.337 537.207 196.39 544.867 189.157C537.204 196.387 537.151 198.365 544.41 205.997"
          fill="#4B5D66"
        />
      </g>
      <defs>
        <clipPath id="clip0_1876_16553">
          <rect width="592" height="257" fill="white" />
        </clipPath>
        <clipPath id="clip1_1876_16553">
          <rect width="136" height="194" fill="white" transform="translate(236 34)" />
        </clipPath>
      </defs>
    </svg>
  );
};
