import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Paper } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ConfigManager } from '../../../../../../application/config/ConfigManager';
import { useI18n } from '../../../../../../application/language/useI18n';
import { ContainerContext } from '../../../../../../common/container/ContainerContext';
import { IUnitListAdminPageDomain } from '../../../../../../extension/learning/view/admin/page/unitListPage/store/IUnitListAdminPageDomain';
import { UnitListAdminPageDomain } from '../../../../../../extension/learning/view/admin/page/unitListPage/store/UnitListAdminPageDomain';
import { UserProjectRoleAction } from '../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { FormMultiAutocomplete } from '../../../../../design/form/FormMultiAutocomplete';
import { FormTextArea } from '../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../design/form/FormTextField';
import { IAdminPageProperties } from '../../../IAdminPageProperties';
import { RecommendedUnits } from './components/RecommendedUnits';
import { UserProjectRoleAdminFormDomain } from './store/UserProjectRoleAdminFormDomain';
import { areArraysEqual } from './utils/areArraysEqual';

export interface IUserProjectRoleAdminFormProperties extends IAdminPageProperties {}

export const UserProjectRoleAdminForm = observer(({ layoutDomain }: IUserProjectRoleAdminFormProperties) => {
  const { translate } = useI18n();
  const container = useContext(ContainerContext);
  const [domain] = useState(new UserProjectRoleAdminFormDomain(layoutDomain, container));
  const [unitListAdminPageDomain] = useState<IUnitListAdminPageDomain>(new UnitListAdminPageDomain(layoutDomain));
  const { roleId = null } = useParams<{ roleId?: string }>();
  const uiEntity = domain.ui.model.entity;
  const extensions = ConfigManager.getConfig()?.extensions?.include;
  const [checkedUnits, setCheckedUnits] = useState<string[]>([]);
  const [initialUnits, setInitialUnitsList] = useState<string[]>([]);

  const onSave = async () => {
    let urlParams = areArraysEqual(checkedUnits, initialUnits) ? '' : '?unitsUpdating=true';
    const newId = await domain.save(urlParams);

    if (extensions.includes('learning') && (roleId || newId)) {
      unitListAdminPageDomain.updateUnitsProjectRoles(roleId || newId, checkedUnits);
    }
  };

  useEffect(() => {
    domain.loadData(roleId);
    if (extensions.includes('learning')) {
      unitListAdminPageDomain.loadData();
    }
  }, [domain, roleId]);

  return (
    <>
      <Paper sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', padding: '16px', alignItems: 'center' }}>
          <Button onClick={domain.backToRolesPage}>
            <ArrowBackIcon sx={{ marginRight: '8px' }} />
            <span>{translate('phrases.projectRoles')}</span>
          </Button>
        </Box>
        <Box sx={{ display: 'flex', gap: '8px', padding: '16px' }}>
          <Button variant="outlined" color="secondary" onClick={domain.cancelEdit}>
            {translate('phrases.cancel')}
          </Button>

          <Button variant="contained" color="success" onClick={onSave}>
            {translate('phrases.save')}
          </Button>
        </Box>
      </Paper>

      <FormTextField
        errorMessage={domain.getValidationErrorFor('name')?.message}
        value={uiEntity.name}
        onChange={domain.getUpdateFieldHandler('name')}
        label={translate('projectRoles.name')}
        required={true}
      />

      <FormTextArea
        errorMessage={domain.getValidationErrorFor('description')?.message}
        value={uiEntity.description}
        onChange={domain.getUpdateFieldHandler('description')}
        label={translate('phrases.description')}
        required={true}
      />

      <FormMultiAutocomplete
        values={domain.actionsValues}
        onChangeSelect={(values) => {
          if (!domain.ui.model.entity.permissions?.data) {
            domain.ui.model.setEntity({
              ...domain.ui.model.entity,
              permissions: {
                data: [],
              },
            });
          }
          const permissions = values.map((item) => {
            return {
              isGranted: true,
              action: item.value,
            };
          });
          domain.ui.model.setEntity({ ...domain.ui.model.entity, permissions: { data: permissions } });
        }}
        key="permissions"
        selected={
          !domain.ui.model.entity.permissions?.data
            ? [UserProjectRoleAction.showRegulators]
            : uiEntity.permissions?.data
                ?.filter((item) => {
                  const actionValue = domain.actionsValues.find((value) => value.value === item.action);
                  return actionValue?.value && item.isGranted;
                })
                .map((item) => {
                  const actionValue = domain.actionsValues.find((value) => value.value === item.action);
                  return actionValue?.value || '';
                })
        }
        label={translate('phrases.rights')}
      />

      {extensions.includes('learning') && (
        <RecommendedUnits
          layoutDomain={layoutDomain}
          currentRoleId={roleId}
          checkedUnits={checkedUnits}
          setCheckedUnits={setCheckedUnits}
          setInitialUnitsList={setInitialUnitsList}
        />
      )}
    </>
  );
});
