import { observer } from 'mobx-react';
import React from 'react';

import { UserSystemRoleModelPermissionMap } from '../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ListItemLink } from '../view/design/list/ListItemLink';

export interface ILearningNavigation {
  layoutDomain: IMainLayoutDomainStore;
}
export const LearningNavigation = observer(({ layoutDomain }: ILearningNavigation) => {
  const isAdmin = layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);

  return (
    <ListItemLink
      itemKey="learning"
      url={isAdmin ? '/learning/admin' : `/learning/user`}
      primaryText={'navigation.learning'}
      isSelected={layoutDomain.isSelected.value === 'learning'}
      onClick={() => {
        layoutDomain.isSelected.setValue('learning')
      }}
    />
  );
});
