import React from 'react';

export const UnitStarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.33334 8.00016C1.33334 4.32016 4.31334 1.3335 7.99334 1.3335C11.68 1.3335 14.6667 4.32016 14.6667 8.00016C14.6667 11.6802 11.68 14.6668 7.99334 14.6668C4.31334 14.6668 1.33334 11.6802 1.33334 8.00016ZM8.00001 10.3002L10.82 12.0002L10.0733 8.78683L12.56 6.6335L9.28001 6.3535L8.00001 3.3335L6.72001 6.36016L3.44001 6.64016L5.92668 8.7935L5.18001 12.0002L8.00001 10.3002Z"
      fill="#90CAF9"
    />
  </svg>
);
