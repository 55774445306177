import { PerformerTypeAdminDomain } from '../../view/admin/page/performer/type/store/PerformerTypeAdminDomain';
import { ConfigManager } from '../../application/config/ConfigManager';
import { IContainer } from '../../common/container/IContainer';
import { UserProjectRoleActionExtension } from '../../service/projectRole/entity/actions/UserProjectRoleActionExtension';
import { SystemRolesAdditionalPermissionsInject } from '../../view/admin/page/systemRole/entity/store/injects/SystemRolesAdditionalPermissionsInject';
import {
  applicationApplicationProtocolTemplateInject,
  applicationApplicationTestProgramTemplateInject,
  applicationReportTemplateInject,
} from '../../view/user/page/application/info/inject/ApplicationInfoTemplateInjects';
import { isAcceptRequirementAvailableExtension } from '../../view/user/page/application/info/inject/isAcceptRequirementAvailableExtension';
import { IsShowAllRequirementVendorList } from '../../view/user/page/project/list/inject/IsShowAllRequirementVendorList';
import { IExtension } from '../interface/IExtension';
import { addGPBTranslations } from './locale/addGPBTranslations';
import { extendAdminPerformerTypeTableDomain } from './performerType/extendAdminPerformerTypeTableDomain';
import { GPBIsProjectRoleCanVerfifyRequirementsExtension } from './projectRole/GPBIsProjectRoleCanVerfifyRequirementsExtension';
import { GPBProjectRoleExtension } from './projectRole/GPBProjectRoleExtension';
import { GPBSystemRoleExtensions } from './systemRole/GPBSystemRolesExtension';
import { UserProjectRoleTranslationInjection } from '../../view/admin/page/projectRole/entity/table/store/inject/TranslationExtensions';
import {
  RequirementAuthorField,
  RequirementPerformersCustomField,
} from '../../view/admin/page/requirement/newEntity/store/drawer/parts/EditModeComponent';
import { EmptyComponent } from './emptyFields/EmptyComponent';
import { RequirementSimpleInfo } from '../../view/admin/page/requirement/newEntity/store/drawer/parts/InfoModeComponent';
import { ViewModeListsExtended } from './questionnaire/ExtendQuestionnaireView';
import { EditModeListsExtended } from './questionnaire/ExtendQuestionnaireEdit';
import {
  QuestionnaireVersion,
  ViewModeLists,
} from '../../view/user/page/application/info/parts/application/ApplicationInfoDetailsView';
import { QuestionnaireEdit } from '../../view/user/page/application/info/parts/application/ApplicationInfoDetailsForm';
import { AppExtension } from '../../view/user/page/project/info/parts/AppExtension';
import { GPBRatingAppTitleExtension, GPBRatingAppValuesExtension } from './application/ratingApp/RatingAppExtension';
import { ProjectFormFieldCodeName, ProjectFormFieldJiraLink } from '../../view/user/page/project/form/ProjectForm';
import {
  ApplicationInfoReport, DownloadAcceptanceTests, generateMajorVersion,
  generateName, generateWithMinorVersion, QuestionnaireDownload, RequirementsDownload, TestingProgramDownload,
} from '../../view/user/page/application/info/parts/report/ApplicationInfoReport';
import { ApplicationInfoReportWithColor } from './application/reports/RatingAppExtension';
import { ProjectInfoDomain } from '../../view/user/page/project/info/store/ProjectInfoDomain';
import { extendProjectInfoDomain } from './application/ratingApp/store/extendProjectInfoDomain';
import {
  extendedGenerateMajorVersion,
  extendedGenerateName,
  extendedGenerateWithMinorVersion,
} from './report/ExtendedNamesGenerators';
import { VersionListTextWithNumber } from './application/versionListText/VersionListTextWithNumber'
import { VersionListText, removeThirdVesrsionAudit } from '../../view/user/page/application/info/parts/applicationInfoHistory/ApplicationInfoHistory';
import { ExtendButtons } from '../../view/user/page/application/info/parts/requirements/ActiveRequirementsActions';
import { ActionButtons } from './requierments/ApplicationRequirementPageActions';
import { ExtendedDownloadAcceptanceTests } from './report/DownloadProtocolInHistory';
import { ExtendedApplicationProtocolReport } from './report/ExtendedApplicationProtocolReport';
import {
  ExtendedQuestionnaireDownload,
  QuestionnaireVersionExtended,
  RequirementVersionExtended,
} from './report/QuestionnaireExtends';
import {
  RequirementVersion
} from "../../view/user/page/application/info/parts/requirements/ApplicationRequirementContainer";
import { ExtendedRequirementsDownload } from './report/RequirementsExtends';
import { ExtendedTestingProgramDownload } from './report/TestingProgramExtends';
import { NotificationTextWithExtention } from './notifications/GpbNotificationText'
import { NotificationText } from '../../view/user/page/notification/parts/NotificationText';
import { removeThirdVesrsionAuditExtend } from './application/historyFilter/removeThirdVesrsionAudit';
import { ApplicationProtocolReport } from '../../view/user/page/application/info/parts/report/ApplicationProtocolReport';

export default class GPBExtension implements IExtension {
  async loadExtension(container: IContainer) {
    container.setByToken(IsShowAllRequirementVendorList, false);
    container.set(generateName, extendedGenerateName);
    container.set(generateWithMinorVersion, extendedGenerateWithMinorVersion);
    container.set(generateMajorVersion, extendedGenerateMajorVersion);
    container.set(QuestionnaireDownload, ExtendedQuestionnaireDownload);
    container.set(RequirementsDownload, ExtendedRequirementsDownload);
    container.set(TestingProgramDownload, ExtendedTestingProgramDownload);

    container.set(RequirementVersion, RequirementVersionExtended);

    container.set(QuestionnaireVersion, QuestionnaireVersionExtended);

    container.setByToken(applicationReportTemplateInject, '/extension/gpb/templates/applicationReportTemplate.docx');
    container.setByToken(
      applicationApplicationTestProgramTemplateInject,
      '/extension/gpb/templates/applicationApplicationTestProgramTemplate.docx',
    );
    container.setByToken(
      applicationApplicationProtocolTemplateInject,
      '/extension/gpb/templates/applicationApplicationProtocolTemplate.docx',
    );

    container.set(ProjectFormFieldCodeName, EmptyComponent);
    container.set(ProjectFormFieldJiraLink, EmptyComponent);

    container.set(RequirementPerformersCustomField, EmptyComponent);
    container.set(RequirementAuthorField, EmptyComponent);
    container.set(RequirementSimpleInfo, EmptyComponent as any);

    const performerTypeDomainConstructor = container.get(PerformerTypeAdminDomain);
    const extendedPerformerTypeDomainConstructor = extendAdminPerformerTypeTableDomain(performerTypeDomainConstructor);
    container.set(PerformerTypeAdminDomain, extendedPerformerTypeDomainConstructor);

    container.set(ViewModeLists, ViewModeListsExtended);
    container.set(QuestionnaireEdit, EditModeListsExtended);
    container.set(VersionListText, VersionListTextWithNumber);
    container.set(NotificationText, NotificationTextWithExtention)

    const projectRoleActionsExtension = container.get(UserProjectRoleActionExtension);
    projectRoleActionsExtension.push(...GPBProjectRoleExtension);
    container.set(UserProjectRoleActionExtension, projectRoleActionsExtension);

    const translationExtensions = container.get(UserProjectRoleTranslationInjection);
    translationExtensions.push(...GPBProjectRoleExtension);

    let systemRolesInjects = container.get(SystemRolesAdditionalPermissionsInject);
    systemRolesInjects.push(...GPBSystemRoleExtensions);
    let verifyRequirementsHandlers = container.get(isAcceptRequirementAvailableExtension);
    verifyRequirementsHandlers.push(GPBIsProjectRoleCanVerfifyRequirementsExtension);

    container.set(AppExtension, {
      TableTitleExtension: GPBRatingAppTitleExtension,
      TableCellExtension: GPBRatingAppValuesExtension,
    });

    const ProjectInfoDomainRef = container.get(ProjectInfoDomain);
    const ExtendedProjectInfoDomainRef = extendProjectInfoDomain(ProjectInfoDomainRef);
    container.set(ProjectInfoDomain, ExtendedProjectInfoDomainRef);

    container.set(ApplicationInfoReport, ApplicationInfoReportWithColor);

    container.set(ExtendButtons, ActionButtons);
    container.set(DownloadAcceptanceTests, ExtendedDownloadAcceptanceTests);

    container.set(ApplicationProtocolReport, ExtendedApplicationProtocolReport);

    container.set(removeThirdVesrsionAudit, removeThirdVesrsionAuditExtend);

    ConfigManager.getConfig().ui.project.form.isUnitedWithApplicationByDefault = true;

    ConfigManager.getConfig().ui.application.acceptWithVersion = true;

    container = await addGPBTranslations(container);
  }
}
