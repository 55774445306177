import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { useI18n } from '../../../../../application/language/useI18n';
import { ITask, ITaskLanguage } from '../../../service/unit/interface/IUnitPractice';
import { IPassingPracticeDomain } from '../../admin/page/passing/parts/practice/store/IPassingPracticeDomain';
import { PassingFileTree } from './parts/passingFileTree/PassingFileTree';
import { PassingReview } from './parts/passingReview/PassingReview';
import { IPassingCodeEditorDomain } from './passingStore/IPassingCodeEditorDomain';

export interface IPassingCodeEditor {
  lang: ITaskLanguage;
  domain: IPassingCodeEditorDomain;
  rootDomain: IPassingPracticeDomain;
  task: ITask;
  setShowSidebar?: (value: boolean) => void;
}

export const PassingCodeEditor = observer(({ domain, lang, rootDomain, task, setShowSidebar }: IPassingCodeEditor) => {
  const { translate } = useI18n();
  const containerRef = useRef<HTMLDivElement>(null);
  const [editorHeight, setEditorHeight] = useState<number>(0);

  const currentAnswer = rootDomain.ui.answersList.list.filter((answer) => answer.task === task.pseudoId)[0];
  const canCheck = currentAnswer && rootDomain?.canCheckTask(currentAnswer);

  useEffect(() => {
    if (lang !== null) {
      domain.ui.fileTree.setList(lang.files);
      domain.ui.lang.setValue(lang);
      domain.ui.currentFile.setValue(lang.files[0]);
      domain.boot();
    }
  }, [domain, lang, rootDomain]);

  useEffect(() => {
    if (containerRef.current) {
      setEditorHeight(containerRef.current.clientHeight);
    }
  }, [rootDomain?.ui.isFullScreen.value]);

  const toggleFullscreen = () => {
    rootDomain?.ui.isFullScreen.setValue(!rootDomain?.ui.isFullScreen.value);
    setShowSidebar?.(!rootDomain?.ui.isFullScreen.value);
  };

  return (
    <Box
      ref={containerRef}
      id="fullscreen-container"
      sx={{
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        height: '100%',
        position: 'relative',
        minHeight: '453px',
        background: '#263238',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '8px 16px',
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
          borderTop: !rootDomain?.ui.isFullScreen.value ? '1px solid rgba(255, 255, 255, 0.12)' : 'none',
        }}
      >
        <Tooltip title={!rootDomain?.ui.isFullScreen.value ? translate('phrases.expand') : translate('phrases.fold')}>
          <IconButton
            onClick={toggleFullscreen}
            sx={{
              alignSelf: 'center',
              height: '24px',
              width: '24px',
              background: '#f5f5f5',
              borderRadius: '4px',
              color: 'black',
              '&:hover': {
                background: '#1976D2',
                color: 'white',
              },
            }}
          >
            {!rootDomain?.ui.isFullScreen.value && <ExpandLess />}
            {rootDomain?.ui.isFullScreen.value && <ExpandMore />}
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <Box sx={{ borderRight: '1px solid rgba(255, 255, 255, 0.12)' }}>
          <Typography sx={{ padding: '4px 8px' }}>{translate('phrases.project')}</Typography>
          <Box
            sx={{
              minWidth: '218px',
              maxWidth: '218px',
              overflowY: 'auto',
              height: '100%',
              maxHeight: rootDomain?.ui.isFullScreen.value ? 'calc(100vh - 146px)' : '378px',
              overflowWrap: 'anywhere',
              paddingBottom: '24px',
            }}
          >
            {' '}
            <PassingFileTree
              fileTree={domain.ui.fileTree.list}
              currentFile={domain.ui.currentFile.value}
              pickingFile={domain.pickingFile}
            />
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            maxWidth: '100%',
            overflowX: 'hidden',
            //TODO: при ширине 900 и меньше DiffEditor начинает некорректно отображать данные
            minWidth: '901px'
          }}
        >
          <PassingReview
            currentAnswer={currentAnswer}
            canCheck={canCheck}
            task={task}
            file={domain.ui.currentFile.value}
            lang={lang.name}
            currentOption={domain.ui.currentOption.value}
            pickingOption={domain.pickingOption}
            addAnswer={rootDomain?.addAnswer}
            height={editorHeight}
            isExpand={rootDomain?.ui.isFullScreen.value}
            getOption={rootDomain?.getOption}
            setCorrectOptionForPickedLanguage={rootDomain?.setCorrectOptionForPickedLanguage}
          />
        </Box>
      </Box>
    </Box>
  );
});
