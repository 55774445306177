import Editor from '@monaco-editor/react';
import { Add, FileCopy } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { useI18n } from '../../../../../../../../../application/language/useI18n';
import { IQuestion } from '../../../../../../../service/unit/interface/IUnitQuiz';
import { generateUUID } from '../../../../../../../utils/generateUUID';
import { FormMultiAutocompleteStandard } from '../../../../../../design/form/FormMultiAutocompleteStandard';
import { FormTextFieldStandard } from '../../../../../../design/form/FormTextFieldStandard';
import { languagesList } from './LanguagesList';

export interface IQuestionPageProperties {
  question: IQuestion;
}
export const QuestionPage = observer(({ question }: IQuestionPageProperties) => {
  const { translate } = useI18n();
  const [currentCodeIndex, setCurrentCodeIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    setCurrentCodeIndex(undefined);
  }, [question]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '760px',
        width: '100%',
        gap: '64px',
        paddingBottom: '32px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
        <Typography variant="h6">{`${translate('phrases.question')} ${question.sortIndex}`}</Typography>
        <FormTextFieldStandard
          multiline
          label={translate('phrases.question')}
          value={question.name}
          onChange={(newValue) => {
            question.name = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          label="Пояснение"
          value={question.explanation}
          onChange={(newValue) => {
            question.explanation = newValue;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
        <Typography variant="h6">Выберите тип</Typography>
        <FormControl sx={{ paddingLeft: '16px' }}>
          <RadioGroup
            value={question.type}
            onChange={(event) => {
              question.type = event.target.value;
              question.answers.forEach((answer) => {
                answer.isCorrect = false;
              });
            }}
          >
            <FormControlLabel value="single" control={<Radio />} label="Один" />
            <FormControlLabel value="multi" control={<Radio />} label="Мульти" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
        <Typography variant="h6">Ответы</Typography>
        <FormControlLabel
          control={<Checkbox checked={question.randomize} />}
          label="Не рандомизировать"
          sx={{ paddingLeft: '16px' }}
          onChange={(newValue) => {
            question.randomize = !question.randomize;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
        <FormControl>
          <RadioGroup
            onChange={(event, value) => {
              question.answers.forEach((answer) => {
                answer.isCorrect = false;
              });
              question.answers[+value].isCorrect = true;
            }}
          >
            {question.answers?.map((answer, index) => {
              return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormTextFieldStandard
                    multiline={true}
                    label={`${translate('passing.option')} ${index + 1}`}
                    value={answer.answer}
                    onChange={(newValue) => {
                      answer.answer = newValue;
                    }}
                  />
                  <FormControlLabel
                    value={index}
                    control={
                      question.type === 'single' ? (
                        <Radio checked={answer.isCorrect} />
                      ) : (
                        <Checkbox
                          onChange={(event) => {
                            answer.isCorrect = !answer.isCorrect;
                          }}
                          checked={answer.isCorrect}
                        />
                      )
                    }
                    label="Правильно"
                    sx={{ paddingLeft: '16px' }}
                  />
                </Box>
              );
            })}
          </RadioGroup>
        </FormControl>

        <Button
          startIcon={<Add />}
          size={'small'}
          sx={{ width: 'fit-content' }}
          variant="outlined"
          onClick={() => {
            question.answers.push({
              answer: `${translate('passing.option')} ${question?.answers?.length + 1}`,
              isCorrect: false,
              pseudoId: generateUUID(),
            });
          }}
        >
          Добавить ответ
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
        <FormControlLabel
          control={
            <Switch
              checked={question.isCodeExample}
              onChange={(event) => {
                question.isCodeExample = event.target.checked;
              }}
            />
          }
          label="Пример кода"
        />
        {question.isCodeExample && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
            <FormMultiAutocompleteStandard
              selected={question.codeExample.map((code) => code.language)}
              values={languagesList}
              label={'Языки'}
              onChangeSelect={(newValue) => {
                const newCode = newValue.map((item) => {
                  const existingLanguage = question.codeExample.find((code) => code.language === item.value);
                  return existingLanguage || { language: item.value as string, value: '', name: item.name as string };
                });

                question.codeExample = newCode;

                if (currentCodeIndex !== undefined && currentCodeIndex >= newCode.length) {
                  setCurrentCodeIndex(undefined);
                }
              }}
            />
            <Box sx={{ display: 'flex', gap: '8px' }}>
              {question.codeExample.map((code, index) => (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '16px',
                    cursor: 'pointer',
                    background: code.language ? '#42a5f5' : '',
                  }}
                  onClick={() => setCurrentCodeIndex(index)}
                >
                  {code.name}
                </Button>
              ))}
            </Box>
            {currentCodeIndex !== undefined && currentCodeIndex < question.codeExample.length && (
              <Box>
                <Box
                  sx={{
                    borderRadius: '16px 16px 0px 0px',
                    background: '#1E1E1E',
                    padding: '4px 16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant="body1">{question.codeExample[currentCodeIndex].name}</Typography>
                  <IconButton
                    sx={{ cursor: 'pointer', fontSize: '20px' }}
                    onClick={() => {
                      navigator.clipboard.writeText(question.codeExample[currentCodeIndex].value);
                    }}
                  >
                    <FileCopy />
                  </IconButton>
                </Box>
                <Editor
                  theme="vs-dark"
                  height="400px"
                  language={question.codeExample[currentCodeIndex].language.toLowerCase()}
                  value={question.codeExample[currentCodeIndex].value}
                  onChange={(newValue) => {
                    return newValue ? (question.codeExample[currentCodeIndex].value = newValue) : '';
                  }}
                  options={{
                    minimap: { enabled: false },
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
});
