import { FormControlLabel, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { RequirementCategoryAdminModalDomain } from './RequirementCategoryAdminModalDomain';

export interface IRequirementCategoryAdminModalProperties {
  domain: RequirementCategoryAdminModalDomain;
}
export const RequirementCategoryAdminModal = observer(({ domain }: IRequirementCategoryAdminModalProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? 'Редактировать' : 'Добавить');
  }, [domain]);

  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <RequirementNameCustomFieldText domain={domain} />
      <RequirementShortNameCustomFieldText domain={domain} />
      <RequirementDescriptionCustomFieldArea domain={domain} />
      <RequirementActiveStatusCustomSwitch domain={domain} />
      <RequirementOrderCustomFieldText domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const RequirementNameCustomFieldText = observer(({ domain }: { domain: RequirementCategoryAdminModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
    />
  );
});
const RequirementShortNameCustomFieldText = observer(({ domain }: { domain: RequirementCategoryAdminModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Короткое название'}
      value={domain.ui.model.entity.shortName}
      onChange={domain.getUpdateFieldHandler('shortName')}
      errorMessage={domain.getValidationErrorFor('shortName')?.message}
    />
  );
});

const RequirementDescriptionCustomFieldArea = observer(
  ({ domain }: { domain: RequirementCategoryAdminModalDomain }) => {
    return (
      <FormTextArea
        required={true}
        label={'Описание'}
        value={domain.ui.model.entity.description}
        onChange={domain.getUpdateFieldHandler('description')}
        errorMessage={domain.getValidationErrorFor('description')?.message}
      />
    );
  },
);

const RequirementActiveStatusCustomSwitch = observer(({ domain }: { domain: RequirementCategoryAdminModalDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isActive = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isActive);
  }, [domain.ui.model.entity.isActive]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
      label="Статус"
    />
  );
});

const RequirementOrderCustomFieldText = observer(({ domain }: { domain: RequirementCategoryAdminModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Порядок'}
      type="text"
      value={domain.ui.model.entity.orderIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
      errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
      inputProps={{ maxLength: 9 }}
    />
  );
});
