import React from 'react';

export const UnitNotPassedIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3333 7.3335C10.8856 7.3335 11.3333 6.88578 11.3333 6.3335C11.3333 5.78121 10.8856 5.3335 10.3333 5.3335C9.78106 5.3335 9.33334 5.78121 9.33334 6.3335C9.33334 6.88578 9.78106 7.3335 10.3333 7.3335Z"
      fill="#C62828"
    />
    <path
      d="M5.66668 7.3335C6.21896 7.3335 6.66668 6.88578 6.66668 6.3335C6.66668 5.78121 6.21896 5.3335 5.66668 5.3335C5.11439 5.3335 4.66668 5.78121 4.66668 6.3335C4.66668 6.88578 5.11439 7.3335 5.66668 7.3335Z"
      fill="#C62828"
    />
    <path
      d="M7.99334 1.3335C4.31334 1.3335 1.33334 4.32016 1.33334 8.00016C1.33334 11.6802 4.31334 14.6668 7.99334 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 7.99334 1.3335ZM8.00001 13.3335C5.05334 13.3335 2.66668 10.9468 2.66668 8.00016C2.66668 5.0535 5.05334 2.66683 8.00001 2.66683C10.9467 2.66683 13.3333 5.0535 13.3333 8.00016C13.3333 10.9468 10.9467 13.3335 8.00001 13.3335ZM8.00001 9.3335C6.73334 9.3335 5.58001 9.98016 4.90001 11.0535C4.75334 11.2868 4.82668 11.5935 5.06001 11.7402C5.29334 11.8868 5.60001 11.8135 5.74668 11.5802C6.24001 10.7935 7.08001 10.3268 8.00001 10.3268C8.92001 10.3268 9.76001 10.7935 10.2533 11.5802C10.3467 11.7335 10.5133 11.8135 10.68 11.8135C10.7733 11.8135 10.86 11.7868 10.9467 11.7402C11.18 11.5935 11.2533 11.2868 11.1067 11.0535C10.42 9.9735 9.26668 9.3335 8.00001 9.3335Z"
      fill="#C62828"
    />
  </svg>
);
