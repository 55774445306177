import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { FormTextFieldStandard } from '../../../../../../design/form/FormTextFieldStandard';
import { IUnitDomain } from '../../../store/IUnitDomain';
import { useI18n } from '../../../../../../../../../application/language/useI18n';

interface IPracticeResult {
  unitDomain: IUnitDomain;
}
export const PracticeResult = observer(({ unitDomain }: IPracticeResult) => {
  const {translate} = useI18n() 
  const { success, neutral, failure } = unitDomain.ui.unit.entity.practice.results;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '66px', flex: 1, padding: '24px', overflowY: 'scroll' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6">{translate('passing.success')}</Typography>
        <Typography variant="body1">{translate('passing.successMessage')}</Typography>
        <FormTextFieldStandard
          multiline
          label={translate('phrases.title')}
          value={translate(success.title)}
          onChange={(newValue) => {
            success.title = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          label={translate('phrases.subtitle')}
          value={translate(success.subtitle)}
          onChange={(newValue) => {
            success.subtitle = newValue;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6">{translate('passing.neutral')}</Typography>
        <Typography variant="body1">{translate('passing.neutralMessage')}</Typography>
        <FormTextFieldStandard
          multiline
          label={translate('phrases.title')}
          value={translate(neutral.title)}
          onChange={(newValue) => {
            neutral.title = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          label={translate('phrases.subtitle')}
          value={translate(neutral.subtitle)}
          onChange={(newValue) => {
            neutral.subtitle = newValue;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6">{translate('passing.failure')}</Typography>
        <Typography variant="body1">{translate('passing.failureMessage')}</Typography>
        <FormTextFieldStandard
          multiline
          label={translate('phrases.title')}
          value={translate(failure.title)}
          onChange={(newValue) => {
            failure.title = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          label={translate('phrases.subtitle')}
          value={translate(failure.subtitle)}
          onChange={(newValue) => {
            failure.subtitle = newValue;
          }}
        />
      </Box>
    </Box>
  );
});
