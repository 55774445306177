import React from 'react';

export const UnitPassedIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00001 1.3335C4.32001 1.3335 1.33334 4.32016 1.33334 8.00016C1.33334 11.6802 4.32001 14.6668 8.00001 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 8.00001 1.3335ZM6.66668 11.3335L3.33334 8.00016L4.27334 7.06016L6.66668 9.44683L11.7267 4.38683L12.6667 5.3335L6.66668 11.3335Z"
      fill="#66BB6A"
    />
  </svg>
);
