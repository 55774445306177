import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React from 'react';

import { TranslateText } from '../../../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../../../application/language/useI18n';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';

export const Chapter = observer(({ data }) => {
  const { translate } = useI18n();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        padding: '16px 42px ',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <Typography sx={{ marginRight: 'auto' }}>
          <TranslateText i18nKey='createUnit.chapterName' />
        </Typography>
      </Box>
      <FormTextFieldStandard
        variant='outlined'
        multiline
        label={translate('createUnit.text')}
        value={data.title}
        onChange={(event) => {
          data.title = event;
        }}
      />
    </Box>
  );
});
