import { IStatusEntityModel } from '../../../../../../../service/status/entity/IStatusEntityModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { StatusEntityModal } from './StatusEntityModal';

export class StatusEntityModalUI
  extends DataTableFormUI<IStatusEntityModel>
  implements IDataTableFormUI<IStatusEntityModel> {
  constructor(
  ) {
    super();
    this.formComponent.setValue(StatusEntityModal);
  }
}
