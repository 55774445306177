import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import DOMPurify from 'dompurify';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useI18n } from '../../../../../application/language/useI18n';


// need for loading translation;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const translation = require('ckeditor5-custom-build/build/translations/en');


function AddTargetToLinks(editor) {
  editor.conversion.for('downcast').add((dispatcher) => {
    dispatcher.on('attribute:linkHref:default', (evt, data, conversionApi) => {
      const viewWriter = conversionApi.writer;
      const viewSelection = viewWriter.document.selection;

      const anchorElement = viewWriter.createAttributeElement(
        'a',
        {
          href: data.attributeNewValue,
          target: '_blank',
        },
        { priority: 5 },
      );

      viewWriter.setAttributes({ target: '_blank' }, anchorElement);
      conversionApi.mapper.bindElements(data.item, anchorElement);
      viewWriter.wrap(viewSelection.getFirstRange(), anchorElement);
    });
  });
}

export const LearningRichTextDisplay = ({ data }) => {
  const classes = useStyles();

  const createMarkup = (htmlString) => {
    const sanitizedHtmlString = DOMPurify.sanitize(htmlString, { USE_PROFILES: { html: true } });

    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedHtmlString, 'text/html');
    const anchors = doc.querySelectorAll('a');

    anchors.forEach((anchor) => {
      anchor.setAttribute('target', '_blank');
      anchor.setAttribute('rel', 'noopener noreferrer');
    });

    return { __html: sanitizedHtmlString };
  };

  return <div className={classes.passingStyle} dangerouslySetInnerHTML={createMarkup(data || '')} />;
};

export const LearningRichTextEditor = observer(
  ({ defaultInputValue = '', changeHandler, minHeight = '0%', disabled = false, error = '' }) => {
    const { getCurrentLanguage } = useI18n();
    const [editorData, setEditorData] = useState(defaultInputValue);
    const escapeUserInput = (text) => {
      return text.replace(/<(?![^<>]*>)/g, '&lt;').replace(/(?<!<[^<>]*)>/g, '&gt;');
    };
    const classes = useStyles();
    const handleChange = async (editor) => {
      let data = editor.getData();
      data = DOMPurify.sanitize(data, { USE_PROFILES: { html: true } });
      data = escapeUserInput(data);

      setEditorData(data);
      changeHandler(data);
    };

    const debouncedChangeHandler = debounce(handleChange, 300);
    // console.log(translation)
    return (
      <div className={classes.textEditor}>
        <CKEditor
          editor={Editor}
          data={editorData || ''}
          config={{
            language: {
              ui: getCurrentLanguage(),
              content: getCurrentLanguage(),
            },
            extraPlugins: [AddTargetToLinks],
            toolbar: {
              items: [
                'bold',
                'italic',
                'Underline',
                'fontColor',
                'link',
                '|',
                'numberedList',
                'bulletedList',
                '|',
                'undo',
                'redo',
              ],
            },
          }}
          onChange={(event, editor) => {
            debouncedChangeHandler(editor);
          }}
        />
      </div>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  textEditor: () => ({
    '--ck-border-radius': '4px',
    '--ck-font-size-base': '14px',
    '--ck-custom-background': '#263238',
    '--ck-custom-foreground': 'hsl(255, 3%, 18%)',
    '--ck-custom-border': 'rgba(255, 255, 255, 0.23)',
    '--ck-custom-white': 'hsl(0, 0%, 100%)',
    '--ck-color-base-border': 'var(--ck-custom-border)',
    '--ck-color-base-foreground': 'var(--ck-custom-background)',
    '--ck-color-focus-border': 'white',
    '--ck-focus-ring': '1px solid white',
    '--ck-color-text': 'hsl(0, 0%, 98%)',
    '--ck-color-shadow-drop': 'hsla(0, 0%, 0%, 0.2)',
    '--ck-color-shadow-inner': 'hsla(0, 0%, 0%, 0.1)',
    '--ck-color-button-default-background': 'var(--ck-custom-background)',
    '--ck-color-button-default-hover-background': 'hsl(270, 1%, 22%)',
    '--ck-color-button-default-active-background': 'hsl(270, 2%, 20%)',
    '--ck-color-button-default-active-shadow': 'hsl(270, 2%, 23%)',
    '--ck-color-button-default-disabled-background': 'var(--ck-custom-background)',
    '--ck-color-button-on-background': 'var(--ck-custom-foreground)',
    '--ck-color-button-on-hover-background': 'hsl(255, 4%, 16%)',
    '--ck-color-button-on-active-background': 'hsl(255, 4%, 14%)',
    '--ck-color-button-on-active-shadow': 'hsl(240, 3%, 19%)',
    '--ck-color-button-on-disabled-background': 'var(--ck-custom-foreground)',
    '--ck-color-button-action-background': 'hsl(168, 76%, 42%)',
    '--ck-color-button-action-hover-background': 'hsl(168, 76%, 38%)',
    '--ck-color-button-action-active-background': 'hsl(168, 76%, 36%)',
    '--ck-color-button-action-active-shadow': 'hsl(168, 75%, 34%)',
    '--ck-color-button-action-disabled-background': 'hsl(168, 76%, 42%)',
    '--ck-color-button-action-text': 'var(--ck-custom-white)',
    '--ck-color-button-save': 'hsl(120, 100%, 46%)',
    '--ck-color-button-cancel': 'hsl(15, 100%, 56%)',
    '--ck-color-dropdown-panel-background': 'var(--ck-custom-background)',
    '--ck-color-dropdown-panel-border': 'var(--ck-custom-foreground)',
    '--ck-color-split-button-hover-background': 'var(--ck-color-button-default-hover-background)',
    '--ck-color-split-button-hover-border': 'var(--ck-custom-foreground)',
    '--ck-color-input-background': 'hsl(270, 1%, 29%)',
    '--ck-color-input-border': 'hsl(257, 3%, 43%)',
    '--ck-color-input-text': 'hsl(0, 0%, 98%)',
    '--ck-color-input-disabled-background': 'hsl(255, 4%, 21%)',
    '--ck-color-input-disabled-border': 'hsl(250, 3%, 38%)',
    '--ck-color-input-disabled-text': 'hsl(0, 0%, 78%)',
    '--ck-color-labeled-field-label-background': 'var(--ck-custom-background)',
    '--ck-color-list-background': 'var(--ck-custom-background)',
    '--ck-color-list-button-hover-background': 'var(--ck-custom-foreground)',
    '--ck-color-list-button-on-background': 'hsl(208, 88%, 52%)',
    '--ck-color-list-button-on-text': 'var(--ck-custom-white)',
    '--ck-color-panel-background': 'hsl(270, 1%, 29%)',
    '--ck-color-panel-border': 'var(--ck-custom-border)',
    '--ck-color-toolbar-background': 'var(--ck-custom-background)',
    '--ck-color-toolbar-border': 'var(--ck-custom-border)',
    '--ck-color-tooltip-background': 'hsl(252, 7%, 14%)',
    '--ck-color-tooltip-text': 'hsl(0, 0%, 93%)',
    '--ck-color-image-caption-background': 'hsl(0, 0%, 97%)',
    '--ck-color-image-caption-text': 'hsl(0, 0%, 20%)',
    '--ck-color-widget-blurred-border': 'hsl(0, 0%, 87%)',
    '--ck-color-widget-hover-border': 'hsl(43, 100%, 68%)',
    '--ck-color-widget-editable-focus-background': 'white',
    '--ck-color-link-default': 'rgba(144, 202, 249, 1)',
    '& a': {
      color: 'rgba(144, 202, 249, 1)',
    },
    '& .ck-editor__main > .ck': {
      backgroundColor: '#263238 !important',
    },
    '& p': {
      margin: '0px',
    },
  }),
  passingStyle: {
    '& a': {
      color: 'rgba(144, 202, 249, 1)',
    },
  },
}));
