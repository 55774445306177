import { FileUploadUI } from "../../../../../../../design/fileUpload/store/FileUploadUi";

export class UploadPreviewUI extends FileUploadUI {
  constructor() {
    super();
    this.accept.setList(['.png','.jpg','.jpeg']);
    this.key.setValue('image/png');
    this.maxSize.setValue(5000000);
    
  }
}
