import AssignmentIcon from '@mui/icons-material/Assignment';
import { Button, Grid, Menu, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react';

import { ICoreSearchAudit } from '../../../service/coreCommon/common/audit/ICoreSearchAudit';
import { UserProjectRoleAction } from '../../../service/projectRole/entity/actions/UserProjectRoleAction';
import {
  UserSystemRoleModelPermissionMap,
} from '../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';

import { IActiveRequirementsActionsProperties } from '../../../view/user/page/application/info/parts/requirements/ActiveRequirementsActions';
import { ContainerContext } from '../../../common/container/ContainerContext';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { IApplicationModel } from '../../../service/application/entity/IApplicationModel';
import { ExtendedDownloadAcceptanceTests } from './DownloadProtocolInHistory'
import { IApplicationModelWithUser } from '../../../view/user/page/application/info/parts/applicationInfoHistory/store/ApplicationHistoryUI'

export interface IApplicationInfoReportProperties extends IActiveRequirementsActionsProperties {
  domain: ApplicationDetailDomain;
  audit?: ICoreSearchAudit;
  isNeedColor?: boolean;
  listWithAudits?: IApplicationModelWithUser[];
  fullVersion?: string;
  application?: IApplicationModel;
  displayedAuditList: IApplicationModelWithUser[];
  isLatestVersion: boolean;
  fullAuditList: IApplicationModelWithUser[];
}

const formats = ['docx', 'pdf'];

export const generateName = ({
  domain,
  reportName,
}: {
  domain: ApplicationDetailDomain;
  reportName?: string;
}): string => {
  return ``;
};

export const generateWithMinorVersion = ({ domain }: { domain: ApplicationDetailDomain }): string => {
  return ``;
};

export const generateMajorVersion = ({ domain }: { domain: ApplicationDetailDomain }): string => {
  return ``;
};


export const ExtendedApplicationProtocolReport = observer(({ domain, audit, listWithAudits, fullVersion,
  displayedAuditList, application, isLatestVersion, fullAuditList }: IApplicationInfoReportProperties) => {
  const isCanSeeReports =
    domain.ui.userProjectRoleActions.value.includes(UserProjectRoleAction.reportsAccess) ||
    domain.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]) ||
    domain.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['project-not-in-team-permission-report-access'],
    ]);
  const classes = useStyles();

  return isCanSeeReports ? (
    <Grid key="downloadApplicationReport" className={classes.button} item>
      <PopupState variant="popover" popupId="reportsDownloadMenu">
        {(popupState) => (
          <React.Fragment>
            <Button variant="outlined" startIcon={<AssignmentIcon />} color="secondary" {...bindTrigger(popupState)}>
              Протоколы
            </Button>
            <Menu {...bindMenu(popupState)}>

              {displayedAuditList.map((auditApp) => {
                const audit = domain.ui.application.entity.versionNumber === auditApp.versionNumber
                  ? undefined
                  : {
                    isReplaceId: true,
                    auditDateInMS: (application?.auditDate || new Date()).getTime() + 10 * 1000,
                  };
                return formats.map((format) => {
                  return (
                    <ExtendedDownloadAcceptanceTests
                      domain={domain}
                      format={format}
                      audit={audit}
                      popupState={popupState}
                      listWithAudits={listWithAudits}
                      fullVersion={fullVersion}
                      application={auditApp}
                      fullAuditList={fullAuditList}
                    />
                  );
                })
              })}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </Grid>
  ) : (
    <Fragment />
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: theme.spacing(1),
  },
}));
