import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { OverflownContent } from '../../../admin/page/LearningUnitPreviewPage/shared/OverflownContent';
import { useI18n } from '../../../../../../application/language/useI18n';

interface IUnitHeaderProps {
  title?: string;
  backButtonTitle?: string;
  backLink?: string;
  classes?: string;
}

export const UnitHeader = ({ title, backButtonTitle, backLink, ...props }: IUnitHeaderProps) => {
  const {translate} = useI18n()
  const classes = useStyles();
  const headerClasses = classNames(classes.header, props.classes)

  return (
    <header className={headerClasses}>
      <div className={classes.backButton}>
        <Link to={String(backLink)}>
          <Button variant="text" startIcon={<ArrowBackIcon className={classes.backIcon} />}>
            {translate(`phrases.${backButtonTitle}`)}
          </Button>
        </Link>
      </div>
      <OverflownContent contentClasses={classes.title}>{title}</OverflownContent>
      <div></div>
    </header>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '16px',
    justifyContent: 'space-between',
  },
  backButton: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    marginLeft: '16px'
  },
  backIcon: {
    color: '#90CAF9',
    width: '12px',
    height: '12px',
  },
  title: {
    maxWidth: '500px',
    position: 'relative',
    left: '-100px'
  },
}));
