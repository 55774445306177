import * as React from 'react';
import { useContext, useState } from 'react';

import { RequirementEntityDrawerDomain } from '../RequirementEntityDrawerDomain';
import { Grid, Theme, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { AdditionalColumnType } from '../../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { DownloadFileColumn } from '../../../../../../../design/fileUpload/DownloadFileColumn';
import { IFileUploadDomain } from '../../../../../../../design/fileUpload/store/IFileUploadDomain';
import { FileUploadDomain } from '../../../../../../../design/fileUpload/store/FileUploadDomain';
import { makeStyles } from '@mui/styles';
import { Colors } from '../../../../../../../design/color/Colors';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { ContainerContext } from '../../../../../../../../common/container/ContainerContext';
import { RichTextArea } from '../../../../../../../design/RichTextEditor/RichTextEditor';
import { RequirementCreationType } from '../../../../../../../../service/requirement/entity/RequirementType';

export const ExcludedColumns = ['uploadFile', 'editableText'];

const RequirementShortNameInfo = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  return (
    <Typography
      variant={'h5'}
    >
      {domain.ui.model.entity?.shortName}
    </Typography>
  );
});

const RequirementCategoryInfo = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const categoryValues = [
    ...domain.ui.categories.list.map((category) => ({
      value: category.id,
      name: `${category.name}  ${category.isActive ? '' : '(не активно)'}`,
    })),
  ];
  const data = categoryValues.find(category => category.value === domain.ui.model.entity?.categoryId)?.name || '—';

  return (
    <>
      <Grid item xs={3} style={{ marginBottom: 10, marginRight: 10 }}>
        <Typography variant={'body2'}>
          Категория
        </Typography>
      </Grid>
      <Grid item xs={8} style={{ marginBottom: 10 }}>
        <TypographyBold data={data} />
      </Grid>
    </>
  );
});

const RequirementSpecificationsInfo = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const specificationCategories = domain.ui.specificationsCategories.list;
  const specifications = domain.ui.specifications.list || [];
  const currentSpecificationsList = specifications?.filter((spec) => domain.ui.model.entity?.specificationsIds?.includes(spec.id || ''));

  const mappedSpecifications: any[] = specificationCategories.reduce((acc, category) => {
    const currentCategorySpecs = currentSpecificationsList.filter((spec) => {
      return spec.categoryId === category.id;
    });
    if (currentCategorySpecs.length) {
      // @ts-ignore
      acc.push({ category, currentCategorySpecs });
    }
    return acc;
  }, []);

  return (
    <>
      <Grid item xs={3} style={{ marginBottom: 10, marginRight: 10 }}>
        <Typography variant={'body2'}>
          Характеристики
        </Typography>
      </Grid>
      <Grid item xs={8} style={{ marginBottom: 10 }}>
        {
          mappedSpecifications?.length ? mappedSpecifications.map((merged) => (<div key={merged.category.id}>
            <TypographyBold data={merged.category.name} />
            <div style={{ marginBottom: 10 }}>
              {merged.currentCategorySpecs.map((spec) => (
                <Typography key={spec.id} variant={'body2'}>
                  {spec.name}
                </Typography>
              ))}
            </div>
          </div>)) : <Typography>—</Typography>
        }
      </Grid>
    </>
  );
});

const RequirementTagsInfo = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const tagCategories = domain.ui.tagCategory.list;
  const tags = domain.ui.tags.list;
  const currentTags = tags?.filter((tag) => domain.ui.model.entity?.tagsIds?.includes(tag.id || ''));

  const mappedTags: any[] = tagCategories.reduce((acc, category) => {
    const currentCategoryTags = currentTags.filter((tag) => {
      return tag.categoryId === category.id;
    });
    if (currentCategoryTags.length) { // @ts-ignore
      acc.push({ category, currentCategoryTags });
    }
    return acc;
  }, []);

  return (
    <>
      <Grid item xs={3} style={{ marginBottom: 10, marginRight: 10 }}>
        <Typography variant={'body2'}>
          Теги
        </Typography>
      </Grid>
      <Grid item xs={8} style={{ marginBottom: 10 }}>
        {mappedTags.length ? mappedTags.map((merged) => (<div key={merged.category.id}>
          <TypographyBold data={merged.category.name} />
          <div style={{ marginBottom: 10 }}>
            {merged.currentCategoryTags.map((tag) => (
              <Typography key={tag.id} variant={'body2'}>
                {tag.name}
              </Typography>
            ))}
          </div>
        </div>)) : (<Typography variant={'body2'}>&mdash;</Typography>)}
      </Grid>
    </>
  );
});

const RequirementDescriptionInfo = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  return (
    <>
      <Grid item xs={3} style={{ marginBottom: 10, marginRight: 10 }}>
        <Typography variant={'body2'}>
          Описание
        </Typography>
      </Grid>
      <Grid item xs={8} style={{ marginBottom: 10 }}>
        <RichTextArea
          data={domain.ui.model.entity?.description || ''}
          dataWithStyles={domain.ui.model.entity?.descriptionWithStyles || ''}
        />
      </Grid>
    </>
  );
});

const RequirementSystemsInfo = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  return (
    <>
      <Grid item xs={3} style={{ marginBottom: 10, marginRight: 10 }}>
        <Typography variant={'body2'}>
          Системы
        </Typography>
      </Grid>
      <Grid item xs={8} style={{ marginBottom: 10 }}>
        <TypographyBold data={'—'} />
      </Grid>
    </>
  );
});

const TypographyBold = ({ data }) => (
  <Typography fontWeight={'bold'} variant={'body2'} style={{ marginBottom: 3 }}>
    {data}
  </Typography>
);

export const RequirementSimpleInfo = observer(({ title, content }: { title: string, content: string }) => (
  <>
    <Grid item xs={3} style={{ marginBottom: 10, marginRight: 10 }}>
      <Typography variant={'body2'}>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={8} style={{ marginBottom: 10 }}>
      <Typography variant={'body2'}>
        {content || '—'}
      </Typography>
    </Grid>
  </>
));

const RequirementAdditionalColumnsInfo = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const contentForRequirement = domain.getRequirementAdditionalColumns(domain.ui.model.entity?.id, domain);
  const [uploadDomain] = useState<IFileUploadDomain>(new FileUploadDomain(domain.layoutDomain));

  const contentForAll = domain.ui.additionalColumnContent.list
    .filter((item) => item.requirementsRelationType === 'all')
    .map(content => ({ id: content.id, additionalColumnId: content.additionalColumnId, content: content.content, contentWithStyles: content.contentWithStyles, fileUrl: content.uploadedFileUrl }));
  const targetedContent = domain.ui.additionalColumnContent.list
    .filter((item) => item.requirementsRelationType !== 'all')
    .filter(item => contentForRequirement.includes(item.id))
    .map(content => ({ id: content.id, additionalColumnId: content.additionalColumnId, content: content.content, contentWithStyles: content.contentWithStyles, fileUrl: content.uploadedFileUrl }));

  const allAdditionalColumnContentIds = contentForAll.map(content => content.additionalColumnId);
  const targetedAdditionalColumnContentIds = targetedContent.map(content => content.additionalColumnId);
  const categoriesForAll = domain.ui.additionalColumns.list.filter(col => allAdditionalColumnContentIds.includes(col.id || '') &&
    (
      col.isActive &&
      col.type !== AdditionalColumnType.UPLOAD_FILE &&
      col.type !== AdditionalColumnType.EDITABLE_TEXT &&
      col.type !== AdditionalColumnType.DATE
    ));
  const categoriesForRequirement = domain.ui.additionalColumns.list.filter(col => targetedAdditionalColumnContentIds.includes(col.id || '') &&
    (
      col.isActive &&
      col.type !== AdditionalColumnType.UPLOAD_FILE &&
      col.type !== AdditionalColumnType.EDITABLE_TEXT &&
      col.type !== AdditionalColumnType.DATE
    ));

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <Typography variant={'h6'}>
          Дополнительные колонки
        </Typography>
      </Grid>
      {categoriesForAll.map((column) => {
        const contentForAdditionalColumn =
          contentForAll.filter(content => content.additionalColumnId === column.id).map(mapItem => ({ content: mapItem?.content, contentWithStyles: mapItem?.contentWithStyles || '' }))
        return (
          <>
            <Grid item xs={3} style={{ marginBottom: 10, marginRight: 10 }}>
              <TypographyBold data={`${column.name} (для всех требований)`} />
            </Grid>
            <Grid item xs={8} style={{ marginBottom: 10 }}>
              {(column.type === AdditionalColumnType.DOWNLOAD_FILE) ?
                contentForAll.filter(content => content.additionalColumnId === column.id).map(mapItem =>
                (
                  <DownloadFileColumn
                    sx={{ width: 'fit-content' }}
                    uploadDomain={uploadDomain}
                    originalFileName={mapItem.content || 'без названия'}
                    defaultValue={mapItem.fileUrl || ''}
                    isCanCancel={false}
                  />
                ),
                ) : (
                  contentForAdditionalColumn.map(item => {
                    return <RichTextArea data={item.content} dataWithStyles={item.contentWithStyles} />
                  })
                )
              }
            </Grid >
          </>
        )
      }
      )}
      {
        categoriesForRequirement.map((category) => {
          return (
            <>
              <Grid item xs={3} style={{ marginBottom: 10, marginRight: 10 }}>
                <TypographyBold data={category.name} />
              </Grid>
              <Grid item xs={8} style={{ marginBottom: 10 }}>
                {(category.type === AdditionalColumnType.DOWNLOAD_FILE) ?
                  targetedContent.filter(contentItem => contentItem.additionalColumnId === category.id).map(mapItem =>
                  (
                    <DownloadFileColumn
                      sx={{ width: 'fit-content' }}
                      uploadDomain={uploadDomain}
                      originalFileName={mapItem.content || 'без названия'}
                      defaultValue={mapItem.fileUrl || ''}
                      isCanCancel={false}
                    />
                  ),
                  ) :
                  <Typography variant={'body2'}>
                    {targetedContent
                      .filter(filterItem => (filterItem.additionalColumnId === category.id) && (contentForRequirement.includes(filterItem.id)))
                      .map(mapItem => (<>{mapItem.content}<br /></>))}
                  </Typography>
                }
              </Grid>
            </>
          )
        })
      }
      {
        !categoriesForRequirement.length && !categoriesForAll.length &&
        (<Typography variant={'body2'}>&mdash;</Typography>)
      }
    </>
  );
});

const RequirementVendorInfo = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const vendors = domain.getRequirementVendor(domain.ui.model.entity?.id, domain);

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <Typography variant={'h6'}>
          Источник требования
        </Typography>
      </Grid>
      {vendors.length ? vendors.map((vendor) => (
        <>
          <Grid item xs={3} style={{ marginBottom: 10, marginRight: 10 }}>
            <TypographyBold data={vendor.shortName} />
          </Grid>
          <Grid item xs={8} style={{ marginBottom: 10 }}>
            <RichTextArea data={vendor.description} dataWithStyles={vendor.descriptionWithStyles} />
          </Grid>
        </>
      )) : (<Typography variant={'body2'}>&mdash;</Typography>)}
    </>
  );
});

export const RequirementInfo = ({ domain, requirementFormExtensionComponents }: { domain: RequirementEntityDrawerDomain, requirementFormExtensionComponents: any }) => {
  const container = useContext(ContainerContext);
  const classes = useStyles();

  const RequirementSimpleInfoExtended = container.get(RequirementSimpleInfo);

  const performers = domain.ui.performers.list
    .filter((performer) => domain.ui.model.entity?.performersTypesIds
      ?.includes(performer.id || ''));
  const author = domain.ui.users.list.find((user) => user.id === domain.ui.model.entity?.authorId);
  const orderIndex = domain.ui.model.entity?.orderIndex?.toString();
  let isUpadated = false;
  let upadatedText = '';
  if (domain.ui.model.entity.creationType === RequirementCreationType.updated) {
    upadatedText = 'Изменено';
    isUpadated = true;
  } else if (domain.ui.model.entity.creationType === RequirementCreationType.new) {
    isUpadated = true;
    upadatedText = 'Добавлено';
  };

  return (
    <Grid>
      {
        domain.ui.model.entity?.isActive ?
          (<Grid item paddingLeft={'15px'}>
            <div className={classes.container}>
              <span className={classes.activeSpan}>
                <DoneIcon fontSize={'small'} />Опубликовано
              </span>
              {isUpadated && <div className={classes.notVerified}>
                {upadatedText}
              </div>}
            </div>
          </Grid>) :
          (<Grid item paddingLeft={'15px'}>
            <div className={classes.container}>
              <span className={classes.disabledSpan}>
                <ClearIcon fontSize={'small'} />Не опубликовано
              </span>
              {isUpadated && <div className={classes.notVerified}>
                {upadatedText}
              </div>}
            </div>
          </Grid>)
      }
      <Grid item paddingLeft={'15px'}>
        <RequirementShortNameInfo domain={domain} />
      </Grid>
      <Grid padding={'15px'} container>
        <RequirementCategoryInfo domain={domain} />
        <RequirementDescriptionInfo domain={domain} />
        <RequirementSpecificationsInfo domain={domain} />
        <RequirementTagsInfo domain={domain} />
        <RequirementSimpleInfoExtended title={'Исполнитель'} content={performers.length ? performers.map(item => item.name).join(', ') : '—'} />
        <RequirementSimpleInfoExtended title={'Автор'} content={author?.displayName || author?.login || '—'} />
        <RequirementSimpleInfo title={'Порядок'} content={orderIndex || '—'} />
        <RequirementSystemsInfo domain={domain} />
        {requirementFormExtensionComponents.map((FormExtensionComponent) => (
          <FormExtensionComponent domain={domain} isInfoMode={true} isDrawer={true} />
        ))}
      </Grid>
      {
        domain.dataTableDomain.detailDomain.ui.isDetail &&
        <Grid padding={'15px'} container>
          <RequirementVendorInfo domain={domain} />
        </Grid>
      }
      {
        domain.dataTableDomain.detailDomain.ui.isSecondArray &&
        <Grid padding={'15px'} container>
          <RequirementAdditionalColumnsInfo domain={domain} />
        </Grid>
      }
    </Grid >
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  activeSpan: {
    borderRadius: '150px',
    backgroundColor: Colors.positiveActive,
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    color: Colors.mainPassive,
    height: '30px'
  },
  notVerified: {
    borderRadius: '16px',
    color: '#FFD700',
    border: '1px solid #FFD700',
    height: '30px',
    width: '150px',
    textAlign: 'center',
    padding: '2px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '40px',
    justifyContent: 'space-between',
    width: '310px'
  },
  disabledSpan: {
    borderRadius: '15px',
    backgroundColor: Colors.negativeActive,
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    color: Colors.mainPassive
  },
}));