import { FormControlLabel, Grid, Paper, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ConfigManager } from '../../../../../application/config/ConfigManager';
import { useI18n } from '../../../../../application/language/useI18n';
import { ContainerContext } from '../../../../../common/container/ContainerContext';
import translate from '../../../../../locales/ru/translation';
import { UserProjectRoleAction } from '../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IAdminPageProperties } from '../../../../admin/page/IAdminPageProperties';
import { FormActions } from '../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../design/form/FormAutocomplete';
import { FormCheckboxField } from '../../../../design/form/FormCheckboxField';
import { FormMultiAutocomplete } from '../../../../design/form/FormMultiAutocomplete';
import { FormTextArea } from '../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../design/form/FormTextField';
import { FormTitleText } from '../../../../design/form/FormTitleText';
import { ApplicationEdit } from '../../application/info/ApplicationEdit';
import { ProjectFormBottomExtensions } from './parts/ProjectFormBottomExtensions';
import { isShowProjectFormField } from './ProjectFormInjects';
import { ProjectFormDomain } from './store/ProjectFormDomain';
import { ProjectFormModel } from './store/ProjectFormModel';

export interface IProjectFormProperties extends IAdminPageProperties {}

export const ProjectForm = observer(({ layoutDomain }: IProjectFormProperties) => {
  const { translate } = useI18n();
  const ProjectFormDomainRef = useContext(ContainerContext).get(ProjectFormDomain);
  const [domain] = useState(new ProjectFormDomainRef(layoutDomain));

  const ProjectFormBottomExtensionsComponents = useContext(ContainerContext).get(ProjectFormBottomExtensions);
  const { projectId = null } = useParams<any>();
  const uiEntity = domain.ui.model.entity;

  const container = useContext(ContainerContext);
  const ProjectFormFieldCodeNameExtended = container.get(ProjectFormFieldCodeName);
  const ProjectFormFieldJiraLinkExtended = container.get(ProjectFormFieldJiraLink);
  const [isShowProjectForm, setIsShowProjectForm] = useState(true);

  uiEntity.customFieldsValues = uiEntity.customFieldsValues || {};
  useEffect(() => {
    domain.loadData(projectId);
    setIsShowProjectForm(container.get(isShowProjectFormField)());
  }, [domain, projectId, container]);

  let isCanEditFields = true;
  let isCanEditTeam = true;
  if (projectId) {
    const editProjectPermission = layoutDomain.userHaveAnyAccessToEditProject(projectId);
    if (editProjectPermission.isCanEditFields || editProjectPermission.isCanEditTeam) {
      isCanEditFields = editProjectPermission.isCanEditFields;
      isCanEditTeam = editProjectPermission.isCanEditTeam;
    } else {
      return <div>У вас нет доступа к проекту</div>;
    }
  }

  useEffect(() => {
    if (uiEntity.name) {
      layoutDomain.setPageTitle(
        projectId
          ? `${translate('phrases.editProject')}:  ${uiEntity.name}`
          : `${translate('phrases.addProject')}: ${uiEntity.name}`,
      );
    }
  }, [uiEntity.name, layoutDomain, projectId]);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
      <Grid item width={'100%'}>
        <Paper elevation={3}>
          {uiEntity.fieldsMap?.fields?.map((field) => {
            return field.values?.map((fieldValue) => {
              if (fieldValue.type === 'info') {
                return (
                  <ProjectFormCustomFieldInfo
                    domain={domain}
                    uiEntity={uiEntity}
                    fieldValue={fieldValue}
                    disabled={!isCanEditFields}
                  />
                );
              }
              if (fieldValue.type === 'checkbox') {
                return (
                  <ProjectFormFieldCheckbox
                    domain={domain}
                    uiEntity={uiEntity}
                    fieldValue={fieldValue}
                    disabled={!isCanEditFields}
                  />
                );
              }
              if (fieldValue.type === 'text') {
                return (
                  <ProjectFormCustomFieldText
                    domain={domain}
                    uiEntity={uiEntity}
                    fieldValue={fieldValue}
                    disabled={!isCanEditFields}
                  />
                );
              }
              if (fieldValue.type === 'multiText') {
                return (
                  <ProjectFormCustomFieldMultiText
                    domain={domain}
                    uiEntity={uiEntity}
                    fieldValue={fieldValue}
                    disabled={!isCanEditFields}
                  />
                );
              }
              if (fieldValue.type === 'list') {
                return (
                  <ProjectFormCustomFieldList
                    domain={domain}
                    uiEntity={uiEntity}
                    fieldValue={fieldValue}
                    disabled={!isCanEditFields}
                  />
                );
              }
              if (fieldValue.type === 'name') {
                return (
                  <ProjectFormFieldName
                    domain={domain}
                    uiEntity={uiEntity}
                    fieldValue={fieldValue}
                    disabled={!isCanEditFields}
                  />
                );
              }
              if (fieldValue.type === 'codeName' && isShowProjectForm) {
                return (
                  <ProjectFormFieldCodeNameExtended
                    domain={domain}
                    uiEntity={uiEntity}
                    fieldValue={fieldValue}
                    disabled={!isCanEditFields}
                  />
                );
              }
              if (fieldValue.type === 'jiraLink' && isShowProjectForm) {
                return (
                  <ProjectFormFieldJiraLinkExtended
                    domain={domain}
                    uiEntity={uiEntity}
                    fieldValue={fieldValue}
                    disabled={!isCanEditFields}
                  />
                );
              }
              if (fieldValue.type === 'comment') {
                return (
                  <ProjectFormFieldComment
                    domain={domain}
                    uiEntity={uiEntity}
                    fieldValue={fieldValue}
                    disabled={!isCanEditFields}
                  />
                );
              }
              if (fieldValue.type === 'rolesMap') {
                return (
                  <ProjectFormFieldRolesMap
                    domain={domain}
                    uiEntity={uiEntity}
                    fieldValue={fieldValue}
                    disabled={!isCanEditTeam}
                  />
                );
              }
              return <div />;
            });
          })}

          {ProjectFormBottomExtensionsComponents.map((Extension) => (
            <Extension.Component projectFormDomain={domain} projectId={projectId} layoutDomain={layoutDomain} />
          ))}
        </Paper>
        {!domain.ui.model.entity.id && <IsUnitedWithApplication domain={domain} />}

        {!domain.ui.model.entity.isUnitedWithApplication && (
          <FormActions onSave={() => domain.save()} onCancel={() => domain.cancelEdit()} />
        )}
      </Grid>
      {!!domain.ui.model.entity.isUnitedWithApplication && (
        <ApplicationEdit projectFormDomain={domain} layoutDomain={layoutDomain} />
      )}
    </Grid>
  );
});

const IsUnitedWithApplication = observer(({ domain }: { domain: ProjectFormDomain }) => {
  const { translate } = useI18n();
  const isCanCreateProjects = domain.layoutDomain.userHaveAnyAccess([
    UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-project'],
  ]);
  const isCanCreateApplications = domain.layoutDomain.userHaveAnyAccess([
    UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-application'],
  ]);

  const unitedApplicationLabel = isCanCreateApplications
    ? translate('phrases.integratedWithTheSystem')
    : `${translate('phrases.integratedWithTheSystem')}(${translate('phrases.youDoNotHavePermissionsToCreateSystems')})`;

  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={
        <Switch
          color="success"
          disabled={!(isCanCreateProjects && isCanCreateApplications)}
          checked={!!domain.ui.model.entity.isUnitedWithApplication}
          onChange={(event: any) => {
            domain.ui.model.entity.isUnitedWithApplication = event.target.checked;
          }}
        />
      }
      label={unitedApplicationLabel}
    />
  );
});

const ProjectFormCustomFieldText = observer(({ domain, uiEntity, fieldValue, disabled }: any) => {
  return (
    <FormTextField
      disabled={disabled}
      value={uiEntity.customFieldsValues[fieldValue.name]}
      onChange={domain.getUpdateCustomFieldHandler(fieldValue.name)}
      label={fieldValue.title}
      helperText={fieldValue?.description || ''}
    />
  );
});

const ProjectFormCustomFieldInfo = observer(({ domain, uiEntity, fieldValue }: any) => {
  return <FormTitleText text={fieldValue.title} description={fieldValue.description} />;
});

const ProjectFormCustomFieldMultiText = observer(({ domain, uiEntity, fieldValue, disabled }: any) => {
  return (
    <FormTextArea
      disabled={disabled}
      value={uiEntity.customFieldsValues[fieldValue.name]}
      onChange={domain.getUpdateCustomFieldHandler(fieldValue.name)}
      label={fieldValue.title}
      helperText={fieldValue.description || ''}
    />
  );
});

const ProjectFormCustomFieldList = observer(({ domain, uiEntity, fieldValue, disabled }: any) => {
  return (
    <FormAutocomplete
      disabled={disabled}
      values={fieldValue.extraData?.values}
      onChangeSelect={(newValue: any) => {
        domain.setCustomValue(fieldValue.name, newValue.value);
      }}
      key={fieldValue.name}
      selected={uiEntity.customFieldsValues[fieldValue.name]}
      label={fieldValue.description}
    />
  );
});

const ProjectFormFieldName = observer(({ domain, uiEntity, fieldValue, disabled }: any) => {
  const { translate } = useI18n();
  return (
    <FormTextField
      disabled={disabled}
      errorMessage={domain.getValidationErrorFor('name')?.message}
      value={uiEntity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      label={fieldValue.title || translate('phrases.nameTitle')}
      helperText={fieldValue?.description || translate('phrases.fieldShouldContainNameOfProject')}
      required={true}
      inputProps={{
        'data-cy': 'project-short-name',
      }}
    />
  );
});

const ProjectFormFieldCheckbox = observer(({ domain, uiEntity, fieldValue, disabled }: any) => {
  return (
    <FormCheckboxField
      disabled={disabled}
      value={uiEntity.customFieldsValues[fieldValue.name]}
      defaultValue={false}
      onChange={(value) => {
        domain.setCustomValue(fieldValue.name, value);
      }}
      description={fieldValue.description}
      label={fieldValue.title}
      dataCy="project-form-checkbox"
    />
  );
});

export const ProjectFormFieldCodeName = observer(({ domain, uiEntity, fieldValue, disabled }: any) => {
  const { translate } = useI18n();
  return (
    <FormTextField
      disabled={disabled}
      errorMessage={domain.getValidationErrorFor('codeName')?.message}
      value={uiEntity.codeName}
      onChange={domain.getUpdateFieldHandler('codeName')}
      label={fieldValue.title || 'Код'}
      helperText={fieldValue?.description || translate('phrases.fieldShouldContainUniqueIdentifierOfProject')}
      required={false}
      inputProps={{
        'data-cy': 'project-product-id',
      }}
    />
  );
});

export const ProjectFormFieldJiraLink = observer(({ domain, uiEntity, fieldValue, disabled }: any) => {
  const { translate } = useI18n();
  return (
    <FormTextField
      disabled={disabled}
      errorMessage={domain.getValidationErrorFor('jiraLink')?.message}
      value={uiEntity.jiraLink}
      onChange={domain.getUpdateFieldHandler('jiraLink')}
      label={fieldValue.title || translate('phrases.jiraLink')}
      helperText={fieldValue.description || translate('phrases.jiraLinkDescription')}
      inputProps={{
        'data-cy': 'project-jira-link',
      }}
    />
  );
});

const ProjectFormFieldComment = observer(({ domain, uiEntity, fieldValue, disabled }: any) => {
  const { translate } = useI18n();

  return (
    <FormTextArea
      disabled={disabled}
      errorMessage={domain.getValidationErrorFor('comment')?.message}
      value={uiEntity.comment}
      onChange={domain.getUpdateFieldHandler('comment')}
      label={fieldValue.title}
      helperText={fieldValue.description || translate('phrases.anyAdditionalProjectInformation')}
      dataCy="project-description"
    />
  );
});

// const ProjectFormFieldDevelopersIds = observer(({ domain, uiEntity, fieldValue }: any) => {
//   return (
//     <FormMultiAutocomplete
//       values={domain.ui.developersAutocompleteValues()}
//       onChangeSelect={domain.getMultiSelectFieldHandler('developersIds')}
//       key="developersIds"
//       selected={uiEntity.developersIds}
//       label={fieldValue.title || 'Участники проекта'}
//       helperText={
//         fieldValue.description ||
//         'Поле содержит пользователей, обладающих полномочиями по изменению статусов выполнения требований во всех АС проекта'
//       }
//     />
//   );
// });

// const ProjectFormFieldManagersIds = observer(({ domain, uiEntity, fieldValue }: any) => {
//   return (
//     <FormMultiAutocomplete
//       values={domain.ui.managersAutocompleteValues()}
//       onChangeSelect={domain.getMultiSelectFieldHandler('managersIds')}
//       key="managersIds"
//       selected={uiEntity.managersIds}
//       label={fieldValue.title || 'Менеджеры проекта'}
//       helperText={
//         fieldValue.description ||
//         'Поле содержит пользователей, обладающих полномочиями по созданию и изменению новых АС и изменению статусов требований в проекте'
//       }
//     />
//   );
// });

// const ProjectFormFieldSecurityTestersIds = observer(({ domain, uiEntity, fieldValue }: any) => {
//   return (
//     <FormMultiAutocomplete
//       values={domain.ui.securityTestersAutocompleteValues()}
//       onChangeSelect={domain.getMultiSelectFieldHandler('securityTestersIds')}
//       key="securityTestersIds"
//       selected={uiEntity.securityTestersIds}
//       label={fieldValue.title || 'Эксперт по тестированию'}
//       helperText={
//         fieldValue.description ||
//         'Поле содержит пользователей, обладающих полномочиями по изменению статусов тестирования требований во всех АС проекта'
//       }
//     />
//   );
// });

const ProjectFormFieldRolesMap = observer(
  ({
    domain,
    uiEntity,
    fieldValue,
    disabled,
  }: {
    domain: ProjectFormDomain;
    uiEntity: ProjectFormModel;
    fieldValue: any;
    disabled: boolean;
  }) => {
    return (
      <Fragment>
        <FormTitleText text={fieldValue.title} description={fieldValue.description} />
        {domain.ui.projectRoles.list.map((projectRole) => {
          const selected: any[] =
            uiEntity.rolesMap?.data?.filter((role) => role.roleId === projectRole.id).map((role) => role.userId) || [];
          return (
            <FormMultiAutocomplete
              disabled={disabled}
              selectAllOption={true}
              values={domain.ui.projectRoleAutocompleteValues(projectRole.id || '')}
              onChangeSelect={(values) => {
                if (!uiEntity.rolesMap?.data) {
                  uiEntity.rolesMap = {
                    data: [],
                  };
                }

                const otherRolesValues = uiEntity.rolesMap.data.filter((item) => item.roleId !== projectRole.id);
                const valueRoles = values.map((value) => ({ userId: value.value || '', roleId: projectRole.id || '' }));

                uiEntity.rolesMap.data = [...otherRolesValues, ...valueRoles];
              }}
              key={'rolesMap' + projectRole.id}
              selected={selected}
              label={projectRole.name || ' '}
              helperText={projectRole.description || ' '}
              dataCy={projectRole.name ? projectRole.name : `project-role-option`}
            />
          );
        })}
      </Fragment>
    );
  },
);
