import { IPassingStepperDomain } from '../../../../../../../design/passingStepper/store/IPassingStepperDomain';
import { PassingStepperDomain } from '../../../../../../../design/passingStepper/store/PassingStepperDomain';
import { IPassingPracticeDomain } from '../IPassingPracticeDomain';
import { PassingPracticeDomain } from '../PassingPracticeDomain';
export class PassingPracticeStepperDomain extends PassingStepperDomain implements IPassingStepperDomain {
  public rootDomain: IPassingPracticeDomain;
  constructor(rootDomain: IPassingPracticeDomain) {
    super();
    this.rootDomain = rootDomain || new PassingPracticeDomain();
    this.practiceDomain = rootDomain;
    this.isPracticeAdditionalAtions.setValue(true);
    this.practiceAdditionalActions.setList([
      {
        name: 'phrases.check',
      },
    ]);
  }
}
