import { useMemo } from "react";
import { ILocalization } from "../../../../../../../../application/language/ILocalization";
import { useI18n } from "../../../../../../../../application/language/useI18n";
import { BlockType, ITheoryBlock } from "../../../../../../service/unit/interface/IUnitTheory";

export interface IAdditionalElementsList {
  title: string;
  element: ITheoryBlock<any>;
}

const createParagraphElement = ({translate}: ILocalization) => ({
  title: translate('createUnit.p'),
  element: {
    block: BlockType.PARAGRAPH,
    data: {
      text: '',
    },
    sortIndex: 0,
    pseudoId: '',
    parentId: '',
    name: translate('createUnit.p'),
  },
});

const createListElement = ({translate}: ILocalization) => ({
  title: translate('createUnit.list'),
  element: {
    block: BlockType.LIST,
    data: {
      type: 'Numbered',
      elementCount: 0,
      listData: [],
    },
    sortIndex: 0,
    pseudoId: '',
    parentId: '',
    name: translate('createUnit.list'),
  },
});

const createTableElement = ({translate}: ILocalization) => ({
  title: translate('createUnit.table'),
  element: {
    block: BlockType.TABLE,
    data: {
      columnsCount: 0,
      rowsCount: 0,
      tableData: {
        headerText: [],
        rows: [],
      },
    },
    sortIndex: 0,
    pseudoId: '',
    parentId: '',
    name: translate('createUnit.table'),
  },
});

const createCodeExampleElement = ({translate}: ILocalization) => ({
  title: translate('createUnit.codeExample'),
  element: {
    block: BlockType.CODE_EXAMPLE,
    data: {
      languages: [],
      example: {
        language: '',
        content: '',
      },
    },
    sortIndex: 0,
    pseudoId: '',
    parentId: '',
    name: translate('createUnit.codeExample'),
  },
});

const createSpecialBlockElement = ({translate}: ILocalization) => ({
  title: translate('createUnit.specialBlock'),
  element: {
    block: BlockType.SPECIAL_BLOCK,
    data: {
      name: '',
      color: '',
      contentBlocks: [],
    },
    sortIndex: 0,
    pseudoId: '',
    parentId: '',
    name: translate('createUnit.specialBlock'),
  },
});

const createImageElement = ({translate}: ILocalization) => ({
  title: translate('createUnit.img'),
  element: {
    block: BlockType.IMAGE,
    data: {
      name: '',
      originalName: '',
      data: '',
      indexName: '',
      type: 'image',
    },
    sortIndex: 0,
    pseudoId: '',
    parentId: '',
    name: translate('createUnit.img'),
  },
});


const createVideoElement = ({translate}: ILocalization) => ({
  title: translate('createUnit.video'),
  element: {
    block: BlockType.VIDEO,
    data: {
      name: '',
      originalName: '',
      data: '',
      indexName: '',
      type: 'video',
    },
    sortIndex: 0,
    pseudoId: '',
    parentId: '',
    name: translate('createUnit.video'),
  },
});


const createTabsElement = ({translate}: ILocalization) => (  {
  title: translate('createUnit.tabs'),
  element: {
    block: BlockType.TABS,
    data: {
      tabsCount: 0,
      tabsData: {
        title: '',
        contentBlocks: [],
        sortIndex: 0,
        pseudoId: '',
      },
    },
    sortIndex: 0,
    pseudoId: '',
    parentId: '',
    name: translate('createUnit.tabs'),
  },
});

const createTagsElement = ({translate}: ILocalization) => ({
  title: translate('createUnit.tags'),
  element: {
    block: BlockType.TAGS,
    data: {
      tagsCount: 0,
      tagsData: {
        title: '',
        contentBlocks: [],
        sortIndex: 0,
        pseudoId: '',
      },
    },
    sortIndex: 0,
    pseudoId: '',
    parentId: '',
    name: translate('createUnit.tags'),
  },
});

const ALL_CREATIONS = new Map<BlockType, (i18n: ILocalization) => IAdditionalElementsList>([
  [BlockType.PARAGRAPH, createParagraphElement],
  [BlockType.LIST, createListElement],
  [BlockType.TABLE, createTableElement],
  [BlockType.CODE_EXAMPLE, createCodeExampleElement],
  [BlockType.SPECIAL_BLOCK, createSpecialBlockElement],
  [BlockType.IMAGE, createImageElement],
  [BlockType.VIDEO, createVideoElement],
  [BlockType.TABS, createTabsElement],
  [BlockType.TAGS, createTagsElement],
]);

type IArgs = {
  includes: BlockType[]
}

export const useAdditionalElementsList = ({ includes }: IArgs) => {
  const i18n = useI18n();
  const additionalElementsList = useMemo(() => {

    const list: IAdditionalElementsList[] = [];
    for (const block of includes) {
      const creationFun = ALL_CREATIONS.get(block);
      if (!creationFun) continue;

      list.push(creationFun(i18n))
    }

    return list;
  }, [i18n, includes])

  return {
    additionalElementsList
  }
}