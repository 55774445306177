import { CRUDServiceType } from '../common/service/CRUDServiceType';
import { CoreRestCRUDService } from '../coreCommon/common/rest/CoreRestCRUDService';
import { IFileStoreModel } from './IFileStoreModel';
import { FileType, IFileStoreService } from './IFileStoreService';

export class RestFileStoreService extends CoreRestCRUDService<IFileStoreModel> implements IFileStoreService {
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/minio_client`;
  }

  async uploadImage(data: any, fileType: FileType): Promise<any> {
    const formData = new FormData();
    formData.append(fileType, data);

    let response;

    switch (fileType) {
      case FileType.IMAGE:
        response = await fetch(this.getEndpoint() + '/image', {
          method: 'POST',
          body: formData,
        }).then((res) => res.json());
        break;
      case FileType.DOC:
        response = await fetch(this.getEndpoint() + '/doc', {
          method: 'POST',
          body: formData,
        }).then((res) => res.json());
        break;
      case FileType.VIDEO:
        response = await fetch(this.getEndpoint() + '/video', {
          method: 'POST',
          body: formData,
        }).then((res) => res.json());
        break;
    }
    return response.fileUrl;

  }

  async uploadImageWithData(data: any, fileType: FileType): Promise<any> {
    const formData = new FormData();
    formData.append(fileType, data);

    let response;

    switch (fileType) {
      case FileType.IMAGE:
        response = await fetch(this.getEndpoint() + '/image-withdata', {
          method: 'POST',
          body: formData,
        }).then((res) => res.json());

        const sizeInMB = (data.size / (1024 * 1024)).toFixed(2) + ' MB';
        return {
          fileUrl: response.fileUrl,
          name: data.name,
          size: sizeInMB,
        };
      }
  }

  async deleteByFile(fileName: string, fileType: FileType): Promise<any> {
    const response = await fetch(this.getEndpoint() + '/' + fileType + '/' + fileName, {
      method: 'DELETE',
    }).then((res) => res.json());

    return response;
  }
}
