import React, { createContext, useEffect, useState } from 'react';

import { ConfigManager } from '../config/ConfigManager';
import { ILocalization } from './ILocalization';
import { createTranslation } from './Translation';

export const I18nContext = createContext<ILocalization | null>(null);

const I18nProvider: React.FC = ({ children }) => {
  const [i18n, setI18n] = useState<ILocalization | null>(null);

  useEffect(() => {
    const initI18n = async () => {
      const translation = await createTranslation();
      translation.changeLanguage(ConfigManager.serverConfig.localization?.toLowerCase() === 'en' ? 'en' : 'ru');
      setI18n(translation);
    };
    initI18n();
  }, []);

  if (!i18n) {
    return <div>Loading...</div>;
  }

  return <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>;
};

export default I18nProvider;
