import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Box } from '@mui/material';
import React, { Fragment, useState } from 'react';

export interface ConfirmModalProperties {
  title: string;
  text: string;
  onOk?: () => void;
}

export const InformModal = ({ title, text, onOk}: ConfirmModalProperties) => {
  const [isConfirmed, setConfirmed] = useState(false);
  const onConfirm = () => {
    setConfirmed(true);
    if (onOk) {
      onOk();
    }
  }
  return (
    <Fragment>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      {isConfirmed && (
        <Box sx={{ position: 'absolute', bottom: '5px', right: '25px'}}>
          <CircularProgress color='secondary' size={"30px"} />
        </Box>
      )}
      <DialogActions>
        <Button disabled={isConfirmed}  onClick={onConfirm} color="secondary" autoFocus>
          Ок
        </Button>
      </DialogActions>
    </Fragment>
  );
};
