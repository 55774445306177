
import { IStatusValueModel } from '../../../../../../../service/status/value/IStatusValueModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { StatusValueModal } from './StatusValueModal';


export class StatusValueModalUI
  extends DataTableFormUI<IStatusValueModel>
  implements IDataTableFormUI<IStatusValueModel> {

  constructor(

  ) {
    super();
    this.formComponent.setValue(StatusValueModal);
  }
}
