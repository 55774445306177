import { FormControlLabel, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../../../design/form/FormAutocomplete';
import { FormMultiAutocomplete } from '../../../../../../design/form/FormMultiAutocomplete';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { ApplicationAffectModalDomain } from './ApplicationAffectModalDomain';

export interface IApplicationAffectAdminTableProperties {
  domain: ApplicationAffectModalDomain;
}

export const ApplicationAffectModal = observer(({ domain }: IApplicationAffectAdminTableProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
  }, [domain]);
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <NameField domain={domain} />
      <Description domain={domain} />
      <Requirements domain={domain} />
      <OrderIndex domain={domain} />
      <IsActive domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const NameField = observer(({ domain }: { domain: ApplicationAffectModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
    />
  );
});

const Description = observer(({ domain }: { domain: ApplicationAffectModalDomain }) => {
  return (
    <FormTextArea
      required={true}
      label={'Описание'}
      value={domain.ui.model.entity.description}
      onChange={domain.getUpdateFieldHandler('description')}
      errorMessage={domain.getValidationErrorFor('description')?.message}
    />
  );
});

const Requirements = observer(({ domain }: { domain: ApplicationAffectModalDomain }) => {
  const requirements: any = [
    ...domain.ui.requirements.list.map((requirement) => ({
      value: requirement.id,
      name: requirement.shortName,
    })),
  ];
  return (
    <FormMultiAutocomplete
      values={requirements}
      onChangeSelect={(newValue: any[]) => {
        domain.ui.model.entity.requirementsIds = newValue.map((newValue: any) => {
          return newValue?.value;
        });
      }}
      selected={domain.ui.model.entity.requirementsIds}
      label="Требования"
      errorMessage={undefined}
    />
  );
});

const OrderIndex = observer(({ domain }: { domain: ApplicationAffectModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Порядок'}
      type="text"
      value={domain.ui.model.entity.orderIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
      errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
      inputProps={{ maxLength: 9 }}
    />
  );
});

const IsActive = observer(({ domain }: { domain: ApplicationAffectModalDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isActive = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isActive);
  }, [domain.ui.model.entity.isActive]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
      label="Статус"
    />
  );
});
