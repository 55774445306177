import {
  Chip,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { Colors } from '../../../../../../design/color/Colors';
import { RichTextArea } from '../../../../../../design/RichTextEditor/RichTextEditor';

const RequirementEntityDetailComponents = observer(({ row, detailsList, redirect, secondDetailList, rest }) => {
  const theme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        paper: Colors.detailsPanel('0.1'),
      },
    },
  });
  if (secondDetailList.length !== 0) {
    for (let i = 0; i < secondDetailList.length + 1; i++) {
      rest.additionalColumnEntity.list.forEach((value) => {
        if (secondDetailList[i] && value.id === secondDetailList[i].additionalColumnId) {
          secondDetailList[i].additionalColumnId = value.name;
        }
      });
    }
  }
  return (
    <ThemeProvider theme={theme}>
      {detailsList.length !== 0 && (
        <Stack sx={{ py: 2, height: 1, boxSizing: 'border-box' }} direction="column" id={row.id}>
          <Typography variant="h5" sx={{ py: 2, paddingLeft: '28px' }}>
            Источник требования "{row.row.shortName}"
          </Typography>
          <Grid container>
            <Grid item sx={{ width: '100%', padding: '0px 28px' }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" sx={{ width: '100%' }}>
                  <TableHead
                    sx={{
                      background: Colors.tableRowDisabled,
                    }}
                  >
                    <TableRow>
                      <TableCell align="left">Короткое название</TableCell>
                      <TableCell align="left">Описание</TableCell>
                      <TableCell align="right">Статус</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailsList.map((row) => (
                      <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left" component="th" scope="row" onClick={() => { }}>
                          <Chip label={row.shortName} />
                        </TableCell>
                        <TableCell align="left" sx={{ width: '50%' }}>
                          {row.description}
                        </TableCell>
                        <TableCell align="right">{row.isActive ? 'Активен' : 'Не Активен'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Stack>
      )}
      {secondDetailList.length !== 0 && (
        <Stack sx={{ py: 2, height: 1, boxSizing: 'border-box', marginTop: '20px' }} direction="column" id={row.id}>
          <Typography variant="h5" sx={{ py: 2, paddingLeft: '28px' }}>
            Дополнительные колонки требования "{row.row.shortName}"
          </Typography>
          <Grid container>
            <Grid item sx={{ width: '100%', padding: '0px 28px' }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" sx={{ width: '100%' }}>
                  <TableHead
                    sx={{
                      background: Colors.tableRowDisabled,
                    }}
                  >
                    <TableRow>
                      <TableCell align="left">Название</TableCell>
                      <TableCell align="left">Контент</TableCell>
                      <TableCell align="right">Статус</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {secondDetailList.map((row) => (
                      <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left" component="th" scope="row">
                          {row.additionalColumnId}
                        </TableCell>
                        <RichTextArea data={row?.content} dataWithStyles={row?.contentWithStyles}/>
                        <TableCell align="right">{row.isActive ? 'Активен' : 'Не Активен'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Stack>
      )}
    </ThemeProvider>
  );
});

export default RequirementEntityDetailComponents;
