import { ArrowDownward, ArrowUpward, DeleteForever, VisibilityOff } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { TranslateText } from '../../../../../../../../application/language/TranslateText';
export interface ISpacing48Properties {
  onDelete: Function;
  onUp: Function;
  onDown: Function;
}
export const Spacing48 = observer(({ onDelete, onUp, onDown }: ISpacing48Properties) => {
  return (
    <Box
      sx={{
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        height: '91px',
        padding: '16px 42px ',
        display: 'flex',
        flexDirection: 'column',
        '&:hover .action': { visibility: 'visible' },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', alignItems: 'flex-end' }}>
        <Typography sx={{ marginRight: 'auto' }}>
          <TranslateText i18nKey='createUnit.spacingSize' options={{ size: '48px' }} />
        </Typography>
        <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
          <DeleteForever
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onDelete();
            }}
          />
          <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => { }} />
          <ArrowUpward
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onUp();
            }}
          />
          <ArrowDownward
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onDown();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
});
