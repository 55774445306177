import { injectRootService } from "../../../../../../../../../../service/RootServiceFactory";
import { FileType } from "../../../../../../../../../../service/fileStore/IFileStoreService";
import { IMainLayoutDomainStore } from "../../../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore";
import { FileUploadDomain } from "../../../../../../../design/fileUpload/store/FileUploadDomain";
import { IFileUploadUI } from "../../../../../../../design/fileUpload/store/IFileUploadUI";
import { UploadPreviewUI } from "./UploadPreviewUI";


export class UploadPreviewDomain extends FileUploadDomain {
  public ui: IFileUploadUI;
  constructor(public layoutDomain: IMainLayoutDomainStore,    protected rootService = injectRootService(layoutDomain.serviceType.value),
) {
    
    super(layoutDomain);
    this.ui = new UploadPreviewUI();
  }
  async uploadToFileStore(file, fileType: FileType) {
    return await this.rootService.fileStore.uploadImageWithData(file, fileType);
  }
}
