import { Box, Typography } from '@mui/material';
import React from 'react';

import { IUnitModel } from '../../../../../../service/unit/interface/IUnitModel';
import { IPassingListPageDomain } from '../../store/IPassingListPageDomain';

interface Props {
  title: string;
  unitList: IUnitModel[];
  domain: IPassingListPageDomain;
}

export const GroupCardComponentList = ({ title, domain, unitList }: Props) => {
  if (!unitList.length) {
    return null;
  }

  return (
    <Box>
      <Typography variant="overline" sx={{ color: 'rgba(255, 255, 255, 0.7)', letterSpacing: '1.5px' }}>
        {title}
      </Typography>
    </Box>
  );
};
