import { ArrowBack, ArrowForward, Cloud, CloudUpload } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Step, StepButton, Stepper, SxProps, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';

import { TranslateText } from '../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../application/language/useI18n';
import { inject } from '../../../../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { LearningRoutes } from '../../../constants/LearningRoutes';
import { OverflownContent } from '../../admin/page/LearningUnitPreviewPage/shared/OverflownContent';
import { IUnitDomain } from '../../admin/page/unit/store/IUnitDomain';

export interface IStepContent {
  component: any;
  isEnable: boolean;
  name: string;
}
export interface ISubAction {
  key: 'string';
  action: any;
}
export interface IStepper {
  title?: string;
  unitDomain: IUnitDomain;
  stepsTitles: string[];
  stepsCodes: string[];
  stepContent: IStepContent[];
  startStep?: number;
  stepperButtons?: boolean;
  header?: boolean;
  maxWidth?: string;
  contentStyle?: SxProps;
  titleHeading: string;
}

export const CustomStepper = observer(
  ({
    title = '',
    stepsTitles,
    stepsCodes,
    stepContent,
    startStep = 0,
    stepperButtons = true,
    header = true,
    maxWidth = '100%',
    contentStyle = {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    unitDomain,
    titleHeading,
  }: IStepper) => {
    const router = inject<IRouterService>(RouterServiceToken);
    const { translate } = useI18n()
    const [currentStepIndex, setCurrentStepIndex] = useState(startStep || 0);
    const [isPublishButtonDisabled, setIsPublishButtonDisabled] = useState(false);
    const [completed] = useState({});
    const classes = useStyles();

    const totalSteps = () => {
      return stepsTitles.length;
    };

    const completedSteps = () => {
      return Object.keys(completed).length;
    };

    const isLastStep = () => {
      return currentStepIndex === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };

    const handleNext = async () => {
      unitDomain.editUnit();
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? stepsTitles.findIndex((step, i) => !(i in completed))
          : currentStepIndex + 1;
      setCurrentStepIndex(newActiveStep);
    };

    const handleBack = () => {
      unitDomain.editUnit();
      setCurrentStepIndex((prevActiveStep) => prevActiveStep - 1);
    };

    const areAllStepsValid = useMemo(
      () => !Object.values(unitDomain.ui.validSteps?.entity).filter((item) => !item).length,
      [unitDomain.ui.validSteps?.entity],
    );

    const currentStepCode = stepsCodes[currentStepIndex];
    const isCurrentStepValid = unitDomain.ui.validSteps?.entity[currentStepCode];
    const canActivateStep = (index) => isCurrentStepValid || index < currentStepIndex;

    useEffect(() => {
      if (
        (!unitDomain.ui.unit.entity.theory.isEnabled &&
          !unitDomain.ui.unit.entity.quiz.isEnabled &&
          !unitDomain.ui.unit.entity.practice.isEnabled) ||
        !areAllStepsValid
      ) {
        setIsPublishButtonDisabled(true);
      } else {
        setIsPublishButtonDisabled(false);
      }
    }, [
      unitDomain.ui.unit.entity.theory.isEnabled,
      unitDomain.ui.unit.entity.quiz.isEnabled,
      unitDomain.ui.unit.entity.practice.isEnabled,
      unitDomain.ui.validSteps.entity,
      areAllStepsValid,
    ]);
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '16px 0px',
          height: '100%',
          justifyContent: 'space-between',
          gap: '16px',
        }}
      >
        {header && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <OverflownContent contentClasses={classes.unitTitle} placement="bottom">
                {titleHeading !== '' ? titleHeading : translate('createUnit.newUnit')}
              </OverflownContent>
              <Box
                sx={{
                  width: '100%',
                  padding: '0 0px 16px',
                  display: 'flex',
                  flexDirection: 'flex-end',
                  maxWidth: maxWidth,
                }}
              >
                <Box sx={{ marginRight: 'auto', minWidth: '575px' }}>
                  <Typography variant="h4" sx={{ marginBottom: '16px' }}>
                    {title}
                  </Typography>
                  <Stepper nonLinear activeStep={currentStepIndex}>
                    {stepsTitles.map((step, index) => (
                      <Step key={step}>
                        <StepButton
                          sx={{ paddingTop: 0, paddingBottom: 0 }}
                          color="inherit"
                          disabled={!canActivateStep(index)}
                          onClick={async (event) => {
                            event.stopPropagation();
                            if (stepContent[index].isEnable) {
                              unitDomain.editUnit();
                              setCurrentStepIndex(index);
                            }
                          }}
                        >
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography>{step}</Typography>
                            {index !== 0 && stepsCodes[index] !== 'finalResult' && (
                              <Typography
                                onClick={(event) => {
                                  event.stopPropagation();
                                  unitDomain.toggleStepContent(stepContent[index]);
                                  if (index === currentStepIndex) {
                                    setCurrentStepIndex(0);
                                  }
                                }}
                                sx={{
                                  alignItems: 'flex-start',
                                  cursor: 'pointer',
                                  borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
                                  fontSize: '12px',
                                }}
                              >
                                <TranslateText i18nKey={`phrases.${stepContent[index].isEnable ? 'exclude' : 'include'}`} />
                              </Typography>
                            )}
                          </Box>
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Box>

                {stepperButtons && (
                  <Box sx={{ alignSelf: 'flex-end', display: 'flex', gap: '16px' }}>
                    <Button
                      variant="contained"
                      disabled={isPublishButtonDisabled}
                      startIcon={<Cloud />}
                      onClick={async () => {
                        await unitDomain.unpublishUnit();
                        router.goBack();
                      }}
                    >
                      <TranslateText i18nKey='phrases.saveDraft' />
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<VisibilityIcon />}
                      onClick={async () => {
                        await unitDomain.editUnit(unitDomain.ui.unit.entity.isPublished);
                        window.open(
                          generatePath(LearningRoutes.LearningUnitPreview, { unitId: unitDomain.ui.unit.entity.id }),
                          '_blank',
                        );
                      }}
                    >
                      <TranslateText i18nKey='phrases.preview' />
                    </Button>
                    <Button
                      sx={{ background: 'rgba(144, 202, 249, 1)' }}
                      disabled={isPublishButtonDisabled}
                      onClick={async () => {
                        await unitDomain.publishUnit();
                        router.goTo('/learning/admin');
                      }}
                      variant="contained"
                      startIcon={<CloudUpload />}
                    >
                      <TranslateText i18nKey='phrases.publish' />
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            <Divider sx={{ width: '100%' }} />
          </>
        )}
        {stepContent.length && (
          <Box sx={{ ...contentStyle }}>
            {stepContent[currentStepIndex].isEnable && (
              <Box
                sx={{
                  height: '100%',
                  flex: 1,
                  maxHeight: 'calc(100vh - 300px)',
                  overflowY: currentStepIndex === stepContent.length - 1 ? 'scroll' : 'hidden',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
                }}
              >
                {stepContent[currentStepIndex].component}
              </Box>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: '16px 0px' }}>
              {currentStepIndex !== 0 && (
                <Button
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  variant="contained"
                  color="secondary"
                  startIcon={<ArrowBack />}
                >
                  {stepsTitles[currentStepIndex - 1]}
                </Button>
              )}
              <Box sx={{ flex: '1 1 auto' }} />
              {currentStepIndex !== stepsTitles.length - 1 && (
                <Button
                  disabled={!isCurrentStepValid}
                  onClick={() => {
                    handleNext();
                  }}
                  sx={{ mr: 1 }}
                  variant="contained"
                  endIcon={<ArrowForward />}
                >
                  {stepsTitles[currentStepIndex + 1]}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  },
);

const useStyles = makeStyles(() => ({
  unitTitle: {
    fontSize: '2rem',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    lineClamp: 1,
  },
}));
