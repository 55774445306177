import { extensionContainer } from '../../../../../../../common/container/ContainerContext';
import { injectEntity } from '../../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../../common/store/interface/IEntityStore';
import { ISpecificationCategoryModel } from '../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ILocalization, ILocalizationToken } from '../../../../../application/language/ILocalization';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { generateUUID } from '../../../../../utils/generateUUID';
import { IGroupedUnit } from './IGroupedUnit';
import { IUnitListAdminPageUI } from './IUnitListAdminPageUI';

const createDefaultGroupedUnits = () => {
  const { translate }: ILocalization = extensionContainer.getByToken(ILocalizationToken)

  return injectEntityList([
    { groupName: translate('learningAdminPage.withoutContext'), unitsList: [] },
  ])
}

const createDefaultUnit = (): IEntityStore<IUnitModel> => {
  const { translate }: ILocalization = extensionContainer.getByToken(ILocalizationToken)

  return injectEntity({
    id: '',
    createDate: undefined,
    isDeleted: false,
    isActive: true,
    isIncluded: false,
    isPublished: false,
    wasPublished: false,
    quiz: {
      isEnabled: true,
      passageTime: '0',
      results: {
        success: {
          title: '',
          subtitle: '',
        },
        neutral: {
          title: '',
          subtitle: '',
        },
        failure: {
          title: '',
          subtitle: '',
        },
      },
      questions: [
        {
          name: '',
          explanation: '',
          type: 'single',
          randomize: true,
          answers: [],
          sortIndex: 1,
          pseudoId: generateUUID(),
          isCodeExample: false,
          codeExample: [],
        },
      ],
    },
    practice: {
      isEnabled: true,
      passageTime: '0',
      results: {
        success: {
          title: '',
          subtitle: '',
        },
        neutral: {
          title: '',
          subtitle: '',
        },
        failure: {
          title: '',
          subtitle: '',
        },
      },
      tasks: [
        {
          name: translate('createUnit.taskOne'),
          explanation: '',
          type: 'review',
          languages: [],
          sortIndex: 1,
          pseudoId: generateUUID(),
        },
      ],
    },
    theory: {
      chapters: [
        {
          title: translate('createUnit.chapterNumber', { number: 1 }),
          theoryBlocks: [],
          sortIndex: 0,
          pseudoId: generateUUID(),
        },
      ],
      isEnabled: true,
    },
    finalResult: {
      isEnabled: true,
      results: {
        success: {
          title: translate('passing.resultSuccessTitle'),
          subtitle: translate('passing.resultSuccessSubtitle'),
        },
        neutral: {
          title: translate('passing.resultOkTitle'),
          subtitle: translate('passing.resultOkSubtitle'),
        },
        failure: {
          title: translate('passing.resultFailureTitle'),
          subtitle: translate('passing.resultFailureSubtitle'),
        },
      },
    },
    settings: {
      contextId: '',
      characteristicsId: '',
      characteristicsIds: [],
      teamRolesIds: [],
      titleHeading: translate('createUnit.newUnit'),
      titleLead: '',
      preview: { data: '', name: '', originalName: '', type: 'image' },
    },
    lastUpdateDate: undefined,
    score: 0,
  })
}

export class UnitListAdminPageUI implements IUnitListAdminPageUI {
  constructor(

    public unitList = injectEntityList<IUnitModel>([]),

    public characteristic: IEntityListStore<ISpecificationEntityModel> = injectEntityList([]),
    public specificationCategory: IEntityListStore<ISpecificationCategoryModel> = injectEntityList([]),

    public isLoading = injectPrimitive<boolean>(false),

    public groupedUnits: IEntityListStore<IGroupedUnit> = createDefaultGroupedUnits(),

    public filtredGroupedUnits: IEntityListStore<IGroupedUnit> = injectEntityList([]),
    public filterByIsPublished = injectPrimitive('all'),
    // TODO: refactore this part of code (isIncluded field)
    public unit: IEntityStore<IUnitModel> = createDefaultUnit(),
  ) { }
}
