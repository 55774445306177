import { ILocalization, ILocalizationToken } from '../../../../../application/language/ILocalization';
import { inject } from '../../../../../common/container/inject';
import { IError } from '../../../../../common/error/IError';
import { IValidationErrorData } from '../../../../../common/error/IValidationErrorData';
import { Store } from '../../../../../common/store/base/Store';
import { WrongCredentialError } from '../../../../../service/public/activeUser/error/WrongCredentialError';
import { IRootPublicService, RootPublicServiceToken } from '../../../../../service/public/RootPublicService';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { ISignInPageDomain } from './ISignInPageDomain';
import { SignInPageUIStore } from './SignInPageUiStore';

export class SignInPageDomain extends Store implements ISignInPageDomain {
  constructor(
    public ui = new SignInPageUIStore(),
    public i18n: ILocalization = inject<ILocalization>(ILocalizationToken),
    private publicRootService: IRootPublicService = inject<IRootPublicService>(RootPublicServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
  ) {
    super();
  }

  async boot() {
    this.checkCurrentUser();
    await this.loadAdServers();
  }

  async checkCurrentUser() {
    const activeUser = await this.publicRootService.activeUser.getActiveUser();
    if (activeUser.id) {
      this.router.goTo('/');
    }
  }

  async loadAdServers(): Promise<void> {
    const adServers = await this.publicRootService.activeDirectory.server.search({
      limit: 1000,
      filter: { isActive: true },
    });

    const mainAd = adServers.data.find((adServer) => adServer.isMainLoginMethod === true);

    if (mainAd) {
      this.ui.loginMethodValue.setValue(mainAd.id);
    }

    this.ui.loginMethods.setList([
      { name: this.i18n.translate('login.basic'), value: null },
      ...adServers.data.map((server) => ({ name: server.name, value: server.id })),
    ]);
  }

  login = async (): Promise<boolean> => {
    this.clearValidationErrors();
    this.ui.isLockForm.setValue(true);
    let isLogged = false;
    const login = this.ui.loginField.value;
    const password = this.ui.passwordField.value;
    const loginMethodValue = this.ui.loginMethodValue.value;
    isLogged = await this.publicRootService.activeUser
      .loginUserByPassword(login, password, loginMethodValue)
      .catch(this.handleLoginUserByPasswordError);

    this.ui.isLockForm.setValue(false);

    if (isLogged) {
      this.redirectToUserPage();
    }

    return isLogged;
  };

  logout = async (): Promise<boolean> => {
    await this.publicRootService.activeUser.logoutActiveUser();
    this.ui.loginField.setValue('');
    this.ui.passwordField.setValue('');
    this.router.goTo('/login');
    return true;
  };

  changeLoginMethod(value: any): void {
    this.ui.loginMethodValue.setValue(value);
  }

  private handleLoginUserByPasswordError = (error: IError): boolean => {
    if (error instanceof WrongCredentialError) {
      this.setWrongCredentialError(error.data);
    }
    return false;
  };

  private clearValidationErrors() {
    this.ui.isError.setValue(false);
    this.ui.loginErrorMessage.setValue('');
    this.ui.passwordErrorMessage.setValue('');
  }

  private setWrongCredentialError = (validationErrorData: IValidationErrorData[]) => {
    this.ui.isError.setValue(true);
    const loginError = validationErrorData.find((validationError) => validationError.target === 'login');
    const passwordError = validationErrorData.find((validationError) => validationError.target === 'password');
    !loginError || this.ui.loginErrorMessage.setValue(loginError.message);
    !passwordError || this.ui.passwordErrorMessage.setValue(passwordError.message);
    this.ui.passwordField.setValue('');
    this.ui.isLockForm.setValue(false);
  };

  private redirectToUserPage() {
    this.router.goTo('/');
  }
}
