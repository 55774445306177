import { ConfigManager } from '../../../../../../../application/config/ConfigManager';
import { IConfig } from '../../../../../../../application/config/IConfig';
import { inject } from '../../../../../../../common/container/inject';
import { IError } from '../../../../../../../common/error/IError';
import { Store } from '../../../../../../../common/store/base/Store';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { IMainLayoutDomainStore } from '../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../service/LearningRootService';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { IStepContent } from '../../../../design/stepper/CustomStepper';
import { IUnitDomain } from './IUnitDomain';
import { UnitUi } from './UnitUi';

export class UnitDomain extends Store implements IUnitDomain {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public ui = new UnitUi(),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private rootService = injectRootService(layoutDomain.serviceType.value),
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
    public config: IConfig = ConfigManager.getConfig(),
  ) {
    super();
  }
  publishUnit = async () => {
    const unitData: IUnitModel = JSON.parse(JSON.stringify(this.ui.unit.entity));

    unitData.theory.chapters = unitData.theory.chapters.map((chapter) => {
      return {
        ...chapter,
        theoryBlocks: chapter.theoryBlocks.map((theoryBlock) => {
          const { Element, ...rest } = theoryBlock;
          return rest;
        }),
      };
    });

    await this.learningRootService.unit
      .publishByModel({ ...unitData, isPublished: true })
      .catch(this.dataLoadErrorCatch);
  };

  unpublishUnit = async () => {
    const unitData: IUnitModel = JSON.parse(JSON.stringify(this.ui.unit.entity));

    unitData.theory.chapters = unitData.theory.chapters.map((chapter) => {
      return {
        ...chapter,
        theoryBlocks: chapter.theoryBlocks.map((theoryBlock) => {
          const { Element, ...rest } = theoryBlock;
          return rest;
        }),
      };
    });

    await this.learningRootService.unit
      .unpublishByModel({ ...unitData, isPublished: false })
      .catch(this.dataLoadErrorCatch);
  };

  createUnit = async () => {
    return await this.learningRootService.unit.createByModel(this.ui.unit.entity).catch(this.dataLoadErrorCatch);
  };

  editUnit = async (isPublished?: boolean) => {
    const unitData: IUnitModel = JSON.parse(JSON.stringify(this.ui.unit.entity));

    unitData.theory.chapters = unitData.theory.chapters.map((chapter) => {
      return {
        ...chapter,
        theoryBlocks: chapter.theoryBlocks.map((theoryBlock) => {
          const { Element, ...rest } = theoryBlock;
          return rest;
        }),
      };
    });

    await this.learningRootService.unit
      .updateByModel({ ...unitData, isPublished: isPublished || false })
      .catch(this.dataLoadErrorCatch);
  };

  boot = async (titles: string[], codes: string[], content: IStepContent[]) => {
    this.ui.stepsTitles.setList(titles);
    this.ui.stepsCodes.setList(codes);
    this.ui.stepContent.setList(content);
  };

  loadData = async (unitId: string) => {
    await this.learningRootService.unit.getByIdWithAnswers(unitId).then((unit) => this.ui.unit.setEntity(unit));
    this.ui.startLoading();
    const { data: contextData } = await this.learningRootService.context
      .search({ limit: 10000, fields: { include: ['context', 'id'] } })
      .catch(this.dataLoadErrorCatch);
    const { data: characteristicData } = await this.rootService.specification.entity
      .search({ limit: 10000, fields: ['name'] })
      .catch(this.dataLoadErrorCatch);
    const { data: teamRolesData } = await this.rootService.projectRole.entity
      .search({ limit: 10000, fields: ['name'] })
      .catch(this.dataLoadErrorCatch);

    this.ui.teamRoles.setList(teamRolesData);
    this.ui.contexts.setList(contextData);
    this.ui.characteristic.setList(characteristicData);

    this.ui.endLoading();
  };

  toggleStepContent = async (toggleContent: IStepContent) => {
    const currentUnit = this.ui.unit.entity;
    if (currentUnit.hasOwnProperty(toggleContent.name)) {
      currentUnit[toggleContent.name].isEnabled = !toggleContent.isEnable;
      await this.learningRootService.unit
        .updateByModel({ ...this.ui.unit.entity, isPublished: false })
        .catch(this.dataLoadErrorCatch);
      this.ui.unit.setEntity(currentUnit);
    }
  };

  private dataLoadErrorCatch = (error: IError) => {
    this.layoutDomain.errors.handleError(error);
    return {
      data: [],
    };
  };
}
