import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { ISpecificationCategoryModel } from '../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ApplicationDetailDomain } from '../../../../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { ApplicationDetailUI } from '../../../../../../view/user/page/application/info/store/ApplicationDetailUI';
import { ApplicationDetailsMode } from '../../../../../../view/user/page/application/info/store/ApplicationDetailsMode';
import { IUnitModel } from '../../../../service/unit/interface/IUnitModel';
import { IUnitResultModel } from '../../../../service/unitResult/IUnitResultModel';
import { IGroupedUnit } from '../../../admin/page/unitListPage/store/IGroupedUnit';
import { LearningApplicationInfoPage } from '../LearningApplicationInfoPage';
import { ILearningApplicationDetailUI } from './ILearningApplicationDetailUI';
import { IRouterService, RouterServiceToken } from '../../../../service/route/IRouterService';
import { inject } from '../../../../../../common/container/inject';

export const extendApplicationDetailUIConstructor = (
  Ref: new (...args: any[]) => ApplicationDetailUI,
): new (...args: any[]) => ILearningApplicationDetailUI => {
  class ExtendedDomainRef extends Ref {
    constructor(
      
      public unitList = injectEntityList<IUnitModel>([]),
      public passingList = injectEntityList<IUnitModel>([]),
      public isLoading = injectPrimitive<boolean>(false),
      public characteristicList = injectEntityList<ISpecificationEntityModel>([]),
      public specificationCategory: IEntityListStore<ISpecificationCategoryModel> = injectEntityList([]),

      public teamUserIds = injectEntityList<string>([]),
      public roleFilterIds = injectEntityList<string>([]),
      public rolesWithUnits = injectEntityList<{ roleId: string; name: string }>([]),
      public isShowWarning = injectPrimitive<boolean>(false),
      public applicationSpecificationsSet = injectPrimitive<Set<string>>(new Set()),

      public groupedUnits: IEntityListStore<IGroupedUnit> = injectEntityList([]),
      public passingResultList = injectEntityList<IUnitResultModel>([]),
      public isAdmin = injectPrimitive<boolean>(false),
    ) {
      super();
      const router = inject<IRouterService>(RouterServiceToken);
      const activeURL = router.getActiveUrl();
      
      if (!activeURL.includes('/settings/application/template')) {
        this.tabsData.value.tabs.push({
          Component: LearningApplicationInfoPage,
          getTitle: (domain: ApplicationDetailDomain, translate) =>
            domain.ui.mode.value === ApplicationDetailsMode.view ? translate('projectPage.training') : translate('projectPage.trainingPreview'),
          key: 'learning',
        });
      }
    }
  }

  return ExtendedDomainRef;
};
