import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import React from 'react';

interface IApplicationInfoWarning {
  warningText: string;
  description: string;
  redirectTo?: () => void;
  btnText?: string;
}

export const ApplicationInfoWarning = observer(
  ({ redirectTo, warningText, btnText, description }: IApplicationInfoWarning) => {
    return (
      <Box
        sx={{
          padding: '6px 16px 6px 16px',
          width: '100%',
          border: '1px solid rgba(255, 167, 38, 1)',
          borderRadius: '4px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'rgba(255, 167, 38, 0.8)',
          boxShadow:
            '0 0 .1rem #fff, 0 0 .1rem #fff, 0 0 .5rem rgba(255, 167, 38, 0.8), 0 0 0.2rem rgba(255, 167, 38, 0.8), 0 0 0.3rem rgba(255, 167, 38, 0.8),inset 0 0 .3rem rgba(255, 167, 38, 0.8)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <WarningAmberOutlinedIcon />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '15px',
            }}
          >
            <Box
              sx={{
                fontWeight: '700',
                fontSize: '16px',
              }}
            >
              {warningText}
            </Box>
            <Box>{description}</Box>
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '22px',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            cursor: 'pointer',
            '&:hover': {
              color: 'rgba(255, 255, 255, 1)',
            },
          }}
          onClick={redirectTo}
        >
          {btnText}
        </Box>
      </Box>
    );
  },
);
