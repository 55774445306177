import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { useI18n } from '../../../../../../../application/language/useI18n';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { getLocaleByCount } from '../../../../../utils/localeByCount';
import { PracticeIcons } from '../../passingListPage/icons/PracticeIcon';
import { TimeIcon } from '../../passingListPage/icons/TimeIcon';
import { UnitIcon } from '../../passingListPage/icons/UnitIcon';
import { useFormatTime } from '../../passingListPage/parts/utils/useFormatTime';

type Props = {
  unit: IUnitModel;
};

export const UnitCardStats = observer(({ unit }: Props) => {
  const i18n = useI18n();
  const time = useFormatTime(
    Number(unit?.quiz.isEnabled && unit?.quiz.passageTime) +
      Number(unit?.practice.isEnabled && unit?.practice.passageTime) +
      (unit?.theory.isEnabled ? 30 : 0),
  );

  const getQuestionsTranslate = (value: number) =>
    getLocaleByCount(
      value,
      i18n.i18n.language === 'en' ? ['question', 'questions', 'questions'] : ['вопрос', 'вопроса', 'вопросов'],
    );

  const getTasksTranslate = (value: number) =>
    getLocaleByCount(
      value,
      i18n.i18n.language === 'en' ? ['task', 'tasks', 'tasks'] : ['практика', 'практики', 'практик'],
    );

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {unit?.quiz.isEnabled && (
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <UnitIcon />
            <Box>{getQuestionsTranslate(unit?.quiz.questions.length)}</Box>
          </Box>
        )}
        {unit?.practice.isEnabled && (
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <PracticeIcons />
            <Box>{getTasksTranslate(unit?.practice.tasks.length)}</Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          <TimeIcon />
          <Box>{time}</Box>
        </Box>
      </Box>
    </Box>
  );
});
