import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { IStatusEntityModel } from '../../../../../../../service/status/entity/IStatusEntityModel';
import { IStatusEntityService } from '../../../../../../../service/status/entity/IStatusEntityService';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { StatusEntityAdminDomain } from '../StatusEntityAdminDomain'
import { StatusEntityModalUI } from './StatusEntityModalUI';

export class StatusEntityModalDomain
  extends DataTableFormDomain<IStatusEntityModel, IStatusEntityService>
  implements IDataTableFormDomain<IStatusEntityModel> {
  public ui: StatusEntityModalUI;
  constructor(
    entityService: IStatusEntityService,
    layoutDomain: IMainLayoutDomainStore,
    public dataTableDomain: StatusEntityAdminDomain,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new StatusEntityModalUI());
    this.ui = new StatusEntityModalUI();
  }

  async loadData(id: string | null) {
    await super.loadData(id);
  }
}
