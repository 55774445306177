import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { IStatusValueModel } from '../../../../../../../service/status/value/IStatusValueModel';
import { IStatusValueService } from '../../../../../../../service/status/value/IStatusValueService';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { StatusValueAdminDomain } from '../StatusValueAdminDomain'
import { StatusValueModalUI } from './StatusValueModalUI';

export class StatusValueModalDomain
  extends DataTableFormDomain<IStatusValueModel, IStatusValueService>
  implements IDataTableFormDomain<IStatusValueModel> {
  public ui: StatusValueModalUI;
  constructor(
    entityService: IStatusValueService,
    layoutDomain: IMainLayoutDomainStore,
    public dataTableDomain: StatusValueAdminDomain,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new StatusValueModalUI());
    this.ui = new StatusValueModalUI();
  }

  async loadData(id: string | null) {
    await super.loadData(id);
  }
}
