import { injectEntityList } from '../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../common/store/base/injectPrimitive';
import { ServiceType, injectRootService } from '../../../../service/RootServiceFactory';
import { CommonFilterDomain } from '../../../../view/common/requirement/filter/store/CommonFilterDomain';
import { RequirementViewModel } from '../../../../view/user/page/application/info/store/RequirementViewModel';
import { RiskManagerRootService } from '../../../riskManager/service/RiskManagerRootService';

export class RiskRequirementFilterDomain {
  constructor(
    public rootDomain: CommonFilterDomain,
    private rootService = injectRootService(ServiceType.admin),
    private rootPrivateServices = new RiskManagerRootService('admin'),
    public risks = injectEntityList<{
      id: string;
      name: string;
      level: string;
    }>([
      {
        id: '',
        name: '',
        level: '',
      },
    ]),

    public isShow = injectPrimitive(false),
    public links = injectEntityList<any>([]),
    public searchRiskIds = injectPrimitive<string[]>([]),
  ) {
    this.rootDomain = rootDomain;
  }

  async loadData(applicationId: string) {
    console.log(1)
    const links = await this.rootPrivateServices.requirement.link.search({ limit: 10000 });
    console.log(2, links)
    this.links.setList(links.data);
    const application = await this.rootService.application.entity.getById(applicationId);
    console.log(3, application)
    const riskData = await this.rootPrivateServices.manager.data.getByProjectId(application.projectId || '');
    console.log(riskData, 'riskData', 1)
    if (riskData && riskData.templateId && riskData.requirementThreatLevelsData.data.length) {
      const riskTemplate = await this.rootPrivateServices.manager.template.getById(riskData.templateId);
      console.log(riskTemplate, 'riskTempalte', 2)
      const riskLevelsData = riskData.requirementThreatLevelsData.data.map(async (item) => {
        console.log(item, 'item', 3)
        const riskName = await this.rootPrivateServices.risk.getById(item.riskId);
        console.log(item, 'riskName', 4)
        const riskRequirementThreats = riskTemplate.requirementThreatsLevelSettings.levels.find(
          (requirementThreatsLevelSettingsItem) =>
            requirementThreatsLevelSettingsItem.pseudoId === item.requirementThreatLevelSettingsPseudoId,
        )?.name;

        return {
          id: item.riskId,
          name: riskName.name + ' - ' + riskRequirementThreats,
          level: riskRequirementThreats,
        };
      });

      const result = await Promise.all(riskLevelsData);

      const hasUndefinedLevel = result.some((item) => item.level === undefined);

      this.risks.setList(result as any);
      console.log(hasUndefinedLevel, 'hasUndefinedLevel', 5)
      if (!hasUndefinedLevel) {
        this.isShow.setValue(true);
        this.rootDomain.addExtensionFunctionality(
          'risk-filter',
          ((requirements) => this.filterByRisks(requirements)).bind(this),
          'filters',
        );
        this.rootDomain.addExtensionFunctionality(
          'risk-clearSearch',
          (() => this.clearSearch()).bind(this),
          'clearSearch',
        );
      }
    } else {
      console.log('end else')
      this.isShow.setValue(false);
    }
  }

  filterByRisks = (data: RequirementViewModel[]): RequirementViewModel[] => {
    const searchRisksIds = this.searchRiskIds;
    if (searchRisksIds.value.length > 0) {
      const riskManagerReqs = this.links.list;

      const filteredRiskManagerReqs = riskManagerReqs.filter((req) => {
        return req.risksMap.risksIds.some((riskId) => searchRisksIds.value.includes(riskId as any));
      });

      data = data.filter((req) => {
        return filteredRiskManagerReqs.some((riskManagerReq) => riskManagerReq.requirementId === req.id);
      });
    }

    this.rootDomain.ui.isCleanSearchExtension.setValue(searchRisksIds.value.length > 0);

    return data;
  };

  clearSearch() {
    this.searchRiskIds.setValue([]);
    this.rootDomain.triggerSearch();
  }
}
