import React from 'react';

export const UnitIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.77778 6.22217H2.66667V13.3333H9.77778V6.22217ZM6.35395 11.1468L8.57617 8.48008L7.42384 7.51981L5.72738 9.55555L4.97478 8.80295L3.91412 9.86361L5.24745 11.1969L5.82818 11.7777L6.35395 11.1468Z"
      fill="#DCE775"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.72223 4.1665H11.8333V8.27762H9.77778V9.77762H11.8333H13.3333V8.27762V4.1665V2.6665H11.8333H7.72223H6.22223V4.1665V4.44428H7.72223V4.1665Z"
      fill="#FF7F7F"
    />
  </svg>
);
