import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AnalyticsPageContent } from '../../../../view/admin/page/analytics/parts/AnalyticsPageContent';
import { AnalyticsPageHeader } from '../../../../view/admin/page/analytics/parts/AnalyticsPageHeader';
import { AnalyticsDomain } from '../../../../view/admin/page/analytics/store/AnalyticsDomain';
import { Colors } from '../design/colors/Colors';
import { StudyDashboardWrapper } from './components/StudyResultsDashboard';
import { TranslateText } from '../../../../application/language/TranslateText';

export const LearningAnalyticsPage = observer(({ layoutDomain }) => {
  const [analyticsDomain] = useState(new AnalyticsDomain(layoutDomain));
  const location = useLocation();
  const history = useHistory();

  const routes = {
    system: '/analytics/system',
    learning: '/analytics/learning',
  };

  const [tabValue, setTabValue] = useState(routes.system);
  const classes = useStyles();

  useEffect(() => {
    analyticsDomain.loadReqData();
    setTabValue(location.pathname);
  }, [location, analyticsDomain]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    history.push(newValue);
  };

  return (
    <Grid container display={'flex'} flexDirection={'column'} width={'100%'}>
      <AnalyticsPageHeader domain={analyticsDomain} />
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="analytics tabs" classes={{ indicator: classes.indicator }}>
            <Tab label={<TranslateText i18nKey={'phrases.systems'} />} value={routes.system} />
            <Tab label={<TranslateText i18nKey={'phrases.learning'} />} value={routes.learning} />
          </TabList>
        </Box>
        <TabPanel value={routes.system}>
          <AnalyticsPageContent domain={analyticsDomain} />
        </TabPanel>
        <TabPanel value={routes.learning}>
          <StudyDashboardWrapper layoutDomain={layoutDomain} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    backgroundColor: Colors.selectedTabs('1'),
  },
}));
