import React from 'react';

export const UsersIcon = (props) => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.0002 4.99992C10.9202 4.99992 11.6602 4.25325 11.6602 3.33325C11.6602 2.41325 10.9202 1.66659 10.0002 1.66659C9.08016 1.66659 8.3335 2.41325 8.3335 3.33325C8.3335 4.25325 9.08016 4.99992 10.0002 4.99992ZM5.00016 4.33325C6.10683 4.33325 6.9935 3.43992 6.9935 2.33325C6.9935 1.22659 6.10683 0.333252 5.00016 0.333252C3.8935 0.333252 3.00016 1.22659 3.00016 2.33325C3.00016 3.43992 3.8935 4.33325 5.00016 4.33325ZM10.0002 6.33325C8.78016 6.33325 6.3335 6.94659 6.3335 8.16659V8.99992C6.3335 9.36659 6.6335 9.66659 7.00016 9.66659H13.0002C13.3668 9.66659 13.6668 9.36659 13.6668 8.99992V8.16659C13.6668 6.94659 11.2202 6.33325 10.0002 6.33325ZM5.00016 5.66659C3.44683 5.66659 0.333496 6.44659 0.333496 7.99992V8.99992C0.333496 9.36659 0.633496 9.66659 1.00016 9.66659H5.00016V8.16659C5.00016 7.59992 5.22016 6.60659 6.58016 5.85325C6.00016 5.73325 5.44016 5.66659 5.00016 5.66659Z"
        fill="white"
        fill-opacity="0.54"
      />
    </svg>
  );
};
