export const LearningRoutes = {
    LearningAdmin :'/learning/admin',
    LearningUnitCreate :'/learning/admin/create/:unitId',
    LearningUnitEdit :'/learning/admin/edit/:unitId',
    LearningUser :'/learning/user',
    LearningUnitPassing :'/learning/:passingId',
    LearningUserRating :'/learning/user-rating/:userId',
    LearningAdminAnalytics :'/learning/admin/analytics',
    LearningUserAnalytics :'/learning/user/analytics',
    LearningUnitPreview :'/learning/preview/:unitId',
    LearningAnalyticsTab:'/analytics/learning',
  };
  