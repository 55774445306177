import { injectEntity } from '../../../../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../../../common/store/base/injectPrimitive';
import { IMainLayoutDomainStore } from '../../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IUnitModel } from '../../../../../../../service/unit/interface/IUnitModel';
import { ITask, ITaskResult } from '../../../../../../../service/unit/interface/IUnitPractice';
import { ITasksQuizResult, IUnitResultModel } from '../../../../../../../service/unitResult/IUnitResultModel';
import { IPassingNavigationItemProperties } from '../../../components/PassingBody/PassingBodySidebar/PassingSidebarPractice/PassingPracticeNavigationItem';
import { IPassingPracticeUI, IPassingStepContent } from './IPassingPracticeUI';

export class PassingPracticeUI implements IPassingPracticeUI {
  constructor(
    public passingTaskList = injectEntityList<ITask>([]),
    public stepperList = injectEntityList<ITask>([] as any),
    public activeStep = injectPrimitive<number>(0),
    public startStep = injectPrimitive<number>(0),
    public stepContent = injectEntityList<IPassingStepContent>([] as any),
    public stepsTitles = injectEntityList<string>([] as any),
    public completed = injectPrimitive<any>({}),
    public redirectTo = injectPrimitive<string>(''),
    public layoutDomain = injectEntity<IMainLayoutDomainStore>(null as any),
    public passingId = injectPrimitive<string>(''),
    public additionalActions = injectEntityList<any>([]),
    public passageTime = injectEntity<string>(''),
    public results = injectEntity<ITaskResult>({} as any),
    public correctAnswersAmount = injectEntity<number>(0),
    public navigationList = injectEntityList<IPassingNavigationItemProperties>([]),
    public pickedLang = injectPrimitive<string>(''),
    public isExpanded = injectPrimitive<boolean>(false),
    public answersList = injectEntityList<ITasksQuizResult>([]),
    public correctOption = injectPrimitive<string>(''),
    public isEnebled = injectPrimitive<boolean>(false),
    public isFullScreen = injectPrimitive<boolean>(false),
    public currentUnit = injectPrimitive<IUnitModel>({} as any),
    public currentUnitResult = injectPrimitive<IUnitResultModel>({} as any),
    public timeIsLeft = injectPrimitive<number>(0),
  ) {
    this.redirectTo.setValue('completeUnit');
  }
}
