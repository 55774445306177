import { ConstructorType, inject, injectValue } from '../../../../../../common/container/inject';
import { IError } from '../../../../../../common/error/IError';
import { DomainStore } from '../../../../../../common/store/base/DomainStore';
import { IApplicationModel } from '../../../../../../service/application/entity/IApplicationModel';
import { IProjectModel } from '../../../../../../service/project/IProjectModel';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../service/route/IRouterService';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { defaultProjectView, IProjectView } from './model/IProjectView';
import { ProjectInfoDataMapper } from './model/ProjectInfoDataMapper';
import { ProjectInfoUI } from './ProjectInfoUI';
import { IUserProjectRoleModel } from '../../../../../../service/projectRole/entity/IUserProjectRoleModel';
import { IUserEntityModel } from '../../../../../../service/user/IUserEntityModel';
import { LayoutNotificationType } from '../../../../../layout/common/notification/store/ILayoutNotification';
import {
  UserSystemRoleModelPermissionMap,
} from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IApplicationDataModel } from '../../../../../../service/application/data/IApplicationDataModel';
import { IStatusValueModel } from '../../../../../../service/status/value/IStatusValueModel';

export class ProjectInfoDomain extends DomainStore {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    private uiStore = new (injectValue<ConstructorType<ProjectInfoUI>>(ProjectInfoUI))(),
    private publicRootService = injectRootService(layoutDomain.serviceType.value),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private dataMapper = new ProjectInfoDataMapper(),
  ) {
    super();
  }

  async loadData(projectId?: string): Promise<void> {
    this.uiStore.startLoading();

    const loadDataPromises = [this.loadProjects(projectId)];

    await Promise.all(loadDataPromises);

    this.uiStore.endLoading();
  }

  private async loadProjects(projectId?: string) {
    const projects = await this.publicRootService.project.entity
      .search({ limit: 100000, filter: { id: projectId } })
      .catch(this.dataLoadErrorCatch(this.errorDefaultSearchValue<IProjectModel>()));
    const projectIds = projects.data.map((project) => project.id as string) || [];
    const usersIds = new Set<string>();
    projects.data.forEach((project) => {
      const projectUsersIds = project.rolesMap?.data?.map((userRole) => userRole.userId) || [];
      projectUsersIds.forEach((userId) => usersIds.add(userId));
    });
    const applicationsFilter = this.getApplicationsFilter(projectIds);
    const applicationsDataFilter = this.getApplicationsDataFilter(projectIds);
    const [applications,
      applicationsData,
      projectRoles,
      users,
      projectNewMessagesCounterData,
      statusesValues] = await Promise.all([
        this.publicRootService.application.entity.search({
          limit: 100000,
          filter: applicationsFilter,
          fields: ['id', 'name', 'performerTypeId', 'dataId', 'projectId', 'isUnitedWithProject'],
        }),
        this.publicRootService.application.data.search({
          limit: 100000,
          filter: applicationsDataFilter,
          fields: ['id', 'requirementCount', 'applicationId', 'projectId', 'statusesStatistic', 'statusesIds', 'statusesValuesIds'],
        }),
        this.publicRootService.projectRole.entity.search({ limit: 100000 }),
        this.publicRootService.user.entity.search({
          limit: 1000000,
          filter: { ids: { in: Array.from(usersIds) } },
          fields: ['id', 'displayName', 'login','email'],
        }),
        this.publicRootService.project.entity.getProjectsApplicationsCounters({
          limit: 100000,
          filter: { ids: { in: projectIds } },
          fields: ['id', 'isActive', 'name'],
        }),
        this.publicRootService.status.value.search({ limit: 100000 }),
      ]);

    let projectViews = await Promise.all(
      projects.data.map((project) =>
        this.makeProjectView(project, projectNewMessagesCounterData, applications.data, applicationsData.data, users.data, projectRoles.data, statusesValues.data),
      ),
    );
    projectViews = this.sortEmptyProjectsToBottomOfList(projectViews);
    this.ui.projects.setList(projectViews);
    this.ui.allStatusesValues.setList(statusesValues.data);
    this.ui.projectsNewMessagesData.setValue(projectNewMessagesCounterData);
    projects.data.length > 0 ? this.ui.projects.setList(projectViews) : this.ui.projects.setList([defaultProjectView]);
    this.setTeamsData(projectViews);
  }

  private getApplicationsFilter(projectIds: string[]) {
    if (projectIds.length > 1) {
      if (this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any'], UserSystemRoleModelPermissionMap['project-not-in-team-access']])) {
        return {};
      } else {
        return { projectId: { in: projectIds } };
      }
    } else {
      return { projectId: { equal: projectIds[0] } };
    }
  }

  private getApplicationsDataFilter(projectIds: string[]) {
    if (projectIds.length > 1) {
      if (this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any'], UserSystemRoleModelPermissionMap['project-not-in-team-access']])) {
        return {};
      } else {
        return { projectId: { in: projectIds } };
      }
    } else {
      return { projectId: { equal: projectIds[0] } };
    }
  }

  private setTeamsData(projectViews: IProjectView[]) {
    const result: {
      projectId: string;
      data: { memberId: string; data: { style?: any; content: any }[] }[];
    }[] = [];
    projectViews.forEach((view) => {
      result.push({
        projectId: view.id,
        data: view.team.map((member) => ({
          memberId: member.id,
          data: [
            { content: `${member.fullName} (${member.login})` },
            { width: 40, content: '' },
            { content: member.roleName },
          ],
        })),
      });
    });

    this.ui.teamTableRowsContent.setList(result);
  }

  private sortEmptyProjectsToBottomOfList(projectViews: IProjectView[]): IProjectView[] {
    return projectViews.sort((projectView) => (projectView.applications.length < 0 ? 1 : -1));
  }

  private makeProjectView = async (
    project: IProjectModel,
    projectNewMessagesCounterData: {
      applicationId: string;
      newMessages: number;
      projectId: string;
    }[],
    allApplications: IApplicationModel[],
    allApplicationsData: IApplicationDataModel[],
    allUsers: IUserEntityModel[],
    allProjectRoles: IUserProjectRoleModel[],
    allStatusesValues: IStatusValueModel[],
  ): Promise<IProjectView> => {
    const usersIds = project?.rolesMap?.data?.map((item) => item.userId) || [];
    const projectView = this.dataMapper.mapProject(
      {
        project,
        projectApplicationsData: allApplicationsData.filter((applicationData) => applicationData.projectId === project?.id),
        projectApplications: allApplications.filter((application) => application.projectId === project?.id),
        projectUsers: usersIds.length > 0 ? allUsers.filter((user) => usersIds.includes(user.id || '')) : [],
        projectRolesList: allProjectRoles,
        activeUser: this.layoutDomain.ui.activeUser.entity,
        layoutDomain: this.layoutDomain,
      },
      {
        allStatusesValues: allStatusesValues
      },
      projectNewMessagesCounterData,
    )
      ;

    return projectView;
  };

  private errorDefaultSearchValue<ValueType>(): { data: ValueType[] } {
    return { data: [] };
  }

  private dataLoadErrorCatch = <DefaultType>(defaultValue: DefaultType) => {
    const handler = (error: IError): DefaultType => {
      this.layoutDomain.errors.handleError(error);
      return defaultValue;
    };
    return handler;
  };

  get ui() {
    return this.uiStore;
  }

  async transferApplicationToOtherProject() {
    this.uiStore.isTransferLoading.setValue(true);
    const projectId = this.uiStore.transferProjectId.value;
    const application = this.uiStore.transferApplication.entity;

    if (projectId && application) {
      await this.publicRootService.application.entity.transferApplication(application.id, projectId);
      await this.uiStore.isOpenTransferModal.setValue(false);
      this.router.goTo(`/project/${projectId}/info`);
      await this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.success,
        text: `Система ${application.name} перенесена`,
      });
    } else {
      await this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: `Ошибка валидации, выберите проект для переноса`,
      });
    }
    this.uiStore.isTransferLoading.setValue(false);
  }

  async copyApplicationToOtherProject() {
    this.uiStore.isTransferLoading.setValue(true);
    const projectId = this.uiStore.copyApplicationData.value.projectId;
    const application = this.uiStore.transferApplication.entity;
    const newApplicationName = this.uiStore.copyApplicationData.value.newApplicationName;
    const currentData = this.uiStore.copyApplicationData.value;

    if (projectId && application && newApplicationName) {
      try {
        currentData.applicationId = application.id;
        await this.publicRootService.application.entity.copyApplication(currentData);
        await this.uiStore.isOpenTransferModal.setValue(false);
        this.router.goTo(`/project/${projectId}/info`);
        await this.loadData(projectId);
        await this.layoutDomain.notifications.showNotification({
          type: LayoutNotificationType.success,
          text: `Система ${application.name} копирована`,
        });
        this.ui.transferApplicationType.setValue(null);
        this.ui.transferProjectId.setValue('');
        this.ui.transferApplication.setEntity(null);
        this.ui.copyApplicationData.setValue({
          newApplicationName: '',
          applicationId: '',
          projectId: '',
          newLabelsForJira: [],
          needToCopy: {
            isNeedLocalRequirements: false,
            isNeedStatuses: false,
            isNeedAdditionalColumns: false,
            isNeedJiraIntegrations: false,
            isNeedVerifyAllRequirements: false,
          },
        });
      } catch (e) {
        await this.layoutDomain.notifications.showNotification({
          type: LayoutNotificationType.error,
          //@ts-ignore
          text: e?.data[0]?.message || `Ошибка копирования, попробуйте снова.`,
        });
      }
    } else {
      await this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: `Ошибка валидации, проверьте заполненность полей`,
      });
    }
    this.uiStore.isTransferLoading.setValue(false);
  }

  async loadAllProjectsNamesAndId() {
    const projects = await this.publicRootService.project.entity
      .search({
        limit: 10000,
        fields: ['id', 'name'],
        filter: {
          isUnitedWithApplication: {
            equal: false,
          },
        },
      })
      .catch(this.dataLoadErrorCatch(this.errorDefaultSearchValue<IProjectModel>()));

    this.uiStore.projectNames.setList(projects.data);
  }

  async openTransferModalWindow(projectId) {
    this.uiStore.isOpenTransferModal.setValue(true);
    this.uiStore.transferProjectId.setValue(projectId);
  }

  isUserCanTransferApplication() {
    const isCanTransferApplication = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-user-system-role-application-transfer'],
    ]);

    return isCanTransferApplication;
  }

  searchRequest(searchTerm) {
    this.ui.searchTerm.setValue(searchTerm);

    const searchRegex = new RegExp(this.ui.searchTerm.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

    const filteredRows = [...this.ui.projects.list].filter((row) => {
      return Object.keys(row)
        .some((field) => {
          let haveSome = false;
          if (field === 'applications') {
            const currentProjectApplications = row[field];
            const filtredResult = currentProjectApplications.filter((application) => {
              return searchRegex.test(application.name);
            });
            haveSome = filtredResult.length > 0;
          }
          return haveSome;
        });
    });

    this.ui.filteredProjects.setList(filteredRows.length ? filteredRows : []);
  }

  async getCountOfProjectsAndApplications() {
    const count = await this.publicRootService.project.entity.getCount().catch(this.dataLoadErrorCatch);
    this.ui.projectsTotalCount.setValue(count?.projectCount || 0);
    this.ui.applicationsTotalCount.setValue(count?.applicationCount || 0);
  }
}
