import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DataTable } from '../../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { StatusEntityAdminDomain } from './store/StatusEntityAdminDomain'

export interface IStatusEntityAdminTableProperties extends IAdminPageProperties { }

export const StatusEntityAdminTable = observer(({ layoutDomain }: IStatusEntityAdminTableProperties) => {
  const [domain] = useState<StatusEntityAdminDomain>(new StatusEntityAdminDomain(layoutDomain));
  useEffect(() => {
    layoutDomain.ui.pagePadding.setValue(0)
    return () => layoutDomain.ui.pagePadding.setValue(24)
  }, [])

  return <DataTable dataTableDomain={domain} tableHeight={window.innerHeight} />;
});
