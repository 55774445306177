import MaterialTable, { MTableAction, MTableEditField } from '@material-table/core';
import { Alert } from '@mui/lab';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect } from 'react';

import { useI18n } from '../../../../application/language/useI18n';
import { ICommonProperties } from '../../../../common/properties/ICommonProperties';
import { IServiceModel } from '../../../../service/common/model/IServiceModel';
import { ISearchRequestData } from '../../../../service/common/search/ISearchRequestData';
import { TableIcons } from '../../../design/table/icons/TableIcons';
import { TableLoader } from '../../../design/table/TableLoader';
import { TableTranslationEN, TableTranslationRU } from '../../../design/table/text/TableTranslation';
import { IAdminTableDomain } from './store/IAdminTableDomain';

export interface IAdminTableProperties extends ICommonProperties {
  tableDomain: IAdminTableDomain<IServiceModel, ISearchRequestData>;
}

export const AdminTable = observer(({ tableDomain }: IAdminTableProperties) => {
  const { translate, i18n } = useI18n();
  const classes = useStyles();
  useEffect(() => {
    tableDomain.loadData();
  }, [tableDomain]);

  const handleActionResult = (result: boolean): void => {
    if (!result) {
      throw result;
    }
  };
  const dataItemsCount = tableDomain.ui.entities.list.length;
  const paging =
    dataItemsCount > 0
      ? {
          pageSize: dataItemsCount > 100 ? 100 : dataItemsCount,
          pageSizeOptions: dataItemsCount > 100 ? [10, 100, dataItemsCount] : [dataItemsCount],
        }
      : {};

  const options = {
    addRowPosition: 'first',
    actionsColumnIndex: -1,
    maxBodyHeight: window.innerHeight - 230,
    overflowY: 'inherit',
    headerStyle: { position: 'sticky', top: 0, zIndex: 1 },
    editCellStyle: {
      verticalAlign: 'top',
    },
    ...paging,
    ...(tableDomain.ui.tableOptions || {}),
  };

  const isEditable = {
    isEditable: (rowData: any) => {
      const result = tableDomain.ui.isEditable && tableDomain.ui.isEditable(rowData);
      return result ?? true;
    },
    isDeletable: (rowData: any) => {
      if (rowData.name && rowData.name === translate('phrases.realizationStatus')) {
        return false;
      }
      const result = tableDomain.ui.isDeletable && tableDomain.ui.isDeletable(rowData);
      return result ?? true;
    },
  };
  const editableSettings = {};

  if (tableDomain?.ui?.isShowDelete?.value && tableDomain.ui.isCanDelete.value) {
    editableSettings['isDeletable'] = isEditable.isDeletable;
    editableSettings['onRowDelete'] = async (oldData: any) => {
      const result = await tableDomain.removeById(oldData.id);
      handleActionResult(result);
    };
  }

  if (tableDomain.ui.isInlineEdit.value && tableDomain.ui.isCanEdit.value) {
    editableSettings['onRowUpdate'] = async (newData: any, oldData: any) => {
      newData.id = oldData.id;
      const result = await tableDomain.updateEntity(newData);
      handleActionResult(result);
    };
    editableSettings['isEditable'] = isEditable.isEditable;
  }

  if (tableDomain.ui.isInlineEdit.value && tableDomain.ui.isCanCreate.value) {
    editableSettings['onRowAdd'] = async (newData: any) => {
      const result = await tableDomain.createNew(newData);
      handleActionResult(result);
    };
  }

  return (
    <MaterialTable
      key={dataItemsCount}
      //@ts-ignore
      icons={TableIcons}
      localization={i18n.language === 'ru' ? TableTranslationRU : TableTranslationEN}
      title=""
      isLoading={tableDomain.ui.isLoading.value}
      columns={[...tableDomain.ui.columns.list]}
      data={[...tableDomain.ui.entities.list]}
      onSearchChange={(term) => {
        tableDomain.onSearchChange(term);
      }}
      options={{
        detailPanelColumnAlignment: 'right',
        ...options,
      }}
      actions={tableDomain.ui.actions.list}
      detailPanel={tableDomain.ui.detailPanel || []}
      components={{
        OverlayLoading: TableLoader,
        Action: (props) => {
          if (props?.action?.tooltip === 'Cancel') {
            const onCancel = props.action.onClick;
            props.action.onClick = (event: any) => {
              tableDomain.removeValidationErrors();
              onCancel(event);
            };
          }
          return <MTableAction {...props} />;
        },
        EditField: (props) => {
          const fieldName = props?.columnDef?.field;
          const isDisabled = fieldName === 'name' && props.value === translate('phrases.realizationStatus');
          const additionalProps: any = {};
          const error = tableDomain.getValidationErrorFor(fieldName);
          const isMultiline = !!props?.columnDef?.custom?.multiline;
          additionalProps['className'] = classes.default;
          if (isMultiline) {
            additionalProps['multiline'] = true;
            additionalProps['rowsMax'] = '200';
            additionalProps['className'] = classes.input;
          }
          if (props.columnDef.isRequired) {
            props.columnDef.title = `${props.columnDef.title}*`;
          }
          return (
            <Fragment>
              <MTableEditField
                disabled={isDisabled}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                  }
                }}
                style={{ display: 'none !important' }}
                {...props}
                {...additionalProps}
                onChange={(event: any) => {
                  props.onChange(event);
                  tableDomain.removeValidationErrorFor(fieldName);
                }}
              />
              <br /> <br />
              {error && (
                <Alert icon={false} className={classes.errorAlert} variant="outlined" severity="error">
                  {error.message}
                </Alert>
              )}
            </Fragment>
          );
        },
      }}
      editable={editableSettings}
    />
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  errorAlert: {
    width: '100%',
  },
  input: {
    width: '100%',
    height: '100%',
    verticalAlign: 'top',
  },
  default: {},
  editRowDefault: {
    verticalAlign: 'top',
  },
}));
