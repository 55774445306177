import { Search } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { TranslateText } from '../../../../../../../application/language/TranslateText';
import { IUnitListAdminPageDomain } from '../store/IUnitListAdminPageDomain';

enum FilterValues {
  All = 'all',
  Published = 'published',
  NotPublished = 'notPublished',
}

export const UnitListSearch = observer(({ domain }: { domain: IUnitListAdminPageDomain }) => {

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '24px', alignItems: 'flex-end' }}>
      <TextField
        size="small"
        onChange={(event) => {
          domain.handleSearchByGroupsAndName(event.target.value);
        }}
        sx={{ flex: '1' }}
        label={<TranslateText i18nKey="learningUserPage.findByContext" />}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end">
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormControl>
        <InputLabel><TranslateText i18nKey="learningUserPage.showUnits" /></InputLabel>
        <Select
          sx={{ width: '160px' }}
          variant="outlined"
          size="small"
          label="Показывать юниты"
          value={domain.ui.filterByIsPublished.value}
          onChange={(event) => {
            domain.handleFilterByIsPublished(event.target.value as FilterValues);
          }}
        >
          <MenuItem value={FilterValues.All}><TranslateText i18nKey="userListSearch.all" /></MenuItem>
          <MenuItem value={FilterValues.Published}><TranslateText i18nKey="userListSearch.published" /></MenuItem>
          <MenuItem value={FilterValues.NotPublished}><TranslateText i18nKey="userListSearch.notPublished" /></MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
});
