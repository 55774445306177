import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { UserDataLevel } from '../../../../../service/userData/IUserDataModel';
import { useI18n } from '../../../../../../../application/language/useI18n';

export interface ILevelBarsProps {
  level: UserDataLevel;
}

export const LevelBars = observer(({ level }: ILevelBarsProps) => {
  const i18n = useI18n()
  return (
    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      {level === UserDataLevel.JUNIOR ? (
        <>
          <Box sx={{ display: 'flex', gap: '2px' }}>
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(244, 67, 54, 1)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(255, 255, 255, 0.12)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(255, 255, 255, 0.12)' }} />
          </Box>
          <Typography>{i18n.translate('phrases.junior')}</Typography>
        </>
      ) : level === UserDataLevel.MIDDLE ? (
        <>
          {' '}
          <Box sx={{ display: 'flex', gap: '2px' }}>
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(255, 167, 38, 1)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(255, 167, 38, 1)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(255, 255, 255, 0.12)' }} />
          </Box>
          <Typography>{i18n.translate('phrases.middle')}</Typography>
        </>
      ) : level === UserDataLevel.SENIOR ? (
        <>
          <Box sx={{ display: 'flex', gap: '2px' }}>
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(102, 187, 106, 1)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(102, 187, 106, 1)' }} />
            <Box sx={{ width: '12px', height: '4px', background: 'rgba(102, 187, 106, 1)' }} />
          </Box>
          <Typography>{i18n.translate('phrases.champion')}</Typography>
        </>
      ) : (
        ''
      )}
    </Box>
  );
});
