import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ITheoryBlock, ITheoryBlocks } from '../../../../../../service/unit/interface/IUnitTheory';
import { ITheoryComponent } from '../ITheoryComponent';
import { TheoryComponentList } from '../TheoryComponentList';

export interface ITagsSubBlocks {
  name: string;
  subBlocks: ITheoryBlock<ITheoryBlocks>[];
}
export interface ITagsBlock {
  tagsCount: number;
  tagsData: {
    title: string;
    contentBlocks: ITagsSubBlocks[];
    sortIndex: 0;
    pseudoId: '';
  };
}
export const TagsBlock = observer(({ data }: ITheoryComponent<ITagsBlock>) => {
  const [value, setValue] = React.useState<string>(data.tagsData.contentBlocks[0]?.name || '');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%', marginBottom: '32px' }}>
      <TabContext value={value}>
        <Box sx={{}}>
          <TabList
            onChange={handleChange}
            sx={{
              '& .MuiTabs-flexContainer': {
                gap: '4px',
                flexWrap: 'wrap',
              },
              '& .Mui-selected': {
                background: '#1976D2',
              },
              '& span.MuiTabs-indicator': {
                display: 'none',
              },
              '& .MuiButtonBase-root': {
                padding: '4px 16px',
                minHeight: 'inherit',
              },
            }}
          >
            {data.tagsData.contentBlocks.map((tag) => {
              return (
                <Tab
                  key={tag.name}
                  label={tag.name}
                  value={tag.name}
                  sx={{
                    background: 'rgba(255,255,255,0.2)',
                    borderRadius: '16px',
                  }}
                />
              );
            })}
          </TabList>
        </Box>
        {data.tagsData.contentBlocks.map((tag) => {
          return (
            <TabPanel
              key={tag.name}
              value={tag.name}
              sx={{
                '& > div': { background: 'transparent', boxShadow: 'none' },
                '& .MuiTableContainer-root': { background: 'transparent', boxShadow: 'none' },
              }}
            >
              {tag.subBlocks.map((block) => {
                const Element: ITheoryComponent<any> = TheoryComponentList[block.block];
                // @ts-ignore
                return <Element key={block.pseudoId} data={block.data} />;
              })}
            </TabPanel>
          );
        })}
      </TabContext>
    </Box>
  );
});
