import { StarRounded } from '@mui/icons-material';
import { Box, LinearProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { useI18n } from '../../../../../../../application/language/useI18n';
import { IUserDataModel, UserDataLevel } from '../../../../../service/userData/IUserDataModel';

export interface IUserInfoCard {
  fio: string;
  role: string | null;
  userData: IUserDataModel;
}
export const UserInfoCard = observer(
  ({ userData: { toNextLevelScore, level, totalScore, maxScore } }: IUserInfoCard) => {
    const i18n = useI18n();
    const [nextLevelScore, setNextLevelScore] = useState<string>('');
    useEffect(() => {
      if (toNextLevelScore === 0) {
        setNextLevelScore(maxScore === 0 ? i18n.translate('userCardInfo.noAssignedUnits') : i18n.translate('userCardInfo.allUnitsPassed'));
      } else {
        setNextLevelScore(
          level === UserDataLevel.SENIOR
            ?
              `${i18n.translate('userCardInfo.pointsToCompletion')}` + toNextLevelScore
            : level === UserDataLevel.MIDDLE
            ? `${i18n.translate('userCardInfo.pointsToNextLevel')}: ` + toNextLevelScore
            : `${i18n.translate('userCardInfo.pointsToNextLevel')}: ` + toNextLevelScore,
        );
      }
    }, [toNextLevelScore, totalScore, maxScore, level]);
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <Box sx={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
              <StarRounded
                sx={{
                  width: '24px',
                  height: '24px',
                  background: 'rgba(144, 202, 249, 1)',
                  borderRadius: '50%',
                  color: '#29363D',
                }}
              />
              <Typography>{totalScore}</Typography>
            </Box>
            <Typography color="rgba(255,255,255,0.5)">{nextLevelScore}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <LinearProgress
                variant="determinate"
                value={
                  level === UserDataLevel.SENIOR
                    ? 100
                    : level === UserDataLevel.MIDDLE
                    ? 100
                    : totalScore === 0
                    ? 0
                    : Math.round(totalScore / ((maxScore * 0.35) / 100))
                }
                sx={{ width: '117px' }}
              />
              <Typography sx={{ color: level === UserDataLevel.JUNIOR ? 'white' : 'rgba(255,255,255,0.5)' }}>
                {i18n.translate('phrases.junior')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <LinearProgress
                variant="determinate"
                value={
                  level === UserDataLevel.SENIOR
                    ? 100
                    : level === UserDataLevel.MIDDLE
                    ? Math.round((totalScore - Math.round((maxScore * 35) / 100 + 1)) / ((maxScore * 0.34) / 100))
                    : 0
                }
                sx={{ width: '117px' }}
              />
              <Typography sx={{ color: level === UserDataLevel.MIDDLE ? 'white' : 'rgba(255,255,255,0.5)' }}>
                {i18n.translate('phrases.middle')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <LinearProgress
                variant="determinate"
                value={
                  level === UserDataLevel.SENIOR
                    ? toNextLevelScore === 0
                      ? 100
                      : Math.round((totalScore - Math.round((maxScore * 69) / 100 + 1)) / ((maxScore * 0.31) / 100))
                    : 0
                }
                sx={{ width: '117px' }}
              />
              <Typography sx={{ color: level === UserDataLevel.SENIOR ? 'white' : 'rgba(255,255,255,0.5)' }}>
                {i18n.translate('phrases.champion')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
);
