import { FormControlLabel, Grid, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { useI18n } from '../../../../../../../application/language/useI18n';
import { ContainerContext } from '../../../../../../../common/container/ContainerContext';
import { UserSystemRoleModelPermissionMap } from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormMultiAutocomplete } from '../../../../../../design/form/FormMultiAutocomplete';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import {
  SystemRolesAdditionalPermissionsInject,
  SystemRolesPermissionsExtensionItemData,
} from '../injects/SystemRolesAdditionalPermissionsInject';
import { SystemRolesAdditionalPermissionsToExistsGroupsIds } from '../injects/SystemRolesAdditionalPermissionsToExistsGroupInject';
import { useNotificationMapValues } from './modalConstValues';
import { SystemRoleAdminModalDomain } from './SystemRoleAdminModalDomain';

export interface ISystemRoleAdminModalProperties {
  domain: SystemRoleAdminModalDomain;
}

interface IRelatedSwitcherEntity {
  name: UserSystemRoleModelPermissionMap;
  value: boolean;
  label: string;
}

interface IRelatedSwitchersEntities {
  activated: IRelatedSwitcherEntity;
  activator: IRelatedSwitcherEntity;
}

const projectNotInTeamPermission = [
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-team-project'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-fields-project'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-project'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-delete-project'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-application'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-application'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-delete-application'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-report-access'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-custom-requirement-access'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-accept-cancel-access'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-show-unverified-requirements-access'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-project'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-show-regulators'],
  UserSystemRoleModelPermissionMap['project-not-in-team-permission-jira-intergration'],
];

export const SystemRoleAdminModal = observer(({ domain }: ISystemRoleAdminModalProperties) => {
  const { translate } = useI18n();
  useEffect(() => {
    domain.layoutDomain.setPageTitle(
      domain.ui.model.entity.id ? `${translate('phrases.edit')} ` : `${translate('phrases.add')} `,
    );
  }, [domain]);
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <SystemRoleName domain={domain} />
      <SystemRoleDescription domain={domain} />
      <SystemRolePermissions domain={domain} />
      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const SystemRoleName = observer(({ domain }: { domain: SystemRoleAdminModalDomain }) => {
  const { translate } = useI18n();
  return (
    <FormTextField
      required={true}
      label={translate('phrases.nameTitle')}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
      disabled={
        //TODO: отрефакторить когда будут переведены роли
        (domain.ui.model.entity.name === 'Admin' || domain.ui.model.entity.name === 'Администратор') &&
        !!domain.ui.model.entity.id &&
        domain.ui.model.entity.permissions.allowedPermissions.includes(
          UserSystemRoleModelPermissionMap['global-allow-any'],
        )
      }
    />
  );
});

const SystemRoleDescription = observer(({ domain }: { domain: SystemRoleAdminModalDomain }) => {
  const { translate } = useI18n();
  return (
    <FormTextArea
      required={true}
      label={translate('phrases.description')}
      value={domain.ui.model.entity.description}
      onChange={domain.getUpdateFieldHandler('description')}
      errorMessage={domain.getValidationErrorFor('description')?.message}
    />
  );
});

const SystemRolePermissions = observer(({ domain }: { domain: SystemRoleAdminModalDomain }) => {
  const { translate } = useI18n();
  const notificationMapValues = useNotificationMapValues();
  const systemRolesAdditionalPermissionsExtensions = useContext(ContainerContext).get(
    SystemRolesAdditionalPermissionsInject,
  );

  const notInTeamExtensionPermission = systemRolesAdditionalPermissionsExtensions
    .filter((item) => item.id === SystemRolesAdditionalPermissionsToExistsGroupsIds.notInTeam)
    .reduce<any[]>((extensionValues, item) => {
      if (item.values) {
        extensionValues.push(...item.values);
      }
      return extensionValues;
    }, []);

  return (
    <Fragment>
      <Grid style={{ width: 700 }} xs container flexDirection={'column'}>
        <Grid xs item>
          <Switcher
            domain={domain}
            label={translate('systemRoles.addRoleModal.noRestrictions')}
            name={UserSystemRoleModelPermissionMap['global-allow-any']}
            value={domain.ui.model.entity.permissions.allowedPermissions.includes(
              UserSystemRoleModelPermissionMap['global-allow-any'],
            )}
            disabled={
              (domain.ui.model.entity.name === 'Администратор' || domain.ui.model.entity.name === 'Admin') &&
              !!domain.ui.model.entity.id &&
              domain.ui.model.entity.permissions.allowedPermissions.includes(
                UserSystemRoleModelPermissionMap['global-allow-any'],
              )
            }
          />
        </Grid>
        <Grid xs item>
          <Grid xs flexDirection={'column'} container>
            {/* <Grid xs item>
              <FormMultiAutocomplete
                values={[
                  { value: UserSystemRoleModelPermissionMap['project-in-team-delete'], name: 'Удалять проекты' },
                  { value: UserSystemRoleModelPermissionMap['project-in-team-update'], name: 'Обновлять проекты' },
                  { value: UserSystemRoleModelPermissionMap['project-can-create'], name: 'Создавать проекты' },
                ]}
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['project-in-team-delete'],
                    UserSystemRoleModelPermissionMap['project-in-team-update'],
                    UserSystemRoleModelPermissionMap['project-can-create'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label="Права в проектах с проектой ролью"
              />
            </Grid> */}
            <RelatedSwitchers
              domain={domain}
              entities={{
                activated: {
                  label: translate('systemRoles.addRoleModal.accessToTheProjectsSection'),
                  name: UserSystemRoleModelPermissionMap['is-have-access-to-projects'],
                  value: domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['is-have-access-to-projects'],
                  ),
                },
                activator: {
                  label: translate('systemRoles.addRoleModal.accessInProjectsWithoutAnyProjectRole'),
                  name: UserSystemRoleModelPermissionMap['project-not-in-team-access'],
                  value: domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['project-not-in-team-access'],
                  ),
                },
              }}
            />
            <Grid item>
              <FormMultiAutocomplete
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['project-not-in-team-access'],
                  )
                }
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-project'], // права
                    name: translate('systemRoles.addRoleModal.creatingProjects'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-team-project'],
                    name: translate('systemRoles.addRoleModal.editingProjectTeams'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-fields-project'],
                    name: translate('systemRoles.addRoleModal.editingProjectFields'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-delete-project'],
                    name: translate('systemRoles.addRoleModal.deletingProjects'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-application'],
                    name: translate('systemRoles.addRoleModal.creatingSystems'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-application'],
                    name: translate('systemRoles.addRoleModal.updatingSystems'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-delete-application'],
                    name: translate('systemRoles.addRoleModal.deletingSystems'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-report-access'],
                    name: translate('systemRoles.addRoleModal.accessToReports'),
                  },
                  {
                    value:
                      UserSystemRoleModelPermissionMap[
                        'project-not-in-team-permission-show-unverified-requirements-access'
                      ],
                    name: translate('systemRoles.addRoleModal.viewingUnverifiedRequirements'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-custom-requirement-access'],
                    name: translate('systemRoles.addRoleModal.accessToCreatingOrEditingLocalRequirements'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-accept-cancel-access'],
                    name: translate('systemRoles.addRoleModal.acceptingOrCancelingRequirements'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-show-regulators'],
                    name: translate('systemRoles.addRoleModal.viewingRequirementsOfExternalRegulatoryAuthorities'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['project-not-in-team-permission-jira-intergration'],
                    name: translate('systemRoles.addRoleModal.integratingAsWithJira'),
                  },
                  ...notInTeamExtensionPermission,
                ]}
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    ...projectNotInTeamPermission,
                    ...notInTeamExtensionPermission.map((item) => item.value),
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.rightsInProjectsWithoutAnyProjectRole')}
              />

              <Grid item>
                <Switcher
                  domain={domain}
                  label={translate('systemRoles.addRoleModal.itIsPossibleToTransferOrCopySystems')}
                  name={UserSystemRoleModelPermissionMap['settings-user-system-role-application-transfer']}
                  value={domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-user-system-role-application-transfer'],
                  )}
                  disabled={domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  )}
                />
              </Grid>

              <FormMultiAutocomplete
                values={[
                  ...notificationMapValues,
                  ...systemRolesAdditionalPermissionsExtensions
                    .filter((item) => item.id === SystemRolesAdditionalPermissionsToExistsGroupsIds.notifications)
                    .reduce<any[]>((extensionValues, item) => {
                      if (item.values) {
                        extensionValues.push(...item.values);
                      }
                      return extensionValues;
                    }, []),
                ]}
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    ...notificationMapValues,
                    ...systemRolesAdditionalPermissionsExtensions
                      .filter((item) => item.id === SystemRolesAdditionalPermissionsToExistsGroupsIds.notifications)
                      .reduce<any[]>((extensionValues, item) => {
                        if (item.values) {
                          extensionValues.push(...item.values);
                        }
                        return extensionValues;
                      }, []),
                  ]
                    .map((item) => item.value)
                    .filter((item) => !newValue.includes(item));
                  unsetItems.forEach((value) => domain.setModelNotificationPermission(value, false));
                  newValue.forEach((value) => domain.setModelNotificationPermission(value.value, true));
                }}
                selected={domain.ui.model.entity.notificationPermissions}
                label={translate('systemRoles.addRoleModal.notificationSettings')}
              />
            </Grid>
            <Grid item>
              <Switcher
                domain={domain}
                label={translate('systemRoles.addRoleModal.accessToSettingsIsAvailable')}
                name={UserSystemRoleModelPermissionMap['settings-is-allow']}
                value={domain.ui.model.entity.permissions.allowedPermissions.includes(
                  UserSystemRoleModelPermissionMap['settings-is-allow'],
                )}
                disabled={domain.ui.model.entity.permissions.allowedPermissions.includes(
                  UserSystemRoleModelPermissionMap['global-allow-any'],
                )}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-specifications-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-specifications-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-specifications-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-specifications-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-specifications-read'],
                    UserSystemRoleModelPermissionMap['settings-specifications-create'],
                    UserSystemRoleModelPermissionMap['settings-specifications-update'],
                    UserSystemRoleModelPermissionMap['settings-specifications-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsFeaturesOfAS')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-requirements-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-requirements-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-requirements-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-requirements-delete'],
                    name: translate('phrases.deleting'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-requirements-accept'],
                    name: translate('phrases.verification'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-requirements-read'],
                    UserSystemRoleModelPermissionMap['settings-requirements-create'],
                    UserSystemRoleModelPermissionMap['settings-requirements-update'],
                    UserSystemRoleModelPermissionMap['settings-requirements-delete'],
                    UserSystemRoleModelPermissionMap['settings-requirements-accept'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsTemplatesOfRequirements')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-requirement-vendors-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-requirement-vendors-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-requirement-vendors-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-requirement-vendors-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-requirement-vendors-read'],
                    UserSystemRoleModelPermissionMap['settings-requirement-vendors-create'],
                    UserSystemRoleModelPermissionMap['settings-requirement-vendors-update'],
                    UserSystemRoleModelPermissionMap['settings-requirement-vendors-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsRequirementsOfExternalRegulatoryAuthorities')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-tags-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-tags-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-tags-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-tags-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-tags-read'],
                    UserSystemRoleModelPermissionMap['settings-tags-create'],
                    UserSystemRoleModelPermissionMap['settings-tags-update'],
                    UserSystemRoleModelPermissionMap['settings-tags-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsTagsForRequirements')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-statuses-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-statuses-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-statuses-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-statuses-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-statuses-read'],
                    UserSystemRoleModelPermissionMap['settings-statuses-create'],
                    UserSystemRoleModelPermissionMap['settings-statuses-update'],
                    UserSystemRoleModelPermissionMap['settings-statuses-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsStatuses')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-performers-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-performers-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-performers-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-performers-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-performers-read'],
                    UserSystemRoleModelPermissionMap['settings-performers-create'],
                    UserSystemRoleModelPermissionMap['settings-performers-update'],
                    UserSystemRoleModelPermissionMap['settings-performers-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsExecutorsOfRequirements')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-additional-columns-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-additional-columns-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-additional-columns-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-additional-columns-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-additional-columns-read'],
                    UserSystemRoleModelPermissionMap['settings-additional-columns-create'],
                    UserSystemRoleModelPermissionMap['settings-additional-columns-update'],
                    UserSystemRoleModelPermissionMap['settings-additional-columns-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsAdditionalColumns')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-integrations-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-integrations-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-integrations-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-integrations-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-integrations-read'],
                    UserSystemRoleModelPermissionMap['settings-integrations-create'],
                    UserSystemRoleModelPermissionMap['settings-integrations-update'],
                    UserSystemRoleModelPermissionMap['settings-integrations-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsIntegrations')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-entity-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-entity-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-entity-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-entity-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-user-entity-read'],
                    UserSystemRoleModelPermissionMap['settings-user-entity-create'],
                    UserSystemRoleModelPermissionMap['settings-user-entity-update'],
                    UserSystemRoleModelPermissionMap['settings-user-entity-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsUsers')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-system-role-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-system-role-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-system-role-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-system-role-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-user-system-role-read'],
                    UserSystemRoleModelPermissionMap['settings-user-system-role-create'],
                    UserSystemRoleModelPermissionMap['settings-user-system-role-update'],
                    UserSystemRoleModelPermissionMap['settings-user-system-role-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsSystemRoles')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-project-role-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-project-role-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-project-role-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-user-project-role-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-user-project-role-read'],
                    UserSystemRoleModelPermissionMap['settings-user-project-role-create'],
                    UserSystemRoleModelPermissionMap['settings-user-project-role-update'],
                    UserSystemRoleModelPermissionMap['settings-user-project-role-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsProjectRoles')}
              />
            </Grid>
            <Grid item>
              <FormMultiAutocomplete
                values={[
                  {
                    value: UserSystemRoleModelPermissionMap['settings-application-template-read'],
                    name: translate('phrases.reading'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-application-template-create'],
                    name: translate('phrases.creating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-application-template-update'],
                    name: translate('phrases.updating'),
                  },
                  {
                    value: UserSystemRoleModelPermissionMap['settings-application-template-delete'],
                    name: translate('phrases.deleting'),
                  },
                ]}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
                onChangeSelect={(newValue: any) => {
                  const unsetItems = [
                    UserSystemRoleModelPermissionMap['settings-application-template-read'],
                    UserSystemRoleModelPermissionMap['settings-application-template-create'],
                    UserSystemRoleModelPermissionMap['settings-application-template-update'],
                    UserSystemRoleModelPermissionMap['settings-application-template-delete'],
                  ].filter((item) => !newValue.includes(item));

                  unsetItems.forEach((value) => domain.setModelPermissions(value, false));
                  newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                }}
                selected={domain.ui.model.entity.permissions.allowedPermissions}
                label={translate('systemRoles.addRoleModal.settingsTemplatesOfProfiles')}
              />
            </Grid>

            <Grid item>
              <Switcher
                domain={domain}
                label={translate('systemRoles.addRoleModal.logging')}
                name={UserSystemRoleModelPermissionMap['plugin-settings-logger-is-allow']}
                value={domain.ui.model.entity.permissions.allowedPermissions.includes(
                  UserSystemRoleModelPermissionMap['plugin-settings-logger-is-allow'],
                )}
                disabled={
                  domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['global-allow-any'],
                  ) ||
                  !domain.ui.model.entity.permissions.allowedPermissions.includes(
                    UserSystemRoleModelPermissionMap['settings-is-allow'],
                  )
                }
              />
            </Grid>

            {systemRolesAdditionalPermissionsExtensions
              .filter((item) => item.label)
              .map((permissionsData: SystemRolesPermissionsExtensionItemData) => {
                return (
                  <Grid item>
                    <FormMultiAutocomplete
                      values={permissionsData.values}
                      disabled={
                        domain.ui.model.entity.permissions.allowedPermissions.includes(
                          UserSystemRoleModelPermissionMap['global-allow-any'],
                        ) ||
                        !domain.ui.model.entity.permissions.allowedPermissions.includes(
                          UserSystemRoleModelPermissionMap['settings-is-allow'],
                        )
                      }
                      onChangeSelect={(newValue: any) => {
                        const unsetItems = permissionsData.values
                          .map((item) => item.value)
                          .filter((item) => !newValue.includes(item));

                        unsetItems.forEach((value) =>
                          domain.setModelPermissions(value as UserSystemRoleModelPermissionMap, false),
                        );
                        newValue.forEach((value) => domain.setModelPermissions(value.value, true));
                      }}
                      selected={domain.ui.model.entity.permissions.allowedPermissions}
                      label={permissionsData.label || 'none'}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
});

const Switcher = observer(
  ({
    domain,
    label,
    name,
    value,
    disabled,
  }: {
    name: UserSystemRoleModelPermissionMap;
    domain: SystemRoleAdminModalDomain;
    label: string;
    value: boolean;
    disabled?: boolean;
  }) => {
    const [isChecked, setChecked] = useState(value);
    const systemRolesAdditionalPermissionsExtensions = useContext(ContainerContext).get(
      SystemRolesAdditionalPermissionsInject,
    );

    const handleChange = (event: any) => {
      setChecked(event.target.checked);
      domain.setModelPermissions(name, event.target.checked);

      if (name === UserSystemRoleModelPermissionMap['global-allow-any'] && event.target.checked) {
        for (const permission of Object.values(UserSystemRoleModelPermissionMap)) {
          if (permission !== 'global-allow-any') {
            domain.setModelPermissions(permission, false);
          }
        }
      }

      if (name === UserSystemRoleModelPermissionMap['settings-is-allow'] && !event.target.checked) {
        if (
          domain.ui.model.entity.permissions.allowedPermissions.includes(
            UserSystemRoleModelPermissionMap['plugin-settings-logger-is-allow'],
          )
        ) {
          domain.setModelPermissions(UserSystemRoleModelPermissionMap['plugin-settings-logger-is-allow'], false);
        }
        for (const permission of Object.values(UserSystemRoleModelPermissionMap)) {
          if (
            permission !== 'settings-is-allow' &&
            permission !== 'plugin-settings-logger-is-allow' &&
            permission !== 'settings-user-system-role-application-transfer'
          ) {
            if (permission.includes('settings')) {
              domain.setModelPermissions(permission, false);
            }
          }
        }

        systemRolesAdditionalPermissionsExtensions
          .filter((item) => item.label)
          .map((permissionsData: SystemRolesPermissionsExtensionItemData) => {
            const unsetItems = permissionsData.values.map((item) => item.value);

            unsetItems.forEach((value) => domain.setModelPermissions(value as UserSystemRoleModelPermissionMap, false));
          });
      }
    };
    useEffect(() => {
      setChecked(value);
    }, [value, domain, name]);

    useEffect(() => {
      if (name === UserSystemRoleModelPermissionMap['global-allow-any'] && value) {
        for (const permission of Object.values(UserSystemRoleModelPermissionMap)) {
          if (permission !== 'global-allow-any') {
            domain.setModelPermissions(permission, false);
          }
        }
      }

      if (name === UserSystemRoleModelPermissionMap['settings-is-allow'] && !value) {
        if (
          domain.ui.model.entity.permissions.allowedPermissions.includes(
            UserSystemRoleModelPermissionMap['plugin-settings-logger-is-allow'],
          )
        ) {
          domain.setModelPermissions(UserSystemRoleModelPermissionMap['plugin-settings-logger-is-allow'], false);
        }
        for (const permission of Object.values(UserSystemRoleModelPermissionMap)) {
          if (
            permission !== 'settings-is-allow' &&
            permission !== 'plugin-settings-logger-is-allow' &&
            permission !== 'settings-user-system-role-application-transfer'
          ) {
            if (permission.includes('settings')) {
              domain.setModelPermissions(permission, false);
            }
          }
        }
      }
    }, []);

    return (
      <FormControlLabel
        disabled={disabled}
        style={{ width: '100%', padding: '16px', margin: 0 }}
        control={<Switch color={'success'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
        label={label}
      />
    );
  },
);

const RelatedSwitchers = observer(
  ({ domain, entities }: { domain: SystemRoleAdminModalDomain; entities: IRelatedSwitchersEntities }) => {
    const [isCheckedActivated, setCheckedActivated] = useState(entities.activated.value);
    const [isCheckedActivator, setCheckedActivator] = useState(entities.activator.value);

    const handleChangeActivated = (event: any) => {
      if (isCheckedActivator) {
        setCheckedActivator(event.target.checked);
        domain.setModelPermissions(entities.activator.name, event.target.checked);
      }
      setCheckedActivated(event.target.checked);
      domain.setModelPermissions(entities.activated.name, event.target.checked);

      if (!event.target.checked) {
        projectNotInTeamPermission.forEach((item) => {
          domain.setModelPermissions(item, false);
        });
      }
    };
    const handleChangeActivator = (event: any) => {
      if (!isCheckedActivated) {
        setCheckedActivated(event.target.checked);
        domain.setModelPermissions(entities.activated.name, event.target.checked);
      }
      setCheckedActivator(event.target.checked);
      domain.setModelPermissions(entities.activator.name, event.target.checked);

      if (!event.target.checked) {
        projectNotInTeamPermission.forEach((item) => {
          domain.setModelPermissions(item, false);
        });
      }
    };

    useEffect(() => {
      setCheckedActivated(entities.activated.value);
      setCheckedActivator(entities.activator.value);
    }, [entities, domain]);

    return (
      <>
        <FormControlLabel
          disabled={domain.ui.model.entity.permissions.allowedPermissions.includes(
            UserSystemRoleModelPermissionMap['global-allow-any'],
          )}
          style={{ width: '100%', padding: '16px', margin: 0 }}
          control={
            <Switch
              color={'success'}
              checked={isCheckedActivated}
              onChange={handleChangeActivated}
              defaultChecked={true}
            />
          }
          label={entities.activated.label}
        />
        <FormControlLabel
          style={{ width: '100%', padding: '16px', margin: 0 }}
          disabled={domain.ui.model.entity.permissions.allowedPermissions.includes(
            UserSystemRoleModelPermissionMap['global-allow-any'],
          )}
          control={
            <Switch
              color={'success'}
              checked={isCheckedActivator}
              onChange={handleChangeActivator}
              defaultChecked={true}
            />
          }
          label={entities.activator.label}
        />
      </>
    );
  },
);

// const TagActiveStatusCustomSwitch = observer(({ domain }: { domain: SystemRoleAdminModalDomain }) => {
//   const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);

//   const handleChange = (event: any) => {
//     setChecked(event.target.checked);
//     domain.ui.model.entity.isActive = event.target.checked;
//   };
//   useEffect(() => {
//     setChecked(domain.ui.model.entity.isActive);
//   }, [domain.ui.model.entity.isActive]);
//   return (
//     <FormControlLabel
//       style={{ width: '100%', padding: '16px', margin: 0 }}
//       control={<Switch color={'secondary'} checked={isChecked} onChange={handleChange} defaultChecked={true} />}
//       label="Статус"
//     />
//   );
// });
