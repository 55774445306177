import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { FilterTitle } from './FilterTitle';
import { RequirementEntityAdminDomain } from '../../../../admin/page/requirement/newEntity/store/RequirementEntityAdminDomain';
import { RequirementCreationType } from '../../../../../service/requirement/entity/RequirementType';

interface RequirementCreationTypeFilterProperties {
  searchRequirementCreationTypes: IPrimitiveStore<string[]>;
  setNewValue: (value: string, checked: boolean) => void;
  rootDomain?: any;
}
export const RequirementCreationTypeFilter = observer(
  ({
    searchRequirementCreationTypes,
    setNewValue,
    rootDomain
  }: RequirementCreationTypeFilterProperties) => {
    return (
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        style={{ boxShadow: "none", position: 'inherit' }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FilterTitle selectedCount={searchRequirementCreationTypes.value.length} title={'Статус шаблона требования'} />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container={!(rootDomain instanceof RequirementEntityAdminDomain)} display={'flex'} flexDirection={'column'}>
            <FormControlLabel
              style={{ marginBottom: 15 }}
              control={<Checkbox checked={searchRequirementCreationTypes.value.includes(RequirementCreationType.new)} name={'Добавлено'} />}
              onChange={(event: any) => {
                setNewValue(RequirementCreationType.new, event.target.checked);
              }}
              label={'Добавлено'}
            />
            <FormControlLabel
              style={{ marginBottom: 15 }}
              control={<Checkbox checked={searchRequirementCreationTypes.value.includes(RequirementCreationType.updated)} name={'Изменено'} />}
              onChange={(event: any) => {
                setNewValue(RequirementCreationType.updated, event.target.checked);
              }}
              label={'Изменено'}
            />
            <FormControlLabel
              style={{ marginBottom: 15 }}
              control={<Checkbox checked={searchRequirementCreationTypes.value.includes(RequirementCreationType.saved)} name={'Верифицировано'} />}
              onChange={(event: any) => {
                setNewValue(RequirementCreationType.saved, event.target.checked);
              }}
              label={'Верифицировано'}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },
);
