export const TableTranslationRU = {
  body: {
    emptyDataSourceMessage: 'Нет данных',
    addTooltip: 'Добавить',
    deleteTooltip: 'Удалить',
    editTooltip: 'Редактировать',
    filterRow: {
      filterTooltip: 'Фильтр',
    },
    editRow: {
      deleteText: 'Вы уверены, что хотите удалить?',
      cancelTooltip: 'Отменить',
      saveTooltip: 'Сохранить',
    },
  },
  grouping: {
    placeholder: 'Перетащите заголовки ...',
  },
  header: {
    actions: 'Действия',
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} из {count}',
    labelRowsSelect: 'записей',
    labelRowsPerPage: 'Записей на страницу:',
    firstAriaLabel: 'Первая старница',
    firstTooltip: 'Первая страница',
    previousAriaLabel: 'Предыдущая страница',
    previousTooltip: 'Предыдущая страница',
    nextAriaLabel: 'Следующая страница',
    nextTooltip: 'Следующая страница',
    lastAriaLabel: 'Последняя страница',
    lastTooltip: 'Последняя страница',
  },
  toolbar: {
    addRemoveColumns: 'Добавить или удалить колонки',
    nRowsSelected: '{0} запись(-ей) выбрано',
    showColumnsTitle: 'Показать колонки',
    showColumnsAriaLabel: 'Показать колонки',
    exportTitle: 'Экспортировать',
    exportAriaLabel: 'Экспортировать',
    exportName: 'Экспортировать в CSV',
    searchTooltip: 'Поиск',
    searchPlaceholder: 'Поиск',
  },
};

export const TableTranslationEN = {
  body: {
    emptyDataSourceMessage: 'No data',
    addTooltip: 'Add',
    deleteTooltip: 'Delete',
    editTooltip: 'Edit',
    filterRow: {
      filterTooltip: 'Filter',
    },
    editRow: {
      deleteText: 'Are you shure you want to delete?',
      cancelTooltip: 'Cancel',
      saveTooltip: 'Save',
    },
  },
  grouping: {
    placeholder: 'Drag title ...',
  },
  header: {
    actions: 'Actions',
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} of {count}',
    labelRowsSelect: 'items',
    labelRowsPerPage: 'Items per page:',
    firstAriaLabel: 'First page',
    firstTooltip: 'First page',
    previousAriaLabel: 'Previous page',
    previousTooltip: 'Previous page',
    nextAriaLabel: 'Next page',
    nextTooltip: 'Next page',
    lastAriaLabel: 'Last page',
    lastTooltip: 'Last page',
  },
  toolbar: {
    addRemoveColumns: 'Add or delete columns',
    nRowsSelected: '{0} item(s) picked',
    showColumnsTitle: 'Show columns',
    showColumnsAriaLabel: 'Show columns',
    exportTitle: 'Export',
    exportAriaLabel: 'Export',
    exportName: 'Export CSV',
    searchTooltip: 'Search',
    searchPlaceholder: 'Search',
  },
};
