import React from 'react'
import { Link, Typography } from '@mui/material';
import { INotificationModel, NotificationEvent, NotificationType } from '../../../../../service/notification/INotificationModel';

export const NotificationText = ({ notification }: { notification: INotificationModel }) => {
  switch (notification.event) {
    case NotificationEvent.PROJECT:
      return <ProjectNotificationText notification={notification} />;
    case NotificationEvent.APPLICATION:
      return <ApplicationNotificationText notification={notification} />;
    case NotificationEvent.JIRA:
      return <JiraNotificationText notification={notification} />;
    case NotificationEvent.REQUIREMENT:
      return <RequirementNotificationText notification={notification} />;
    case NotificationEvent.REQUIREMENT_TEMPLATE:
      return <RequirementTemplateNotificationText notification={notification} />;
    case NotificationEvent.REQUIREMENT_ACCEPT:
      return <RequirementAcceptNotificationText notification={notification} />;
    case NotificationEvent.TRANSFER_APPLICATION:
      return <TransferApplicationNotificationText notification={notification} />;
    default:
      return <Typography>{notification.action}</Typography>;
  }
};

const ProjectNotificationText = ({ notification }: { notification: INotificationModel }) => {
  if (notification.data.projectName && notification.data.id) { // добавить проверку на удаление
    const url = `project/${notification.data.id}/info`
    return (
      <Typography>
        {replaceSystemNameWithLink(notification.action, `"${notification.data.projectName}"`, url)}
      </Typography>
    )
  } else {
    return (
      <Typography>
        {notification.action}
      </Typography>
    )
  }
};
const ApplicationNotificationText = ({ notification }: { notification: INotificationModel }) => {
  if (notification.data.applicationName && notification.data.applicationId) {
    const url = `application/${notification.data.applicationId}/info`
    return (
      <Typography>
        {replaceSystemNameWithLink(notification.action, `"${notification.data.applicationName}"`, url)}
      </Typography>
    )
  } else {
    return (
      <Typography>
        {notification.action}
      </Typography>
    )
  }
};

const JiraNotificationText = ({ notification }: { notification: INotificationModel }) => {
  return (
    <Typography>
      {notification.action}
    </Typography>
  )
};

const RequirementNotificationText = ({ notification }: { notification: INotificationModel }) => {
  if (notification.data.applicationName && notification.data.applicationId) {
    const url = `/application/${notification.data.applicationId}/info`;
    return (
      <Typography>
        {replaceSystemNameWithLink(notification.action, `"${notification.data.applicationName}"`, url)}
      </Typography>
    )
  } else {
    return (
      <Typography>
        {notification.action}
      </Typography>
    )
  }
};

const RequirementTemplateNotificationText = ({ notification }: { notification: INotificationModel }) => {
  if (notification.data.requirementShortName) {
    const url = `/settings/requirement/entity`;
    return (
      <Typography>
        {replaceSystemNameWithLink(notification.action, `"${notification.data.requirementShortName}"`, url)}
      </Typography>
    )
  } else {
    return (
      <Typography>
        {notification.action}
      </Typography>
    )
  }
};

const RequirementAcceptNotificationText = ({ notification }: { notification: INotificationModel }) => {
  const url = `/application/${notification.data.applicationId}/info`;
  return (
    <Typography >
      {replaceSystemNameWithLink(notification.action, notification.data.applicationName, url)}
    </Typography>
  )
};

const TransferApplicationNotificationText = ({ notification }: { notification: INotificationModel }) => {
  if (notification.data.oldProjectId && notification.data.newProjectId && notification.data.oldProjectName && notification.data.newProjectName) {
    const urlApplication = `/application/${notification.data.id}/info`;
    const urlNewProject = `/project/${notification.data.newProjectId}/info`;
    const index = notification.action.indexOf("в проект");
    const firstPart = notification.action.substring(0, index).trim();
    const secondPart = notification.action.substring(index).trim();
    const oldPartData = replaceSystemNameWithLink(firstPart, `"${notification.data.applicationName}"`, urlApplication)
    const newPartData = replaceSystemNameWithLink(secondPart, `"${notification.data.newProjectName}"`, urlNewProject)
    return (
      <Typography>
        {oldPartData} {newPartData}
      </Typography>
    )
  } else {
    return (
      <Typography>
        {notification.action}
      </Typography>
    )
  }
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const replaceSystemNameWithLink = (text, itemName, url) => {
  const regex = new RegExp(escapeRegExp(itemName), 'g'); // Регулярное выражение для поиска всех вхождений systemName
  return text.split(regex).reduce((acc, part, index, array) => (
    <>
      {acc}
      {part}
      {index < array.length - 1 && <Link href={url}>{itemName}</Link>}
    </>
  ), null);
};