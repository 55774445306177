import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { AnalyticsDomain } from '../store/AnalyticsDomain';
import { TranslateText } from '../../../../../application/language/TranslateText';

export const AnalyticsPageHeader = observer(({ domain }: { domain: AnalyticsDomain }) => {

  return (
    <>
      <Grid container marginBottom={'24px'}>
        <Grid container direction={'column'}>
          <Grid item fontSize={16}>
            <TranslateText i18nKey={'analytics.description'} />
          </Grid>
          <Grid item fontSize={48}>
          <TranslateText i18nKey={'analytics.title'} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
