import { IUserProjectRoleModel } from '../../../../../../../service/projectRole/entity/IUserProjectRoleModel';
import {
  ApplicationAcceptStatus,
  IApplicationModel,
} from '../../../../../../../service/application/entity/IApplicationModel';
import { IApplicationState } from '../../../../../../../service/application/entity/IApplicationState';
import { IProjectModel } from '../../../../../../../service/project/IProjectModel';
import { UserProjectRoleAction } from '../../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { IStatusValueModel } from '../../../../../../../service/status/value/IStatusValueModel';
import {
  UserSystemRoleModelPermissionMap,
} from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IUserEntityModel } from '../../../../../../../service/user/IUserEntityModel';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ILayoutUser } from '../../../../../../layout/main/store/ui/ILayoutUser';
import { IProjectApplicationView } from './IProjectApplicationView';
import { IProjectTeamMemberView } from './IProjectTeamView';
import { IProjectView } from './IProjectView';
import { ConfigManager } from '../../../../../../../application/config/ConfigManager';
import { IApplicationDataModel } from '../../../../../../../service/application/data/IApplicationDataModel';

export interface IProjectMapData {
  projectApplications: IApplicationModel[];
  projectApplicationsData: IApplicationDataModel[],
  projectRolesList: IUserProjectRoleModel[];
  project: IProjectModel;
  projectUsers: IUserEntityModel[];
  activeUser: ILayoutUser;
  layoutDomain: IMainLayoutDomainStore;
}

export class ProjectInfoDataMapper {
  mapProject(
    mapData: IProjectMapData,
    dependencies: {
      allStatusesValues: IStatusValueModel[]
    },
    projectNewMessagesCounterData?: {
      applicationId: string;
      newMessages: number;
      projectId: string;
    }[],
  ): IProjectView {
    const { project, projectApplications, projectApplicationsData } = mapData;
    const userRole = project.rolesMap?.data?.find((roleItem) => roleItem.userId === mapData.activeUser.id);
    const userRoleActions = mapData.projectRolesList
      .find((role) => role.id === userRole?.roleId)
      ?.permissions.data.filter((permission) => permission.isGranted === true)
      .map((permission) => permission.action);

    const getIsCanEditProject = (projectId: string) => {
      const editProjectPermission = mapData.layoutDomain.userHaveAnyAccessToEditProject(project.id);
      if (project?.isUnitedWithApplication) {

        const isCanEditProject = editProjectPermission.isCanEditFields || editProjectPermission.isCanEditTeam;
        const isCanEditApplication = mapData.layoutDomain.userHaveAnyAccess(
          [UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-application']],
          { id: projectId, permissions: [UserProjectRoleAction.editApplication] },
        );

        return isCanEditProject && isCanEditApplication;
      } else {
        const isCanEditProject = editProjectPermission.isCanEditFields || editProjectPermission.isCanEditTeam;

        return isCanEditProject;
      }
    };

    const isHaveAccessToEditProject = getIsCanEditProject(project.id || '');

    const isHaveAccessToAddApplication =
      userRoleActions?.includes(UserProjectRoleAction.creteApplication) ||
      mapData.activeUser.allowedPermissions.includes(UserSystemRoleModelPermissionMap['global-allow-any']) ||
      mapData.activeUser.allowedPermissions.includes(
        UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-application'],
      );

    const projectNewMessagesData =
      projectNewMessagesCounterData?.filter((projectCounterData) => projectCounterData.projectId === project.id);
    const applications = this.mapApplications(projectApplications, projectApplicationsData, projectNewMessagesData);
    const applicationStatusesValues: { statusId: string; values: IStatusValueModel[] }[] =
      applications.reduce((applicationStatusesValues, application) => {
        application.data?.statusesIds?.forEach(statusId => {
          if (applicationStatusesValues.findIndex(data => data.statusId === statusId) === -1) {
            applicationStatusesValues.push({
              statusId,
              values: [],
            });
          }
        });

        application.data?.statusesValuesIds?.forEach(statusValueId => {
          const valueEntity = dependencies.allStatusesValues.find(value => value.id === statusValueId);
          if (valueEntity) {
            const applicationStatusIndex = applicationStatusesValues.findIndex(data => data.statusId === valueEntity.statusId);
            if (applicationStatusIndex !== -1) {
              const applicationStatusValueIndex = applicationStatusesValues[applicationStatusIndex].values.findIndex(data => data.id === valueEntity.id);
              if (applicationStatusValueIndex === -1) {
                applicationStatusesValues[applicationStatusIndex].values.push(valueEntity);
              }
            }
          }
        });
        return applicationStatusesValues;
      }, [] as { statusId: string; values: IStatusValueModel[] }[]);
    return {
      id: project.id || '',
      applications,
      name: project.name || '',
      description: project.comment || '',
      team: this.mapTeamMembers(mapData),
      activeUserCanAddApplications: isHaveAccessToAddApplication,
      activeUserCanEditProject: isHaveAccessToEditProject,
      applicationStatusesValues: applicationStatusesValues,
      isUnitedWithApplication: project.isUnitedWithApplication as any,
      unitedApplicationId: project.unitedApplicationId as any,
    };
  }

  private mapApplications(
    projectApplications: IApplicationModel[],
    projectApplicationsData: IApplicationDataModel[],
    applicationCounterData?: { applicationId: string; newMessages: number }[] | null,
  ): IProjectApplicationView[] {
    return projectApplications.map((application) => {
      const applicationData = projectApplicationsData.find(data => data.applicationId === application.id) as IApplicationDataModel;
      const newMessagesCount =
        applicationCounterData?.find((counterData) => counterData.applicationId === application.id)?.newMessages || 0;
      return this.mapProjectApplication(application, applicationData, newMessagesCount);
    });
  }

  private mapProjectApplication = (
    projectApplication: IApplicationModel,
    projectApplicationData: IApplicationDataModel,
    newMessagesCount: number,
  ): IProjectApplicationView => {
    const projectApplicationNameWithStatus = (projectApplication: IApplicationModel) => {
      if (ConfigManager.getConfig().ui.application.acceptWithVersion) {
        if (projectApplication.acceptStatus === ApplicationAcceptStatus.draft) {
          return `${projectApplication.name} (черновик)`;
        }
        if (projectApplication.acceptStatus === ApplicationAcceptStatus.review) {
          return `${projectApplication.name} (на проверке)`;
        }
        const fullVersion = projectApplication.versionNumber?.split('.');
        if (fullVersion) {
          const versionWithoutAcceptanceTests = [fullVersion[0], fullVersion[1]].join('.');
          if (projectApplication.acceptStatus === ApplicationAcceptStatus.accepted) {
            return `${projectApplication.name} (версия ${versionWithoutAcceptanceTests})`;
          } else {
            return `${projectApplication.name} (версия ${projectApplication.versionNumber})`;
          }
        }
      }
      return projectApplication.name || '';
    };

    return {
      id: projectApplication.id || '',
      name: projectApplicationNameWithStatus(projectApplication),
      newMessagesCount: newMessagesCount,
      stage: this.translateStageName(projectApplication.state),
      isUnitedWithProject: projectApplication.isUnitedWithProject as any,
      projectId: projectApplication.projectId as any,
      data: projectApplicationData,
    };
  };

  private translateStageName(stage: any) {
    const stageToNameMap: any = {
      [IApplicationState.preDevelopment]: 'Формирование требований',
      [IApplicationState.development]: 'Разработка',
      [IApplicationState.postDevelopment]: 'Готова к испытаниям',
      none: '-',
    };

    return stageToNameMap[stage] || stageToNameMap.none;
  }

  private mapTeamMembers(mapData: IProjectMapData): IProjectTeamMemberView[] {
    return (
      mapData.project.rolesMap?.data?.map((linkData): IProjectTeamMemberView => {
        const user = mapData.projectUsers.find((user) => user.id === linkData.userId);
        const role = mapData.projectRolesList.find((role) => role.id === linkData.roleId);
        return {
          id: user?.id || '',
          fullName: `${user?.displayName}`,
          roleName: role?.name || '',
          login: user?.login || '',
          email:user?.email || ''
        };
      }) || []
    );
  }
}
