import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { SpecificationCategoryAdminTable } from '../../specification/category/SpecificationCategoryAdminTable';
import { SpecificationEntityAdminTable } from '../../specification/entity/SpecificationEntityAdminTable';
import { ISettingsItem } from '../ISettingsItem';

export const SpecificationSettingsItem: ISettingsItem = {
  id: 'specification',
  routers: [
    {
      key: 'specification-entity-settings',
      path: '/settings/specification/entity',
      pageTitle: 'Характеристики',
      component: SpecificationEntityAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-specifications-read']],
    },
    {
      key: 'specification-category-settings',
      path: '/settings/specification/category',
      pageTitle: 'Категории характеристик',
      component: SpecificationCategoryAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-specifications-read']],
    },
  ],
  settingsItem: {
    key: 'specification',
    title: 'settings.systemSpecificationsTitle',
    buttons: [
      {
        title: 'settings.categories',
        url: `/settings/specification/category`,
        permissions: [UserSystemRoleModelPermissionMap['settings-specifications-read']],
      },
      {
        title: 'settings.values',
        url: `/settings/specification/entity`,
        permissions: [UserSystemRoleModelPermissionMap['settings-specifications-read']],
      },
    ],
    permissions: [UserSystemRoleModelPermissionMap['settings-specifications-read']],
    orderIndex: 100,
    infoText:
      'settings.systemSpecificationsDes',
  },
};
