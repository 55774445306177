import React, { useContext } from 'react';

import { observer } from 'mobx-react';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { ICoreSearchAudit } from '../../../service/coreCommon/common/audit/ICoreSearchAudit';
import { IApplicationModel } from '../../../service/application/entity/IApplicationModel';
import { ContainerContext } from '../../../common/container/ContainerContext';
import { Version } from '../../../view/user/page/application/info/store/ApplicationDetailUI';
import { MenuItem } from '@mui/material';
import { generateName } from '../../../view/user/page/application/info/parts/report/ApplicationInfoReport';

export const ExtendedRequirementsDownload = observer((
  {
    domain,
    format,
    audit,
    isNeedColor,
    popupState,
    application
  }: {
    domain: ApplicationDetailDomain,
    format: string,
    audit?: ICoreSearchAudit,
    isNeedColor?: boolean,
    popupState: any,
    application?: IApplicationModel,
  }) => {
  const container = useContext(ContainerContext);
  const extendedGenerateName = container.get(generateName);

  const reportBtnMinor = ' версии ' + domain.ui.getVersionWithStatus({versionType: Version.minor, application});
  const reportNameMinor = '_версия ' + domain.ui.getVersionWithStatus({versionType: Version.minor, application});

  const versionNumber = application?.versionNumber;
  return (
    <MenuItem
      onClick={() => {
        domain.downloadApplicationRequirementsReport({
          format,
          audit,
          isNeedColor,
          reportName: `${extendedGenerateName({ domain, reportName: 'Требования ИБ' })}${reportNameMinor}`,
          versionNumber: versionNumber || ''
        });
        popupState.close();
      }}
    >
      Требования ({format}){reportBtnMinor}
    </MenuItem>
  )
})