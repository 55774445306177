import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { useI18n } from '../../../../../../../../application/language/useI18n';
import { IPrimitiveStore } from '../../../../../../../../common/store/interface/IPrimitiveStore';
import { replaceValueToName } from '../../../unit/parts/practice/store/Languages';

export interface IPassingPracticeStart {
  languages: string[];
  pickingLang: (name: string) => void;
  pickedLang: IPrimitiveStore<string>;
}
export const PassingPracticeStart = observer(({ languages, pickingLang, pickedLang }: IPassingPracticeStart) => {
  const { translate } = useI18n();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '64px', alignItems: 'center', gap: '16px' }}>
      <Typography variant="h6">{translate('phrases.choosePracticeLang')}</Typography>
      <Box sx={{ display: 'flex', gap: '4px', maxWidth: '500px' }}>
        {languages?.map((lang, index) => {
          return (
            <Box
              key={index}
              onClick={() => {
                pickingLang(lang);
              }}
              sx={{
                padding: '8px 16px',
                borderRadius: '16px',
                background: lang === pickedLang?.value ? 'rgba(25, 118, 210, 0.6)' : 'rgba(255, 255, 255, 0.08)',
                cursor: 'pointer',
              }}
            >
              {replaceValueToName(lang)}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});
