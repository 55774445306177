import React from 'react';

import { UnitNotPassedIcon } from '../../icons/UnitNotPassedIcon';
import { UnitPassedIcon } from '../../icons/UnitPassedIcon';
import { UnitStarIcon } from '../../icons/UnitStarIcon';
import { UnitStatus } from '../models/unitStatus';
import { IUnitStatusNode } from '../models/unitStatusNode';

export const UNIT_LIST_ITEMS_STYLES: Record<UnitStatus, IUnitStatusNode> = {
  passed: {
    baseColor: 'rgba(46, 125, 50, 0.12)',
    color: '#66BB6A',
    borderColor: 'rgba(46, 125, 50, 0.18)',
    progressColor: '#2E7D32',
    hoverColor: 'rgba(46, 125, 50, 0.18)',
    icon: <UnitPassedIcon />,
  },
  failed: {
    baseColor: '#F443361F',
    color: '#E57373',
    borderColor: '#F443362F',
    progressColor: '#D32F2F',
    hoverColor: '#F443362F',
    icon: <UnitNotPassedIcon />,
  },
  new: {
    baseColor: 'rgba(144, 202, 249, 0.03)',
    color: '#FFFFFF',
    borderColor: 'rgba(144, 202, 249, 0.08)',
    progressColor: '#90CAF9',
    hoverColor: 'rgba(144, 202, 249, 0.08)',
    icon: <UnitStarIcon />,
  },
};

export const getUnitStatus = (isPassed) => {
  if (isPassed === true) {
    return UnitStatus.Passed;
  } else if (isPassed === false) {
    return UnitStatus.Failed;
  }

  return UnitStatus.New;
};
