import {
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react';

import { StatusValueColor } from '../../../../../admin/page/status/value/color/StatusValueColor';
import { Colors } from '../../../../../design/color/Colors';
import { ListItemLink, ListItemLinkColor } from '../../../../../design/list/ListItemLink';
import { useApplicationRequirementStyle, widthInPercents } from '../../../application/ApplicationRequirementStyle';
import { IProjectApplicationView, IProjectApplicationViewStatusData } from '../store/model/IProjectApplicationView';
import { IProjectView } from '../store/model/IProjectView';
import { ProjectInfoDomain } from '../store/ProjectInfoDomain';
import { ContainerContext } from '../../../../../../common/container/ContainerContext';
import { AppExtension } from './AppExtension';
import { TransferApplicationType } from './TransferTypeEnum';
import { useI18n } from '../../../../../../application/language/useI18n';

export interface IProjectApplicationsTableProperties {
  applications: IProjectApplicationView[];
  project: IProjectView;
  domain: ProjectInfoDomain;
}

export const ProjectApplicationsTable = observer(
  ({ applications, project, domain }: IProjectApplicationsTableProperties) => {
    const isCanTransferApplication = domain.isUserCanTransferApplication();
    const {translate} = useI18n();
    const tableClasses = useTableStyles();
    const { TableTitleExtension } = useContext(ContainerContext).get(AppExtension);
    return (
      <Fragment>
        <TableContainer
          style={{
            marginLeft: '-10px',
          }}
        >
          <Table className={tableClasses.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell className={tableClasses.applicationHeader}>{translate('projectPage.system')}</TableCell>
                <TableCell className={tableClasses.header}></TableCell>
                {/* <TableCell className={tableClasses.header}>Этап</TableCell> */}
                {domain.ui.tableSettings.value.showRequirement && (
                  <TableCell className={tableClasses.header} align='right'>
                   {translate('projectPage.requirements')}
                  </TableCell>
                )}
                {<TableTitleExtension />}
                <TableCell className={tableClasses.header}></TableCell>
                {domain.ui.tableSettings.value.showStatuses && (
                  <TableCell className={tableClasses.header} align='left'>
                    {project.applicationStatusesValues.map((status, index) => {
                      const fragment = status.values
                        .filter((item) => item.isShowInStats)
                        .map((statusValue) => {
                          const color = StatusValueColor.find((item) => item.id === statusValue.colorId);
                          return (
                            <Fragment>
                              <Chip
                                size='small'
                                style={{
                                  borderRadius: 0,
                                  background: Colors.rgbaOpacityTemplate(color?.rgbaTemplate, '0.6'),
                                }}
                                key={statusValue.id}
                                label={statusValue.name}
                              />
                            </Fragment>
                          );
                        });
                      if (index !== project.applicationStatusesValues.length && fragment.length > 0) {
                        fragment.push(<br />);
                      }
                      return fragment;
                    })}
                  </TableCell>
                )}
                {!project.isUnitedWithApplication && isCanTransferApplication
                  && <TableCell align='center'>{translate('phrases.actions')}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {applications.map((projectApplication) => {
                return (
                  <ApplicationRow key={projectApplication.id} domain={domain} projectApplication={projectApplication} />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  },
);

interface StatusRowProperties {
  projectApplication: IProjectApplicationView;
  domain: ProjectInfoDomain;
}

const ApplicationRow = observer(({ projectApplication, domain }: StatusRowProperties) => {
  const {translate} = useI18n();
  // const totalStatuses = projectApplication.statusValueData.reduce((total, statusItem) => total + statusItem.count, 0);
  const createProjectApplicationLink = (id: string) => {
    return `/application/${id}/info`;
  };

  const openTransferModalWindow = (projectApplication) => {
    domain.ui.isOpenTransferModal.setValue(true);
    domain.ui.transferApplication.setEntity(projectApplication);
  };

  const isCanTransferApplication = domain.isUserCanTransferApplication();
  const rowClasses = useTableRowStyles();
  const { TableCellExtension } = useContext(ContainerContext).get(AppExtension);
  return (
    <TableRow key={projectApplication.id}>
      <TableCell className={rowClasses.titleCell}>
        <Grid container className={rowClasses.titleContainer}>
          <Grid item>
            <ListItemLink
              color={ListItemLinkColor.light}
              primaryText={projectApplication.name}
              url={createProjectApplicationLink(projectApplication.id)}
              dataCy={`${projectApplication.name}-system-info`}
              primaryTextClassName={rowClasses.primaryTitleText}
              containerClassName={rowClasses.titleText}
            />
          </Grid>
          {projectApplication.newMessagesCount > 0 && (
            <Grid item>
              <Typography variant='caption'>({projectApplication.newMessagesCount})</Typography>
            </Grid>
          )}
        </Grid>
      </TableCell>

      <TableCell className={rowClasses.spaceRow1} component='th' scope='row'></TableCell>
      {domain.ui.tableSettings.value.showRequirement && (
        <TableCell className={rowClasses.requirementCell} component='th' scope='row'>
          {projectApplication.data?.requirementCount || 0}
        </TableCell>
      )}
      <TableCellExtension domain={domain} projectApplication={projectApplication} />
      <TableCell className={rowClasses.spaceRow2} component='th' scope='row'></TableCell>
      {domain.ui.tableSettings.value.showStatuses && (
        <TableCell className={rowClasses.statusCell} align='left'>
          <Grid container direction='row'>
            {projectApplication.data?.statusesStatistic?.valuesCount?.map((statusData => {
              return statusData.values
                .filter((item) => {
                  const value = domain.ui.allStatusesValues.list.find((statusValue) => statusValue.id === item.statusValueId);
                  return value?.isShowInStats;
                })
                .map((statusValue) => {
                  const totalStatusesCount = statusData.values
                    .reduce((total, statusItem) => total + statusItem.requirementsWithThisValueCount, 0);

                  const statusValueEntity = domain.ui.allStatusesValues.list
                    .find((item) => item.id === statusValue.statusValueId);
                  return (
                    <StatusItem
                      key={statusValueEntity?.name}
                      statusItem={{
                        id: statusValueEntity?.id || '',
                        name: statusValueEntity?.name || '',
                        colorId: statusValueEntity?.colorId || '',
                        count: statusValue.requirementsWithThisValueCount,
                      }}
                      totalStatusesCount={totalStatusesCount}
                    />
                  );
                });
            }))}
          </Grid>

          {/* <Grid container direction="row">
          {projectApplication.statusValueData.map((status) => (
            <StatusItem key={status.name} statusItem={status} totalStatusesCount={totalStatuses} />
          ))}
        </Grid> */}
        </TableCell>
      )}
      <TableCell className={rowClasses.activityCell}>
        <Grid container>
          <Grid item>
            {!projectApplication.isUnitedWithProject && isCanTransferApplication && (
              <>
                <Tooltip title={translate('projectPage.moveSystem')}>
                  <Button
                    onClick={() => {
                      domain.ui.transferApplicationType.setValue(TransferApplicationType.Transfer);
                      openTransferModalWindow(projectApplication);
                    }}
                  >
                    {<ArrowBackIcon />}
                  </Button>
                </Tooltip>
                <Tooltip title={translate('projectPage.copySystem')}>
                  <Button
                    onClick={() => {
                      domain.ui.transferApplicationType.setValue(TransferApplicationType.Copy);
                      openTransferModalWindow(projectApplication);
                    }}
                  >
                    {<ContentCopyIcon />}
                  </Button>
                </Tooltip>
              </>
            )}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
});

interface StatusItemProperties {
  statusItem: IProjectApplicationViewStatusData;
  totalStatusesCount: number;
}

const StatusItem = observer(({ statusItem, totalStatusesCount }: StatusItemProperties) => {
  const classes = useApplicationRequirementStyle();
  const color = StatusValueColor.find((item) => item.id === statusItem.colorId);
  return (
    <Fragment>
      {Number(statusItem.count) > 0 && (
        <Grid
          item
          className={`${classes.statusItem}`}
          style={{
            backgroundColor: Colors.rgbaOpacityTemplate(color?.rgbaTemplate, '0.6'),
            width: `${widthInPercents(statusItem.count, totalStatusesCount)}%`,
          }}
          data-cy={`${statusItem.name}-req-status-filter`}
        >
          {' '}
          {statusItem.count}
        </Grid>
      )}
    </Fragment>
  );
});

export const useTableStyles = makeStyles((theme: Theme) => ({
  table: {
    width: 'auto',
    tableLayout: 'auto',
  },
  header: {
    fontWeight: 'bold',
  },
  applicationHeader: {
    fontWeight: 'bold',
    paddingLeft: '30px',
  },
  actionHeader: {
    paddingRight: '12px',
    fontWeight: 'bold',
  },
  stateHeader: {
    width: '240px',
    fontWeight: 'bold',
  },
}));

export const useTableRowStyles = makeStyles((theme: Theme) => {
  const cellStyle = {
    borderBottom: 'none',
    verticalAlign: 'top',
  };

  return {
    titleText: {},
    primaryTitleText: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '20px',
      // lineHeight: '36px',
    },
    titleContainer: {
      flexWrap: 'nowrap',
    },
    secondaryTitleText: {},
    titleCell: {
      maxWidth: '720px',
      ...cellStyle,
    },
    spaceRow1: {
      maxWidth: '40px',
      minWidth: '40px',
      ...cellStyle,
    },
    stageCell: {
      maxWidth: '180px',
      minWidth: '130px',
      paddingTop: '35px',
      ...cellStyle,
    },
    requirementCell: {
      maxWidth: '50px',
      minWidth: '50px',
      paddingTop: '35px',
      textAlign: 'end',
      ...cellStyle,
    },
    spaceRow2: {
      maxWidth: '50px',
      minWidth: '50px',
      ...cellStyle,
    },
    statusCell: {
      ...cellStyle,
    },
    activityCell: {
      ...cellStyle,
    },
  };
});
