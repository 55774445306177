import { Close } from '@mui/icons-material';
import { Button, Divider, FormControlLabel, Grid, IconButton, Switch, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { FormModalContainer } from '../../../../../../view/design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../view/design/form/FormActions';
import { FormAutocomplete } from '../../../../../../view/design/form/FormAutocomplete';
import { FormTextField } from '../../../../../../view/design/form/FormTextField';
import { RiskManagerQuestionFieldInputTypeArray } from '../../../../service/question/field/RiskManagerQuestionFieldInputType';
import { RiskManagerQuestionFieldRelationTypeArray } from '../../../../service/question/field/RiskManagerQuestionFieldRelationType';
import { RiskManagerQuestionFieldFormDomain } from './RiskManagerQuestionFormDomain';

export interface IRiskManagerQuestionFieldFormProperties {
  domain: RiskManagerQuestionFieldFormDomain;
}

export const RiskManagerQuestionFieldForm = observer(({ domain }: IRiskManagerQuestionFieldFormProperties) => {
  const classes = useStyles();
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <NameField domain={domain} />
      <DescriptionField domain={domain} />
      <RelationTypeField domain={domain} />
      <InputTypeField domain={domain} />

      <SortIndexField domain={domain} />

      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const NameField = observer(({ domain }: { domain: RiskManagerQuestionFieldFormDomain }) => {
  return (
    <FormTextField
      required={true}
      multiline={false}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
    />
  );
});

const DescriptionField = observer(({ domain }: { domain: RiskManagerQuestionFieldFormDomain }) => {
  return (
    <FormTextField
      required={false}
      multiline={true}
      rows={4}
      label={'Описание'}
      value={domain.ui.model.entity.description || ''}
      onChange={domain.getUpdateFieldHandler('description')}
      errorMessage={domain.getValidationErrorFor('description')?.message}
    />
  );
});

const RelationTypeField = observer(({ domain }: { domain: RiskManagerQuestionFieldFormDomain }) => {
  const answerPresetsValues = [
    ...RiskManagerQuestionFieldRelationTypeArray.map((relationType) => ({
      value: relationType,
      name: domain.dataTableDomain.ui.translateRelationType(relationType),
    })),
  ];
  return (
    <FormAutocomplete
      values={answerPresetsValues}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.relationType = newValue?.value;
      }}
      key="relationType"
      errorMessage={domain.getValidationErrorFor('relationType')?.message}
      selected={domain.ui.model.entity.relationType}
      label="Тип Связи"
    />
  );
});

const InputTypeField = observer(({ domain }: { domain: RiskManagerQuestionFieldFormDomain }) => {
  const answerPresetsValues = [
    ...RiskManagerQuestionFieldInputTypeArray.map((inputType) => ({
      value: inputType,
      name: domain.dataTableDomain.ui.translateInputType(inputType),
    })),
  ];
  return (
    <FormAutocomplete
      values={answerPresetsValues}
      onChangeSelect={(newValue: any) => {
        domain.ui.model.entity.inputType = newValue?.value;
      }}
      key="inputType"
      errorMessage={domain.getValidationErrorFor('inputType')?.message}
      selected={domain.ui.model.entity.inputType}
      label="Тип поля"
    />
  );
});

const SortIndexField = observer(({ domain }: { domain: RiskManagerQuestionFieldFormDomain }) => {
  return (
    <FormTextField
      type="text"
      key="sortIndex"
      required={true}
      label={'Порядок сортировки'}
      value={domain.ui.model.entity.sortIndex?.toString()}
      onChange={domain.getUpdateIntegerFieldHandler('sortIndex')}
      errorMessage={domain.getValidationErrorFor('sortIndex')?.message}
      inputProps={{ maxLength: 9 }}
    />
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  answerTitleContainer: {
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  answerContainer: {
    // padding: theme.spacing(2),
    height: '500px',
    width: '100%',
    // overflow: 'scroll',
    overflow: 'scroll',
    overflowY: 'scroll',
  },
}));
