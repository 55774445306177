import { Box, Divider, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { useI18n } from '../../../../../../../../application/language/useI18n';
import { PassingQuestion } from './PassingQuestion';
import { IPassingQuizDomain } from './store/IPassingQuizDomain';

interface IPassingQuizLongreadProps {
  rootDomain: IPassingQuizDomain;
}

export const PassingQuizLongread = observer(({ rootDomain }: IPassingQuizLongreadProps) => {
  const { translate, i18n } = useI18n();
  function getBallDeclension(num) {
    if (num === 1) {
      return i18n.language === 'en' ? 'point' : 'балл';
    }
    if (num >= 2 && num <= 4) {
      return i18n.language === 'en' ? 'points' : 'балла';
    }
    return i18n.language === 'en' ? 'points' : 'баллов';
  }
  function determineQuizResult() {
    const correctAnswers = rootDomain.ui.scoreDiff.value.userCorrectAnswers;
    const totalQuestions = rootDomain.ui.scoreDiff.value.totalQuestionsAmount;
    if (correctAnswers === totalQuestions || correctAnswers === totalQuestions - 1) {
      return 'success';
    } else if (correctAnswers >= totalQuestions * 0.5) {
      return 'neutral';
    } else {
      return 'failure';
    }
  }
  return (
    <>
      {rootDomain.ui && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography id="quiz_title" variant="h3" sx={{ marginBottom: '30px' }}>
            {translate('phrases.quiz')}
          </Typography>
          {!rootDomain.ui.isEbaleToRePass.value && (
            <Box
              sx={{
                borderRadius: '8px',
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                background: 'rgba(68, 79, 85, 0.3)',
                marginRight: '24px',
                marginBottom: '40px',
              }}
            >
              <Typography variant="h3">
                {translate('passing.quizResultMessage', {
                  title: rootDomain.ui.results.entity[determineQuizResult()].title,
                  correctAnswers: rootDomain.ui.scoreDiff.value.userCorrectAnswers,
                  totalQuestions: rootDomain.ui.scoreDiff.value.totalQuestionsAmount,
                })}{' '}
                {Math.round(rootDomain.ui.scoreDiff.value.score)}{' '}
                {getBallDeclension(Math.round(rootDomain.ui.scoreDiff.value.score))}.
              </Typography>
              <Typography variant="body1">{rootDomain.ui.results.entity[determineQuizResult()].subtitle}</Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {rootDomain.ui.passingQuestionList.list.map((question, index) => {
              const currentAnswer = rootDomain.ui.currentUnitResult.value.quizResult.questions.filter((answer) => {
                return answer.pseudoId === question.pseudoId;
              })[0];
              return (
                <div key={question.pseudoId}>
                  <PassingQuestion index={index} question={question} answer={currentAnswer} rootDomain={rootDomain} />
                  <Divider />
                </div>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
});
