import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IRootPublicService } from '../../../../../../../service/public/RootPublicService';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { IRootAdminService } from '../../../../../../../service/RootAdminService';
import { CommonFilterDomain } from '../../../../../../common/requirement/filter/store/CommonFilterDomain';
import { RequirementEntityAdminDomain } from '../RequirementEntityAdminDomain';
import { RequirementEntityAdminModel } from '../RequirementEntityAdminModel';

export class RequirementEntityAdminFilter extends CommonFilterDomain {
  private sourceEntities: RequirementEntityAdminModel[] = [];

  constructor(
    rootService: IRootPublicService | IRootAdminService,
    private rootDomain: RequirementEntityAdminDomain,
    public isOpenFilter = injectPrimitive<boolean | undefined>(true),
    public filterSearchTerm = injectPrimitive<string>(''),
    public filteredTags = injectPrimitive<any>(null),
    public filteredSpecifications = injectPrimitive<any>(null),
  ) {
    super(rootService);
    this.setFilters();
  }

  setFilters() {
    this.addExtensionFunctionality(
      'textSearch',
      (requirements: IRequirementModel[]) =>
        this.rootDomain.searchRequest(this.rootDomain.ui.rows.searchTerm.value || ''),
      'filters',
    );
    this.addExtensionFunctionality(
      'adminTableSpecification',
      (requirements: IRequirementModel[]) => this.filterBySpecifications(requirements),
      'filters',
    );
    this.addExtensionFunctionality(
      'adminTableTags',
      (requirements: IRequirementModel[]) => this.filterByTags(requirements),
      'filters',
    );
    this.addExtensionFunctionality(
      'adminRequirementCreationType',
      (requirements: IRequirementModel[]) => this.filterByRequirementCreationType(requirements),
      'filters',
    );
  }

  protected async loadStatuses() { }
  protected async loadVendors() { }

  protected async loadData() {
    await super.loadData();
    this.ui.isShowTextSearch.setValue(false);
  }

  async setSettings() {
    super.setSettings();
    this.ui.isShowRequirementCreationTypeSearch.setValue(true);
    this.ui.isShowStatusesSearch.setValue(false);
    this.ui.isShowVendorsSearch.setValue(false);
  }

  getRequirements(): any[] {
    const defaultRequirements = super.getRequirements();
    if (this.sourceEntities.length === 0) {
      this.sourceEntities = this.rootDomain.ui.rows.entities.list;
    }
    return this.sourceEntities || defaultRequirements;
  }

  setRequirements(requirements: IRequirementModel[]) {
    if (requirements.length) {
      this.rootDomain.ui.noRowsLabel.setValue('');
    } else {
      this.rootDomain.ui.noRowsLabel.setValue('Нет данных по вашим критериям поиска');
    }
    super.setRequirements(requirements);
    this.rootDomain.ui.rows.filteredEntities.setList(requirements);
  }

  filterBySpecifications(data: RequirementEntityAdminModel[]): RequirementEntityAdminModel[] {
    const searchSpecificationsIds = this.ui.searchSpecificationsIds.value;
    if (searchSpecificationsIds.length > 0) {
      const specificationsByCategories: any = {};
      searchSpecificationsIds.forEach((specificationId) => {
        const searchSpecification = this.ui.specifications.list.find(
          (specification) => specification.id === specificationId,
        );
        if (searchSpecification?.categoryId) {
          if (!specificationsByCategories[searchSpecification?.categoryId]) {
            specificationsByCategories[searchSpecification?.categoryId] = [];
          }
          specificationsByCategories[searchSpecification?.categoryId].push(specificationId);
        }
      });

      data = data.filter((requirement) => {
        for (const categoryId in specificationsByCategories) {
          const categorySpecifications = specificationsByCategories[categoryId];
          const sameSpecificationsInCategory = requirement.specificationsIds?.filter((specificationId) =>
            categorySpecifications.includes(specificationId),
          );

          if (sameSpecificationsInCategory?.length === 0) {
            return false;
          }
        }
        return true;
      });
    }
    return data;
  }

  filterByRequirementCreationType(data: RequirementEntityAdminModel[]): RequirementEntityAdminModel[] {
    const searchRequirementCreationTypes = this.ui.searchRequirementCreationTypes.value;
    if (searchRequirementCreationTypes.length > 0) {
      return data.filter(requirement =>
        searchRequirementCreationTypes.some(creationType => requirement.creationType === creationType)
      );
    }
    return data;
  }

  filterByTags(data: RequirementEntityAdminModel[]): RequirementEntityAdminModel[] {
    const searchTagsIds = this.ui.searchTagsIds.value;
    if (searchTagsIds.length > 0) {
      const tagsByCategories: any = {};
      searchTagsIds.forEach((tagId) => {
        const searchTag = this.ui.tags.list.find((tag) => tag.id === tagId);
        if (searchTag?.categoryId) {
          if (!tagsByCategories[searchTag?.categoryId]) {
            tagsByCategories[searchTag?.categoryId] = [];
          }
          tagsByCategories[searchTag?.categoryId].push(tagId);
        }
      });

      data = data.filter((requirement) => {
        for (const categoryId in tagsByCategories) {
          const categoryTags = tagsByCategories[categoryId];
          const sameTagsInCategory = requirement.tagsIds?.filter((tagId) => categoryTags.includes(tagId));

          if (sameTagsInCategory?.length === 0) {
            return false;
          }
        }
        return true;
      });
    }

    return data;
  }

  searchFiltersRequest = (searchTerm?: string) => {
    this.filterSearchTerm.setValue(searchTerm || '');
    const newTags = this.ui.tags.list.filter((item) => {
      return item.name.toLowerCase().includes(this.filterSearchTerm.value.toLowerCase());
    });
    const newSpecifications = this.ui.specifications.list.filter((item) => {
      return item.name.toLowerCase().includes(this.filterSearchTerm.value.toLowerCase());
    });

    this.filteredTags.setValue([...newTags]);
    this.filteredSpecifications.setValue([...newSpecifications]);
  };
}
