import { ArrowDownward, ArrowUpward, DeleteForever, VisibilityOff } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { TranslateText } from '../../../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../../../application/language/useI18n';
import { ITheoryBlock } from '../../../../../../service/unit/interface/IUnitTheory';
import { IHeading } from '../../../../../../service/unit/interface/theoryBlocks/IHeading';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';

export interface HeadingProperties {
  data: ITheoryBlock<IHeading>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
}
export const Heading1Component = observer(({ data: { data, pseudoId }, onDelete, onUp, onDown }: HeadingProperties) => {
  const { translate } = useI18n()
  return (
    <Box
      key={pseudoId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        padding: '16px 42px ',
        '&:hover .action': { visibility: 'visible' },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', alignItems: 'flex-end' }}>
        <Typography sx={{ marginRight: 'auto' }}>
          <TranslateText i18nKey='createUnit.heading1' />
        </Typography>
        <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
          <DeleteForever
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onDelete();
            }}
          />
          <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => { }} />
          <ArrowUpward
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onUp();
            }}
          />
          <ArrowDownward
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onDown();
            }}
          />
        </Box>
      </Box>
      <FormTextFieldStandard
        variant="outlined"
        multiline
        key={pseudoId}
        label={translate('createUnit.text')}
        value={data.text}
        onChange={(newValue) => {
          data.text = newValue;
        }}
      />
    </Box>
  );
});
