import { ArrowDownward, ArrowUpward, DeleteForever, VisibilityOff } from '@mui/icons-material';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { TranslateText } from '../../../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../../../application/language/useI18n';
import { FileType } from '../../../../../../../../service/fileStore/IFileStoreService';
import { IMainLayoutDomainStore } from '../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ITheoryBlock } from '../../../../../../service/unit/interface/IUnitTheory';
import { IFile } from '../../../../../../service/unit/interface/theoryBlocks/IFile';
import { FileUpload } from '../../../../fileUpload/FileUpload';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';
import { VideoUploadDomain } from './upload/VideoUploadDomain';

export interface VideoProperties {
  data: ITheoryBlock<IFile>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
  layoutDomain: IMainLayoutDomainStore;
}

export const VideoComponent = observer(
  ({ data: { data, pseudoId }, onDelete, onUp, onDown, layoutDomain }: VideoProperties) => {
    const { translate } = useI18n();
    const [source, setSource] = useState<string>('link');
    const [error, setError] = useState<string>('');
    const [uploadDomain] = useState<VideoUploadDomain>(new VideoUploadDomain(layoutDomain));
    data.type = 'video';


    return (
      <Box
        key={pseudoId}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          background: 'rgba(0,0,0,0.2)',
          padding: '16px 42px ',
          '&:hover .action': { visibility: 'visible' },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', alignItems: 'flex-end' }}>
          <Typography sx={{ marginRight: 'auto' }}>
            <TranslateText i18nKey='createUnit.video' />
          </Typography>
          <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
            <DeleteForever
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                onDelete();
              }}
            />
            <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => { }} />
            <ArrowUpward
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                onUp();
              }}
            />
            <ArrowDownward
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                onDown();
              }}
            />
          </Box>
        </Box>
        <FormControl sx={{ marginBottom: '32px' }}>
          <RadioGroup
            defaultValue="link"
            onChange={(event) => {
              setSource(event.target.value);
            }}
          >
            <FormControlLabel value="link" control={<Radio />} label={translate('createUnit.link')} />
            <FormControlLabel value="file" control={<Radio />} label={translate('createUnit.file')} />
          </RadioGroup>
        </FormControl>
        {source === 'link' && (
          <FormTextFieldStandard
            variant="outlined"
            label={translate('createUnit.link')}
            placeholder="http://domain/video.mp4"
            helperText={translate('createUnit.linkHelper')}
            value={data.data}
            errorMessage={error}
            onBlur={(event) => {
              const format = event.substr(event.indexOf('.'));
              const isCorrect = uploadDomain.ui.accept.list.includes(format);
              if (!isCorrect) {
                setError(translate('createUnit.linkExtsError'));
              }
            }}
            onChange={(event) => {
              setError('');
              data.data = event;
            }}
          />
        )}
        {source === 'file' && (
          <FileUpload uploadDomain={uploadDomain} onChange={(event) => (data.data = event)} fileType={FileType.VIDEO} />
        )}
        <FormTextFieldStandard
          variant="outlined"
          label={translate('phrases.description')}
          multiline
          value={data.indexName}
          onChange={(event) => {
            data.indexName = event;
          }}
        />
      </Box>
    );
  },
);
