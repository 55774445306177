import { useI18n } from '../../../../../../../../application/language/useI18n';
import { getLocaleByCount } from '../../../../../../utils/localeByCount';

export const useFormatTime = (minutes: number): string => {
  const i18n = useI18n();
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const hourLocales = i18n.i18n.language === 'en' ? ['hour', 'hours', 'hours'] : ['час', 'часа', 'часов'];
  const minuteLocales = i18n.i18n.language === 'en' ? ['minute', 'minutes', 'minutes'] : ['минута', 'минуты', 'минут'];
  let result = '';

  if (hours > 0) {
    result += getLocaleByCount(hours, hourLocales);
  }

  if (remainingMinutes > 0) {
    if (result.length > 0) result += ' ';
    result += getLocaleByCount(remainingMinutes, minuteLocales);
  }

  return result;
};
