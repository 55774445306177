import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { ICommonProperties } from '../../../../../../../common/properties/ICommonProperties';
import { ApplicationDetailDomain } from '../../store/ApplicationDetailDomain';
import { ActiveRequirementsActions } from './ActiveRequirementsActions';
import { ActiveRequirementsTable } from './ActiveRequirementsTable';
import { ActiveRequirementTableFilter } from './ActiveRequirementTableFilter';
import { ContainerContext } from '../../../../../../../common/container/ContainerContext';

export interface IApplicationRequirementContainerProperties extends ICommonProperties {
  domain: ApplicationDetailDomain;
}

export const RequirementVersion = observer(({domain}) => {
  return (<></>);
})

export const ApplicationRequirementContainer = observer(({ domain }: IApplicationRequirementContainerProperties) => {
  const classes = useStyles();
  const container = useContext(ContainerContext);
  const RequirementVersionExtended = container.get(RequirementVersion);

  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={3} style={{ width: '100%' }}>
        <div className={classes.containerElement} />
        <RequirementVersionExtended domain={domain} />
        <div className={classes.table}>
          <ActiveRequirementTableFilter domain={domain.filterDomain} layoutDomain={domain.layoutDomain} />
        </div>
      </Paper>
      <Paper className={classes.root} elevation={3}>
        <div className={classes.containerElement} />
        <div className={classes.actions}>
          <ActiveRequirementsActions domain={domain} />
        </div>
        <div className={classes.containerElement} />
        <div className={classes.table}>
          <ActiveRequirementsTable domain={domain} />
        </div>
        <div className={classes.containerElement} />
      </Paper>
    </React.Fragment>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    padding: '0px',
  },
  containerElement: {
    marginTop: theme.spacing(2),
  },
  table: {
    paddingLeft: '20px',
  },
  actions: {
    paddingLeft: '15px',
  },
}));
