import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { Fragment, ReactNode, useState } from 'react';
import { TranslateText } from '../../../../application/language/TranslateText';

export interface ConfirmModalProperties {
  title: string;
  text: ReactNode;
  onOk?: () => Promise<void>;
  onCancel?: () => Promise<void>;
}

export const ConfirmModal = ({ title, text, onOk, onCancel = async () => { } }: ConfirmModalProperties) => {
  const [isConfirmed, setConfirmed] = useState(false);
  const onConfirm = () => {
    setConfirmed(true);
    if (onOk) {
      onOk();
    }
  }
  return (
    <Fragment>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      {isConfirmed && (
        <Box sx={{ position: 'absolute', bottom: '5px', right: '25px' }}>
          <CircularProgress color='secondary' size={"30px"} />
        </Box>
      )}
      <DialogActions>
        <Button disabled={isConfirmed} onClick={onCancel} color="secondary">
          <TranslateText i18nKey='phrases.cancel' />
        </Button>
        <Button disabled={isConfirmed} onClick={onConfirm} color="secondary" autoFocus>
          <TranslateText i18nKey='phrases.yes' />
        </Button>
      </DialogActions>
    </Fragment>
  );
};
