import { useContext } from 'react';

import { ILocalization } from './ILocalization';
import { I18nContext } from './LocalizationProvider';

export const useI18n = (): ILocalization => {
  const context = useContext(I18nContext);
  if (!context) {
    throw new Error('useI18n must be used within an I18nProvider');
  }
  return context;
};
