import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { useI18n } from '../../../../../../../../../../application/language/useI18n';
import { FormTextFieldStandard } from '../../../../../../../design/form/FormTextFieldStandard';

interface ISingleResultFormProperties {
  item: { title: string; subtitle: string };
  title?: string;
  subtitle?: string;
}

export const SingleResultForm = observer(({ item, title, subtitle }: ISingleResultFormProperties) => {
  const classes = useStyles();
  const { translate } = useI18n();

  return (
    <div className={classes.singleResultContainer}>
      <Typography className={classes.singleResultTitle} variant="h6">
        {title}
      </Typography>
      <Typography className={classes.singleResultSubtitle} variant="body1">
        {subtitle}
      </Typography>
      <div className={classes.singleResultControlsContainer}>
        <FormTextFieldStandard
          multiline
          variant="outlined"
          label={translate('phrases.title')}
          value={item.title}
          onChange={(newValue) => {
            item.title = newValue;
          }}
        />
        <FormTextFieldStandard
          multiline
          variant="outlined"
          label={translate('phrases.subtitle')}
          value={item.subtitle}
          onChange={(newValue) => {
            item.subtitle = newValue;
          }}
        />
      </div>
    </div>
  );
});

const useStyles = makeStyles(() => ({
  singleResultContainer: {
    borderRadius: '8px',
    background: '#444F55',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  singleResultTitle: {
    marginBottom: '8px',
  },
  singleResultSubtitle: {
    marginBottom: '16px',
  },
  singleResultControlsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));
