import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IStatusValueSearch } from '../../../../../../service/status/value/IStatusValueSearch';
import { IStatusValueModel } from '../../../../../../service/status/value/IStatusValueModel';
import { StatusValueAdminUI } from './StatusValueAdminUI'
import { StatusValueModalDomain } from './modal/StatusValueModalDomain'
import { IStatusEntityModel } from '../../../../../../service/status/entity/IStatusEntityModel';

export class CustomAppBar {
  Toolbar = TableHeader;
}
export class StatusValueAdminDomain extends DataTableDomain<
  IStatusValueModel,
  IStatusValueSearch
> {
  public ui: StatusValueAdminUI;
  public statusEntities = injectEntityList<IStatusEntityModel>([])
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.status.value);
    this.ui = new StatusValueAdminUI(this, null);
    this.modalDomain = new StatusValueModalDomain(rootPrivateServices.status.value, layoutDomain, this);
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle.setValue('Статусы');
    this.ui.tableSubtitle.setValue('Значения');
  }

  async loadData() {
    await this.loadDepenicies();
    await super.loadList();
    this.ui.isLoading.setValue(false);
  }
  
  async loadDepenicies() {
    this.ui.isLoading.setValue(true);
    const statusEntities = await this.rootPrivateServices.status.entity.search({ limit: 100000 });
    this.statusEntities.setList(statusEntities.data);
    this.ui.isLoading.setValue(false);
  }

  async setPermissions() {
    await super.setPermissions();

    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-statuses-update']]),
    );
  }
}
